import React from 'react';
import {GridItemWrapper} from './Grid.styles';

export type GridItemProps = {
  cols?: number;
  gutterWidth?: number;
};

const GridItem: React.FC<GridItemProps> = ({
  cols = 1,
  children,
  gutterWidth = 10,
}) => {
  if (!cols) {
    return null;
  }
  return (
    <GridItemWrapper cols={cols} gutterWidth={gutterWidth}>
      {children}
    </GridItemWrapper>
  );
};

export default GridItem;
