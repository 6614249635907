import React, {useCallback, useEffect, useState} from 'react';
import {TabProps} from './Tab';
import * as Styled from './Tabs.styles';

type TabsProps = {
  children: React.ReactElement<TabProps>[];
  currentIndex?: number;
  onCurrentChange?: (currentIndex: number) => void;
};

const Tabs: React.FC<TabsProps> = ({
  children,
  currentIndex,
  onCurrentChange,
}) => {
  const [current, setCurrent] = useState(currentIndex || 0);
  const tabs = React.Children.map(children, (child) => {
    if (child) {
      return {title: child.props.title};
    }
    return null;
  });

  const handleTabClick = useCallback(
    (key) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (typeof onCurrentChange === 'function') {
        onCurrentChange(key);
      } else {
        setCurrent(key);
      }
    },
    [onCurrentChange]
  );

  useEffect(() => {
    if (typeof currentIndex !== 'undefined') {
      setCurrent(currentIndex);
    }
  }, [currentIndex]);

  return (
    <Styled.Wrapper>
      <Styled.TabList>
        {tabs.map((tab, key) => (
          <Styled.TabItem
            className={[current === key ? 'tab-active' : ''].join(' ')}
            onClick={handleTabClick(key)}
            key={key}
          >
            {tab.title}
          </Styled.TabItem>
        ))}
      </Styled.TabList>
      <Styled.CurrentTab>{children[current]}</Styled.CurrentTab>
    </Styled.Wrapper>
  );
};

export default Tabs;
