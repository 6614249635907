import React, {useCallback, useMemo, useState} from 'react';
import Loader from '@components/Loader';
import Page from '@components/Page';
import StatusBadge from '@components/StatusBadge';
import workspaceStatusMap from '@features/workspaces/workspace-status-map';
import {
  WorkspaceModel,
  WorkspaceStatusEnum,
} from '@features/workspaces/workspace.model';
import * as Styled from './WorkspaceDetailsPage.styles';
import instanceTypeList from '@features/workspaces/instanceTypeList';
import GitCloneUrl from '@features/workspaces/components/GitCloneUrl';
import Builds from '@features/workspaces/components/Builds';
import Button from '@components/Button';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import WorkspaceUsers from '@features/workspaces/components/WorkspaceUsers';
import ConfirmationModal from '@modals/ConfirmationModal';
import CompanyConnectors from '@features/companies/components/CompanyConnectors';
import CreateCompanyConnector from '@features/companies/components/CreateCompanyConnector';
import {ConnectorModel} from '@features/connectors/connector.model';
import EditCompanyConnector from '@features/companies/components/EditCompanyConnector';
import WorkspaceEnvironment from '@features/workspaces/components/WorkspaceEnvironment';
import WorkspaceSettings from '@features/workspaces/components/WorkspaceSettings';
import HasPermission from '@features/auth/components/HasPermission';
import {ReactComponent as AirflowLogo} from '@assets/images/airflow-logo.svg';
import WorkspaceMonitoring from '@features/workspaces/components/WorkspaceMonitoring';
import BuildLogsModal from '@features/workspaces/modals/BuildLogsModal';

const instanceTypeMap = instanceTypeList.reduce((accum, instanceType) => {
  accum[instanceType.value] = instanceType;
  return accum;
}, {} as any);

type WorkspaceDetailsPageProps = {
  workspace?: WorkspaceModel;
  onDelete: () => Promise<any>;
  isLoading?: boolean;
  isDeleting?: boolean;
};

const WorkspaceDetailsPage: React.FC<WorkspaceDetailsPageProps> = ({
  workspace,
  onDelete,
  isLoading = false,
  isDeleting = false,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isBuildLogsModalOpen, setIsBuildLogsModalOpen] = useState(false);
  const [executionId, setExecutionId] = useState<string | null>(null);

  const [connectorsView, setConnectorsView] = useState<
    'list' | 'create' | 'edit'
  >('list');
  const [connectorToEdit, setConnectorToEdit] = useState<ConnectorModel | null>(
    null
  );

  const meltanoUrl = useMemo(() => {
    return workspace && workspace.infrastructure
      ? `https://${workspace.infrastructure.meltanoUiUrl}`
      : '';
  }, [workspace]);

  const airflowUrl = useMemo(() => {
    return workspace && workspace.infrastructure
      ? `https://${workspace.infrastructure.airflowUiUrl}`
      : '';
  }, [workspace]);

  const handleShowConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleShowBuildLogsModal = useCallback((executionId: string) => {
    setExecutionId(executionId);
    setIsBuildLogsModalOpen(true);
  }, []);

  const handleCloseBuildLogsModal = useCallback(() => {
    setIsBuildLogsModalOpen(false);
    setExecutionId(null);
  }, []);

  const handleDeleteWorkspace = useCallback(() => {
    handleCloseConfirmationModal();
    return onDelete();
  }, [handleCloseConfirmationModal, onDelete]);

  const handleEditConnector = useCallback((connector: ConnectorModel) => {
    console.log(connector);
    setConnectorToEdit(connector);
    setConnectorsView('edit');
  }, []);

  const handleClearEditState = useCallback(() => {
    setConnectorsView('list');
    setConnectorToEdit(null);
  }, []);

  if (!workspace || isLoading) {
    return <Loader message="Loading project..." />;
  }

  const cloneUrl =
    workspace && workspace.infrastructure
      ? workspace.infrastructure.repositoryUrl
      : '';

  return (
    <Page
      title={
        <Styled.PageTitle>
          <span className="title">{workspace.name}</span>
          <span className="status">
            <StatusBadge
              statusMap={workspaceStatusMap}
              value={workspace.status}
            />
          </span>
        </Styled.PageTitle>
      }
      actions={[
        <HasPermission permissions="delete:projects">
          <Button
            onClick={handleShowConfirmationModal}
            isLoading={isDeleting}
            icon="delete"
            disabled={workspace.status === WorkspaceStatusEnum.CREATING}
            variant="danger"
          >
            Delete
          </Button>
        </HasPermission>,
      ]}
      breadcrumbs={[
        {title: 'Projects', link: '/projects'},
        {title: workspace.name, link: ''},
      ]}
    >
      <ConfirmationModal
        message="Are you sure you want to delete project? This action cannot be undone."
        onDismiss={handleCloseConfirmationModal}
        onConfirm={handleDeleteWorkspace}
        isOpen={isConfirmationModalOpen}
      />
      {executionId && (
        <BuildLogsModal
          executionId={executionId}
          workspaceId={workspace.id}
          isOpen={isBuildLogsModalOpen}
          onClose={handleCloseBuildLogsModal}
        />
      )}
      <Tabs>
        <Tab title="Project details">
          <Styled.Wrapper>
            {workspace.infrastructure && (
              <Styled.Details>
                <Styled.Section>
                  <Styled.PropertyList>
                    <Styled.Property>
                      <Styled.PropertyLabel>Instance type</Styled.PropertyLabel>
                      <Styled.PropertyValue>
                        {
                          instanceTypeMap[workspace.infrastructure.instanceType]
                            .title
                        }
                      </Styled.PropertyValue>
                    </Styled.Property>
                    <Styled.Property>
                      <Styled.PropertyLabel>CPU count</Styled.PropertyLabel>
                      <Styled.PropertyValue>
                        {
                          instanceTypeMap[workspace.infrastructure.instanceType]
                            .cpu
                        }
                        &nbsp;vCPU
                      </Styled.PropertyValue>
                    </Styled.Property>
                    <Styled.Property>
                      <Styled.PropertyLabel>Memory</Styled.PropertyLabel>
                      <Styled.PropertyValue>
                        {instanceTypeMap[workspace.infrastructure.instanceType]
                          .ram / 1024}
                        &nbsp;Gb
                      </Styled.PropertyValue>
                    </Styled.Property>
                  </Styled.PropertyList>
                </Styled.Section>
                {workspace.infrastructure.repositoryUrl && (
                  <Styled.Section>
                    <GitCloneUrl
                      workspaceId={workspace.id}
                      url={cloneUrl || ''}
                    />
                  </Styled.Section>
                )}
                {workspace.status === WorkspaceStatusEnum.RUNNING && (
                  <Styled.Section className="section-no-padding">
                    <Styled.Property className="property-no-padding">
                      <Styled.WorkspaceUrl
                        href={meltanoUrl}
                        target="_blank"
                        className="meltano-branded"
                        rel="noreferrer noopener"
                      >
                        <i className="icon branded-icon icon-meltano-logo" />
                        Meltano Dashboard
                        <i className="icon animated-icon icon-arrow_forward1" />
                      </Styled.WorkspaceUrl>
                    </Styled.Property>
                    <Styled.Property className="property-no-padding">
                      <Styled.WorkspaceUrl
                        href={airflowUrl}
                        target="_blank"
                        className="airflow-branded"
                        rel="noreferrer noopener"
                      >
                        <AirflowLogo
                          height="20"
                          width="20"
                          viewBox="0 0 60 60"
                        />
                        Airflow Dashboard
                        <i className="icon animated-icon icon-arrow_forward1" />
                      </Styled.WorkspaceUrl>
                    </Styled.Property>
                  </Styled.Section>
                )}
              </Styled.Details>
            )}

            <Styled.Builds>
              <Builds
                builds={workspace.builds || []}
                onViewLogs={handleShowBuildLogsModal}
              />
            </Styled.Builds>
          </Styled.Wrapper>
        </Tab>
        <Tab title="Users">
          <WorkspaceUsers workspaceId={workspace.id} />
        </Tab>
        <Tab title="Connectors">
          {connectorsView === 'list' && (
            <CompanyConnectors
              onCreate={() => setConnectorsView('create')}
              onEdit={handleEditConnector}
            />
          )}
          {connectorsView === 'create' && (
            <CreateCompanyConnector
              onGoBack={() => setConnectorsView('list')}
            />
          )}
          {connectorsView === 'edit' && connectorToEdit && (
            <EditCompanyConnector
              connector={connectorToEdit}
              onGoBack={handleClearEditState}
            />
          )}
        </Tab>
        <Tab title="Monitoring">
          <WorkspaceMonitoring workspaceId={workspace.id} />
        </Tab>
        <Tab title="Environment">
          <WorkspaceEnvironment workspaceId={workspace.id} />
        </Tab>
        <Tab title="Settings">
          <WorkspaceSettings workspaceId={workspace.id} />
        </Tab>
      </Tabs>
    </Page>
  );
};

export default WorkspaceDetailsPage;
