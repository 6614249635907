import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import CompleteRegistrationPageDisplay from './CompleteRegistrationPage';
import {completeRegistration, getProfile} from '@features/auth/auth.slice';
import {CompleteRegistrationDto} from '@features/auth/dto/complete-registration.dto';
import {unwrapResult} from '@reduxjs/toolkit';
import {useSelector} from '@app/store';

const connectRedux = connect(null, {completeRegistration, getProfile});

type CompleteRegistrationPageProps = ConnectedProps<typeof connectRedux>;

const CompleteRegistrationPage: React.FC<CompleteRegistrationPageProps> = ({
  completeRegistration,
  getProfile,
}) => {
  const errors = useSelector((state) => state.auth.errors);
  const user = useSelector((state) => state.auth.user);
  const handleSubmit = useCallback(
    (values: CompleteRegistrationDto) => {
      return completeRegistration(values)
        .then(unwrapResult)
        .then(() => {
          window.location = window.location;
        });
    },
    [completeRegistration]
  );

  return (
    <CompleteRegistrationPageDisplay
      onSubmit={handleSubmit}
      errors={errors}
      billingEmail={user.email || ''}
    />
  );
};

export default connectRedux(CompleteRegistrationPage);
