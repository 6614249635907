import styled from 'styled-components';

export const Wrapper = styled.div`
  &.toggle-field-inline {
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 15px;
  color: ${({theme}) => theme.colors.text};

  .toggle-field-inline & {
    margin-left: 5px;
    margin-bottom: 0;
    font-size: 16px;
    order: 1;
  }

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ValueLabel = styled.span`
  display: inline-block;
  margin-left: 10px;
  color: ${({theme}) => theme.colors.text};
`;
