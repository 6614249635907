import ToggleField from '@components/ToggleField';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import React, {useCallback, useState} from 'react';
import * as Styled from './PipelineEnabledToggler.styles';

type PipelineEnabledTogglerProps = {
  workspaceId: string;
  pipeline: PipelineModel;
  onToggle: () => Promise<any>;
};

const PipelineEnabledToggler: React.VFC<PipelineEnabledTogglerProps> = ({
  workspaceId,
  pipeline,
  onToggle,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = useCallback(async () => {
    setIsLoading(true);
    await onToggle();
    setIsLoading(false);
  }, [onToggle]);

  return (
    <Styled.Wrapper>
      <ToggleField
        disabled={isLoading}
        name={pipeline.id}
        value={pipeline.isEnabled}
        onChange={handleToggle}
      />
    </Styled.Wrapper>
  );
};

export default PipelineEnabledToggler;
