import {darken, lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: ${({theme}) => darken(0.35, theme.colors.orange)};
  background-color: ${({theme}) => lighten(0.37, theme.colors.orange)};
  border: 1px solid ${({theme}) => lighten(0.33, theme.colors.orange)};
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 30px;

  .icon {
    margin-right: 5px;
    font-size: 1.2em;
  }
`;
