import {darken} from 'polished';
import styled from 'styled-components';
import {Button as ButtonStyles} from '../Button/Button.styles';

export const Button = styled(ButtonStyles)`
  text-decoration: none;
  &.button-disabled {
    background-color: ${(props) =>
      darken(0.15, props.theme.colors.mediumGray)} !important;
    color: #fff !important;
    font-weight: 400 !important;
    pointer-events: none;
    cursor: not-allowed !important;
  }
  &.button-fullwidth {
    width: 100%;
    display: block;
  }
`;
