import React from 'react';
import {WorkspaceModel} from '@features/workspaces/workspace.model';
import WorkspaceCard from '@features/workspaces/components/WorkspaceCard';
import * as Styled from './WorkspaceList.styles';

type WorkspaceListProps = {
  workspaces: WorkspaceModel[];
};

const WorkspaceList: React.VFC<WorkspaceListProps> = ({workspaces}) => {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {workspaces.map((workspace, key) => (
          <Styled.ListItem key={key}>
            <WorkspaceCard workspace={workspace} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default WorkspaceList;
