import React, {useRef, useState} from 'react';
import {useEffect} from 'react';
import {useCallback} from 'react';
import * as Styled from './FieldHelpMessage.styles';

type FieldHelpMessageProps = {
  message?: React.ReactNode;
};

const FieldHelpMessage: React.VFC<FieldHelpMessageProps> = ({message = ''}) => {
  const [isMessageShown, setIsMessageShown] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsMessageShown((prev) => !prev);
  }, []);

  const handleOutsideClick = useCallback((e: any) => {
    if (wrapperRef && !wrapperRef.current?.contains(e.target)) {
      setIsMessageShown(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  if (!message) {
    return null;
  }

  return (
    <Styled.Wrapper ref={wrapperRef}>
      <Styled.Toggler onClick={handleClick}>
        <i className="tooltip-icon icon icon-help_outline" />
      </Styled.Toggler>
      {isMessageShown && <Styled.Message>{message}</Styled.Message>}
    </Styled.Wrapper>
  );
};

export default FieldHelpMessage;
