import {media} from '@shared/theme';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const PageTitle = styled.div`
  display: block;
  ${media.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  > .title {
    display: block;
    ${media.tablet} {
      display: inline-block;
    }
  }
  > .status {
    display: block;
    ${media.tablet} {
      display: inline-block;
      padding-bottom: 3px;
      margin-left: 20px;
    }
  }
`;
