import React, {useState} from 'react';
import {CreatePipelineDto} from '@features/workspaces/dto/create-pipeline.dto';
import {useCallback} from 'react';
import CreatePipelineScreen from './CreatePipelineScreen';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import {GenerateCatalogDto} from '@features/workspaces/dto/generate-catalog.dto';

type CreatePipelineScreenContainerProps = {
  workspaceId?: string;
  onSubmit: (data: CreatePipelineDto) => Promise<any>;
  onGoBack?: () => any;
  isLoading?: boolean;
};

const prepareFormData = (data: CreatePipelineDto) => {
  return {
    ...data,
    emailList: data.emailList
      ? data.emailList.filter((email) => !!email.trim())
      : [],
  };
};

const prepareCatalogData = (data: CreatePipelineDto): GenerateCatalogDto => {
  return {
    sourceId: data.sourceId || '',
    sourceConfig: data.sourceConfig || {},
  };
};

const CreatePipelineScreenContainer: React.VFC<
  CreatePipelineScreenContainerProps
> = ({workspaceId = '', onSubmit, isLoading = false, onGoBack}) => {
  const handleGetCatalog = useCallback(
    async (data: CreatePipelineDto) => {
      const workspaceService = new WorkspaceService();
      const response = await workspaceService.getCatalog(
        workspaceId,
        prepareCatalogData(data)
      );
      return response.data.catalog;
    },
    [workspaceId]
  );

  const handleSubmit = useCallback(
    async (data: CreatePipelineDto) => {
      console.log(data);
      return onSubmit(prepareFormData(data));
    },
    [onSubmit]
  );

  return (
    <CreatePipelineScreen
      onSubmit={handleSubmit}
      onGetCatalog={handleGetCatalog}
      isLoading={isLoading}
      onGoBack={onGoBack}
    />
  );
};

export default CreatePipelineScreenContainer;
