import React from 'react';

const SingerlyLogo = () => {
  return (
    <svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.224 4.363c-.128.203-.267.347-.416.432-.139.086-.32.128-.544.128-.235 0-.47-.048-.704-.144-.235-.106-.49-.218-.768-.336a6.934 6.934 0 0 0-.912-.32 3.915 3.915 0 0 0-1.168-.16c-.63 0-1.115.123-1.456.368-.33.235-.496.566-.496.992 0 .31.107.566.32.768.224.203.517.384.88.544.363.15.773.294 1.232.432a30.9 30.9 0 0 1 1.408.464c.48.17.95.374 1.408.608.459.224.87.507 1.232.848.363.33.65.736.864 1.216.224.48.336 1.056.336 1.728 0 .822-.15 1.579-.448 2.272a4.787 4.787 0 0 1-1.344 1.792c-.587.502-1.317.89-2.192 1.168-.864.278-1.861.416-2.992.416-.565 0-1.13-.053-1.696-.16a12.038 12.038 0 0 1-1.648-.432 9.516 9.516 0 0 1-1.456-.64 6.642 6.642 0 0 1-1.152-.816l1.152-1.824a1.43 1.43 0 0 1 .48-.496c.203-.128.459-.192.768-.192.288 0 .544.064.768.192.235.128.485.267.752.416.267.15.576.288.928.416.363.128.816.192 1.36.192.384 0 .71-.037.976-.112.267-.085.48-.197.64-.336.16-.138.277-.293.352-.464.075-.17.112-.346.112-.528 0-.33-.112-.602-.336-.816a2.817 2.817 0 0 0-.88-.544 8.914 8.914 0 0 0-1.248-.432 30.768 30.768 0 0 1-1.408-.464c-.48-.17-.955-.373-1.424-.608a5.2 5.2 0 0 1-1.232-.912 4.337 4.337 0 0 1-.88-1.36c-.224-.533-.336-1.178-.336-1.936 0-.704.133-1.37.4-2a4.73 4.73 0 0 1 1.232-1.68c.544-.48 1.227-.858 2.048-1.136C5.557.62 6.517.475 7.616.475c.597 0 1.173.054 1.728.16a8.831 8.831 0 0 1 1.584.448c.49.192.939.422 1.344.688.405.256.763.539 1.072.848l-1.12 1.744Z"
        fill="#002343"
      />
    </svg>
  );
};

export default SingerlyLogo;
