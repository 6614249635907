import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
`;

export const ModalWrapper = styled.div`
  min-width: 30em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 2px solid ${({theme}) => theme.colors.borderColor};
`;

export const Title = styled.h4`
  font-size: 18px;
  margin: 0;
`;

export const Body = styled.div`
  padding: 20px 30px;
`;

export const CloseButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  outline: none;
  background-color: ${({theme}) => theme.colors.borderColor};
  color: ${({theme}) => theme.colors.primary};
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  padding: 0 0 2px;
  cursor: pointer;
  border: none;
  transition: 0.2s;

  &:hover,
  &:focus {
    background-color: ${({theme}) => theme.colors.lightPrimary};
  }
`;
