import React from 'react';
import Dropdown from '@components/Dropdown';
import {DropdownOption} from '@components/Dropdown/Dropdown';
import * as Styled from './InstanceTypeDropdown.styles';
import instanceTypes from '@features/workspaces/instanceTypeList';

type InstanceTypeDropdownProps = {
  onChange: (value: string) => void;
  value: string;
};

const options = instanceTypes.map((instanceType) => ({
  title: instanceType.title,
  value: instanceType.value,
}));

const instanceTypesGroupedByValue = instanceTypes.reduce(
  (accum, instanceType) => {
    accum[instanceType.value] = instanceType;
    return accum;
  },
  {} as {[key: string]: typeof instanceTypes[0]}
);

const formatRamValue = (ram: number) => {
  return `${Math.floor(ram / 1024)} GB`;
};

const InstanceTypeOption = (option: DropdownOption) => {
  return (
    <Styled.InstanceTypeWrapper>
      <Styled.InstanceTypeTitle>{option.title}</Styled.InstanceTypeTitle>
      <Styled.InstanceTypeFooter>
        <Styled.InstanceTypeDetail>
          <span className="label">CPU:</span>
          <span className="value">
            {instanceTypesGroupedByValue[option.value].cpu}
          </span>
        </Styled.InstanceTypeDetail>
        <Styled.InstanceTypeDetail>
          <span className="label">RAM:</span>
          <span className="value">
            {formatRamValue(instanceTypesGroupedByValue[option.value].ram)}
          </span>
        </Styled.InstanceTypeDetail>
      </Styled.InstanceTypeFooter>
    </Styled.InstanceTypeWrapper>
  );
};

const InstanceTypeDropdown: React.FC<InstanceTypeDropdownProps> = ({
  onChange,
  value,
}) => {
  return (
    <Dropdown
      value={value}
      label="Instance Type"
      selectFirstByDefault
      renderOption={InstanceTypeOption}
      options={options}
      onChange={onChange}
      tooltip={
        <>
          Meltano &amp; Airflow instance capacity - <strong>vCPU count</strong>{' '}
          and <strong>RAM amount</strong>.
          <br />
          <br />
          This will define how many pipelines you will be able to run
          simultaneously.
        </>
      }
    />
  );
};

export default InstanceTypeDropdown;
