import React from 'react';
import * as Styled from './Grid.styles';
import {GridItemProps} from './GridItem';

type GridProps = {
  gutterWidth?: number;
  children:
    | React.ReactElement<GridItemProps>
    | React.ReactElement<GridItemProps>[];
};

const Grid: React.FC<GridProps> = ({children, gutterWidth = 20}) => {
  return <Styled.Wrapper gutterWidth={gutterWidth}>{children}</Styled.Wrapper>;
};

export default Grid;
