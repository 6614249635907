import {StatusMap} from '../../components/StatusBadge/StatusBadge';
import theme from '../../shared/theme';
import {InvitationStatus} from './invitation-status.enum';

const invitationStatusMap: StatusMap = {
  [InvitationStatus.PENDING]: {
    color: theme.colors.orange,
    title: 'Pending',
  },
  [InvitationStatus.JOINED]: {
    color: theme.colors.green,
    title: 'Joined',
  },
  [InvitationStatus.CANCELED]: {
    color: theme.colors.danger,
    title: 'Cancelled',
  },
};

export default invitationStatusMap;
