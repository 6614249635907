import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const ValuesTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  background: #fff;
  border-bottom: 8px solid #fff;
  width: 100%;
`;

export const ValuesHeader = styled.thead`
  th {
    background-color: ${({theme}) => theme.colors.borderColor};
    color: ${({theme}) => theme.colors.mediumGray};
    font-weight: 700;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 10px 12px;
    border-bottom: 8px solid #fff;

    &:first-child {
      padding-left: 18px;
    }
  }
`;

export const ValuesRow = styled.tr``;

export const ValuesCell = styled.td`
  padding: 5px 10px !important;
  vertical-align: top;
  &:first-child {
    padding: 5px 16px !important;
  }
  &:last-child {
    padding-right: 16px !important;
  }
  input {
    padding: 7px 16px;
    &:focus {
      border-color: ${({theme}) => theme.colors.primary};
    }
  }
`;

export const ActionsCell = styled(ValuesCell)`
  width: 1%;
  padding: 11px 10px !important;
  white-space: nowrap;
`;

export const RemoveButton = styled.button`
  border: none;
  outline: none;
  background-color: ${({theme}) => theme.colors.borderColor};
  border-radius: 50%;
  height: 22px;
  width: 22px;
  font-size: 1.1em;
  cursor: pointer;
  transition: 0.2s;

  &:hover,
  &:active {
    background-color: ${({theme}) => theme.colors.danger};
    color: #fff;
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;
