import Button from '@components/Button';
import {GitReference} from '@components/GitReferencesSelector';
import Loader from '@components/Loader';
import Page from '@components/Page';
import EditConnectorForm from '@features/connectors/components/EditConnectorForm';
import {ConnectorModel} from '@features/connectors/connector.model';
import {UpdateConnectorDto} from '@features/connectors/dto/update-connector.dto';
import React from 'react';

type EditConnectorPageProps = {
  onSubmit: (values: UpdateConnectorDto) => Promise<any>;
  onDelete: () => Promise<any>;
  connector?: ConnectorModel;
  isLoading?: boolean;
  isDeleting?: boolean;
  repoDetails?: {references: GitReference[]; executable: string};
  onRepoUrlChange: (value: string) => void;
  isLoadingRepoDetails?: boolean;
};

const EditConnectorPage: React.VFC<EditConnectorPageProps> = ({
  onSubmit,
  onDelete,
  isLoading = false,
  isDeleting = false,
  connector,
  onRepoUrlChange,
  isLoadingRepoDetails,
  repoDetails,
}) => {
  return (
    <Page
      title="Edit Connector"
      actions={[
        <Button
          icon="delete"
          isLoading={isDeleting}
          disabled={isLoading}
          onClick={onDelete}
          variant="danger"
        >
          Delete
        </Button>,
      ]}
    >
      {isLoading || !connector ? (
        <Loader message="Loading connector data..." />
      ) : (
        <EditConnectorForm
          initialValues={{...connector, configuration: connector.config}}
          onSubmit={onSubmit}
          onRepoUrlChange={onRepoUrlChange}
          repoDetails={repoDetails}
          isLoadingRepoDetails={isLoadingRepoDetails}
        />
      )}
    </Page>
  );
};

export default EditConnectorPage;
