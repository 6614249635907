import {useAppDispatch, useSelector} from '@app/store';
import {getCurrentCompany, getProfile} from '@features/auth/auth.slice';
import {BillingDetails} from '@features/billing/billing-details.interface';
import {BillingStatus} from '@features/billing/billing-status.enum';
import {ChargeModel} from '@features/billing/charge.model';
import {CreateSubscriptionDto} from '@features/billing/dto/create-subscription.dto';
import {InvoiceModel} from '@features/billing/invoice.model';
import {CompaniesService} from '@features/companies/companies.service';
import {useCallback, useEffect, useState} from 'react';

const useCompanyBillingDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
    null
  );
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [transactions, setTransactions] = useState<ChargeModel[]>([]);

  const currentCompany = useSelector(getCurrentCompany);

  const handleCancelSubscription = useCallback(async () => {
    if (currentCompany && currentCompany.id) {
      const companiesService = new CompaniesService();
      try {
        await companiesService.cancelSubscription(currentCompany.id);
        return dispatch(getProfile());
      } catch (e) {
        return null;
      }
    }
  }, [currentCompany, dispatch]);

  const handleRenewSubscription = useCallback(async () => {
    if (currentCompany && currentCompany.id) {
      const companiesService = new CompaniesService();
      try {
        await companiesService.renewSubscription(currentCompany.id);
        return dispatch(getProfile());
      } catch (e) {
        return null;
      }
    }
  }, [currentCompany, dispatch]);

  const handleCreateSubscription = useCallback(
    async (dto: CreateSubscriptionDto) => {
      if (currentCompany && currentCompany.id) {
        const companiesService = new CompaniesService();
        try {
          await companiesService.createSubscription(currentCompany.id, dto);
          return dispatch(getProfile());
        } catch (e) {
          return null;
        }
      }
    },
    [currentCompany, dispatch]
  );

  const handleUpdatePaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      if (currentCompany && currentCompany.id) {
        const companiesService = new CompaniesService();
        try {
          await companiesService.updatePaymentMethod(
            currentCompany.id,
            paymentMethodId
          );
          return dispatch(getProfile());
        } catch (e) {
          return null;
        }
      }
    },
    [currentCompany, dispatch]
  );

  useEffect(() => {
    if (
      currentCompany?.id &&
      currentCompany?.billingStatus !== BillingStatus.PARENT_MANAGED
    ) {
      const companiesService = new CompaniesService();
      Promise.all([
        companiesService
          .getBillingDetails(currentCompany.id)
          .then((response) => {
            setBillingDetails(response.data);
            setIsLoading(false);
          })
          .catch((e) => {
            setBillingDetails(null);
            setIsLoading(false);
          }),
        companiesService
          .getInvoices(currentCompany.id)
          .then((response) => {
            setInvoices(response.data);
          })
          .catch(() => {
            setInvoices([]);
          }),
        companiesService
          .getCharges(currentCompany.id)
          .then((response) => {
            setTransactions(response.data);
          })
          .catch(() => {
            setTransactions([]);
          }),
      ]);
    }
  }, [currentCompany]);

  return {
    isLoading,
    billingDetails,
    invoices,
    transactions,
    handleCancelSubscription,
    handleRenewSubscription,
    handleCreateSubscription,
    handleUpdatePaymentMethod,
  };
};

export default useCompanyBillingDetails;
