import {CompleteInvitationDto} from '@features/invitations/dto/complete-invitation.dto';
import {InvitationsService} from '@features/invitations/invitations.service';
import React, {useCallback, useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router';

import CompleteInvitationPageDisplay from './CompleteInvitationPage';

type CompleteInvitationPageProps = {};

const CompleteInvitationPage: React.VFC<CompleteInvitationPageProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const [gatherProfile, setGatherProfile] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const match = useRouteMatch<{token: string}>();

  useEffect(() => {
    const invitationService = new InvitationsService();
    setIsLoading(true);
    invitationService
      .checkInvitation(match.params.token)
      .then((checkInvitationResponse) => {
        if (checkInvitationResponse) {
          setGatherProfile(checkInvitationResponse.gatherProfile);
          setCompanyName(checkInvitationResponse.companyName);
        }
        setIsInvalid(checkInvitationResponse === null);
        setIsLoading(false);
      });
  }, [match.params.token]);

  const handleSubmit = useCallback(
    async (dto: CompleteInvitationDto | null) => {
      const invitationService = new InvitationsService();
      if (!dto) {
        return invitationService.completeExistingUserInvitation(
          match.params.token
        );
      }
      return invitationService.completeInvitation(match.params.token, dto);
    },
    [match.params.token]
  );

  return (
    <CompleteInvitationPageDisplay
      onSubmit={handleSubmit}
      isLoading={isLoading}
      isInvalid={isInvalid}
      gatherProfile={gatherProfile}
      companyName={companyName}
    />
  );
};

export default CompleteInvitationPage;
