import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {fetchCompanies} from '@features/companies/companies.slice';
import CompanyListPageDisplay from './CompanyListPage';
import {RootState, useSelector} from '@app/store';
import {CompanyModel} from '@features/companies/company.model';
import {getCurrentCompany} from '@features/auth/auth.slice';

const connectRedux = connect(null, {fetchCompanies});

type CompanyListPageProps = {} & ConnectedProps<typeof connectRedux>;

const CompanyListPage: React.FC<CompanyListPageProps> = ({fetchCompanies}) => {
  const companies = useSelector((state) => state.companies.data);
  const isLoading = useSelector((state) => state.companies.isLoading);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  return <CompanyListPageDisplay isLoading={isLoading} companies={companies} />;
};

export default connectRedux(CompanyListPage);
