import React, {useCallback, useMemo} from 'react';
import StatusBadge from '@components/StatusBadge';
import {
  WorkspaceModel,
  WorkspaceStatusEnum,
} from '@features/workspaces/workspace.model';
import * as Styled from './WorkspaceCard.styles';
import workspaceStatusMap from '@features/workspaces/workspace-status-map';
import {differenceInMinutes, parseJSON} from 'date-fns';
import instanceTypeList from '@features/workspaces/instanceTypeList';
import {useHistory} from 'react-router-dom';
import MeltanoLogo from '@features/workspaces/components/WorkspaceTypeSelector/MeltanoLogo';
import SingerlyLogo from '@features/workspaces/components/WorkspaceTypeSelector/SingerlyLogo';

const instanceTypeMap = instanceTypeList.reduce((accum, instanceType) => {
  accum[instanceType.value] = instanceType;
  return accum;
}, {} as any);

type WorkspaceCardProps = {
  workspace: WorkspaceModel;
};

const workspaceTypeBrandMap = {
  meltano: {
    title: 'Meltano Project',
    className: 'meltano',
    logo: <MeltanoLogo />,
  },
  airflow: {
    title: 'Singerly Pipelines',
    className: 'singerly',
    logo: <SingerlyLogo />,
  },
};

const WorkspaceCard: React.FC<WorkspaceCardProps> = ({workspace}) => {
  const history = useHistory();
  const installationType = useMemo(() => {
    return workspace.infrastructure?.installationType || '';
  }, [workspace.infrastructure?.installationType]);

  const timeToComplete = useMemo<number | null>(
    () =>
      workspace.status === WorkspaceStatusEnum.CREATING
        ? Math.round(
            10 - differenceInMinutes(new Date(), parseJSON(workspace.createdAt))
          )
        : null,
    [workspace]
  );
  const handleClick = useCallback(() => {
    history.push(`/projects/${workspace.id}`);
  }, [history, workspace.id]);

  return (
    <Styled.BrandedWrapper
      className={[
        `workspace-${
          installationType && workspaceTypeBrandMap[installationType].className
        }-branded`,
      ].join(' ')}
    >
      {installationType && (
        <Styled.BrandedWrapperContent>
          {/* <Styled.BrandedLogo>
          {workspaceTypeBrandMap[installationType].logo}
        </Styled.BrandedLogo> */}
          <Styled.BrandedTitle>
            {workspaceTypeBrandMap[installationType].title}
          </Styled.BrandedTitle>
        </Styled.BrandedWrapperContent>
      )}

      <Styled.Wrapper onClick={handleClick}>
        <Styled.Inner>
          <Styled.Header>
            <Styled.Title>{workspace.name}</Styled.Title>
            <Styled.WorkspaceStatus>
              <StatusBadge
                statusMap={workspaceStatusMap}
                value={workspace.status}
              />
              {workspace.status === WorkspaceStatusEnum.CREATING && (
                <Styled.TimeLeftToComplete>
                  ~{(timeToComplete || 0) < 0 ? 0 : timeToComplete} minutes to
                  complete
                </Styled.TimeLeftToComplete>
              )}
            </Styled.WorkspaceStatus>
          </Styled.Header>
          {workspace.infrastructure && (
            <>
              <Styled.Section>
                <Styled.PropertyList>
                  <Styled.Property>
                    <Styled.PropertyLabel>Instance type</Styled.PropertyLabel>
                    <Styled.PropertyValue>
                      {
                        instanceTypeMap[workspace.infrastructure.instanceType]
                          .title
                      }
                    </Styled.PropertyValue>
                  </Styled.Property>
                  <Styled.Property>
                    <Styled.PropertyLabel>CPU count</Styled.PropertyLabel>
                    <Styled.PropertyValue>
                      {
                        instanceTypeMap[workspace.infrastructure.instanceType]
                          .cpu
                      }
                      &nbsp;vCPU
                    </Styled.PropertyValue>
                  </Styled.Property>
                  <Styled.Property>
                    <Styled.PropertyLabel>Memory</Styled.PropertyLabel>
                    <Styled.PropertyValue>
                      {instanceTypeMap[workspace.infrastructure.instanceType]
                        .ram / 1024}
                      &nbsp;Gb
                    </Styled.PropertyValue>
                  </Styled.Property>
                </Styled.PropertyList>
              </Styled.Section>
              {/* <Styled.Section>
              <Styled.IconProperty>
                <i className="icon icon-share" />
                <Styled.IconPropertyBody>
                  <Styled.PropertyLabel>Git Clone URL</Styled.PropertyLabel>
                  <Styled.PropertyValue>
                    <small>
                      {workspace.infrastructure.repositoryUrl?.replace(
                        'ssh://',
                        `ssh://${
                          workspace.infrastructure.repositoryUser || ''
                        }@`
                      )}
                    </small>
                  </Styled.PropertyValue>
                </Styled.IconPropertyBody>
              </Styled.IconProperty>
            </Styled.Section>
            <Styled.Section className="section-no-padding">
              <Styled.Property className="property-no-padding property-flex">
                <Styled.WorkspaceUrl
                  href={meltanoUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon icon-share" />
                  Meltano UI
                </Styled.WorkspaceUrl>
                <Styled.WorkspaceUrl
                  href={airflowUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="icon icon-share" />
                  Airflow UI
                </Styled.WorkspaceUrl>
              </Styled.Property>
            </Styled.Section> */}
            </>
            // <Styled.Footer>
            //   <Styled.FooterProperty>
            //     <span className="label">Git URL:</span>
            //     <span className="value">
            //       {workspace.infrastructure.repositoryUrl?.replace(
            //         'ssh://',
            //         `ssh://${workspace.infrastructure.repositoryUser || ''}@`
            //       )}
            //     </span>
            //   </Styled.FooterProperty>
            //   <Styled.FooterProperty>
            //     <span className="label">Meltano URL:</span>
            //     <span className="value">{workspace.infrastructure.endpoint}</span>
            //   </Styled.FooterProperty>
            // </Styled.Footer>
          )}
          <Styled.DetailsLink>
            Project details
            <i className="icon icon-arrow_forward1" />
          </Styled.DetailsLink>
        </Styled.Inner>
      </Styled.Wrapper>
    </Styled.BrandedWrapper>
  );
};

export default WorkspaceCard;
