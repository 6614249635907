import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  flex-basis: calc(50% - 5px);
`;

export const EmptyList = styled.div`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  padding: 16px 20px;
  line-height: 1.4;
  max-width: 30em;

  h4,
  h3 {
    margin: 0 0 0.4em;
  }

  h3 {
    font-size: 20px;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
