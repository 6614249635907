import {InvoiceModel} from './invoice.model';

export enum ChargeStatus {
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed',
}

export interface ChargeModel {
  id: string;
  amount: number;
  currency: string;
  created: number;
  description: string;
  status: ChargeStatus;
  invoice?: InvoiceModel;
}
