import React, {useCallback} from 'react';
import {UserModel} from '../../user.model';
import * as Styled from './UserCard.styles';

type UserCardProps = {
  user: UserModel;
  onClick?: (user: UserModel) => void;
};

const UserCard: React.FC<UserCardProps> = ({user, onClick}) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClick) {
        onClick(user);
      }
    },
    [onClick, user]
  );

  return (
    <Styled.Wrapper onClick={handleClick}>
      <Styled.Avatar style={{backgroundImage: `url(${user.avatar})`}} />
      <Styled.Column>
        <Styled.Name>{user.name}</Styled.Name>
        <Styled.Email>{user.email}</Styled.Email>
      </Styled.Column>
    </Styled.Wrapper>
  );
};

export default UserCard;
