import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import WorkspaceDetailsPageDisplay from './WorkspaceDetailsPage';
import {
  fetchWorkspaceById,
  removeWorkspace,
} from '@features/workspaces/workspace.slice';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import AirflowWorkspacePage from '../AirflowWorkspacePage/AirflowWorkspacePage';
import Loader from '@components/Loader';
import useWorkspace from '@features/workspaces/hooks/useWorkspace';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';

const connectRedux = connect(null, {fetchWorkspaceById, removeWorkspace});

type WorkspaceDetailsPageProps = ConnectedProps<typeof connectRedux>;

const WorkspaceDetailsPage: React.FC<WorkspaceDetailsPageProps> = ({
  removeWorkspace,
}) => {
  const match = useRouteMatch<{id: string}>();
  const history = useHistory();
  const selectedCompany = useSelectedCompany();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleGoBack = useCallback(() => {
    history.replace('/projects');
  }, [history]);

  const {workspace, isLoading} = useWorkspace({
    companyId: selectedCompany?.id,
    workspaceId: match.params.id,
    onError: handleGoBack,
  });

  const handleDelete = useCallback(async () => {
    if (workspace) {
      setIsDeleting(true);
      return removeWorkspace(workspace.id)
        .then(unwrapResult)
        .then(() => {
          setIsDeleting(false);
          history.push('/workspaces');
        });
    }
  }, [history, removeWorkspace, workspace]);

  if (isLoading || !workspace) {
    return <Loader message="Loading project..." />;
  }

  if (
    workspace.infrastructure &&
    workspace.infrastructure.installationType === 'airflow'
  ) {
    return (
      <AirflowWorkspacePage
        workspace={workspace}
        isLoading={isLoading}
        isDeleting={isDeleting}
        onDelete={handleDelete}
      />
    );
  }

  return (
    <WorkspaceDetailsPageDisplay
      workspace={workspace}
      isLoading={isLoading}
      isDeleting={isDeleting}
      onDelete={handleDelete}
    />
  );
};

export default connectRedux(WorkspaceDetailsPage);
