import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {fetchPlans} from '@features/plans/plan.slice';
import PlanSelectorDropdownDisplay from './PlanSelectorDropdown';
import {useSelector} from '@app/store';

const connectRedux = connect(null, {fetchPlans});

type PlanSelectorDropdownProps = {
  onChange: (planId: string) => any;
  value: string;
} & ConnectedProps<typeof connectRedux>;

const PlanSelectorDropdown = ({
  onChange,
  value,
  fetchPlans,
}: PlanSelectorDropdownProps) => {
  const plans = useSelector((state) => state.plans.data);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return (
    <PlanSelectorDropdownDisplay
      plans={plans}
      onChange={onChange}
      value={value}
    />
  );
};

export default connectRedux(PlanSelectorDropdown);
