import {UserModel} from '@features/users/user.model';
import {UserService} from '@features/users/user.service';
import {AxiosResponse} from 'axios';
import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import AddUserToWorkspaceModalDisplay from './AddUserToWorkspaceModal';
import {addUserToWorkspace} from '@features/users/users.slice';
import {unwrapResult} from '@reduxjs/toolkit';

const connectRedux = connect(null, {addUserToWorkspace});

type AddUserToWorkspaceModalProps = {
  workspaceId: string;
  onClose: any;
} & ConnectedProps<typeof connectRedux>;

const AddUserToWorkspaceModal = ({
  workspaceId,
  onClose,
  addUserToWorkspace,
}: AddUserToWorkspaceModalProps) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const {addToast} = useToasts();

  useEffect(() => {
    const usersService = new UserService();
    usersService.getAll().then((response: AxiosResponse<UserModel[]>) => {
      setUsers(response.data);
    });
  }, []);

  const handleSubmit = useCallback(
    (userId: string) => {
      setIsLoading(true);
      return addUserToWorkspace({userId, workspaceId})
        .then(unwrapResult)
        .then(() => {
          addToast('User added to the project successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
          setIsLoading(false);
          onClose();
        });
    },
    [addToast, addUserToWorkspace, onClose, workspaceId]
  );

  return (
    <AddUserToWorkspaceModalDisplay
      onSubmit={handleSubmit}
      isOpen
      isLoading={isLoading}
      users={users}
      onClose={onClose}
    />
  );
};

export default connectRedux(AddUserToWorkspaceModal);
