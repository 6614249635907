export const customMedia = (minWidth: number) => {
  return `@media screen and (min-width: ${minWidth}px)`;
};

export const media = {
  tablet: customMedia(768),
  pc: customMedia(1200),
  pcLarge: customMedia(1800),
};

const themeColors = {
  primary: '#0084FF',
  lightPrimary: '#D9EDFF',
  lightGray: '#F7F7F7',
  text: '#000',
  danger: '#CE0303',
  mediumGray: '#7A7A7A',
  borderColor: '#E8E8E8',
  orange: '#FF961B',
  green: '#04925F',
  meltano: '#464ACB',
};

const theme = {
  colors: themeColors,
  layout: {
    maxWidth: 1280,
  },
  input: {
    normal: {
      borderColor: themeColors.borderColor,
    },
    hover: {
      borderColor: themeColors.primary,
    },
    focus: {
      borderColor: themeColors.primary,
    },
  },
  dropdown: {
    borderRadius: '3px',
    normal: {
      borderColor: themeColors.mediumGray,
    },
    hover: {
      borderColor: themeColors.primary,
    },
  },
};

export default theme;
