import React, {useCallback, useMemo, useRef, useState} from 'react';
import TextField from '@components/TextField';
import * as Styled from './GitCloneUrl.styles';
import UploadSSHKeyModal from '@features/users/modals/UploadSSHKeyModal';
import {AuthUser} from '@features/auth/auth-user.model';

type GitCloneUrlProps = {
  url: string;
  user?: Partial<AuthUser>;
  workspaceId?: string;
};

const GitCloneUrl: React.FC<GitCloneUrlProps> = ({
  workspaceId = '',
  url,
  user,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const userSshKey = useMemo(() => {
    const workspace =
      user && user.workspaces
        ? user.workspaces.find(
            (workspace) => workspace.id === workspaceId && !!workspace.sshKeyId
          )
        : null;
    return workspace ? workspace.sshKeyId : '';
  }, [user, workspaceId]);

  const cloneUrl = useMemo(
    () => url.replace('ssh://', `ssh://${userSshKey}@`),
    [url, userSshKey]
  );

  const handleCopy = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      inputRef.current.setSelectionRange(0, 0);
    }
  }, []);

  const handleShowModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setIsModalOpen(true);
    },
    []
  );

  return (
    <Styled.Wrapper>
      <Styled.Label>
        <i className="icon icon-share" />
        Git Clone URL
        {userSshKey && (
          <button className="upload-button" onClick={handleShowModal}>
            Update your SSH key
          </button>
        )}
      </Styled.Label>
      {userSshKey ? (
        <TextField
          value={cloneUrl}
          inputRef={inputRef}
          readOnly
          buttons={
            <Styled.CopyButton onClick={handleCopy}>
              <i className="icon icon-content_copy" />
            </Styled.CopyButton>
          }
        />
      ) : (
        <Styled.Message>
          Please{' '}
          <button onClick={handleShowModal}>upload your SSH Public key</button>{' '}
          to gain access to the project repository
        </Styled.Message>
      )}
      {isModalOpen && (
        <UploadSSHKeyModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          workspaceId={workspaceId}
        />
      )}
    </Styled.Wrapper>
  );
};

export default GitCloneUrl;
