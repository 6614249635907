import {WorkspaceService} from '@features/workspaces/workspace.service';
import {useQuery} from 'react-query';

const useRepoDetails = (repoUrl: string) => {
  const {data: repoDetails, isLoading: isLoadingRepoDetails} = useQuery({
    queryFn: async () => {
      const workspaceService = new WorkspaceService();
      return workspaceService
        .getRepoDetails(repoUrl)
        .then((response) => response.data);
    },
    queryKey: ['git-details', repoUrl],
    enabled: !!repoUrl,
    retry: 2,
  });

  return {
    repoDetails,
    isLoadingRepoDetails,
  };
};

export default useRepoDetails;
