import {CouponModel} from '@features/billing/coupon.model';
import {CouponService} from '@features/billing/coupon.service';
import React, {useEffect, useState} from 'react';
import CouponsPageDisplay from './CouponsPage';

type CouponsPageProps = {};

const CouponsPage: React.VFC<CouponsPageProps> = () => {
  const [coupons, setCoupons] = useState<CouponModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const couponService = new CouponService();
    setIsLoading(true);
    couponService.getAll().then((response) => {
      setCoupons(response.data);
      setIsLoading(false);
    });
  }, []);

  return <CouponsPageDisplay coupons={coupons} isLoading={isLoading} />;
};

export default CouponsPage;
