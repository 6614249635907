import {BuildModel} from './build.model';
import {InfrastructureModel} from './infrastructure.model';

export enum WorkspaceStatusEnum {
  PENDING = 'pending',
  CREATING = 'creating',
  RUNNING = 'running',
  FAILED = 'failed',
}

export interface WorkspaceModel {
  id: string;
  name: string;
  status: WorkspaceStatusEnum;
  createdAt: string;
  updatedAt: string;
  infrastructure?: InfrastructureModel;
  builds?: BuildModel[];
  currentMonthCosts?: number;
  previousMonthCosts?: number;
}
