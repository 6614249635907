import styled from 'styled-components';
import {media} from '../../shared/theme';

export const Wrapper = styled.header`
  padding: 16px 30px;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const Inner = styled.div`
  ${media.tablet} {
    display: flex;
    align-items: center;
  }
  &.header-simple {
    ${media.tablet} {
      justify-content: center;
    }
  }
`;

export const HeaderUserCompanies = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.h1`
  flex-basis: 250px;
  margin: 0 38px 0 0;
  padding: 0 16px;
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0.005em;
`;

export const LogoutLink = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #fff;
  transition: 0.2s;
  margin-left: auto;

  .icon {
    display: inline-block;
    margin-right: 5px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.primary};
  }

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`;
