import {CouponService} from '@features/billing/coupon.service';
import {CreateCouponDto} from '@features/billing/dto/create-coupon.dto';
import {ValidationErrors} from 'types/validation-errors';
import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {useToasts} from 'react-toast-notifications';
import CreateCouponPageDisplay from './CreateCouponPage';

type CreateCouponPageProps = {};

const CreateCouponPage: React.VFC<CreateCouponPageProps> = () => {
  const {addToast} = useToasts();
  const history = useHistory();
  const [errors, setErrors] = useState<ValidationErrors>({});

  const handleSubmit = useCallback(
    async (data: CreateCouponDto) => {
      const couponService = new CouponService();
      try {
        await couponService.create(data);
        history.push('/coupons');
        addToast('Coupon code successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        setErrors(e.response.data);
      }
    },
    [addToast, history]
  );

  return <CreateCouponPageDisplay onSubmit={handleSubmit} errors={errors} />;
};

export default CreateCouponPage;
