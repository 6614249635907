import {CompanyModel} from '@features/companies/company.model';
import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {updateCompany} from '@features/companies/companies.slice';
import CompanyPlanEditorDisplay from './CompanyPlanEditor';

const connectRedux = connect(null, {updateCompany});

type CompanyPlanEditorProps = {
  company: CompanyModel;
} & ConnectedProps<typeof connectRedux>;

const CompanyPlanEditor: React.VFC<CompanyPlanEditorProps> = ({
  company,
  updateCompany,
}) => {
  const handleSubmit = useCallback(
    (planId: string) => {
      return updateCompany({id: company.id, data: {planId}});
    },
    [company.id, updateCompany]
  );

  return <CompanyPlanEditorDisplay company={company} onSubmit={handleSubmit} />;
};

export default connectRedux(CompanyPlanEditor);
