import {StatusMap} from '../../components/StatusBadge/StatusBadge';
import theme from '../../shared/theme';

export default {
  archived: {
    color: 'gray',
    title: 'Archived',
  },
  disabled: {
    color: 'gray',
    title: 'Disabled',
  },
  active: {
    color: theme.colors.green,
    title: 'Active',
  },
} as StatusMap;
