import Button from '@components/Button';
import Loader from '@components/Loader';
import HasPermission from '@features/auth/components/HasPermission';
import UserList from '@features/users/components/UserList';
import {UserModel} from '@features/users/user.model';
import React from 'react';
import * as Styled from './WorkspaceUsers.styles';

type WorkspaceUsersProps = {
  isLoading?: boolean;
  users?: UserModel[];
  addUser: () => any;
};

const WorkspaceUsers: React.FC<WorkspaceUsersProps> = ({
  isLoading = false,
  users = [],
  addUser,
}) => {
  if (isLoading) {
    return <Loader message="Loading project users..." />;
  }
  return (
    <Styled.Wrapper>
      <UserList users={users} />
      <Styled.ActionBar>
        <HasPermission permissions="assing:project-users">
          <Button size="large" variant="secondary" onClick={addUser}>
            Add user to project
          </Button>
        </HasPermission>
      </Styled.ActionBar>
    </Styled.Wrapper>
  );
};

export default WorkspaceUsers;
