import React from 'react';
import * as Styled from './StatusBadge.styles';

type StatusProps = {
  title: string;
  color: string;
};

export type StatusMap = {
  [key: string]: StatusProps;
};

type StatusBadgeProps = {
  statusMap: StatusMap;
  value: keyof StatusMap;
};

const StatusBadge: React.FC<StatusBadgeProps> = ({statusMap, value}) => {
  return (
    <Styled.Wrapper color={statusMap[value].color}>
      {statusMap[value].title}
    </Styled.Wrapper>
  );
};

export default StatusBadge;
