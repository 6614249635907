import HasPermission from '@features/auth/components/HasPermission';
import BillingStatusWidget from '@features/billing/components/BillingStatusWidget';
import IsManagedCompany from '@features/companies/components/IsManagedCompany';
import IsParentCompany from '@features/companies/components/IsParentCompany';
import React from 'react';
import * as Styled from './Sidebar.styles';

type SidebarProps = {};

const Sidebar: React.FC<SidebarProps> = () => {
  return (
    <Styled.Wrapper>
      <Styled.SidebarList>
        <Styled.SidebarListItem>
          <HasPermission permissions="read:companies">
            <Styled.SidebarLink to="/companies" activeClassName="active">
              <i className="icon icon-companies" />
              Companies
            </Styled.SidebarLink>
          </HasPermission>
        </Styled.SidebarListItem>
        <Styled.SidebarListItem>
          <HasPermission permissions="read:plans">
            <Styled.SidebarLink to="/plans" activeClassName="active">
              <i className="icon icon-view_carousel" />
              Plans
            </Styled.SidebarLink>
          </HasPermission>
        </Styled.SidebarListItem>
        <Styled.SidebarListItem>
          <HasPermission permissions="read:coupons">
            <Styled.SidebarLink to="/coupons" activeClassName="active">
              <i className="icon icon-redeem" />
              Coupons
            </Styled.SidebarLink>
          </HasPermission>
        </Styled.SidebarListItem>
        <Styled.SidebarListItem>
          <Styled.SidebarLink to="/projects" activeClassName="active">
            <i className="icon icon-memory" />
            Projects
          </Styled.SidebarLink>
        </Styled.SidebarListItem>
        <IsParentCompany>
          <HasPermission permissions={['read:managed-companies']}>
            <Styled.SidebarListItem>
              <Styled.SidebarLink
                to="/managed-companies"
                activeClassName="active"
              >
                <i className="icon icon-companies" />
                Managed Companies
              </Styled.SidebarLink>
            </Styled.SidebarListItem>
          </HasPermission>
        </IsParentCompany>
        {/* <HasPermission permissions={['read:connectors']}>
          <Styled.SidebarListItem>
            <Styled.SidebarLink to="/connectors" activeClassName="active">
              <i className="icon icon-connectors" />
              Connectors
            </Styled.SidebarLink>
          </Styled.SidebarListItem>
        </HasPermission> */}
        <HasPermission permissions={['read:connectors']}>
          <Styled.SidebarListItem>
            <Styled.SidebarLink to="/own-connectors" activeClassName="active">
              <i className="icon icon-connectors" />
              My Connectors
            </Styled.SidebarLink>
          </Styled.SidebarListItem>
        </HasPermission>
        <HasPermission permissions={['read:users']}>
          <Styled.SidebarListItem>
            <Styled.SidebarLink to="/users" activeClassName="active">
              <i className="icon icon-group" />
              Users
            </Styled.SidebarLink>
          </Styled.SidebarListItem>
        </HasPermission>
        <HasPermission permissions="read:billing">
          <Styled.SidebarListItem>
            <Styled.SidebarLink to="/billing" activeClassName="active">
              <i className="icon icon-credit_card" />
              Billing
            </Styled.SidebarLink>
          </Styled.SidebarListItem>
        </HasPermission>
      </Styled.SidebarList>
      <Styled.BillingWidget>
        <BillingStatusWidget />
      </Styled.BillingWidget>
    </Styled.Wrapper>
  );
};

export default Sidebar;
