import LinkButton from '@components/LinkButton';
import Loader from '@components/Loader';
import Page from '@components/Page';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import ConnectorList from '@features/connectors/components/ConnectorList';
import {ConnectorModel} from '@features/connectors/connector.model';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import React, {useEffect, useState} from 'react';

type ListConnectorsPageProps = {
  connectors?: ConnectorModel[];
  isLoading?: boolean;
  onChangeConnectorType: (type: ConnectorType) => void;
  onEnabledToggle: (connector: ConnectorModel) => Promise<any>;
  onCreate: () => void;
  onEdit: (connector: ConnectorModel) => void;
};

const ListConnectorsPage: React.VFC<ListConnectorsPageProps> = ({
  connectors = [],
  isLoading = false,
  onChangeConnectorType,
  onEnabledToggle,
  onCreate,
  onEdit,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (currentTab === 0) {
      onChangeConnectorType(ConnectorType.SOURCE);
    }
    if (currentTab === 1) {
      onChangeConnectorType(ConnectorType.DESTINATION);
    }
  }, [currentTab, onChangeConnectorType]);

  return (
    <Page
      title="Manage Connectors"
      actions={[
        <LinkButton to="/own-connectors/create" key="create-connector">
          Add new
        </LinkButton>,
      ]}
    >
      <Tabs onCurrentChange={setCurrentTab} currentIndex={currentTab}>
        <Tab title="Source">
          {isLoading ? (
            <Loader message="Loading connectors..." />
          ) : (
            <ConnectorList
              connectors={connectors}
              onEnabledToggle={onEnabledToggle}
              onCreate={onCreate}
              onEdit={onEdit}
            />
          )}
        </Tab>
        <Tab title="Destination">
          {isLoading ? (
            <Loader message="Loading connectors..." />
          ) : (
            <ConnectorList
              connectors={connectors}
              onEnabledToggle={onEnabledToggle}
              onCreate={onCreate}
              onEdit={onEdit}
            />
          )}
        </Tab>
      </Tabs>
    </Page>
  );
};

export default ListConnectorsPage;
