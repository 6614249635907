import React from 'react';
import {RootState} from '@app/store';
import {AuthUser} from '@features/auth/auth-user.model';
import {ReactNode, useMemo} from 'react';
import {useSelector} from 'react-redux';
import intersection from 'lodash/intersection';

type HasPermissionProps = {
  permissions: string | string[];
  fallback?: ReactNode;
  children?: ReactNode;
};

const HasPermission = ({
  permissions,
  children,
  fallback = null,
}: HasPermissionProps) => {
  const authUser = useSelector<RootState, Partial<AuthUser>>(
    (state) => state.auth.user
  );

  const userPermissions = useMemo(() => {
    return authUser ? authUser.permissions || [] : [];
  }, [authUser]);

  const userHasPermissions = useMemo(() => {
    const permissionsArray = Array.isArray(permissions)
      ? permissions
      : [permissions];
    return intersection(permissionsArray, userPermissions).length > 0;
  }, [permissions, userPermissions]);

  if (!authUser) {
    return null;
  }

  return <>{userHasPermissions ? children : fallback}</>;
};

export default HasPermission;
