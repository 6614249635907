import {ConnectorReplicationMode} from './connector-replication-mode.enum';

const connectorReplicationModeMap: {
  [key in ConnectorReplicationMode]: {
    value: ConnectorReplicationMode;
    title: string;
  };
} = {
  [ConnectorReplicationMode.FULL_TABLE]: {
    title: 'Full Table',
    value: ConnectorReplicationMode.FULL_TABLE,
  },
  [ConnectorReplicationMode.INCREMENTAL]: {
    title: 'Incremental',
    value: ConnectorReplicationMode.INCREMENTAL,
  },
  [ConnectorReplicationMode.LOG_BASED]: {
    title: 'Log Based',
    value: ConnectorReplicationMode.LOG_BASED,
  },
};

export default connectorReplicationModeMap;
