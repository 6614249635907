import FormGroup from '@components/FormGroup';
import Loader from '@components/Loader';
import PasswordField, {
  passwordValidationSchema,
} from '@components/PasswordField';
import TextField from '@components/TextField';
import {CompleteInvitationDto} from '@features/invitations/dto/complete-invitation.dto';
import {useFormik} from 'formik';
import React, {useCallback, useState} from 'react';
import * as Styled from './CompleteInvitation.styles';
import * as Yup from 'yup';
import Button from '@components/Button';
import LinkButton from '@components/LinkButton';

type CompleteInvitationProps = {
  isLoading?: boolean;
  isInvalid?: boolean;
  onSubmit: (values: CompleteInvitationDto | null) => Promise<any>;
  gatherProfile?: boolean;
  companyName?: string;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: passwordValidationSchema.notRequired(),
  passwordConfirmation: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    }
  ),
});

const CompleteInvitation: React.VFC<CompleteInvitationProps> = ({
  isLoading = false,
  isInvalid = false,
  gatherProfile = true,
  companyName = '',
  onSubmit,
}) => {
  const [completedSuccessfully, setCompletedSuccessfully] = useState(false);

  const handleSubmit = useCallback(
    async (dto: CompleteInvitationDto | null) => {
      onSubmit(dto).then(() => {
        setCompletedSuccessfully(true);
      });
    },
    [onSubmit]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });
  if (isLoading) {
    return (
      <Styled.Wrapper>
        <Styled.Inner>
          <Styled.CenteredLayout>
            <Loader />
          </Styled.CenteredLayout>
        </Styled.Inner>
      </Styled.Wrapper>
    );
  }

  if (isInvalid) {
    return (
      <Styled.Wrapper>
        <Styled.Inner>
          <Styled.CenteredLayout>
            <Styled.Logo>singerly</Styled.Logo>
            <Styled.Title>
              Your invitation link is expired or doesn't exist.
            </Styled.Title>
          </Styled.CenteredLayout>
        </Styled.Inner>
      </Styled.Wrapper>
    );
  }

  if (!gatherProfile && !completedSuccessfully) {
    return (
      <Styled.Wrapper>
        <Styled.Inner>
          <Styled.CenteredLayout>
            <Styled.Logo>singerly</Styled.Logo>
            <Styled.Title>
              Click the button bellow to join <strong>{companyName}</strong>
            </Styled.Title>
            <Styled.ActionBar>
              <Button onClick={() => handleSubmit(null)} fullWidth size="large">
                Accept invitation
              </Button>
            </Styled.ActionBar>
          </Styled.CenteredLayout>
        </Styled.Inner>
      </Styled.Wrapper>
    );
  }

  if (completedSuccessfully) {
    return (
      <Styled.Wrapper>
        <Styled.Inner>
          <Styled.CenteredLayout>
            <Styled.Logo>singerly</Styled.Logo>
            <Styled.Title>
              Thank you!
              <br />
              Please follow the link below to sign in.
            </Styled.Title>
            <Styled.ActionBar>
              <LinkButton to="/" size="large">
                Sign in
              </LinkButton>
            </Styled.ActionBar>
          </Styled.CenteredLayout>
        </Styled.Inner>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Styled.Inner>
        <Styled.Logo>singerly</Styled.Logo>
        <Styled.Form>
          <Styled.Title>Please enter your name and password</Styled.Title>
          <FormGroup>
            <TextField
              label="First Name"
              name="firstName"
              placeholder="Enter your first name"
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Last Name"
              name="lastName"
              placeholder="Enter your last name"
              value={formik.values.lastName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <PasswordField
              label="Password"
              name="password"
              placeholder="Enter your password"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              touched={formik.touched.password}
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Password Confirmation"
              type="password"
              name="passwordConfirmation"
              value={formik.values.passwordConfirmation}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
                  ? formik.errors.passwordConfirmation
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <Button
              fullWidth
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              isLoading={formik.isSubmitting}
              size="large"
              onClick={formik.submitForm}
            >
              Join Singerly
            </Button>
          </FormGroup>
        </Styled.Form>
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default CompleteInvitation;
