import styled from 'styled-components';

export const Wrapper = styled.div``;

export const CardElementWrapper = styled.div``;

export const CardElementInput = styled.div`
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  padding: 12px 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;
