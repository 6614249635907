import FieldHelpMessage from '@components/FieldHelpMessage';
import React from 'react';
import FieldErrorMessage from '../FieldErrorMessage';
import * as Styled from './TextAreaField.styles';

interface TextAreaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  tooltip?: React.ReactNode;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  error = '',
  label = '',
  required,
  tooltip = '',
  className = '',
  ...props
}) => {
  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || props.name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.FieldWrapper>
        <Styled.Input
          {...props}
          className={[!!error ? 'has-error' : '', className].join(' ')}
        />
        <FieldErrorMessage error={error} />
      </Styled.FieldWrapper>
    </Styled.Wrapper>
  );
};

export default TextAreaField;
