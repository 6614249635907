import React, {useCallback} from 'react';
import * as Styled from './Toast.styles';
import {
  ToastProps as DefaultToastProps,
  AppearanceTypes,
} from 'react-toast-notifications';

const toastAppearanceIconMap: {[key in AppearanceTypes]: string} = {
  error: 'error',
  success: 'check_circle',
  info: 'help',
  warning: 'error',
};

const Toast: React.FC<DefaultToastProps> = ({
  children,
  appearance,
  onDismiss,
  transitionState,
}) => {
  const handleDismiss = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onDismiss && onDismiss();
    },
    [onDismiss]
  );

  return (
    <Styled.Wrapper
      className={[
        `toast-appearance-${appearance}`,
        `toast-transition-${transitionState}`,
      ].join(' ')}
    >
      <i className={`icon icon-${toastAppearanceIconMap[appearance]}`} />
      <Styled.Message>{children}</Styled.Message>
      <Styled.DismissButton onClick={handleDismiss}>
        &times;
      </Styled.DismissButton>
    </Styled.Wrapper>
  );
};

export default Toast;
