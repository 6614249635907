import {StatusMap} from '../../components/StatusBadge/StatusBadge';
import theme from '../../shared/theme';

export default {
  pending: {
    color: 'gray',
    title: 'Pending',
  },
  creating: {
    color: theme.colors.orange,
    title: 'Creating',
  },
  running: {
    color: theme.colors.green,
    title: 'running',
  },
  failed: {
    color: theme.colors.danger,
    title: 'failed',
  },
} as StatusMap;
