import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Wrapper = styled.button`
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 5px 14px 5px 5px;
  border-radius: 30px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  margin-left: auto;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const Avatar = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: ${({theme}) => theme.colors.lightPrimary};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Username = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const DropdownArrow = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-family: 'icomoon' !important;
  font-size: 10px;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  transform: rotate(90deg);
  transition: 0.2s;
  .header-user-expanded & {
    transform: rotate(0);
  }
  &:before {
    content: '\\e914';
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  /* border: 1px solid ${(props) => props.theme.colors.borderColor}; */
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  transform: translateY(3px);
  overflow: hidden;
`;

export const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const DropdownItem = styled.li`
  color: ${({theme}) => theme.colors.text};
  text-align: left;
`;

export const DropdownLink = styled(Link)`
  display: block;
  padding: 10px 16px;
  text-decoration: none;
  font-size: 14px;
  color: inherit;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.lightPrimary};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
