import React from 'react';
import {BuildModel} from '@features/workspaces/build.model';
import BuildStatus from '@features/workspaces/components/BuildStatus';
import * as Styled from './BuildDetails.styles';
import {formatDifferenceToNow} from '@utils/date.utils';
import {BuildStatusEnum} from '@features/workspaces/build-status.enum';
import {useCallback} from 'react';

type BuildDetailsProps = {
  build: BuildModel;
  onViewLogs?: (executionId: string) => void;
};

const BuildDetails: React.FC<BuildDetailsProps> = ({build, onViewLogs}) => {
  const handleViewLogs = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (typeof onViewLogs === 'function') {
        onViewLogs(build.executionId);
      }
    },
    [build.executionId, onViewLogs]
  );

  return (
    <Styled.Wrapper>
      <Styled.RevisionId>{build.revisionId}</Styled.RevisionId>
      <Styled.RevisionDescription>
        {build.revisionSummary}
        &nbsp;
        {build.status !== BuildStatusEnum.PENDING && (
          <Styled.ViewLogsLink onClick={handleViewLogs}>
            View the logs
          </Styled.ViewLogsLink>
        )}
      </Styled.RevisionDescription>
      <Styled.Status>
        <BuildStatus status={build.status} />
      </Styled.Status>
      <Styled.CreatedAt>
        {formatDifferenceToNow(build.updatedAt)}
      </Styled.CreatedAt>
    </Styled.Wrapper>
  );
};

export default BuildDetails;
