import Table from '@components/Table';
import {CompanyModel} from '@features/companies/company.model';
import {format} from 'date-fns';
import React from 'react';
import * as Styled from './ManagedCompaniesScreen.styles';

type ManagedCompaniesScreenProps = {
  companies: CompanyModel[];
};

const ManagedCompaniesScreen: React.VFC<ManagedCompaniesScreenProps> = ({
  companies,
}) => {
  return (
    <Styled.Wrapper>
      <Table
        data={companies}
        columnDefinitions={[
          {title: 'Name', accessor: (row) => <strong>{row.name}</strong>},
          {title: 'Project Count', accessor: 'workspaceCount', compact: true},
          {
            title: 'Created at',
            compact: true,
            accessor: (row) => format(new Date(row.createdAt), 'MMM d, yyyy'),
          },
        ]}
      />
    </Styled.Wrapper>
  );
};

export default ManagedCompaniesScreen;
