import {RootState} from '@app/store';
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import {CompaniesService} from './companies.service';
import {CompanyModel} from './company.model';
import {CompanyFilterDto} from './dto/company-filter.dto';
import {CompanyUpdateDto} from './dto/company-update.dto';

type CompaniesState = {
  data: CompanyModel[];
  isLoading: boolean;
  message: string;
  errors: object;
};

const initialState: CompaniesState = {
  data: [],
  isLoading: false,
  message: '',
  errors: {},
};

export const fetchCompanies = createAsyncThunk<
  Promise<any>,
  CompanyFilterDto | undefined
>('companies/get-all', async (payload, api) => {
  try {
    const companyService = new CompaniesService();
    const response = await companyService.getAll(payload);
    return response.data as CompanyModel[];
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const fetchCompany = createAsyncThunk<Promise<any>, string>(
  'companies/get-single',
  async (payload, api) => {
    try {
      const companyService = new CompaniesService();
      const response = await companyService.getById(payload);
      return response.data as CompanyModel;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const updateCompany = createAsyncThunk<
  Promise<any>,
  {id: string; data: CompanyUpdateDto}
>('companies/update', async (payload, api) => {
  try {
    const companiesService = new CompaniesService();
    const response = await companiesService.update(payload.id, payload.data);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const archiveCompany = createAsyncThunk<Promise<any>, string>(
  'companies/archive',
  async (payload, api) => {
    try {
      const companiesService = new CompaniesService();
      await companiesService.archive(payload);
      return payload;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const forceDeleteCompany = createAsyncThunk<Promise<any>, string>(
  'companies/archive',
  async (payload, api) => {
    try {
      const companiesService = new CompaniesService();
      await companiesService.forceDelete(payload);
      return payload;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: {
    // List companies
    [fetchCompanies.pending.toString()]: (state) => {
      state.isLoading = true;
      state.message = '';
      state.errors = {};
    },
    [fetchCompanies.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    [fetchCompanies.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    // Get company by id
    [fetchCompany.pending.toString()]: (state) => {
      state.isLoading = true;
      state.message = '';
      state.errors = {};
    },
    [fetchCompany.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    [fetchCompany.fulfilled.toString()]: (
      state,
      action: PayloadAction<CompanyModel>
    ) => {
      state.isLoading = false;
      state.data = state.data
        .filter((company) => company.id !== action.payload.id)
        .concat(action.payload);
    },
    // Update company thunk
    [updateCompany.pending.toString()]: (state) => {
      state.isLoading = true;
      state.message = '';
      state.errors = {};
    },
    [updateCompany.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    [updateCompany.fulfilled.toString()]: (
      state,
      action: PayloadAction<CompanyModel>
    ) => {
      state.isLoading = false;
      const existingIndex = state.data.findIndex(
        (company) => company.id === action.payload.id
      );
      if (existingIndex >= 0) {
        state.data = [
          ...state.data.slice(0, existingIndex),
          action.payload,
          ...state.data.slice(existingIndex + 1),
        ];
      } else {
        state.data.push(action.payload);
      }
    },
    // Archive company thunk
    [archiveCompany.pending.toString()]: (state) => {
      // state.isLoading = true;
    },
    [archiveCompany.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.data = state.data.filter(
        (company) => company.id !== action.payload
      );
      state.message = 'Company deleted successfully';
    },
    [archiveCompany.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    // Force delete company thunk
    [forceDeleteCompany.pending.toString()]: (state) => {
      // state.isLoading = true;
    },
    [forceDeleteCompany.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.data = state.data.filter(
        (company) => company.id !== action.payload
      );
      state.message = 'Company deleted successfully';
    },
    [forceDeleteCompany.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
  },
});

export const getCompanies = (state: RootState) => state.companies;

export const getCompanyById = (state: RootState, companyId: string) =>
  state.companies.data.find((company) => company.id === companyId);

export default companiesSlice.reducer;
