import {darken, lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
`;

export const Title = styled.h3`
  font-size: 20px;
  margin: 0;
  em {
    display: inline-block;
    padding: 0 3px;
    font-style: normal;
    color: ${({theme}) => theme.colors.primary};
  }
  small {
    display: inline-block;
    margin-left: 0.5em;
    font-weight: 400;
    font-size: 18px;
    color: ${({theme}) => theme.colors.mediumGray};
  }
`;

export const LogEntries = styled.div`
  max-height: 800px;
  overflow-y: auto;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 8px;
  background: #fff;
  padding: 20px;
`;

export const LogEntry = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px 0;
  gap: 10px;

  em {
    display: inline-block;
    font-style: normal;
    /* padding: 1px 5px; */
    border-radius: 3px;
    /* background-color: ${({theme}) => theme.colors.lightPrimary}; */
    /* color: ${({theme}) => theme.colors.orange}; */
    color: ${({theme}) => theme.colors.primary};
    font-weight: 700;
  }

  > .message {
    font-size: 15px;
    margin: 0;
    overflow-wrap: anywhere;
  }
  > .timestamp {
    display: inline-block;
    white-space: nowrap;
    border-radius: 30px;
    padding: 2px 10px;
    font-size: 13px;
    font-weight: 700;
    background: ${({theme}) => darken(0.04, theme.colors.borderColor)};
  }
`;

export const LogGroups = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
`;

export const LogGroup = styled.li`
  position: relative;
  /* justify-content: space-between; */
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 700;
  z-index: 10;
  border: 1px transparent;
  cursor: pointer;

  .icon {
    font-size: 1.2em;
  }

  &.active {
    background-color: #fff;
    border-radius: 8px;
    color: ${({theme}) => theme.colors.primary};
    border: 1px solid ${({theme}) => theme.colors.borderColor};
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }
`;

export const LogGroupRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  & + & {
    margin-top: 3px;
  }
`;

export const LogGroupDuration = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  margin-left: 30px;
  font-size: 15px;
  font-weight: 400;

  strong {
    color: ${({theme}) => theme.colors.text};
    font-weight: 700;
  }
`;

export const LogActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  input {
    padding: 7px 16px;
    border-radius: 8px;
  }
  button.filter-reset {
    display: inline-flex;
    align-items: center;
    background: none;
    padding: 3px 10px;
    cursor: pointer;
    color: ${({theme}) => theme.colors.primary};
    outline: none;
    border: none;
    height: 100%;
    transition: 0.2s;

    &:hover {
      color: ${({theme}) => lighten(0.1, theme.colors.primary)};

      > span {
        background-color: ${({theme}) =>
          lighten(0.03, theme.colors.lightPrimary)};
      }
    }

    > span {
      display: inline-block;
      padding: 2px 6px;
      border-radius: 30px;
      background-color: ${({theme}) => theme.colors.lightPrimary};
      font-weight: 700;
      transition: 0.2s;
    }
  }
`;

export const LogActionBarSection = styled.div<{alignment?: 'left' | 'right'}>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${(props) => (props.alignment === 'right' ? `margin-left: auto;` : '')}
`;
