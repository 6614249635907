import React from 'react';
import * as Styled from './ResourceLink.styles';

type ResourceLinkProps = {
  to: string;
};

const ResourceLink: React.FC<ResourceLinkProps> = ({to, children}) => {
  return <Styled.LinkStyled to={to}>{children}</Styled.LinkStyled>;
};

export default ResourceLink;
