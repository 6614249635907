import pipelineStateMap from '@features/workspaces/pipeline-state.map';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import React from 'react';
import * as Styled from './PipelineState.styles';
import {PipelineState as PipelineStateEnum} from '@features/workspaces/pipeline-state.enum';

type PipelineStateProps = {
  pipeline: PipelineModel;
  variant?: 'default' | 'icon-only';
};

const PipelineState: React.VFC<PipelineStateProps> = ({
  pipeline,
  variant = 'default',
}) => {
  const pipelineState =
    pipelineStateMap[pipeline.state || PipelineStateEnum.QUEUED];

  return (
    <Styled.Wrapper>
      <i
        className={`icon icon-${pipelineState.icon}`}
        style={{color: pipelineState.color}}
      />
      {variant === 'default' && pipelineState.title}
    </Styled.Wrapper>
  );
};

export default PipelineState;
