import React, {useCallback, useState} from 'react';
import {TextFieldProps} from '../TextField/TextField';
import * as TextFieldStyled from '../TextField/TextField.styles';
import * as Styled from './PasswordField.styles';
import * as Yup from 'yup';

interface PasswordFieldProps extends TextFieldProps {
  touched?: boolean;
}

const validation = [
  {
    title: '8 character length at least',
    validation: (value: string) => value.length >= 8,
  },
  {
    title: 'At least one capital letter',
    validation: (value: string) => /[A-Z]/.test(value),
  },
  {
    title: 'At least one special characted (*@%#_&$+-.,\\/)',
    validation: (value: string) => /[*@%#_&$+-/\\.,]/.test(value),
  },
];

export const passwordValidationSchema = Yup.string()
  .required('Password is required')
  .min(8, '8 character length at least')
  .matches(/[A-Z]/, {message: 'Should have at least one capital letter'})
  .matches(/[*@%#_&$+-/\\.,]/, {
    message: 'Should have at least one special characted (*@%#_&$+-.,\\/)',
  });

const PasswordField: React.FC<PasswordFieldProps> = ({
  label,
  error,
  className,
  value,
  touched = false,
  ...props
}) => {
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
  const handleToggleRevealPassword = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setIsPasswordRevealed((prev) => !prev);
    },
    []
  );
  return (
    <TextFieldStyled.Wrapper>
      {label && (
        <TextFieldStyled.Label htmlFor={props.id || props.name}>
          {label}
        </TextFieldStyled.Label>
      )}
      <TextFieldStyled.FieldWrapper>
        <TextFieldStyled.Input
          {...props}
          type={isPasswordRevealed ? 'text' : 'password'}
          className={[!!error ? 'has-error' : '', className].join(' ')}
        />
        <Styled.RevealButton onClick={handleToggleRevealPassword}>
          {isPasswordRevealed ? (
            <i className="icon icon-visibility_off" />
          ) : (
            <i className="icon icon-visibility_on" />
          )}
        </Styled.RevealButton>
      </TextFieldStyled.FieldWrapper>
      <Styled.Validation>
        {validation.map((validation, key) => {
          const isValid = validation.validation(value as string);
          const isValidClassName = isValid ? 'valid' : 'invalid';
          return (
            <Styled.ValidationItem
              key={key}
              className={touched ? isValidClassName : ''}
            >
              {validation.title}
            </Styled.ValidationItem>
          );
        })}
      </Styled.Validation>
    </TextFieldStyled.Wrapper>
  );
};

export default PasswordField;
