import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import Modal from '@components/Modal';
import TextField from '@components/TextField';
import ToggleField from '@components/ToggleField';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {StripeCardElementChangeEvent} from '@stripe/stripe-js';
import React, {useCallback, useState} from 'react';
import * as Styled from './CreateSubscriptionModal.styles';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {ValidationErrors} from 'types/validation-errors';
import {CreateSubscriptionDto} from '@features/billing/dto/create-subscription.dto';
import FieldErrorMessage from '@components/FieldErrorMessage';

type CreateSubscriptionModalProps = {
  onClose: any;
  onSubmit: (dto: CreateSubscriptionDto) => Promise<any>;
  billingEmail?: string;
  isOpen: boolean;
  title?: string;
  errors?: ValidationErrors;
};

const validationSchema = Yup.object().shape({
  billingEmail: Yup.string()
    .email('Please provide a valid email')
    .required('Please enter billing email'),
});

const CreateSubscriptionModal: React.VFC<CreateSubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  billingEmail = '',
  errors = {},
  title = 'Setup subscription',
}) => {
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const stripe = useStripe();
  const elements = useElements();

  const submitPaymentMethod = useCallback(async () => {
    const cardElement = elements?.getElement(CardElement);
    if (stripe && cardElement) {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        setPaymentMethodError(error.message || 'Payment method is invalid.');
        throw error;
      }
      return paymentMethod?.id;
    }
  }, [elements, stripe]);

  const handleSubmit = useCallback(
    async (values) => {
      const paymentMethodId = await submitPaymentMethod();
      return onSubmit({paymentMethodId, ...values}).then(() => {
        onClose();
      });
    },
    [onClose, onSubmit, submitPaymentMethod]
  );

  const formik = useFormik({
    initialValues: {
      billingEmail,
      inviteBillingUser: true,
    },
    enableReinitialize: true,
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChange = useCallback((e: StripeCardElementChangeEvent) => {
    setIsEmpty(e.empty);
  }, []);

  console.log(formik);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={title} width="30em">
      <FormGroup>
        <TextField
          label="Billing email"
          name="billingEmail"
          value={formik.values.billingEmail}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={
            formik.touched.billingEmail && formik.errors.billingEmail
              ? formik.errors.billingEmail
              : ''
          }
        />
      </FormGroup>
      {formik.values.billingEmail !== billingEmail && (
        <FormGroup>
          <ToggleField
            inline
            name="inviteBillingUser"
            value={formik.values.inviteBillingUser}
            label="Invite user as a Billing Manager"
            onChange={formik.handleChange}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Styled.CardElementWrapper>
          <Styled.Label>Please provide your credit card info</Styled.Label>
          <Styled.CardElementInput>
            <CardElement
              onChange={handleChange}
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                  },
                },
              }}
            />
          </Styled.CardElementInput>
        </Styled.CardElementWrapper>
        <FieldErrorMessage error={paymentMethodError} />
      </FormGroup>
      <FormGroup>
        <Button
          disabled={formik.isSubmitting || !formik.isValid || isEmpty}
          isLoading={formik.isSubmitting}
          size="large"
          onClick={formik.submitForm}
        >
          Submit
        </Button>
      </FormGroup>
    </Modal>
  );
};

export default CreateSubscriptionModal;
