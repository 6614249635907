import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 5px;
  gap: 5px;
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  background-color: #fff;
`;

export const HeaderValue = styled.span`
  display: inline-block;
  padding: 5px 10px 5px;
  font-size: 15px;
  border-radius: 3px;
  background-color: ${({theme}) => theme.colors.borderColor};
  line-height: 1;

  button {
    display: inline-block;
    font-size: 16px;
    margin-left: 8px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 0 0 7px;
    border-left: 1px solid ${({theme}) => darken(0.1, theme.colors.borderColor)};
    transition: 0.2s;

    &:hover {
      color: ${({theme}) => theme.colors.primary};
    }
  }
`;

export const Popup = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: #fff;
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  margin-top: 3px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
`;

export const Options = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const Option = styled.li`
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const HeaderInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 6px 10px 5px;
  border-radius: 3px;
`;
