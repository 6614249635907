import React from 'react';
import * as Styled from './Toolbar.styles';
import {ToolbarActionProps} from './ToolbarAction';

type ToolbarProps = {
  children:
    | React.ReactElement<ToolbarActionProps>
    | React.ReactElement<ToolbarActionProps>[]
    | undefined[];
};

const Toolbar: React.FC<ToolbarProps> = ({children}) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default Toolbar;
