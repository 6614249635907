import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const FieldWrapper = styled.label`
  display: block;
  position: relative;
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  padding: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.borderColor};
  }
  /* overflow: hidden; */
  input[type='file'] {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;

export const Preview = styled.img`
  object-fit: contain;
  object-position: left;
  width: 100%;
  height: 100%;
  max-height: 60px;
  min-height: 25px;
`;

export const Description = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 14px;
  margin-top: 8px;
`;

export const Loader = styled.div`
  padding: 5px 11px;
`;

export const EmptyMessage = styled.span`
  display: flex;
  padding: 5px;
  gap: 5px;
  align-items: center;

  .icon {
    color: ${({theme}) => theme.colors.primary};
  }
`;
