import {WorkspaceService} from '@features/workspaces/workspace.service';
import {useQuery} from 'react-query';

const usePipeline = (data: {pipelineId: string; workspaceId: string}) => {
  const {data: pipeline, isLoading} = useQuery({
    queryFn: async () => {
      const workspaceService = new WorkspaceService();
      return workspaceService
        .getPipelineById(data.workspaceId, data.pipelineId)
        .then((response) => ({...response.data}));
    },
    queryKey: ['pipelines', data.workspaceId, data.pipelineId],
    enabled: !!data.pipelineId && !!data.workspaceId,
  });

  return {
    pipeline,
    isLoading,
  };
};

export default usePipeline;
