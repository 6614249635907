import Page from '@components/Page';
import CreateInvitationForm from '@features/invitations/components/CreateInvitationForm';
import {CreateInvitationDto} from '@features/invitations/dto/create-invitation.dto';
import {RoleModel} from '@features/users/role.model';
import React from 'react';

type CreateInvitationPageProps = {
  onSubmit: (values: CreateInvitationDto) => Promise<any>;
  roles: RoleModel[];
};

const CreateInvitationPage: React.VFC<CreateInvitationPageProps> = ({
  onSubmit,
  roles,
}) => {
  return (
    <Page title="Create user invitation">
      <CreateInvitationForm onSubmit={onSubmit} roles={roles} />
    </Page>
  );
};

export default CreateInvitationPage;
