import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import ArrayEditor from '@components/ArrayEditor';
import FormGroup from '@components/FormGroup';
import InfoMessage from '@components/InfoMessage';
import ObjectEditor from '@components/ObjectEditor';
import TextField from '@components/TextField';
import ToggleField from '@components/ToggleField';
import {ConnectorConfigType} from '@features/connectors/connector-config-type.enum';
import {ConnectorModel} from '@features/connectors/connector.model';
import * as Styled from './ConnectorConfigurationEditor.styles';
import {JsonEditor} from 'jsoneditor-react';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import PipelineFileUploadField from '@features/pipelines/components/PipelineFileUploadField';

type ConnectorConfigurationEditorProps = {
  connector: ConnectorModel;
  onChange: (value: any) => void;
  value: any;
};

const validationRuleMap: {[key in ConnectorConfigType]: any} = {
  [ConnectorConfigType.STRING]: Yup.string(),
  [ConnectorConfigType.EMAIL]: Yup.string().email(
    'Please provide valid email address'
  ),
  [ConnectorConfigType.OPTIONS]: Yup.array(),
  [ConnectorConfigType.INTEGER]: Yup.number(),
  [ConnectorConfigType.PASSWORD]: Yup.string(),
  [ConnectorConfigType.BOOLEAN]: Yup.boolean(),
  [ConnectorConfigType.ARRAY]: Yup.array(),
  [ConnectorConfigType.OBJECT]: Yup.array(),
  [ConnectorConfigType.JSON]: Yup.mixed(),
  [ConnectorConfigType.FILE_UPLOAD]: Yup.string(),
};

export const buildValidationRules = (connector?: ConnectorModel) => {
  if (!connector || !connector.config) {
    return Yup.object().required();
  }
  const rules = connector.config.reduce((prev, item) => {
    let rule = validationRuleMap[item.kind];
    if (item.isRequired) {
      rule = rule.required('Field is required');
    }
    prev[item.name] = rule;
    return prev;
  }, {} as {[key: string]: any});
  return Yup.object().shape(rules);
};

const configLineEditorMap: {[key in ConnectorConfigType]: React.ReactNode} = {
  [ConnectorConfigType.STRING]: (props: any) => <TextField {...props} />,
  [ConnectorConfigType.FILE_UPLOAD]: (props: any) => (
    <PipelineFileUploadField {...props} />
  ),
  [ConnectorConfigType.EMAIL]: (props: any) => (
    <TextField type="email" {...props} />
  ),
  [ConnectorConfigType.OPTIONS]: (props: any) => (
    <TextField type="email" {...props} />
  ),
  [ConnectorConfigType.INTEGER]: (props: any) => (
    <TextField type="number" {...props} />
  ),
  [ConnectorConfigType.PASSWORD]: (props: any) => (
    <TextField type="password" {...props} />
  ),
  [ConnectorConfigType.BOOLEAN]: (props: any) => <ToggleField {...props} />,
  [ConnectorConfigType.ARRAY]: (props: any) => <ArrayEditor {...props} />,
  [ConnectorConfigType.OBJECT]: (props: any) => <ObjectEditor {...props} />,
  [ConnectorConfigType.JSON]: (props: any) => (
    <JsonEditor
      schema={null}
      onChange={(value: any) =>
        props.onChange({target: {name: props.name, value}})
      }
      ace={ace}
      mode="code"
      theme="ace/theme/github"
      value={props.value}
    />
  ),
};

const ConnectorConfigurationEditor: React.VFC<
  ConnectorConfigurationEditorProps
> = ({connector, onChange, value}) => {
  const [focusedField, setFocusedField] = useState(0);

  const handleChange = useCallback(
    (e: {target: {name: string; value: any}}) => {
      onChange({...value, [e.target.name]: e.target.value});
    },
    [onChange, value]
  );

  return (
    <Styled.Wrapper>
      {!connector.config?.length && (
        <InfoMessage>This connector has no configuration defined.</InfoMessage>
      )}
      {connector.config?.map((configLine, key) => {
        const Component: any = configLineEditorMap[configLine.kind] || null;
        if (Component) {
          return (
            <FormGroup key={key}>
              <Styled.FieldWrapper>
                <Component
                  name={configLine.name}
                  label={configLine.label}
                  value={value[configLine.name]}
                  onChange={handleChange}
                  onFocus={() => setFocusedField(key + 1)}
                  required={configLine.isRequired}
                />
                {configLine.description && focusedField === key + 1 && (
                  <Styled.FieldDescription>
                    {configLine.description}
                  </Styled.FieldDescription>
                )}
              </Styled.FieldWrapper>
            </FormGroup>
          );
        }
        return null;
      })}
    </Styled.Wrapper>
  );
};

export default React.memo(ConnectorConfigurationEditor);
