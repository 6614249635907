import FieldErrorMessage from '@components/FieldErrorMessage';
import FieldHelpMessage from '@components/FieldHelpMessage';
import React, {
  FocusEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Styled from './Dropdown.styles';

export type DropdownOption = {
  title: string;
  value: any;
  children?: DropdownOption[];
  resource?: any;
};

export type DropdownProps = {
  options: DropdownOption[];
  value: any;
  placeholder?: string;
  label?: string;
  error?: string;
  selectFirstByDefault?: boolean;
  renderOption?: (option: DropdownOption) => ReactNode;
  renderValue?: (value: any) => ReactNode;
  onChange: (value: any) => void;
  tooltip?: React.ReactNode;
  onBlur?: FocusEventHandler;
  variant?: 'compact' | 'default';
};

const Dropdown: React.VFC<DropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Click to select',
  label = '',
  error,
  renderOption,
  renderValue,
  onBlur,
  tooltip,
  selectFirstByDefault = false,
  variant = 'default',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);

  const optionsGroupedByValue = useMemo(() => {
    return options.reduce((accum, option) => {
      accum[option.value] = option;
      return accum;
    }, {} as any);
  }, [options]);

  const renderedValue = useMemo(() => {
    if (renderValue) {
      return renderValue(value);
    }
    if (renderOption && optionsGroupedByValue[value]) {
      return renderOption(optionsGroupedByValue[value]);
    }
    return optionsGroupedByValue[value]?.title || placeholder;
  }, [optionsGroupedByValue, placeholder, renderOption, renderValue, value]);

  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleChange = useCallback(
    (value) => {
      onChange(value);
      setIsExpanded(false);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectFirstByDefault && options && options.length && !value) {
      handleChange(options[0].value);
    }
  }, [handleChange, options, selectFirstByDefault, value]);

  const handleOptionClick = useCallback(
    (value: any) => (e: React.MouseEvent<HTMLLIElement>) => {
      handleChange(value);
    },
    [handleChange]
  );

  const handleHeaderClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      toggleExpanded();
    },
    [toggleExpanded]
  );

  const handleOutsideClick = useCallback((e: any) => {
    if (fieldRef && !fieldRef.current?.contains(e.target)) {
      setIsExpanded(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <Styled.Wrapper
      ref={fieldRef}
      onBlur={onBlur}
      className={[`dropdown-variant-${variant}`].join(' ')}
    >
      {label && (
        <Styled.Label>
          {label}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.DropdownWrapper
        tabIndex={0}
        className={[isExpanded ? 'dropdown-state-expanded' : ''].join(' ')}
      >
        <Styled.Header onClick={handleHeaderClick}>
          {renderedValue}
        </Styled.Header>
        {isExpanded && (
          <Styled.Options>
            {options
              .filter((option) => option.value !== value)
              .map((option, key) => (
                <Styled.Option
                  key={key}
                  tabIndex={0}
                  onClick={handleOptionClick(option.value)}
                >
                  {renderOption ? renderOption(option) : option.title}
                </Styled.Option>
              ))}
          </Styled.Options>
        )}
      </Styled.DropdownWrapper>
      <FieldErrorMessage error={error} />
    </Styled.Wrapper>
  );
};

export default Dropdown;
