import React, {useCallback, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {updateConnector} from '@features/connectors/connectors.slice';
import {useToasts} from 'react-toast-notifications';
import EditCompanyConnectorDisplay from './EditCompanyConnector';
import {UpdateConnectorDto} from '@features/connectors/dto/update-connector.dto';
import {ConnectorModel} from '@features/connectors/connector.model';
import {useQuery} from 'react-query';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import {ConnectorsService} from '@features/connectors/connectors.service';
import ConfirmationModal from '@modals/ConfirmationModal';

const connectRedux = connect(null, {updateConnector});

type EditCompanyConnectorProps = {
  onGoBack: () => void;
  connector: ConnectorModel;
} & ConnectedProps<typeof connectRedux>;

const EditCompanyConnector: React.VFC<EditCompanyConnectorProps> = ({
  updateConnector,
  onGoBack,
  connector,
}) => {
  const [repoUrl, setRepoUrl] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);
  const {addToast} = useToasts();

  const isRepoValid = useMemo(() => {
    try {
      const url = new URL(repoUrl);
      if (url.username && !url.password) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }, [repoUrl]);

  const {data, isLoading} = useQuery({
    queryFn: async () => {
      const workspaceService = new WorkspaceService();
      return workspaceService
        .getRepoDetails(repoUrl)
        .then((response) => response.data);
    },
    queryKey: ['git-details', repoUrl],
    enabled: isRepoValid,
  });

  const handleSubmit = useCallback(
    (values: UpdateConnectorDto) => {
      return updateConnector({id: connector.id, data: values}).then(() => {
        onGoBack();
        addToast('Connector updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      });
    },
    [addToast, connector.id, onGoBack, updateConnector]
  );

  const handleDelete = useCallback(async () => {
    const connectorService = new ConnectorsService();
    setIsDeleting(true);
    try {
      await connectorService.remove(connector.id);
      setIsDeleting(false);
      onGoBack();
    } catch (e) {
      setIsDeleting(false);
    }
  }, [connector.id, onGoBack]);

  const handleShowConfirmationModal = useCallback(async () => {
    setIsConfirmationModalShown(true);
  }, []);

  return (
    <>
      <ConfirmationModal
        onConfirm={handleDelete}
        onDismiss={() => setIsConfirmationModalShown(false)}
        isOpen={isConfirmationModalShown}
        message={
          <>
            Are you sure you want to delete connector?
            <br />
            This process <strong>cannot be undone</strong>.
          </>
        }
      />
      <EditCompanyConnectorDisplay
        onSubmit={handleSubmit}
        onDelete={handleShowConfirmationModal}
        onGoBack={onGoBack}
        connector={connector}
        onRepoUrlChange={setRepoUrl}
        repoDetails={data}
        isLoadingRepoDetails={isLoading}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default connectRedux(EditCompanyConnector);
