import React from 'react';
import * as Styled from './InfoMessage.styles';

type InfoMessageProps = {};

const InfoMessage: React.FC<InfoMessageProps> = ({children}) => {
  return (
    <Styled.Wrapper>
      <Styled.Icon>
        <i className="icon icon-error" />
      </Styled.Icon>
      <Styled.Body>{children}</Styled.Body>
    </Styled.Wrapper>
  );
};

export default InfoMessage;
