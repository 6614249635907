import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
import './styles/global.css';
import './styles/icons.css';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {Auth0Provider, withAuthenticationRequired} from '@auth0/auth0-react';
import {ToastProvider} from 'react-toast-notifications';
import auth0Config from './config/auth0';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import theme from '@shared/theme';
import Root from '@components/Root';
import {AppState} from '@auth0/auth0-react';
import {Provider} from 'react-redux';
import store from './app/store';
import Toast from '@components/Toast';
import {CustomToastContainer} from '@components/Toast/Toast.styles';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import {Integrations} from '@sentry/tracing';
import CompleteInvitationPage from '@features/invitations/pages/CompleteInvitationPage';

// Sentry.init({
//   dsn: process.env['REACT_APP_SENTRY_DSN'],
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

const onRedirectCallback = (appState: AppState) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const stripePromise = loadStripe(process.env['REACT_APP_STRIPE_KEY'] || '');

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <ToastProvider
                placement="top-right"
                autoDismissTimeout={5000}
                components={{
                  Toast: Toast,
                  ToastContainer: CustomToastContainer,
                }}
              >
                <Elements stripe={stripePromise}>
                  <Switch>
                    <Route
                      path="/invitation/:token"
                      component={CompleteInvitationPage}
                      exact
                    />
                    <Route
                      path="/"
                      component={withAuthenticationRequired(Root)}
                    />
                  </Switch>
                </Elements>
              </ToastProvider>
            </ThemeProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
