import styled from 'styled-components';
import {media} from '../../../../shared/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  flex-grow: 1;
  max-width: 32em;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 30px;
  ${media.tablet} {
    font-size: 28px;
  }
  small {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mediumGray};
    margin-top: 5px;
    line-height: 1.5;
    letter-spacing: 0.01em;
  }
`;

export const Form = styled.div`
  width: 100%;
`;

export const Action = styled.div`
  margin-top: 40px;
`;

export const CardElementWrapper = styled.div``;

export const CardElementInput = styled.div`
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  padding: 12px 16px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;
