import {MetricsDataModel} from '@features/workspaces/metrics-data.model';
import React, {useCallback, useMemo} from 'react';
import * as Styled from './WorkspaceMonitoring.styles';
import {Line} from 'react-chartjs-2';
import {ChartOptions} from 'chart.js';
import {parseJSON, format} from 'date-fns';
import theme from '@shared/theme';
import {darken, lighten} from 'polished';
import RadioGroupField from '@components/RadioGroupField';
import {MetricsTimeFrame} from '@features/workspaces/metrics-time-frame.enum';
import Loader from '@components/Loader';

type WorkspaceMonitoringProps = {
  data: MetricsDataModel[];
  isLoading?: boolean;
  onChangeTimeFrame: (value: MetricsTimeFrame) => void;
  timeFrame: MetricsTimeFrame;
};

const getChartLabels = (
  timeFrame: MetricsTimeFrame,
  metrics: MetricsDataModel
) => {
  switch (timeFrame) {
    case MetricsTimeFrame.HOUR:
      return metrics.Timestamps?.map((timestamp) =>
        format(parseJSON(timestamp), 'HH:mm')
      );
    case MetricsTimeFrame.DAY:
      return metrics.Timestamps?.map((timestamp) =>
        format(parseJSON(timestamp), 'h:mm a')
      );
    case MetricsTimeFrame.WEEK:
      return metrics.Timestamps?.map((timestamp) =>
        format(parseJSON(timestamp), 'MMM dd')
      );
  }
};

const options: ChartOptions = {
  layout: {
    padding: {
      left: 10,
      right: 20,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          // console.log(context);
          return `${context.dataset.label} - ${
            Math.round((context.raw as number) * 100) / 100
          }%`;
        },
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
      cubicInterpolationMode: 'monotone',
      borderWidth: 5,
    },
    point: {
      radius: 2,
      backgroundColor: '#fff',
      hitRadius: 8,
      hoverRadius: 6,
      hoverBorderWidth: 3,
    },
  },
  scales: {
    x: {
      reverse: true,
      grace: '10%',
      grid: {
        borderColor: darken(0.05, theme.colors.borderColor),
        borderWidth: 3,
        color: lighten(0.02, theme.colors.borderColor),
        borderDash: [4, 4],
      },
    },
    y: {
      grace: '5%',
      grid: {
        borderColor: darken(0.05, theme.colors.borderColor),
        borderWidth: 3,
        color: lighten(0.01, theme.colors.borderColor),
      },
    },
  },
};

const chartDetailsByGroup: Record<string, {title: string; unit: string}> = {
  cpu: {
    title: 'CPU Perfomance',
    unit: '%',
  },
  memory: {
    title: 'Memory Perfomance',
    unit: '%',
  },
};

const WorkspaceMonitoring: React.VFC<WorkspaceMonitoringProps> = ({
  data,
  isLoading = false,
  onChangeTimeFrame,
  timeFrame,
}) => {
  const handleChange = useCallback(
    (e: {target: {value: MetricsTimeFrame}}) => {
      onChangeTimeFrame(e.target.value);
    },
    [onChangeTimeFrame]
  );

  const metricsData = useMemo(() => {
    return data.map((metrics, key) => ({
      group: metrics.Id,
      labels: getChartLabels(timeFrame, metrics),
      datasets: [
        {
          label: metrics.Label,
          data: metrics.Values || [],
          fill: false,
          borderColor: theme.colors.primary,
          backgroundColor: '#fff',
          borderWidth: 2,
          borderCapStyle: 'round',
        },
      ],
    }));
  }, [data, timeFrame]);

  if (isLoading) {
    return <Loader message="Loading perfomance metrics..." />;
  }

  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        <RadioGroupField
          name="granularity"
          value={timeFrame}
          options={[
            {label: 'Last Hour', value: MetricsTimeFrame.HOUR},
            {label: 'Day', value: MetricsTimeFrame.DAY},
            {label: 'Week', value: MetricsTimeFrame.WEEK},
          ]}
          onChange={handleChange}
        />
      </Styled.ActionBar>
      <Styled.Inner>
        {metricsData.length &&
          metricsData.map((data, key) => (
            <Styled.ChartWrapper key={key}>
              {data.group && (
                <Styled.ChartTitle>
                  {chartDetailsByGroup[data.group].title} (
                  {chartDetailsByGroup[data.group].unit})
                </Styled.ChartTitle>
              )}
              <Line type="line" options={options} data={data} />
            </Styled.ChartWrapper>
          ))}
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default WorkspaceMonitoring;
