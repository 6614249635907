import {lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  max-width: 32em;
  align-items: stretch;
  justify-content: stretch;
  padding: 5px;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: #fff;
  transition: 0.1s;
`;

export const Inner = styled.div`
  padding: 8px 16px;
  flex-grow: 1;

  &.highlighted {
    display: flex;
    align-items: center;
    flex-grow: 0;
    background-color: ${({theme}) => lighten(0.03, theme.colors.lightPrimary)};
    border-radius: 5px;
  }
`;

export const Title = styled.h4`
  margin: 0 0 5px;
  font-size: 18px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.primary};
`;

export const Price = styled.div`
  color: ${({theme}) => theme.colors.text};
  font-size: 18px;
  font-weight: 700;
  > small {
    font-size: 14px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mediumGray};
    margin-left: 3px;
  }
`;

export const Constraints = styled.div`
  display: flex;
  align-items: center;
`;

export const Constraint = styled.div`
  font-size: 14px;
  text-align: center;
  & + & {
    margin-left: 30px;
  }
  > .label {
    display: block;
    font-size: 14px;
    color: ${({theme}) => theme.colors.mediumGray};
  }
  > .value {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: ${({theme}) => theme.colors.primary};
    margin-bottom: 6px;
  }
`;
