import {StatusMap} from '@components/StatusBadge/StatusBadge';
import theme from '@shared/theme';
import {InvoiceStatusEnum} from './invoice-status.enum';

const invoiceStatusMap: StatusMap = {
  [InvoiceStatusEnum.DRAFT]: {
    title: 'Draft',
    color: theme.colors.primary,
  },
  [InvoiceStatusEnum.OPEN]: {
    title: 'Open',
    color: theme.colors.orange,
  },
  [InvoiceStatusEnum.PAID]: {
    title: 'Paid',
    color: theme.colors.green,
  },
  [InvoiceStatusEnum.UNCOLLECTIBLE]: {
    title: 'Uncollectible',
    color: theme.colors.danger,
  },
  [InvoiceStatusEnum.VOID]: {
    title: 'Void',
    color: theme.colors.mediumGray,
  },
};

export default invoiceStatusMap;
