import React from 'react';
import ContentLoader from 'react-content-loader';

const TableSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width={400}
    height={15}
    viewBox="0 0 400 15"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    preserveAspectRatio="none"
    style={{width: '100%'}}
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="432" height="15" />
  </ContentLoader>
);

export default TableSkeleton;
