import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
`;

export const Sidebar = styled.div`
  flex-basis: 250px;
  min-width: 250px;
  /* flex-grow: 1; */
  margin-right: 40px;
`;

export const Main = styled.div`
  flex-grow: 1;
`;
