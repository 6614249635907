import styled from 'styled-components';

export const Wrapper = styled.div``;

export const EditorWrapper = styled.div`
  .jsoneditor {
    border: 1px solid ${({theme}) => theme.colors.borderColor};
  }
  .jsoneditor-menu {
    display: none;
  }
`;

export const Toolbar = styled.div`
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Description = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 14px;
  margin-top: 8px;
`;
