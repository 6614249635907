import {ApiClient} from '../../services/api-client';
import {UpdateProfileDto} from './dto/update-profile.dto';
import {AxiosResponse} from 'axios';
import {AuthUser} from './auth-user.model';
import {CompleteRegistrationDto} from './dto/complete-registration.dto';

export class AuthService extends ApiClient {
  async updateProfile(dto: UpdateProfileDto) {
    return this.put('auth/profile', dto);
  }

  async getProfile(): Promise<AxiosResponse<AuthUser>> {
    return this.get('auth/profile');
  }

  async completeRegistration(dto: CompleteRegistrationDto) {
    return this.post('auth/complete-registration', dto);
  }
}
