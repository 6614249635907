import React, {useCallback, useEffect, useState} from 'react';
import {
  fetchConnectors,
  updateConnector,
} from '@features/connectors/connectors.slice';
import {connect, ConnectedProps} from 'react-redux';
import CompanyConnectorsDisplay from './CompanyConnectors';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import {useSelector} from '@app/store';
import {ConnectorModel} from '@features/connectors/connector.model';

const connectRedux = connect(null, {fetchConnectors, updateConnector});

type CompanyConnectorsProps = {
  onCreate: () => any;
  onEdit: (connector: ConnectorModel) => void;
} & ConnectedProps<typeof connectRedux>;

const CompanyConnectors: React.VFC<CompanyConnectorsProps> = ({
  fetchConnectors,
  updateConnector,
  onCreate,
  onEdit,
}) => {
  const [connectorType, setConnectorType] = useState(ConnectorType.SOURCE);

  const connectors = useSelector((state) => state.connectors.data);
  const isLoading = useSelector((state) => state.connectors.isLoading);

  const handleToggleEnabledStatus = useCallback(
    (connector: ConnectorModel) => {
      return updateConnector({
        id: connector.id,
        data: {is_enabled: !connector.is_enabled},
      });
    },
    [updateConnector]
  );

  const handleChangeConnectorType = useCallback(({target: {value}}) => {
    setConnectorType(value);
  }, []);

  useEffect(() => {
    fetchConnectors({type: connectorType});
  }, [connectorType, fetchConnectors]);

  return (
    <CompanyConnectorsDisplay
      isLoading={isLoading}
      connectors={connectors}
      connectorType={connectorType}
      onEnabledToggle={handleToggleEnabledStatus}
      onChangeConnectorType={handleChangeConnectorType}
      onCreate={onCreate}
      onEdit={onEdit}
    />
  );
};

export default connectRedux(CompanyConnectors);
