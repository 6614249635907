import {ApiClient} from '@services/api-client';
import {AxiosResponse} from 'axios';
import {CreatePlanDto} from './dto/create-plan.dto';
import {PlanModel} from './plan.model';

export class PlanService extends ApiClient {
  async getAll(): Promise<AxiosResponse<PlanModel[]>> {
    return this.get('plans');
  }

  async getById(id: string): Promise<AxiosResponse<PlanModel>> {
    return this.get(`plans/${id}`);
  }

  async create(dto: CreatePlanDto): Promise<AxiosResponse<PlanModel>> {
    return this.post('plans', dto);
  }

  async update(
    id: string,
    dto: Partial<CreatePlanDto>
  ): Promise<AxiosResponse<PlanModel>> {
    return this.put(`plans/${id}`, dto);
  }
}
