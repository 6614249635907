import {useEffect, useState} from 'react';
import {RoleModel} from '../role.model';
import {UserService} from '../user.service';

const useRoles = () => {
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userService = new UserService();
    userService.getRoles().then((response) => {
      setRoles(response.data);
      setIsLoading(false);
    });
  }, []);

  return {roles, isLoading};
};

export default useRoles;
