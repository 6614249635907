import React, {useCallback} from 'react';
import 'react-toggle/style.css';
import * as Styled from './ToggleField.styles';
import Toggle from 'react-toggle';

export type ToggleFieldProps = {
  name: string;
  inline?: boolean;
  id?: string;
  label?: string;
  value?: boolean;
  disabled?: boolean;
  required?: boolean;
  trueValueLabel?: string;
  falseValueLabel?: string;
  onChange: (e: {target: {name: string; value: boolean}}) => void;
};

const ToggleField: React.VFC<ToggleFieldProps> = ({
  name,
  id,
  label,
  value = false,
  required = false,
  inline = false,
  disabled,
  onChange,
  trueValueLabel,
  falseValueLabel,
}) => {
  const handleChange = useCallback(
    ({target: {checked}}) => {
      onChange({target: {name, value: checked}});
    },
    [name, onChange]
  );

  const handleClickPropagation = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  return (
    <Styled.Wrapper
      onClick={handleClickPropagation}
      className={[inline ? 'toggle-field-inline' : ''].join(' ')}
    >
      {label && (
        <Styled.Label htmlFor={id || name}>
          {label}
          {required && <span className="required">*</span>}
        </Styled.Label>
      )}
      <Styled.ToggleWrapper>
        <Toggle
          name={name}
          id={id}
          disabled={disabled}
          checked={value}
          onChange={handleChange}
        />
        {value && trueValueLabel && (
          <Styled.ValueLabel>{trueValueLabel}</Styled.ValueLabel>
        )}
        {!value && falseValueLabel && (
          <Styled.ValueLabel>{falseValueLabel}</Styled.ValueLabel>
        )}
      </Styled.ToggleWrapper>
    </Styled.Wrapper>
  );
};

export default ToggleField;
