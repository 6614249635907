import Button from '@components/Button';
import {GitReference} from '@components/GitReferencesSelector';
import CreateConnectorForm from '@features/connectors/components/CreateConnectorForm';
import {CreateConnectorDto} from '@features/connectors/dto/create-connector.dto';
import React from 'react';
import * as Styled from './CreateCompanyConnector.styles';

type CreateCompanyConnectorProps = {
  onSubmit: (values: CreateConnectorDto) => Promise<any>;
  onGoBack: () => any;
  repoDetails?: {references: GitReference[]; executable: string};
  tags?: string[];
  onRepoUrlChange: (value: string) => void;
  isLoadingRepoDetails?: boolean;
};

const CreateCompanyConnector: React.VFC<CreateCompanyConnectorProps> = ({
  onSubmit,
  onGoBack,
  onRepoUrlChange,
  isLoadingRepoDetails,
  repoDetails,
  tags = [],
}) => {
  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        <Button variant="secondary" onClick={onGoBack} icon="arrow_back">
          Go Back to Connector List
        </Button>
        {/* <Styled.Title>Add custom connector</Styled.Title> */}
      </Styled.ActionBar>
      <CreateConnectorForm
        onSubmit={onSubmit}
        onRepoUrlChange={onRepoUrlChange}
        repoDetails={repoDetails}
        tags={tags}
        isLoadingRepoDetails={isLoadingRepoDetails}
      />
    </Styled.Wrapper>
  );
};

export default CreateCompanyConnector;
