import React from 'react';

const MeltanoLogo = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 250 219"
    >
      <defs></defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_2-2" data-name="Layer 2-2">
          <path
            fill="#3438bf"
            d="M53 218h-7.4v-19.19c0-5.47-2.92-7.78-6.57-7.78-3.44 0-6.78 1.26-8.76 4.31a14.22 14.22 0 0 1 .11 2.11v20.41h-7.4V198c-.04-5-2.98-7-6.61-7a9.81 9.81 0 0 0-9 4.84V218H0v-32.34h7.4v3.47l.42.21A13.83 13.83 0 0 1 18.24 185a11.69 11.69 0 0 1 10.22 5.15 15.73 15.73 0 0 1 12.3-5.15c6.78 0 12.2 4.63 12.2 13.78Zm37.8-18.45v3.89H66.31c.52 6 4.06 8.95 11.25 9.05a26.2 26.2 0 0 0 9.8-1.89l1.36 5.47a25.4 25.4 0 0 1-11.16 2.53c-11.15 0-18.76-6.32-18.76-16.73 0-8.84 6.25-16.74 16.47-16.84 9.07-.11 14.91 5.05 15.53 14.52Zm-24.08-1.37H83.3c-.3-4.84-3.55-7.18-7.82-7.18a8.76 8.76 0 0 0-8.76 7.18Zm42 20.42C101 218.6 97 215 97 208.18v-37.46h7.4v36.93c0 2.95 2 4.32 5 4.53Zm1.14-32.94H115v-10.31h7.4v10.31h9.07v5.79h-9.07v15.05c0 3.78 2.19 5.47 5.22 5.47a11.9 11.9 0 0 0 4.69-.74l1.35 5.68a17 17 0 0 1-6.88 1.37c-6.67 0-11.78-4.1-11.78-11.47v-15.36h-5.11Zm51.4 2.42.31-.11v-2.21H169v32.31h-7.41v-3.26l-.41-.11a14 14 0 0 1-10.33 4.11c-8.86 0-16-7.47-16-16.84a16.62 16.62 0 0 1 16.37-16.84h.31a13.56 13.56 0 0 1 9.8 3Zm.31 19.57v-13.89a14.87 14.87 0 0 0-9.07-2.73 10.7 10.7 0 0 0 0 21.36c3.67.1 7.11-1.26 9.09-4.74Zm15.85-22h7.4v4l.42.1c2.4-2.73 6.36-4.73 11.57-4.73 6.57 0 12 4.63 12 13.57V218h-7.4v-19.19c0-5.47-3.23-7.78-7.2-7.78a11.25 11.25 0 0 0-9.38 4.42V218h-7.4Zm54.73-.63c10.22 0 17.83 7.47 17.83 16.84s-7.71 16.83-17.83 16.83-17.82-7.47-17.82-16.83 7.71-17 17.82-16.84Zm0 27.57a10.74 10.74 0 1 0-10.32-11v.32A10.33 10.33 0 0 0 232 212.49a.31.31 0 0 1 .21.11ZM233.84 28.43c-10.53 14-33.36 17.16-35.86 8.74s10.63-14.1 17.62-15c-40.25-10.73-32.95 17.89-55.26 20.63 12.3 6 25.13 15.89 33.78 17 14.8 2.31 35.55-5.8 39.72-31.37Z"
          />
          <path
            fill="#3438bf"
            d="M134 37.17a45.31 45.31 0 0 1-6.88-4.11 7.21 7.21 0 0 1-2.82-8.21 7.93 7.93 0 0 1 .94-1.89c.31.32 2.5 4.84 11.67 9 3.24 1.47 14.81 7.05 15.23 7.05 4.27.94 11-3.48 9.17-7-1.36-2-7.4-8.42-8-12.1-.62-2.95-.52-7.58-7-13.47C140.12.86 126.77-.72 117.39.65c2.71 2.31 7.3 6.52 2.92 8.73a46.06 46.06 0 0 0-7.3 4.21c-7.19 5.79-7.92 18.1.63 25.47 6.46 5.68 14.38 13.89 5 23.58-6.46 6.63-22.1 3.26-31.69-6.53C76.11 45.38 75 25 85.49 9L87 6.75c-.52.11-1 .21-1.67.32-2.5.84-5 1.68-7.4 2.74a70.73 70.73 0 0 0-34.2 31.67c-3.65 6.84-17.3 38 3.76 69.46.52-10.21 3.64-20.21 7.4-25.36 4-5.58 12.51-10.53 20.43 0 5.11 6.73 6.15 16.84 12.72 16.84s7.92-10.21 12.3-16.84c7.2-10.84 17-6.63 20.54-1a35.76 35.76 0 0 1 4.38 26.31c-4.69 22.73-46.92 22.42-61 17.89 0 0 14.8 13.37 42.43 13.68 9.91.11 42.64-3.79 55.46-38.41 15.19-40.89-21.82-63.41-28.15-66.88Zm-2.51-25.05c6.47-6.84 16.06 2.32 15.64 13.37-8.47-8.63-12.43-9.49-19.13-6.84-.21-2 2.51-5.58 3.44-6.53Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default MeltanoLogo;
