import Button from '@components/Button';
import Loader from '@components/Loader';
import RadioGroupField from '@components/RadioGroupField';
import ConnectorList from '@features/connectors/components/ConnectorList';
import {ConnectorModel} from '@features/connectors/connector.model';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import React, {useCallback} from 'react';
import * as Styled from './CompanyConnectors.styles';

type CompanyConnectorsProps = {
  connectors: ConnectorModel[];
  isLoading?: boolean;
  connectorType?: ConnectorType;
  onEnabledToggle: (connector: ConnectorModel) => Promise<any>;
  onChangeConnectorType: (e: {
    target: {name: string; value: ConnectorType};
  }) => void;
  onCreate?: () => any;
  onEdit: (connector: ConnectorModel) => any;
};

const CompanyConnectors: React.VFC<CompanyConnectorsProps> = ({
  connectors,
  isLoading = false,
  onEnabledToggle,
  connectorType = ConnectorType.SOURCE,
  onChangeConnectorType,
  onCreate,
  onEdit,
}) => {
  const handleAddCustom = useCallback(() => {
    if (onCreate) {
      onCreate();
    }
  }, [onCreate]);

  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        <RadioGroupField
          name="connector-type"
          onChange={onChangeConnectorType}
          value={connectorType}
          options={[
            {label: 'Source', value: ConnectorType.SOURCE},
            {label: 'Destination', value: ConnectorType.DESTINATION},
          ]}
        />
        <Button onClick={handleAddCustom} icon="add_circle1">
          Add custom
        </Button>
      </Styled.ActionBar>
      {isLoading ? (
        <Loader message="Loading connectors..." />
      ) : (
        <ConnectorList
          connectors={connectors}
          onEnabledToggle={onEnabledToggle}
          onCreate={handleAddCustom}
          onEdit={onEdit}
        />
      )}
    </Styled.Wrapper>
  );
};

export default CompanyConnectors;
