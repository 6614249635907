import {useCallback, useMemo, useState} from 'react';

function useSelectable<T extends {id: string | number}>() {
  const [selected, setSelected] = useState<{[key: string]: boolean}>({});

  const selectedIds = useMemo(() => {
    return Object.keys(selected).reduce<string[]>((accum, id) => {
      if (selected[id]) {
        accum.push(id);
      }
      return accum;
    }, []);
  }, [selected]);

  const handleSelect = useCallback(
    (item: T) => (isSelected: boolean) => {
      setSelected((prev) => ({...prev, [item.id]: isSelected}));
    },
    []
  );

  const handleBulkSelect = useCallback(
    (items: T[]) => (isSelected: boolean) => {
      setSelected((prev) => ({
        ...prev,
        ...items.reduce((accum, item) => {
          accum[item.id.toString()] = isSelected;
          return accum;
        }, {} as {[key: string]: boolean}),
      }));
    },
    []
  );

  const handleClearSelection = useCallback(() => {
    setSelected({});
  }, []);

  return {
    selected,
    selectedIds,
    onSelect: handleSelect,
    onBulkSelect: handleBulkSelect,
    clearSelection: handleClearSelection,
  };
}

export default useSelectable;
