import styled from 'styled-components';
import {darken, rgba} from 'polished';
import {
  Buttons,
  Input,
} from '../../../../components/TextField/TextField.styles';

export const Wrapper = styled.div`
  ${Buttons} {
    border-radius: 5px;
  }
  ${Input} {
    padding: 8px 12px;
    border-width: 1px !important;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    &:hover,
    &:focus {
      border-color: ${({theme}) => darken(0.1, theme.input.normal.borderColor)};
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${({theme}) => theme.colors.primary};

  .upload-button {
    margin-left: auto;
    display: inline-block;
    background: none;
    outline: none;
    padding: none;
    border: none;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
    color: ${({theme}) => theme.colors.primary};
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.colors.primary};
    min-width: 23px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    font-size: 13px;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    margin-right: 9px;
  }
`;

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${({theme}) => theme.colors.mediumGray};
  background-color: #fff;
  line-height: 1;
  font-size: 18px;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }

  &:active {
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const Message = styled.div`
  font-size: 14px;
  button {
    display: inline-block;
    background: none;
    outline: none;
    padding: none;
    border: none;
    font-weight: 700;
    cursor: pointer;
    padding: 0;
    margin: 0 0.3em;
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
    color: ${({theme}) => theme.colors.primary};
  }
`;
