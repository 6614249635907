import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${(props) => darken(0.03, props.theme.colors.lightGray)};
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
`;

export const StepList = styled.div`
  display: flex;
  justify-content: stretch;
  margin-bottom: 30px;
  border-bottom: 2px solid ${({theme}) => theme.colors.borderColor};
  gap: 40px;
  margin-top: -12px;

  .steps-variant-compact & {
    justify-content: center;
  }

  .steps-alignment-left & {
    justify-content: flex-start;
  }
`;

export const Index = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  width: 1.5em;
  min-width: 1.5em;
  flex-basis: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 1;
  background-color: ${({theme}) => darken(0.05, theme.colors.lightGray)};
  color: ${({theme}) => theme.colors.mediumGray};
`;

export const StepItem = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 12px 16px;
  font-size: 18px;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  background-color: transparent;
  transition: color, border-color 0.2s;

  .steps-variant-compact & {
    flex-grow: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    color: ${({theme}) => theme.colors.mediumGray};
  }

  &&.step-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: inherit;
    font-weight: 400;
    /* border-color: ${({theme}) => theme.colors.primary}; */
  }

  &.step-active {
    color: ${({theme}) => theme.colors.primary};
    font-weight: 700;
    border-color: ${({theme}) => theme.colors.primary};

    ${Index} {
      background-color: ${({theme}) => theme.colors.primary};
      color: #fff;
    }
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: stretch;
  margin-top: 30px;

  &.sticky {
    background-color: rgba(255, 255, 255, 0.7);
  }

  .steps-variant-compact & {
    justify-content: center;
  }
  .steps-alignment-left & {
    justify-content: flex-start;
  }
`;

export const NavigationButtonWrapper = styled.div`
  flex-grow: 1;
  .steps-variant-compact & {
    flex-grow: 0;
  }
  & + & {
    margin-left: 30px;
  }
`;

export const CurrentStep = styled.div``;
