import React from 'react';
import {useFormik} from 'formik';
import * as Styled from './CreateWorkspaceForm.styles';
import TextField from '@components/TextField';
import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import * as Yup from 'yup';
import InstanceTypeDropdown from '@features/workspaces/components/InstanceTypeDropdown';
import {HostingType} from '@features/workspaces/hosting-type.enum';
import PlatformSelector from '../PlatformSelector';
import Azure from '@features/workspaces/PlatformLogos/Azure';
import {PlatformType} from '@features/workspaces/platform-type.enum';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import AzureServiceAccountForm from '../AzureServiceAccountForm';
import {AzureServiceAccountDto} from '@features/workspaces/dto/azure-service-account.dto';
import {azureAccountValidationSchema} from '../AzureServiceAccountForm/AzureServiceAccountForm';
import {CreateWorkspaceDto} from '@features/workspaces/dto/create-workspace.dto';
import {InstallationType} from '@features/workspaces/installation-type.enum';
import WorkspaceTypeSelector from '../WorkspaceTypeSelector';

type CreateWorkspaceFormProps = {
  onSubmit: (values: object) => void | Promise<any>;
  errors?: {[key: string]: string[]};
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(20, 'Maximum length is 20 symbols')
    .required('Name is required'),
  instanceType: Yup.string()
    .oneOf(['sm', 'md', 'lg', 'xlg'])
    .required('Choose one option'),
  hostingType: Yup.string(),
  azureServiceAccount: Yup.object().when('hostingType', {
    is: (hostingType: HostingType) => hostingType === HostingType.ONPREMISES,
    then: azureAccountValidationSchema,
    otherwise: Yup.object().notRequired(),
  }),
  // sshKey: Yup.string().required('SSHKey value is required'),
});

const CreateWorkspaceForm: React.FC<CreateWorkspaceFormProps> = ({
  onSubmit,
  errors = {},
}) => {
  const formik = useFormik<CreateWorkspaceDto>({
    initialValues: {
      name: '',
      installationType: InstallationType.MELTANO,
      hostingType: HostingType.SINGERLY,
      platformType: PlatformType.AZURE_CONTAINERS,
      // sshKey: '',
      instanceType: '',
      azureServiceAccount: {
        subscription_id: '',
        client_id: '',
        client_secret: '',
        tenant_id: '',
      } as AzureServiceAccountDto,
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
  });

  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <GridItem gutterWidth={20}>
            <FormGroup>
              <TextField
                label="Project Name"
                name="name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                maxLength={20}
                error={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ''
                }
              />
            </FormGroup>
            <FormGroup>
              <WorkspaceTypeSelector
                name="installationType"
                value={formik.values.installationType}
                onChange={formik.handleChange}
              />
            </FormGroup>
            {/* <FormGroup>
              <RadioGroupField
                name="hostingType"
                label="Hosting Location"
                value={formik.values.hostingType}
                onChange={formik.handleChange}
                options={[
                  {label: 'Singerly Hosted', value: HostingType.SINGERLY},
                  {
                    label: 'Host on Private Cloud',
                    value: HostingType.ONPREMISES,
                  },
                ]}
              />
            </FormGroup> */}
            {formik.values.hostingType === HostingType.ONPREMISES && (
              <FormGroup>
                <PlatformSelector
                  value={formik.values.platformType}
                  name="platformType"
                  label="Platform"
                  onChange={formik.handleChange}
                  tooltip={
                    <>
                      Your project will be deployed on your private cloud
                      provider.
                      <br />
                      <br />
                      CI/CD and Git repository management will be handled by
                      Singerly.
                    </>
                  }
                  options={[
                    {
                      label: 'Azure Container Instances',
                      logo: <Azure />,
                      value: PlatformType.AZURE_CONTAINERS,
                      badge: 'Beta',
                      badgeStyle: 'accent',
                    },
                    {
                      label: 'Azure Kubernetes Service',
                      logo: <Azure />,
                      value: PlatformType.AZURE_K8S,
                      badge: 'Coming soon',
                      badgeStyle: 'gold',
                      disabled: true,
                    },
                  ]}
                />
              </FormGroup>
            )}
            <FormGroup>
              <InstanceTypeDropdown
                value={formik.values.instanceType}
                onChange={(value) =>
                  formik.handleChange({target: {name: 'instanceType', value}})
                }
              />
            </FormGroup>
            {/* <FormGroup>
          <TextAreaField
            label="Git user SSH key"
            rows={10}
            name="sshKey"
            value={formik.values.sshKey}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Please provide your public SSH RSA key to have access to your project's GIT repository"
            error={
              formik.touched.sshKey && formik.errors.sshKey
                ? formik.errors.sshKey
                : ''
            }
          />
        </FormGroup> */}
            <FormGroup>
              <Button
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.dirty
                }
                isLoading={formik.isSubmitting}
                size="large"
                onClick={formik.submitForm}
              >
                Create Project
              </Button>
            </FormGroup>
          </GridItem>
          <GridItem gutterWidth={20}>
            {formik.values.hostingType === HostingType.ONPREMISES && (
              <AzureServiceAccountForm
                fieldName="azureServiceAccount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.azureServiceAccount}
                errors={formik.errors}
                touched={formik.touched}
              />
            )}
          </GridItem>
        </Grid>
      </form>
    </Styled.Wrapper>
  );
};

export default CreateWorkspaceForm;
