import Loader from '@components/Loader';
import Page from '@components/Page';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import {CompanyStatusEnum} from '@features/companies/company-status.enum';
import {CompanyModel} from '@features/companies/company.model';
import CompanyList from '@features/companies/components/CompanyList';
import React, {useMemo} from 'react';
import * as Styled from './CompanyListPage.styles';

type CompanyListPageProps = {
  companies: CompanyModel[];
  isLoading?: boolean;
};

const CompanyListPage: React.FC<CompanyListPageProps> = ({
  companies,
  isLoading = false,
}) => {
  const archivedCompanies = useMemo(() => {
    return companies.filter(
      (company) => company.status === CompanyStatusEnum.ARCHIVED
    );
  }, [companies]);

  const activeCompanies = useMemo(() => {
    return companies.filter(
      (company) => company.status === CompanyStatusEnum.ACTIVE
    );
  }, [companies]);

  return (
    <Page title="Manage Companies">
      {isLoading ? (
        <Loader message="Loading companies..." />
      ) : (
        <Tabs>
          <Tab title="All">
            <CompanyList companies={companies} />
          </Tab>
          <Tab title="Active">
            <CompanyList companies={activeCompanies} />
          </Tab>
          <Tab title="Archived">
            <CompanyList companies={archivedCompanies} />
          </Tab>
        </Tabs>
      )}
    </Page>
  );
};

export default CompanyListPage;
