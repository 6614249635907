import TextField from '@components/TextField';
import {EnvironmentLine} from '@features/workspaces/environment-line.interface';
import React, {useCallback, useMemo} from 'react';
import * as Styled from './EnvironmentEditor.styles';

type EnvironmentEditorProps = {
  value?: EnvironmentLine[];
  required?: boolean;
  name?: string;
  id?: string;
  label?: string;
  onChange: (e: {target: {value: EnvironmentLine[]; name?: string}}) => void;
};

const EnvironmentEditor: React.VFC<EnvironmentEditorProps> = ({
  value = [],
  name = '',
  onChange,
  label,
  required = false,
  ...props
}) => {
  const valueTransformed: EnvironmentLine[] = useMemo(() => {
    if (!value.length) {
      return [{key: '', value: ''}];
    }
    return value;
  }, [value]);

  const handleKeyChange = useCallback(
    (index: number) => (e: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {
            key: e.target.value,
            value: item.value,
          };
        }
        return item;
      });
      if (index === valueTransformed.length - 1) {
        valueMapped = [...valueMapped, {key: '', value: ''}];
      }
      if (!e.target.value && index < valueTransformed.length - 1) {
        valueMapped = valueMapped.filter((item, _index) => _index !== index);
      }
      onChange({target: {value: valueMapped, name}});
    },
    [name, onChange, valueTransformed]
  );

  const handleValueChange = useCallback(
    (index: number) => ({target}: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {
            key: item.key,
            value: target.value,
          };
        }
        return item;
      });
      if (index === valueTransformed.length - 1) {
        valueMapped = [...valueMapped, {key: '', value: ''}];
      }
      onChange({target: {value: valueMapped, name}});
    },
    [name, onChange, valueTransformed]
  );

  const handleRemoveRow = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onChange({
        target: {
          value: valueTransformed.filter((item, _index) => _index !== index),
          name,
        },
      });
    },
    [name, onChange, valueTransformed]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || name}>
          {label}
          {required && <span className="required">*</span>}
        </Styled.Label>
      )}
      <Styled.ValuesTable>
        <Styled.ValuesHeader>
          <th>Variable Name</th>
          <th>Value</th>
          <th></th>
        </Styled.ValuesHeader>
        <tbody>
          {valueTransformed.map((valueObject, index) => (
            <Styled.ValuesRow key={index}>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.key}
                  onChange={handleKeyChange(index)}
                  placeholder="Enter the key"
                />
              </Styled.ValuesCell>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.value}
                  onChange={handleValueChange(index)}
                  placeholder="Enter the value or leave it blank"
                />
              </Styled.ValuesCell>
              <Styled.ActionsCell>
                <Styled.RemoveButton
                  tabIndex={-1}
                  title="Remove row"
                  onClick={handleRemoveRow(index)}
                >
                  &times;
                </Styled.RemoveButton>
              </Styled.ActionsCell>
            </Styled.ValuesRow>
          ))}
        </tbody>
      </Styled.ValuesTable>
    </Styled.Wrapper>
  );
};

export default EnvironmentEditor;
