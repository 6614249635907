import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {UserModel} from '@features/users/user.model';
import UserCardDisplay from './UserCard';

type UserCardProps = {
  user: UserModel;
};

const UserCard: React.FC<UserCardProps> = ({user}) => {
  const history = useHistory();

  const handleClick = useCallback(
    (_user: UserModel) => {
      history.push(`/users/${_user.id}`);
    },
    [history]
  );

  return <UserCardDisplay user={user} onClick={handleClick} />;
};

export default UserCard;
