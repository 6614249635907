import {RootState} from '@app/store';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ValidationErrors} from 'types/validation-errors';
import {ConnectorModel} from './connector.model';
import {ConnectorsService} from './connectors.service';
import {ConnectorFilterDto} from './dto/connector-filter.dto';
import {CreateConnectorDto} from './dto/create-connector.dto';
import {UpdateConnectorDto} from './dto/update-connector.dto';

type ConnectorsState = {
  data: ConnectorModel[];
  errors: ValidationErrors;
  isLoading: boolean;
  message: string;
};

const initialState: ConnectorsState = {
  data: [],
  isLoading: false,
  message: '',
  errors: {},
};

export const fetchConnectors = createAsyncThunk<
  ConnectorModel[],
  ConnectorFilterDto
>('connectors/get-all', async (payload = {}, api) => {
  try {
    const connnectorService = new ConnectorsService();
    const response = await connnectorService.getAll(payload);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const fetchConnectorById = createAsyncThunk<ConnectorModel, string>(
  'connectors/get-by-id',
  async (payload: string, api) => {
    try {
      const connnectorService = new ConnectorsService();
      const response = await connnectorService.getById(payload);
      return response.data;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const createConnector = createAsyncThunk<
  Promise<any>,
  CreateConnectorDto
>('connectors/create', async (payload, api) => {
  try {
    const connnectorService = new ConnectorsService();
    const response = await connnectorService.create(payload);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const updateConnector = createAsyncThunk<
  Promise<any>,
  {id: string; data: UpdateConnectorDto}
>('connectors/update', async (payload, api) => {
  try {
    const connectorService = new ConnectorsService();
    const response = await connectorService.update(payload.id, payload.data);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

const connectorsSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {},
  extraReducers: {
    // List connectors
    [fetchConnectors.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchConnectors.fulfilled.toString()]: (
      state,
      action: PayloadAction<ConnectorModel[]>
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [fetchConnectors.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    // Fetch connector by id
    [fetchConnectorById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchConnectorById.fulfilled.toString()]: (
      state,
      action: PayloadAction<ConnectorModel>
    ) => {
      state.isLoading = false;
      state.data = state.data
        .filter((connector) => connector.id !== action.payload.id)
        .concat(action.payload);
    },
    [fetchConnectorById.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    // Create connector
    [createConnector.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [createConnector.fulfilled.toString()]: (
      state,
      action: PayloadAction<ConnectorModel>
    ) => {
      state.isLoading = false;
      state.data.push(action.payload);
    },
    [createConnector.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    // Update connector
    [updateConnector.pending.toString()]: (state) => {
      state.isLoading = true;
      state.message = '';
      state.errors = {};
    },
    [updateConnector.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = action.payload.message;
      state.errors = action.payload.errors;
    },
    [updateConnector.fulfilled.toString()]: (
      state,
      action: PayloadAction<ConnectorModel>
    ) => {
      state.isLoading = false;
      state.data = state.data.map((connector) =>
        connector.id === action.payload.id ? action.payload : connector
      );
    },
  },
});

export const getConnectorById = (id: string) => (state: RootState) =>
  state.connectors.data.find(
    (connector: ConnectorModel) => connector.id === id
  );

export default connectorsSlice.reducer;
