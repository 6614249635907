import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.div``;

export const ListItem = styled.div`
  & + & {
    margin-top: 10px;
  }
`;
