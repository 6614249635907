import FieldHelpMessage from '@components/FieldHelpMessage';
import React, {useCallback} from 'react';
import FieldErrorMessage from '../FieldErrorMessage';
import * as Styled from './TextField.styles';

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  description?: string;
  error?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  buttons?: React.ReactNode;
  maxLength?: number;
  tooltip?: React.ReactNode;
}

const TextField: React.VFC<TextFieldProps> = ({
  error = '',
  label = '',
  className = '',
  buttons,
  inputRef,
  required,
  maxLength = 0,
  onChange,
  type = 'text',
  tooltip = '',
  description = '',
  ...props
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'text' && maxLength && e.target.value.length > maxLength) {
        return;
      }
      return typeof onChange === 'function' && onChange(e);
    },
    [maxLength, onChange, type]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || props.name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.FieldWrapper>
        <Styled.Input
          ref={inputRef}
          {...props}
          type={type}
          required={required}
          className={[!!error ? 'has-error' : '', className].join(' ')}
          onChange={handleChange}
        />
        <Styled.Buttons>
          {!!maxLength && typeof props.value === 'string' && (
            <Styled.MaxLengthIndicator>{`${props.value.length}/${maxLength}`}</Styled.MaxLengthIndicator>
          )}
          {buttons}
        </Styled.Buttons>
      </Styled.FieldWrapper>
      <FieldErrorMessage error={error} />
      {description && !error && (
        <Styled.Description>{description}</Styled.Description>
      )}
    </Styled.Wrapper>
  );
};

export default TextField;
