import React from 'react';
import {Link} from 'react-router-dom';
import * as Styled from './LinkButton.styles';

type LinkButtonProps = {
  to: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  icon?: string;
  isExternal?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'danger';
  fullWidth?: boolean;
};

const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  to,
  isExternal = false,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  fullWidth = false,
  icon,
  ...props
}) => {
  const extraProps = isExternal ? {target: '_blank', href: to} : {to};
  return (
    <Styled.Button
      as={isExternal ? 'a' : Link}
      className={[
        `button-size-${size}`,
        `button-variant-${variant}`,
        disabled ? 'button-disabled' : '',
        fullWidth ? 'button-fullwidth' : '',
        props.className || '',
      ].join(' ')}
      {...extraProps}
    >
      <>
        {icon && <i className={`icon icon-${icon}`} />}
        {children}
      </>
    </Styled.Button>
  );
};

export default LinkButton;
