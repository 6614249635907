import {PipelineSchedule} from './pipeline-schedule.enum';

const pipelineScheduleMap: {
  [key in PipelineSchedule]: {title: string};
} = {
  [PipelineSchedule.DAILY]: {
    title: 'Daily',
  },
  [PipelineSchedule.HOURLY]: {
    title: 'Hourly',
  },
  [PipelineSchedule.WEEKLY]: {
    title: 'Weekly',
  },
  [PipelineSchedule.MONTHLY]: {
    title: 'Monthly',
  },
};

export default pipelineScheduleMap;
