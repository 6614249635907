import styled from 'styled-components';
import {lighten, darken} from 'polished';

export const Wrapper = styled.div<{color: string}>`
  display: inline-block;
  padding: 3px 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
  letter-spacing: 0.07em;
  background-color: ${(props) => lighten(0.3, props.color)};
  color: ${(props) => darken(0.5, props.color)};
`;
