import Button from '@components/Button';
import LinkButton from '@components/LinkButton';
import StatusBadge from '@components/StatusBadge';
import billingStatusMap from '@features/billing/billing-status-map';
import {BillingStatus} from '@features/billing/billing-status.enum';
import {CompanyModel} from '@features/companies/company.model';
import {formatDistanceToNowStrict, parseJSON} from 'date-fns';
import React from 'react';
import * as Styled from './BillingStatusWidget.styles';

type BillingStatusWidgetProps = {
  company: Partial<CompanyModel>;
};

const billingStatusesToBeUsed = [
  BillingStatus.CANCELED,
  BillingStatus.TRIALING,
  BillingStatus.INCOMPLETE_EXPIRED,
  BillingStatus.UNPAID,
];

const BillingStatusWidget: React.VFC<BillingStatusWidgetProps> = ({
  company,
}) => {
  if (
    !company.billingStatus ||
    !billingStatusesToBeUsed.includes(company.billingStatus)
  ) {
    return null;
  }
  return (
    <Styled.Wrapper>
      <Styled.Inner>
        <Styled.Title>Subscription status</Styled.Title>
        <Styled.BillingStatusBadge>
          <StatusBadge
            statusMap={billingStatusMap}
            value={company.billingStatus}
          />
        </Styled.BillingStatusBadge>
        {company.billingStatus === BillingStatus.TRIALING && (
          <Styled.TrialPeriodEnd>
            <div className="title">Trial period ends in</div>
            <div className="value">
              {company.billingTrialEnds
                ? formatDistanceToNowStrict(parseJSON(company.billingTrialEnds))
                : '-'}
            </div>
          </Styled.TrialPeriodEnd>
        )}
        <Styled.ActionBar>
          <LinkButton to="/billing" variant="secondary" size="small" fullWidth>
            Go to Billing Details
          </LinkButton>
        </Styled.ActionBar>
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default BillingStatusWidget;
