import Loader from '@components/Loader';
import usePipeline from '@features/pipelines/data-layer/usePipeline';
import {UpdatePipelineDto} from '@features/workspaces/dto/update-pipeline.dto';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {useToasts} from 'react-toast-notifications';

import PipelineDetailsScreen from './PipelineDetailsScreen';

type Props = {
  workspaceId: string;
  pipelineId: string;
  onGoBack?: () => any;
};

export type UpdatePipelineKeys = keyof UpdatePipelineDto;
export type UpdatePipelineValues = UpdatePipelineDto[UpdatePipelineKeys];

const PipelineDetailsScreenContainer: React.VFC<Props> = ({
  pipelineId,
  workspaceId,
  onGoBack,
}) => {
  const queryClient = useQueryClient();
  const {addToast} = useToasts();

  const {isLoading, pipeline} = usePipeline({workspaceId, pipelineId});

  const handleSave = useCallback(
    (property: UpdatePipelineKeys) => async (value: UpdatePipelineValues) => {
      const workspaceService = new WorkspaceService();
      return workspaceService
        .updatePipeline(workspaceId, pipelineId, {
          [property]: value,
        })
        .then(() => {
          queryClient.invalidateQueries(['pipelines', workspaceId]);
          addToast('Pipeline updated successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, pipelineId, queryClient, workspaceId]
  );

  if (!pipeline || isLoading) {
    return <Loader message="Loading pipeline..." />;
  }

  return (
    <PipelineDetailsScreen
      pipeline={pipeline}
      workspaceId={workspaceId}
      onGoBack={onGoBack}
      onSave={handleSave}
    />
  );
};

export default PipelineDetailsScreenContainer;
