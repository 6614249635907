import styled from 'styled-components';
import {rgba} from 'polished';

export const Wrapper = styled.div`
  width: auto;
  position: relative;
  min-width: 10em;
  max-width: 20em;
`;

export const ToggleButton = styled.button`
  display: inline-flex;
  margin-left: auto;
  align-items: center;
  border: none;
  border-left: 1px solid ${({theme}) => theme.colors.borderColor};
  outline: none;
  background: none;
  cursor: pointer;
  line-height: 1;
  padding: 0 14px;

  .companies-dropdown-disabled & {
    display: none;
  }

  &:before {
    content: '\\e902';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 20px;
    color: ${({theme}) => theme.colors.mediumGray};
    transition: 0.2s;
  }
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
  padding: 8px 0;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  cursor: pointer;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s;
  .companies-dropdown-disabled & {
    cursor: default;
    box-shadow: none !important;
  }
  .companies-dropdown-disabled &:hover {
    border: 1px solid ${({theme}) => theme.colors.borderColor};
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  }
  &:hover {
    /* background-color: ${({theme}) => theme.colors.lightGray}; */
    border-color: ${({theme}) => theme.colors.primary};
    box-shadow: 0 1px 0 0 ${({theme}) => rgba(theme.colors.primary, 0.5)};

    ${ToggleButton}:before {
      color: ${({theme}) => theme.colors.primary};
    }
  }
`;

export const HeaderInner = styled.div`
  padding: 0 20px;
`;

export const Selected = styled.div`
  font-size: 17px;
  font-weight: 700;
`;

export const Label = styled.div`
  font-size: 13px;
  color: ${({theme}) => theme.colors.mediumGray};
  margin-bottom: 2px;
`;

export const List = styled.div`
  min-width: 100%;
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 2px;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
`;

export const ChildrenList = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 5px;
  position: absolute;
  margin-top: -1px;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;

  &.has-children:after {
    content: '\\e914';
    display: inline-block;
    width: 0.7em;
    margin-left: auto;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  }
  &.active {
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
  &:hover {
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};

    ${ChildrenList} {
      display: block;
      color: ${({theme}) => theme.colors.text};
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: -1px;
    height: 1px;
    background-color: ${({theme}) => theme.colors.lightGray};
  }
  &:last-child:before {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
