import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const SidebarListItem = styled.li`
  margin-bottom: 2px;
`;

export const SidebarLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  border-radius: 8px;
  font-size: 18px;
  color: #676767;
  padding: 11px 16px;
  &.active {
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: #000;
    font-weight: 700;
  }
  &.active > .icon {
    color: ${({theme}) => theme.colors.primary};
  }
  & > .icon {
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
  }
`;

export const BillingWidget = styled.div`
  margin-top: 50px;
`;
