import {ApiClient} from '@services/api-client';
import {AxiosResponse} from 'axios';

export class PipelineService extends ApiClient {
  async uploadPipelineFile(file: File): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append('file', file);
    return this.post(`/pipelines/file-upload`, formData);
  }

  async run(workspaceId: string, pipelineId: string) {
    return this.post(`/workspaces/${workspaceId}/pipelines/${pipelineId}/run`);
  }
}
