import React, {useCallback, useEffect, useState} from 'react';
import ListConnectorsPageDisplay from './ListConnectorsPage';
import {
  fetchConnectors,
  updateConnector,
} from '@features/connectors/connectors.slice';
import {connect, ConnectedProps} from 'react-redux';
import {useSelector} from '@app/store';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import {ConnectorModel} from '@features/connectors/connector.model';
import {useHistory} from 'react-router';

const connectRedux = connect(null, {fetchConnectors, updateConnector});

type ListConnectorsPageProps = {} & ConnectedProps<typeof connectRedux>;

const ListConnectorsPage: React.VFC<ListConnectorsPageProps> = ({
  fetchConnectors,
  updateConnector,
}) => {
  const connectors = useSelector((state) => state.connectors.data);
  const [connectorType, setConnectorType] = useState(ConnectorType.SOURCE);
  const isLoading = useSelector((state) => state.connectors.isLoading);
  const history = useHistory();

  const handleToggleEnabledStatus = useCallback(
    (connector: ConnectorModel) => {
      return updateConnector({
        id: connector.id,
        data: {is_enabled: !connector.is_enabled},
      });
    },
    [updateConnector]
  );

  const handleCreateConnector = useCallback(() => {
    history.push('/own-connectors/create');
  }, [history]);

  const handleEditConnector = useCallback(
    (connector: ConnectorModel) => {
      history.push(`/own-connectors/${connector.id}`);
    },
    [history]
  );

  useEffect(() => {
    fetchConnectors({type: connectorType});
  }, [connectorType, fetchConnectors]);

  return (
    <ListConnectorsPageDisplay
      connectors={connectors}
      isLoading={isLoading}
      onChangeConnectorType={setConnectorType}
      onEnabledToggle={handleToggleEnabledStatus}
      onCreate={handleCreateConnector}
      onEdit={handleEditConnector}
    />
  );
};

export default connectRedux(ListConnectorsPage);
