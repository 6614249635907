import React from 'react';
import Button from '@components/Button';
import {BuildModel} from '@features/workspaces/build.model';
import BuildDetails from '@features/workspaces/components/BuildDetails';
import * as Styled from './Builds.styles';

type BuildsProps = {
  builds: BuildModel[];
  onViewLogs?: (executionId: string) => void;
};

const Builds: React.FC<BuildsProps> = ({builds, onViewLogs}) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>
          Builds <small>(last 10)</small>
        </Styled.Title>
        {/* <Button variant="secondary" icon="progress" onClick={() => null}>
          Start Build
        </Button> */}
      </Styled.Header>
      <Styled.Sections>
        {builds.map((build, key) => (
          <Styled.Section key={key}>
            <BuildDetails build={build} onViewLogs={onViewLogs} />
          </Styled.Section>
        ))}
      </Styled.Sections>
    </Styled.Wrapper>
  );
};

export default Builds;
