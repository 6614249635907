import {getIn} from 'formik';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as Styled from './ConnectorConfigEditor.styles';
import * as Yup from 'yup';
import startCase from 'lodash/startCase';
import {ConnectorConfigLineModel} from '@features/connectors/connector-config-line.model';
import {ConnectorConfigType} from '@features/connectors/connector-config-type.enum';
import TextField from '@components/TextField';
import Dropdown from '@components/Dropdown';
import Button from '@components/Button';
import GridItem from '@components/Grid/GridItem';
import Grid from '@components/Grid';
import ConfigValueEditorByType from './ConfigValueEditorByType';
import ToggleField from '@components/ToggleField';
import TextAreaField from '@components/TextAreaField';

type ConnectorConfigEditorProps = {
  onChange: any;
  onBlur: any;
  values: ConnectorConfigLineModel[];
  errors: object;
  touched: object;
  fieldName: string;
};

export const validationSchema = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string().required('Please fill in the parameter name'),
      label: Yup.string().required(
        'Please provide display name for the parameter'
      ),
      kind: Yup.string()
        .oneOf([
          ConnectorConfigType.STRING,
          ConnectorConfigType.FILE_UPLOAD,
          ConnectorConfigType.JSON,
          ConnectorConfigType.ARRAY,
          ConnectorConfigType.BOOLEAN,
          ConnectorConfigType.EMAIL,
          ConnectorConfigType.INTEGER,
          ConnectorConfigType.OBJECT,
          ConnectorConfigType.OPTIONS,
          ConnectorConfigType.PASSWORD,
        ])
        .required('Please choose the type'),
    })
  )
  .min(1, 'Please add at least one configuration parameter');

const ConnectorConfigEditor: React.FC<ConnectorConfigEditorProps> = ({
  onChange,
  onBlur,
  values = [],
  errors = {},
  touched = {},
  fieldName,
}) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const rowsCountRef = useRef(values.length);

  const handleExpandToggle = useCallback(
    (index: number) =>
      (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setExpandedIndex((prev) => {
          if (index === prev) {
            return null;
          } else {
            return index;
          }
        });
      },
    []
  );

  const handleAddParameter = useCallback(() => {
    onChange({
      target: {
        name: fieldName,
        value: [
          ...values,
          {name: '', kind: ConnectorConfigType.STRING, description: ''},
        ],
      },
    });
  }, [fieldName, onChange, values]);

  const handleDeleteConfigLine = useCallback(
    (index: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onChange({
        target: {
          name: fieldName,
          value: [...values.filter((_, _index) => _index !== index)],
        },
      });
    },
    [fieldName, onChange, values]
  );

  const handleChangeName = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const nameValue = e.target.value;
      if (
        nameValue &&
        (!values[index].label || !getIn(touched, `${fieldName}.${index}.label`))
      ) {
        onChange({
          target: {
            name: `${fieldName}.${index}.label`,
            value: startCase(nameValue),
          },
        });
      }
      onChange(e);
    },
    [fieldName, onChange, touched, values]
  );

  useEffect(() => {
    if (values.length > rowsCountRef.current) {
      setExpandedIndex(values.length - 1);
    }
    rowsCountRef.current = values.length;
  }, [values.length]);

  return (
    <Styled.Wrapper>
      {values.map((configLine, index) => (
        <Styled.ConfigLineWrapper
          key={index}
          className={[
            index === expandedIndex ? 'config-line-expanded' : '',
          ].join(' ')}
        >
          <Styled.ConfigLineHeader onClick={handleExpandToggle(index)}>
            <Styled.ConfigLineExpandToggle onClick={handleExpandToggle(index)}>
              <i className="icon icon-arrow_forward" />
            </Styled.ConfigLineExpandToggle>
            <Styled.DeleteButton onClick={handleDeleteConfigLine(index)}>
              &times;
            </Styled.DeleteButton>
            <Styled.ConfigLineHeaderInner>
              <Styled.ConfigLineNameParameter>
                {configLine.name || '[Not Configured]'}
              </Styled.ConfigLineNameParameter>
              <Styled.ConfigLineTypeParameter>
                {configLine.kind || '[Not Configured]'}
              </Styled.ConfigLineTypeParameter>
            </Styled.ConfigLineHeaderInner>
          </Styled.ConfigLineHeader>
          <Styled.ConfigLineBody>
            <Grid>
              <GridItem>
                <Styled.ConfigLineFieldWrapper className="connector-config-line-name">
                  <TextField
                    label="Parameter Name"
                    placeholder="e.g. hostname"
                    required
                    name={`${fieldName}.${index}.name`}
                    value={configLine.name}
                    onBlur={onBlur}
                    onChange={handleChangeName(index)}
                    error={
                      getIn(errors, `${fieldName}.${index}.name`) &&
                      getIn(touched, `${fieldName}.${index}.name`)
                        ? getIn(errors, `${fieldName}.${index}.name`)
                        : null
                    }
                  />
                </Styled.ConfigLineFieldWrapper>
                <Styled.ConfigLineFieldWrapper className="connector-config-line-label">
                  <TextField
                    label="Display Name"
                    placeholder="e.g. Hostname"
                    required
                    name={`${fieldName}.${index}.label`}
                    value={configLine.label}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={
                      getIn(errors, `${fieldName}.${index}.label`) &&
                      getIn(touched, `${fieldName}.${index}.label`)
                        ? getIn(errors, `${fieldName}.${index}.label`)
                        : null
                    }
                  />
                </Styled.ConfigLineFieldWrapper>
                <Styled.ConfigLineFieldWrapper className="connector-config-line-description">
                  <TextAreaField
                    label="Description (optional)"
                    name={`${fieldName}.${index}.description`}
                    value={configLine.description}
                    onBlur={onBlur}
                    onChange={onChange}
                    rows={3}
                    error={
                      getIn(errors, `${fieldName}.${index}.description`) &&
                      getIn(touched, `${fieldName}.${index}.description`)
                        ? getIn(errors, `${fieldName}.${index}.description`)
                        : null
                    }
                  />
                </Styled.ConfigLineFieldWrapper>
              </GridItem>
              <GridItem>
                <Styled.ConfigLineFieldWrapper className="connector-config-line-type">
                  <Grid>
                    <GridItem cols={5}>
                      <Dropdown
                        label="Parameter Type"
                        options={[
                          {title: 'String', value: ConnectorConfigType.STRING},
                          {
                            title: 'Integer',
                            value: ConnectorConfigType.INTEGER,
                          },
                          {title: 'Email', value: ConnectorConfigType.EMAIL},
                          {
                            title: 'Password',
                            value: ConnectorConfigType.PASSWORD,
                          },
                          {
                            title: 'Boolean',
                            value: ConnectorConfigType.BOOLEAN,
                          },
                          {
                            title: 'File Upload',
                            value: ConnectorConfigType.FILE_UPLOAD,
                          },
                          {title: 'Array', value: ConnectorConfigType.ARRAY},
                          {title: 'Object', value: ConnectorConfigType.OBJECT},
                          {title: 'JSON', value: ConnectorConfigType.JSON},
                          {
                            title: 'Options',
                            value: ConnectorConfigType.OPTIONS,
                          },
                        ]}
                        value={configLine.kind}
                        onBlur={onBlur}
                        onChange={(value) =>
                          onChange({
                            target: {name: `${fieldName}.${index}.kind`, value},
                          })
                        }
                        error={
                          getIn(errors, `${fieldName}.${index}.kind`) &&
                          getIn(touched, `${fieldName}.${index}.kind`)
                            ? getIn(errors, `${fieldName}.${index}.kind`)
                            : null
                        }
                      />
                    </GridItem>
                    <GridItem>
                      <ToggleField
                        label="Is Required?"
                        name={`${fieldName}.${index}.isRequired`}
                        value={configLine.isRequired}
                        onChange={onChange}
                      />
                    </GridItem>
                  </Grid>
                </Styled.ConfigLineFieldWrapper>
                <Styled.ConfigLineFieldWrapper className="connector-config-line-value">
                  {![ConnectorConfigType.FILE_UPLOAD].includes(
                    configLine.kind
                  ) && (
                    <ConfigValueEditorByType
                      label="Default value (optional)"
                      kind={configLine.kind}
                      name={`${fieldName}.${index}.value`}
                      value={configLine.value}
                      onBlur={onBlur}
                      onChange={onChange}
                    />
                  )}
                </Styled.ConfigLineFieldWrapper>
              </GridItem>
            </Grid>
          </Styled.ConfigLineBody>
        </Styled.ConfigLineWrapper>
      ))}
      <Styled.ActionBar>
        <Button
          size="small"
          variant="primary"
          icon="add_circle1"
          onClick={handleAddParameter}
        >
          Add parameter
        </Button>
      </Styled.ActionBar>
    </Styled.Wrapper>
  );
};

export default ConnectorConfigEditor;
