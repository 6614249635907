import {ConnectorModel} from '@features/connectors/connector.model';
import React, {useCallback, useState} from 'react';
import * as Styled from './ConnectorCard.styles';

type ConnectorCardProps = {
  connector: ConnectorModel;
  onEnabledToggle: (connector: ConnectorModel) => Promise<any>;
  onEdit: (connector: ConnectorModel) => void;
};

const ConnectorCard: React.VFC<ConnectorCardProps> = ({
  connector,
  onEnabledToggle,
  onEdit,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleChange = useCallback(
    ({target}) => {
      setIsUpdating(true);
      onEnabledToggle(connector).then(() => setIsUpdating(false));
    },
    [connector, onEnabledToggle]
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onEdit(connector);
    },
    [connector, onEdit]
  );

  return (
    <Styled.Wrapper onClick={handleClick}>
      <Styled.Inner>
        <Styled.Logo>
          {connector.logo ? (
            <img src={connector.logo} alt={connector.name} />
          ) : (
            <i className="icon icon-electrical_services" />
          )}
        </Styled.Logo>
        <Styled.Group className="connector-group-full">
          <Styled.Title>{connector.label}</Styled.Title>
          <Styled.Description>{connector.description}</Styled.Description>
        </Styled.Group>
        {/* <Styled.Group>
          <Styled.GroupLabel>Enabled</Styled.GroupLabel>
          <Styled.GroupValue>
            <ToggleField
              name={`connector-enabled-toggle-${connector.id}`}
              disabled={isUpdating}
              value={connector.is_enabled}
              onChange={handleChange}
            />
          </Styled.GroupValue>
        </Styled.Group> */}
        {/* <Styled.Group>
          <Styled.GroupLabel>Price</Styled.GroupLabel>
          <Styled.GroupValue className="connector-group-value-price">
            {connector.price ? `$${connector.price}` : 'Free'}
          </Styled.GroupValue>
        </Styled.Group> */}
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default ConnectorCard;
