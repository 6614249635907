import FieldHelpMessage from '@components/FieldHelpMessage';
import {InstallationType} from '@features/workspaces/installation-type.enum';
import React, {useCallback} from 'react';
import * as Styled from './WorkspaceTypeSelector.styles';
import MeltanoLogo from './MeltanoLogo';
import SingerlyLogo from './SingerlyLogo';

export type WorkspaceTypeSelectorOption = {
  label: string;
  logo: React.ReactNode;
  value: InstallationType;
  description?: React.ReactNode;
  disabled?: boolean;
  badge?: string;
  badgeStyle?: 'accent' | 'gold';
};

type WorkspaceTypeSelectorProps = {
  value?: InstallationType;
  onChange: (e: {target: {name: string; value: InstallationType}}) => void;
  tooltip?: string;
  required?: boolean;
  name: string;
  label?: string;
};

const options: WorkspaceTypeSelectorOption[] = [
  {
    label: 'Meltano',
    logo: <MeltanoLogo />,
    value: InstallationType.MELTANO,
    description: 'Managed Meltano + Airflow infrastructure setup',
  },
  {
    label: 'Singerly Pipelines',
    logo: <SingerlyLogo />,
    value: InstallationType.AIRFLOW,
    badge: 'New',
    badgeStyle: 'gold',
    description: 'Managed Singer ETL Pipelines, powered by Airflow',
  },
];

const WorkspaceTypeSelector: React.VFC<WorkspaceTypeSelectorProps> = ({
  name,
  onChange,
  value,
  required = false,
  tooltip = '',
  label = 'Choose project type',
}) => {
  const handleChange = useCallback(
    (value: InstallationType) => (e: React.MouseEvent<HTMLDivElement>) => {
      onChange({target: {name, value}});
    },
    [name, onChange]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.Options>
        {options.map((option, key) => (
          <Styled.Option
            key={key}
            className={[
              option.disabled ? 'disabled' : '',
              option.value === value ? 'active' : '',
            ].join(' ')}
            tabIndex={option.disabled ? -1 : 0}
            onClick={handleChange(option.value)}
          >
            {option.badge && (
              <Styled.OptionBadge
                className={[option.badgeStyle || ''].join(' ')}
              >
                {option.badge}
              </Styled.OptionBadge>
            )}
            <Styled.OptionLogo>{option.logo}</Styled.OptionLogo>
            <Styled.OptionInner>
              <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
              <Styled.OptionDescription>
                {option.description}
              </Styled.OptionDescription>
            </Styled.OptionInner>
          </Styled.Option>
        ))}
      </Styled.Options>
    </Styled.Wrapper>
  );
};

export default WorkspaceTypeSelector;
