import {rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h3`
  font-size: 20px;
  margin: 0 0 14px;
`;

export const HelpLink = styled.div`
  margin: 0 0 20px;
  a {
    font-size: 14px;
    display: inline-block;
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.5)};

    &:hover {
      border-bottom-color: ${({theme}) => rgba(theme.colors.primary, 1)};
    }
  }
`;
