import {useQuery} from 'react-query';
import {WorkspaceService} from '../workspace.service';

const useWorkspace = (options: {
  companyId?: string;
  workspaceId?: string;
  onError?: () => any;
}) => {
  const {data, isLoading} = useQuery({
    queryKey: ['projects', options.companyId, options.workspaceId],
    refetchInterval: 30000,
    enabled: !!options.workspaceId && !!options.companyId,
    onError: options.onError,
    queryFn: () => {
      const workspaceService = new WorkspaceService();
      return workspaceService.getById(options.workspaceId || '');
    },
  });

  return {
    workspace: data?.data,
    isLoading,
  };
};

export default useWorkspace;
