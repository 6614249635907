import React from 'react';
import {useFormik} from 'formik';
import * as Styled from './ProfileForm.styles';
import * as Yup from 'yup';
import PasswordField, {
  passwordValidationSchema,
} from '@components/PasswordField/PasswordField';
import FormGroup from '@components/FormGroup';
import TextField from '@components/TextField';
import Button from '@components/Button';

type ProfileFormProps = {
  onSubmit: (values: object) => void | Promise<any>;
  initialData: {
    firstName?: string;
    lastName?: string;
    email: string;
  };
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  password: passwordValidationSchema.notRequired(),
  passwordConfirmation: Yup.string().test(
    'passwords-match',
    'Passwords must match',
    function (value) {
      return this.parent.password === value;
    }
  ),
});

/**
 * User Profile Form
 */
const ProfileForm: React.FC<ProfileFormProps> = ({onSubmit, initialData}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: initialData.firstName,
      lastName: initialData.lastName,
      password: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit,
  });
  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <TextField
            label="First Name"
            name="firstName"
            placeholder="Enter your first name"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Last Name"
            name="lastName"
            placeholder="Enter your last name"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Email"
            name="email"
            value={initialData.email}
            readOnly
            disabled
          />
        </FormGroup>
        <FormGroup>
          <PasswordField
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Password Confirmation"
            type="password"
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <Button
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            isLoading={formik.isSubmitting}
            size="large"
            onClick={formik.submitForm}
          >
            Submit
          </Button>
        </FormGroup>
      </form>
    </Styled.Wrapper>
  );
};

export default ProfileForm;
