import React from 'react';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Route, RouteProps} from 'react-router';

export interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType;
  premium?: boolean;
  permissions?: string[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  permissions,
  ...args
}) => {
  return <Route component={withAuthenticationRequired(component)} {...args} />;
};

export default PrivateRoute;
