import React, {useCallback, useEffect, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import EditUserPageDisplay from './EditUserPage';
import {
  updateUser,
  fetchUserById,
  removeUser,
} from '@features/users/users.slice';
import {UpdateUserDto} from '@features/users/dto/update-user.dto';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {unwrapResult} from '@reduxjs/toolkit';
import {RootState} from '@app/store';
import {UserModel} from '@features/users/user.model';

const connectRedux = connect(null, {updateUser, fetchUserById, removeUser});

type EditUserPageProps = ConnectedProps<typeof connectRedux>;

const EditUserPage: React.FC<EditUserPageProps> = ({
  updateUser,
  fetchUserById,
  removeUser,
}) => {
  const match = useRouteMatch<{userId: string}>();
  const history = useHistory();
  const {addToast} = useToasts();
  const user = useSelector<RootState, UserModel | undefined>((state) =>
    state.users.data.find((user) => user.id === match.params.userId)
  );
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.users.isLoading
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = useCallback(
    (values: UpdateUserDto) => {
      return updateUser({userId: match.params.userId, data: values})
        .then(unwrapResult)
        .then(() => {
          addToast('User updated successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, match.params.userId, updateUser]
  );

  const handleDelete = useCallback(() => {
    setIsDeleting(true);
    return removeUser(match.params.userId)
      .then(unwrapResult)
      .then(() => {
        setIsDeleting(false);
        addToast('User deleted successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push('/users');
      });
  }, [addToast, history, match.params.userId, removeUser]);

  useEffect(() => {
    fetchUserById({userId: match.params.userId});
  }, [fetchUserById, match.params.userId]);

  return (
    <EditUserPageDisplay
      onDelete={handleDelete}
      onSubmit={handleSubmit}
      isDeleting={isDeleting}
      isLoading={isLoading}
      user={user}
    />
  );
};

export default connectRedux(EditUserPage);
