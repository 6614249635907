import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import React, {useMemo} from 'react';

const IsParentCompany: React.FC<{}> = ({children}) => {
  const selectedCompany = useSelectedCompany();
  const isParentCompany = useMemo(() => {
    if (!selectedCompany) {
      return false;
    }
    return !selectedCompany.parentId;
  }, [selectedCompany]);

  if (isParentCompany) {
    return <>{children}</>;
  }

  return null;
};

export default IsParentCompany;
