import Loader from '@components/Loader';
import React, {useCallback, useState} from 'react';
import * as Styled from './Button.styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => any;
  fullWidth?: boolean;
  isLoading?: boolean;
  loadingMessage?: string;
  variant?: 'primary' | 'secondary' | 'danger';
  size?: 'x-small' | 'small' | 'medium' | 'large';
  icon?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  size = 'medium',
  variant = 'primary',
  isLoading = false,
  loadingMessage = 'Loading...',
  fullWidth = false,
  icon,
  onClick,
  ...props
}) => {
  const [isActionRunning, setIsActionRunning] = useState(false);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const result = onClick();
      if (result instanceof Promise) {
        setIsActionRunning(true);
        result.finally(() => setIsActionRunning(false));
      }
    },
    [onClick]
  );

  return (
    <Styled.Button
      {...props}
      className={[
        `button-size-${size}`,
        `button-variant-${variant}`,
        props.className || '',
        fullWidth ? 'button-fullwidth' : '',
        isLoading || isActionRunning ? 'button-loading' : '',
      ].join(' ')}
      onClick={handleClick}
    >
      {isLoading || isActionRunning ? (
        loadingMessage
      ) : (
        <>
          {icon && <i className={`icon icon-${icon}`} />}
          {children}
        </>
      )}
    </Styled.Button>
  );
};

export default Button;
