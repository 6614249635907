import React from 'react';
import * as Styled from './Loader.styles';

type LoaderProps = {
  message?: string;
  size?: number;
};

const Loader: React.VFC<LoaderProps> = ({message, size = 32}) => {
  return (
    <Styled.Wrapper size={size}>
      {message && <span>{message}</span>}
    </Styled.Wrapper>
  );
};

export default Loader;
