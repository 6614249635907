import styled from 'styled-components';
import {media} from '../../shared/theme';

export const Wrapper = styled.div``;

export const Header = styled.header`
  margin-bottom: 20px;
  ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  strong {
    color: ${({theme}) => theme.colors.primary};
  }
  ${media.tablet} {
    font-size: 24px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${media.tablet} {
    margin-left: auto;
  }
`;

export const Body = styled.div``;

export const Breadcrumbs = styled.div`
  margin-bottom: 7px;
`;
