import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
`;

export const Title = styled.h3`
  font-size: 20px;
  margin: 0;
  em {
    display: inline-block;
    padding: 0 3px;
    font-style: normal;
    color: ${({theme}) => theme.colors.primary};
  }
  small {
    display: inline-block;
    margin-left: 0.5em;
    font-weight: 400;
    font-size: 18px;
    color: ${({theme}) => theme.colors.mediumGray};
  }
`;
