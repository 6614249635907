import {rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 20px;
  background-color: ${({theme}) => theme.colors.lightPrimary};
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const Icon = styled.div`
  margin-right: 10px;
  font-size: 24px;
  color: ${({theme}) => theme.colors.primary};
`;

export const Body = styled.div`
  letter-spacing: 0.008em;
  line-height: 1.4em;
  a {
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.5)};
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
  }
`;
