import LinkButton from '@components/LinkButton';
import Loader from '@components/Loader';
import Page from '@components/Page';
import HasPermission from '@features/auth/components/HasPermission';
import PlanList from '@features/plans/components/PlanList';
import {PlanModel} from '@features/plans/plan.model';
import React from 'react';
import * as Styled from './PlanListPage.styles';

type PlanListPageProps = {
  plans: PlanModel[];
  isLoading?: boolean;
};

const PlanListPage: React.FC<PlanListPageProps> = ({
  plans,
  isLoading = false,
}) => {
  return (
    <Page
      title="Plans"
      actions={[
        <HasPermission permissions="create:plans">
          <LinkButton key="1" to="/plans/create" icon="add_circle1">
            Create new
          </LinkButton>
        </HasPermission>,
      ]}
    >
      {isLoading ? (
        <Loader message="Loading plans..." />
      ) : (
        <PlanList plans={plans} />
      )}
    </Page>
  );
};

export default PlanListPage;
