import {PrivateRouteProps} from '@components/PrivateRoute/PrivateRoute';
import ProfilePage from '@features/auth/pages/ProfilePage';
import CouponsPage from '@features/billing/pages/CouponsPage';
import CreateCouponPage from '@features/billing/pages/CreateCouponPage';
import CompanyBillingPage from '@features/companies/pages/CompanyBillingPage';
import CompanyDetailsPage from '@features/companies/pages/CompanyDetailsPage';
import CompanyListPage from '@features/companies/pages/CompanyListPage';
import CreateManagedCompanyPage from '@features/companies/pages/CreateManagedCompanyPage';
import ManagedCompaniesPage from '@features/companies/pages/ManagedCompaniesPage';
import CreateConnectorPage from '@features/connectors/pages/CreateConnectorPage';
import EditConnectorPage from '@features/connectors/pages/EditConnectorPage';
import ListConnectorsPage from '@features/connectors/pages/ListConnectorsPage';
import CreateInvitationPage from '@features/invitations/pages/CreateInvitationPage';
import CreatePlanPage from '@features/plans/pages/CreatePlanPage';
import EditPlanPage from '@features/plans/pages/EditPlanPage';
import PlanListPage from '@features/plans/pages/PlanListPage';
import EditUserPage from '@features/users/EditUserPage';
import CreateUserPage from '@features/users/pages/CreateUserPage';
import UserListingPage from '@features/users/pages/UserListingPage';
import CreateWorkspacePage from '@features/workspaces/pages/CreateWorkspacePage';
import WorkspaceDetailsPage from '@features/workspaces/pages/WorkspaceDetailsPage';
import WorkspaceListPage from '@features/workspaces/pages/WorkspaceListPage';

const appRoutes: PrivateRouteProps[] = [
  {
    path: '/companies',
    component: CompanyListPage,
    exact: true,
    permissions: ['read:companies'],
  },
  {
    path: '/managed-companies',
    component: ManagedCompaniesPage,
    exact: true,
    permissions: ['read:companies'],
  },
  {
    path: '/managed-companies/create',
    component: CreateManagedCompanyPage,
    exact: true,
    permissions: ['read:companies'],
  },
  {
    path: '/plans',
    component: PlanListPage,
    exact: true,
    permissions: ['read:plans'],
  },
  {
    path: '/plans/create',
    component: CreatePlanPage,
    exact: true,
    permissions: ['create:plans'],
  },
  {
    path: '/plans/:id',
    component: EditPlanPage,
    exact: true,
    permissions: ['update:plans'],
  },
  {
    path: '/coupons',
    component: CouponsPage,
    exact: true,
    permissions: ['read:coupons'],
  },
  {
    path: '/coupons/create',
    component: CreateCouponPage,
    exact: true,
    permissions: ['create:coupons'],
  },
  {
    path: '/companies/:id',
    component: CompanyDetailsPage,
    exact: true,
    permissions: ['read:companies'],
  },
  {
    path: '/projects',
    component: WorkspaceListPage,
    exact: true,
    premium: false,
    permissions: ['read:projects'],
  },
  {
    path: '/billing',
    component: CompanyBillingPage,
    exact: true,
    permissions: ['read:billing'],
  },
  {
    path: '/own-connectors',
    component: ListConnectorsPage,
    exact: true,
    permissions: ['read:connectors'],
  },
  {
    path: '/own-connectors/create',
    component: CreateConnectorPage,
    exact: true,
    permissions: ['create:connectors'],
  },
  {
    path: '/own-connectors/:id',
    component: EditConnectorPage,
    exact: true,
    permissions: ['update:connectors'],
  },
  {
    path: '/projects/create',
    component: CreateWorkspacePage,
    exact: true,
    premium: false,
    permissions: ['create:projects'],
  },
  {
    path: '/projects/:id',
    component: WorkspaceDetailsPage,
    premium: false,
    permissions: ['read:projects'],
  },
  {path: '/profile', component: ProfilePage, exact: true},
  {
    path: '/users',
    component: UserListingPage,
    exact: true,
    permissions: ['read:users'],
  },
  {
    path: '/users/invite',
    component: CreateInvitationPage,
    exact: true,
    permissions: ['read:user-invitations'],
  },
  {
    path: '/users/create',
    component: CreateUserPage,
    exact: true,
    permissions: ['create:users'],
  },
  {
    path: '/users/:userId',
    component: EditUserPage,
    exact: true,
    permissions: ['read:users'],
  },
];

export default appRoutes;
