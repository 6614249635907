import {CompaniesService} from '@features/companies/companies.service';
import {WorkspaceModel} from '@features/workspaces/workspace.model';
import React, {useEffect, useState} from 'react';
import CompanyWorkspacesDisplay from './CompanyWorkspaces';

type CompanyWorkspacesProps = {
  companyId: string;
};

const CompanyWorkspaces = ({companyId}: CompanyWorkspacesProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [workspaces, setWorkspaces] = useState<WorkspaceModel[]>([]);

  useEffect(() => {
    const getWorkspaces = async () => {
      const companyService = new CompaniesService();
      setIsLoading(true);
      try {
        const response = await companyService.getWorkspaces(companyId);
        setWorkspaces(response.data);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    };
    getWorkspaces();
  }, [companyId]);

  return (
    <CompanyWorkspacesDisplay isLoading={isLoading} workspaces={workspaces} />
  );
};

export default CompanyWorkspaces;
