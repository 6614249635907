import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {createInvitation} from '@features/invitations/invitations.slice';
import CreateInvitationPageDisplay from './CreateInvitationPage';
import {CreateInvitationDto} from '@features/invitations/dto/create-invitation.dto';
import {unwrapResult} from '@reduxjs/toolkit';
import {useToasts} from 'react-toast-notifications';
import useRoles from '@features/users/hooks/useRoles';
import {useHistory} from 'react-router';

const connectRedux = connect(null, {createInvitation});

type CreateInvitationPageProps = {} & ConnectedProps<typeof connectRedux>;

const CreateInvitationPage: React.VFC<CreateInvitationPageProps> = ({
  createInvitation,
}) => {
  const {addToast} = useToasts();
  const history = useHistory();
  const {roles} = useRoles();

  const handleSubmit = useCallback(
    (values: CreateInvitationDto) => {
      return createInvitation(values)
        .then(unwrapResult)
        .then(() => {
          history.push('/users');
          addToast('User has been sent an invitation email', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, createInvitation, history]
  );

  return <CreateInvitationPageDisplay onSubmit={handleSubmit} roles={roles} />;
};

export default connectRedux(CreateInvitationPage);
