import {InstanceType} from './instance-type.enum';

export type InstanceTypeDescription = {
  value: InstanceType;
  title: string;
  cpu: number;
  ram: number;
};

const instanceTypeList: InstanceTypeDescription[] = [
  {value: InstanceType.SMALL, title: 'Small', cpu: 1, ram: 4 * 1024},
  {value: InstanceType.MEDIUM, title: 'Medium', cpu: 2, ram: 8 * 1024},
  // {value: InstanceType.LARGE, title: 'Large', cpu: 4, ram: 16 * 1024},
  // {
  //   value: InstanceType.EXTRA_LARGE,
  //   title: 'Extra Large',
  //   cpu: 4,
  //   ram: 24 * 1024,
  // },
];

export default instanceTypeList;
