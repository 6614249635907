import React, {useEffect, useMemo} from 'react';
import './App.css';
import Header from '@components/Header';
import {Helmet} from 'react-helmet';
import appRoutes from '@app/routes';
import PrivateRoute from '@components/PrivateRoute';
import * as Styled from './App.styles';
import Sidebar from '@components/Sidebar';
import {connect, ConnectedProps} from 'react-redux';
import {useSelector} from './app/store';
import {getCurrentCompany, getProfile} from '@features/auth/auth.slice';
import CompleteRegistrationPage from '@features/auth/pages/CompleteRegistrationPage';
import {Redirect, Switch} from 'react-router-dom';
import {BillingStatus} from '@features/billing/billing-status.enum';

const connectRedux = connect(null, {getProfile});

type AppProps = ConnectedProps<typeof connectRedux>;

function App({getProfile}: AppProps) {
  const user = useSelector((state) => state.auth.user);

  const currentCompany = useSelector(getCurrentCompany);

  const userHasCompleteProfile = useMemo<boolean>(() => {
    return (
      (user.permissions || ([] as string[])).includes('update:companies') ||
      (Array.isArray(user.companies) && !!user.companies.length)
    );
  }, [user.companies, user.permissions]);

  const userCanReadCompanies = useMemo<boolean>(() => {
    return (user.permissions || ([] as string[])).includes('read:companies');
  }, [user.permissions]);

  const userHasActiveBilling = useMemo<boolean>(() => {
    if (!currentCompany) {
      return true;
    }
    return (
      currentCompany.billingStatus === BillingStatus.ACTIVE ||
      currentCompany.billingStatus === BillingStatus.TRIALING
    );
  }, [currentCompany]);

  useEffect(() => {
    getProfile();
  }, [getProfile]);

  return (
    <>
      <Helmet title="Singer.ly" />
      {userHasCompleteProfile ? (
        <>
          <Header showCompanySelect={userHasCompleteProfile} />
          <Styled.Wrapper>
            <Styled.Sidebar>
              <Sidebar />
            </Styled.Sidebar>
            <Styled.Main>
              <Switch>
                {appRoutes
                  .filter(
                    (route) =>
                      userHasActiveBilling ||
                      !route.premium ||
                      userCanReadCompanies
                  )
                  .map((route, key) => (
                    <PrivateRoute key={key} {...route} />
                  ))}
                <Redirect to="/projects" />
                <Redirect to="/billing" />
              </Switch>
            </Styled.Main>
          </Styled.Wrapper>
        </>
      ) : (
        <CompleteRegistrationPage />
      )}
    </>
  );
}

export default connectRedux(App);
