import React, {useCallback} from 'react';
import Page from '../../../../components/Page';
import {UpdateProfileDto} from '../../dto/update-profile.dto';
import {AuthUser} from '../../auth-user.model';
import ProfileForm from '../../components/ProfileForm';

type ProfilePageProps = {
  user: Partial<AuthUser>;
  updateProfile: (values: UpdateProfileDto) => Promise<any>;
};

const ProfilePage: React.FC<ProfilePageProps> = ({updateProfile, user}) => {
  const handleSubmit = useCallback(
    (values) => {
      return updateProfile(values);
    },
    [updateProfile]
  );
  return (
    <Page title="Manage Profile">
      <ProfileForm
        onSubmit={handleSubmit}
        initialData={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email || '',
        }}
      />
    </Page>
  );
};

export default ProfilePage;
