import styled from 'styled-components';
import {media} from '../../../../shared/theme';

export const Wrapper = styled.div``;

export const List = styled.div`
  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
  }
`;

export const ListItem = styled.div`
  ${media.tablet} {
    flex-basis: 48%;
  }
  ${media.pcLarge} {
    flex-basis: 33.333%;
  }
`;
