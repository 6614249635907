import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TabList = styled.div`
  display: flex;
  border-bottom: 2px solid ${({theme}) => theme.colors.borderColor};
  margin-bottom: 20px;
  margin-top: -10px;
`;

export const TabItem = styled.div`
  padding: 12px 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: -2px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  background-color: transparent;
  transition: 0.2s;

  & + & {
    margin-left: 30px;
  }

  &:hover {
    color: ${({theme}) => theme.colors.mediumGray};
  }

  &.tab-active {
    color: ${({theme}) => theme.colors.primary};
    border-color: ${({theme}) => theme.colors.primary};
  }
`;

export const CurrentTab = styled.div``;
