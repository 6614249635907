import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Description = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 14px;
  margin-top: 8px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  background: #fff;
  overflow: hidden;
`;

export const FieldValue = styled.span`
  flex-grow: 1;
  padding: 10px 16px;
  background-color: ${({theme}) => theme.colors.lightGray};
`;

export const UploadAction = styled.label`
  display: inline-flex;
  padding: 10px 12px;
  cursor: pointer;
  background-color: ${({theme}) => theme.colors.lightPrimary};
  color: ${({theme}) => theme.colors.primary};
  transition: 0.2s;

  & > .icon {
    font-size: 1.2em;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.primary};
    color: #fff;
  }

  input[type='file'] {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;
