import styled from 'styled-components';

export const Wrapper = styled.div``;

export const CompanyTotal = styled.div``;

export const Group = styled.div`
  /* padding: 14px 20px; */
  /* border-radius: 5px; */
  /* border: 2px solid ${({theme}) => theme.colors.borderColor}; */
  & + & {
    margin-top: 20px;
  }
`;

export const GroupTitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 0.5em;
`;

export const UsageTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};

  th {
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 700;
    color: ${({theme}) => theme.colors.mediumGray};
    text-align: left;

    &:first-child {
      padding-left: 0;
    }
  }

  tr {
    border-top: 1px solid ${({theme}) => theme.colors.borderColor};
  }

  td {
    padding: 8px 20px;
    text-align: left;
    font-size: 15px;

    &:first-child {
      padding-left: 0;
    }
  }
`;
