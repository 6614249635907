import {
  PipelineCatalogModel,
  PipelineCatalogStream,
} from '@features/workspaces/pipeline-catalog.model';

export const getStreamProperties = (stream: PipelineCatalogStream) => {
  // const streamProperties = stream.schema.properties;
  const streamMetadata = stream.metadata;
  return streamMetadata.reduce((prev, item) => {
    if (item.breadcrumb.length) {
      prev.push({
        name: item.breadcrumb[1],
        isSelected:
          typeof item.metadata.selected !== 'undefined'
            ? item.metadata.selected
            : item.metadata['selected-by-default'] || false,
      });
    }
    return prev;
  }, [] as {name: string; isSelected: boolean}[]);
};

export const getIsAllStreamsSelected = (catalog: PipelineCatalogModel) => {
  return !catalog?.streams?.some((stream) => {
    const streamMetadata = stream.metadata.find(
      (item) => !item.breadcrumb.length
    );
    return !streamMetadata?.metadata.selected;
  });
};

export const updateStreamsSelection = (
  catalog: PipelineCatalogModel,
  isSelected: boolean,
  replicationMethod = 'FULL_TABLE'
) => {
  return {
    ...catalog,
    streams: catalog.streams.map((stream) => {
      return {
        ...stream,
        metadata: stream.metadata.map((item) =>
          !item.breadcrumb.length
            ? {
                ...item,
                metadata: {
                  ...item.metadata,
                  selected: isSelected,
                  'replication-key':
                    item.metadata['replication-key'] ||
                    getStreamKeyProperty(stream),
                  'replication-method':
                    item.metadata['replication-method'] ||
                    replicationMethod ||
                    'FULL_TABLE',
                },
              }
            : item
        ),
      };
    }),
  };
};

export const getStreamKeyProperty = (stream: PipelineCatalogStream) => {
  const streamMetadata = getStreamMetadata(stream) || {};
  const streamKeyProperties = streamMetadata['table-key-properties'] || null;
  const streamKeyProperty =
    Array.isArray(streamKeyProperties) && streamKeyProperties.length
      ? streamKeyProperties[0]
      : '';
  return streamKeyProperty;
};

export const getCatalogStreams = (catalog: PipelineCatalogModel) => {
  return catalog.streams.map((stream) => {
    const streamMetadata = stream.metadata.find(
      (item) => !item.breadcrumb.length
    );
    return {
      name: stream.stream,
      originalStream: stream,
      fields: getStreamProperties(stream),
      replicationKey:
        streamMetadata?.metadata['replication-key'] ||
        getStreamKeyProperty(stream),
      replicationMethod:
        streamMetadata?.metadata['replication-method'] || 'FULL_TABLE',
      isSelected: streamMetadata
        ? streamMetadata.metadata.selected || false
        : false,
    };
  });
};

export const getStreamMetadata = (stream: PipelineCatalogStream) => {
  const metadata =
    stream.metadata.find((item) => !item.breadcrumb.length) || null;
  return metadata ? metadata.metadata : null;
};

export const updateStreamMetadata = (
  catalog: PipelineCatalogModel,
  stream: PipelineCatalogStream,
  update: {
    selected?: boolean;
    'replication-method'?: string;
    'replication-key'?: string;
  }
) => {
  const streamMetadata = getStreamMetadata(stream);
  if (!streamMetadata) {
    return catalog;
  }
  let updatedStreamMetadata = {
    ...streamMetadata,
    ...update,
  };
  if (
    !updatedStreamMetadata['replication-key'] &&
    update['replication-method']
  ) {
    updatedStreamMetadata = {
      ...updatedStreamMetadata,
      'replication-key': getStreamKeyProperty(stream),
    };
  }
  if (update.selected) {
    updatedStreamMetadata = {
      ...updatedStreamMetadata,
      'replication-key':
        updatedStreamMetadata['replication-key'] ||
        getStreamKeyProperty(stream),
      'replication-method':
        updatedStreamMetadata['replication-method'] ||
        update['replication-method'] ||
        'FULL_TABLE',
    };
  }
  const updatedStream = {
    ...stream,
    metadata: stream.metadata.map((item) =>
      !item.breadcrumb.length
        ? {...item, metadata: updatedStreamMetadata}
        : item
    ),
  };
  return {
    streams: catalog.streams.map((catalogStream) =>
      catalogStream.stream === stream.stream ? updatedStream : catalogStream
    ),
  };
};

export const updatePropertySelected = (
  catalog: PipelineCatalogModel,
  stream: PipelineCatalogStream,
  property: {name: string; isSelected: boolean}
) => {
  const updatedStream = {
    ...stream,
    metadata: stream.metadata.map((item) =>
      item.breadcrumb.includes(property.name)
        ? {...item, metadata: {...item.metadata, selected: property.isSelected}}
        : item
    ),
  };

  return {
    streams: catalog.streams.map((catalogStream) =>
      catalogStream.stream === stream.stream ? updatedStream : catalogStream
    ),
  };
};
