import {LogEvent} from '@features/workspaces/log-event.model';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useState} from 'react';
import {useEffect} from 'react';
import BuildLogsModalDisplay from './BuildLogsModal';

type BuildLogsModalProps = {
  workspaceId: string;
  executionId: string;
  onClose: any;
  isOpen: boolean;
};

const BuildLogsModal: React.VFC<BuildLogsModalProps> = ({
  executionId,
  workspaceId,
  isOpen,
  onClose,
}) => {
  const [logs, setLogs] = useState<LogEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const workspaceService = new WorkspaceService();
    setIsLoading(true);
    workspaceService.getBuildLogs(workspaceId, executionId).then((response) => {
      setLogs(response.data);
      setIsLoading(false);
    });
  }, [executionId, workspaceId]);

  return (
    <BuildLogsModalDisplay
      logs={logs}
      isLoading={isLoading}
      onClose={onClose}
      isOpen={isOpen}
    />
  );
};

export default BuildLogsModal;
