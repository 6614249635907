import CompleteInvitation from '@features/invitations/components/CompleteInvitation';
import {CompleteInvitationDto} from '@features/invitations/dto/complete-invitation.dto';
import React from 'react';

type CompleteInvitationPageProps = {
  onSubmit: (values: CompleteInvitationDto | null) => Promise<any>;
  isLoading?: boolean;
  isInvalid?: boolean;
  gatherProfile?: boolean;
  companyName?: string;
};

const CompleteInvitationPage: React.FC<CompleteInvitationPageProps> = ({
  onSubmit,
  isLoading = false,
  isInvalid = false,
  gatherProfile = true,
  companyName = '',
}) => {
  return (
    <CompleteInvitation
      gatherProfile={gatherProfile}
      onSubmit={onSubmit}
      isLoading={isLoading}
      isInvalid={isInvalid}
      companyName={companyName}
    />
  );
};

export default CompleteInvitationPage;
