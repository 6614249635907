import {AxiosResponse} from 'axios';
import {ApiClient} from '../../services/api-client';
import {CreateUserDto} from './dto/create-user.dto';
import {UpdateUserDto} from './dto/update-user.dto';
import {UserFilterDto} from './dto/user-filter.dto';
import {RoleModel} from './role.model';
import {UserModel} from './user.model';

export class UserService extends ApiClient {
  async getAll(filterDto?: UserFilterDto): Promise<AxiosResponse<UserModel[]>> {
    return this.get('users', filterDto);
  }

  async getRoles(): Promise<AxiosResponse<RoleModel[]>> {
    return this.get('users/roles');
  }

  async getById(userId: string): Promise<AxiosResponse<UserModel>> {
    return this.get(`users/${userId}`);
  }

  async create(dto: CreateUserDto): Promise<AxiosResponse<UserModel>> {
    return this.post('users', dto);
  }

  async update(
    userId: string,
    dto: UpdateUserDto
  ): Promise<AxiosResponse<UserModel>> {
    return this.put(`users/${userId}`, dto);
  }

  async addToWorkspace(
    userId: string,
    workspaceId: string
  ): Promise<AxiosResponse<UserModel>> {
    return this.post(`workspaces/${workspaceId}/users`, {userId});
  }

  async remove(userId: string) {
    return this.delete(`users/${userId}`);
  }
}
