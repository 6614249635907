import React, {ReactNode} from 'react';
import * as Styled from './WarningMessage.styles';

type WarningMessageProps = {
  message: ReactNode;
};

const WarningMessage: React.FC<WarningMessageProps> = ({message}) => {
  return (
    <Styled.Wrapper>
      <i className="icon icon-error_outline" />
      {message}
    </Styled.Wrapper>
  );
};

export default WarningMessage;
