import {rgba} from 'polished';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: ${({theme}) => theme.colors.mediumGray};
  & + & {
    margin-left: 5px;
  }

  & + &:before {
    content: '\\e914';
    display: inline-block;
    margin-right: 5px;
    font-family: 'icomoon' !important;
    font-size: 8px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    color: ${({theme}) => theme.colors.primary};
    line-height: 1;
  }
`;

export const BreadcrumbLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.06em;
  color: ${({theme}) => theme.colors.primary};
  border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
`;
