import styled from 'styled-components';
import {lighten, rgba} from 'polished';
import {media} from '../../../../shared/theme';

export const Wrapper = styled.div`
  ${media.tablet} {
    display: flex;
    align-items: flex-start;
  }
`;

export const PageTitle = styled.div`
  display: block;
  ${media.tablet} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  > .title {
    display: block;
    ${media.tablet} {
      display: inline-block;
    }
  }
  > .status {
    display: block;
    ${media.tablet} {
      display: inline-block;
      padding-bottom: 3px;
      margin-left: 20px;
    }
  }
`;

export const Details = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: #fff;

  ${media.tablet} {
    flex-basis: 35%;
    margin-right: 20px;
  }
`;

export const Builds = styled.div`
  ${media.tablet} {
    flex-basis: 65%;
  }
`;

export const Section = styled.div`
  padding: 20px;

  &.section-no-padding {
    padding: 0;
  }

  & + & {
    border-top: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;

export const PropertyList = styled.div`
  ${media.tablet} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 -20px;
  }
`;

export const Property = styled.div`
  & + & {
    margin-top: 16px;
  }
  ${media.tablet} {
    padding: 0 20px;
    margin-top: 0 !important;
  }

  &.property-no-padding {
    padding: 0;
  }

  &.property-flex {
    display: flex;
    justify-content: stretch;
  }
`;

export const IconProperty = styled.div`
  display: flex;

  & > .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${({theme}) => theme.colors.primary};
    padding-right: 2px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    margin-right: 8px;
  }
`;

export const IconPropertyBody = styled.div``;

export const PropertyLabel = styled.span`
  display: block;
  font-size: 14px;
  color: ${({theme}) => theme.colors.mediumGray};
  margin-bottom: 3px;
`;
export const PropertyValue = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;

  small {
    font-size: 14px;
  }

  a {
    /* display: inline-block; */
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
  }
`;

export const WorkspaceUrl = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 20px;
  color: ${({theme}) => theme.colors.text};
  flex-grow: 1;
  font-weight: 700;
  transition: 0.2s;

  .icon.branded-icon {
    font-size: 24px;
    color: #fff;
    margin-right: 10px;
  }

  .animated-icon {
    position: relative;
    margin-left: auto;
    transform: translateX(0);
    transition: 0.12s ease-in-out;
  }
  &:hover .animated-icon {
    transform: translateX(10px);
    transition: 0.12s ease-in-out;
  }

  &.airflow-branded {
    transition: 0.2s;
    :hover {
      background-color: ${({theme}) => theme.colors.lightPrimary};
      color: #000;
    }
    svg {
      margin-right: 10px;
      object-fit: contain;
    }
    .animated-icon {
      margin-left: auto;
    }
  }

  &.meltano-branded {
    background-color: ${({theme}) => theme.colors.meltano};
    padding: 18px 20px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    .branded-icon {
      padding-bottom: 1px;
    }
    .icon {
      color: #fff;
    }
    &:hover {
      color: #fff;
      background-color: ${({theme}) => lighten(0.03, theme.colors.meltano)};
    }
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding-right: 2px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    border-radius: 50%;
  }

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }

  & + & {
    border-left: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;
