import Button from '@components/Button';
import Loader from '@components/Loader';
import ToggleField from '@components/ToggleField';
import {WorkspaceSettingsModel} from '@features/workspaces/workspace-settings.model';
import React, {useCallback, useState} from 'react';
import * as Styled from './WorkspaceSettings.styles';

type WorkspaceSettingsProps = {
  settings?: WorkspaceSettingsModel;
  onChange: (settings: WorkspaceSettingsModel) => void;
  onSubmit: () => Promise<any>;
  isLoading?: boolean;
};

const WorkspaceSettings: React.VFC<WorkspaceSettingsProps> = ({
  settings = {},
  onChange,
  onSubmit,
  isLoading = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback(
    ({target}: {target: {name: string; value: any}}) => {
      onChange({...settings, [target.name]: target.value});
    },
    [settings, onChange]
  );

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  }, [onSubmit]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Title>Project Settings</Styled.Title>
        <Button onClick={handleSubmit} isLoading={isSubmitting}>
          Save changes
        </Button>
      </Styled.Header>
      {isLoading ? (
        <Loader message="Loading project settings..." />
      ) : (
        <>
          <Styled.Group>
            <Styled.GroupTitle>Connectors</Styled.GroupTitle>
            <Styled.GroupBody>
              <Styled.Property>
                <Styled.PropertyLabel>
                  Enable community connectors
                </Styled.PropertyLabel>
                <Styled.PropertyEditor>
                  <ToggleField
                    name="enableCommunityConnectors"
                    value={settings.enableCommunityConnectors}
                    onChange={handleChange}
                    trueValueLabel="Enabled"
                    falseValueLabel="Disabled"
                  />
                </Styled.PropertyEditor>
              </Styled.Property>
            </Styled.GroupBody>
          </Styled.Group>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default WorkspaceSettings;
