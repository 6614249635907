import {BalanceTransactionModel} from '@features/billing/balance-transaction.model';
import {BillingDetails} from '@features/billing/billing-details.interface';
import {ChargeModel} from '@features/billing/charge.model';
import {CreateSubscriptionDto} from '@features/billing/dto/create-subscription.dto';
import {InvoiceModel} from '@features/billing/invoice.model';
import {UsageReport} from '@features/billing/usage-report.interface';
import {UserModel} from '@features/users/user.model';
import {WorkspaceModel} from '@features/workspaces/workspace.model';
import {AxiosResponse} from 'axios';
import {ApiClient} from '../../services/api-client';
import {CompanyModel} from './company.model';
import {CompanyFilterDto} from './dto/company-filter.dto';
import {CompanyUpdateDto} from './dto/company-update.dto';
import {CreateManagedCompanyDto} from './dto/create-managed-company.dto';

export class CompaniesService extends ApiClient {
  async validateName(name: string): Promise<boolean> {
    try {
      await this.head('companies/validate-name', {name});
      return true;
    } catch (e) {
      if (e.response.status === 422) {
        return false;
      }
      return true;
    }
  }

  async getAll(
    filterDto?: CompanyFilterDto
  ): Promise<AxiosResponse<CompanyModel[]>> {
    return this.get('companies', filterDto);
  }

  async getById(id: string): Promise<AxiosResponse<CompanyModel>> {
    return this.get(`companies/${id}`);
  }

  async getWorkspaces(id: string): Promise<AxiosResponse<WorkspaceModel[]>> {
    return this.get(`companies/${id}/workspaces`);
  }

  async getUsers(id: string): Promise<AxiosResponse<UserModel[]>> {
    return this.get(`companies/${id}/users`);
  }

  async update(
    id: string,
    dto: CompanyUpdateDto
  ): Promise<AxiosResponse<CompanyModel>> {
    return this.put(`companies/${id}`, dto);
  }

  async archive(id: string) {
    return this.delete(`companies/${id}`);
  }

  async forceDelete(id: string) {
    return this.delete(`companies/${id}/force`);
  }

  async getBillingDetails(
    companyId: string
  ): Promise<AxiosResponse<BillingDetails>> {
    return this.get(`companies/${companyId}/billing`);
  }

  async getInvoices(companyId: string): Promise<AxiosResponse<InvoiceModel[]>> {
    return this.get(`companies/${companyId}/invoices`);
  }

  async getBalanceTransactions(
    companyId: string
  ): Promise<AxiosResponse<BalanceTransactionModel[]>> {
    return this.get(`companies/${companyId}/transactions`);
  }

  async getCharges(companyId: string): Promise<AxiosResponse<ChargeModel[]>> {
    return this.get(`companies/${companyId}/transactions`);
  }

  async cancelSubscription(companyId: string) {
    return this.post(`companies/${companyId}/cancel-subscription`);
  }

  async renewSubscription(companyId: string) {
    return this.post(`companies/${companyId}/renew-subscription`);
  }

  async createSubscription(companyId: string, dto: CreateSubscriptionDto) {
    return this.post(`companies/${companyId}/subscription`, dto);
  }

  async updatePaymentMethod(companyId: string, paymentMethodId: string) {
    return this.post(`companies/${companyId}/payment-method`, {
      paymentMethodId,
    });
  }

  async getManagedCompanies(
    parentId: string
  ): Promise<AxiosResponse<CompanyModel[]>> {
    return this.get(`companies/${parentId}/managed`);
  }

  async createManagedCompany(
    parentId: string,
    dto: CreateManagedCompanyDto
  ): Promise<AxiosResponse<CompanyModel>> {
    return this.post(`companies/${parentId}/managed`, dto);
  }

  async getUsageReport(companyId: string): Promise<AxiosResponse<UsageReport>> {
    return this.get(`usage/${companyId}/report`);
  }
}
