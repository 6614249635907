import styled from 'styled-components';

export const Wrapper = styled.div``;

export const EmptyPlaceholder = styled.div`
  padding: 90px 0 90px 320px;
  background-size: 400px auto;
  background-repeat: no-repeat;
  background-position: -50px -30px;
  min-height: 320px;
`;

export const Title = styled.h2`
  font-size: 28px;
  line-height: 1.3;
  margin: 0 0 20px;

  em {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    display: block;
    color: ${({theme}) => theme.colors.mediumGray};
    margin-top: 10px;
  }
`;

export const ActionBar = styled.div``;
