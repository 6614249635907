import FieldHelpMessage from '@components/FieldHelpMessage';
import GitReferenceSelector, {
  GitReference,
} from '@components/GitReferencesSelector';
import Loader from '@components/Loader';
import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Styled from './GitReferenceSelectorDropdown.styles';

type GitReferenceSelectorDropdownProps = {
  references: GitReference[];
  isLoading?: boolean;
  label?: string;
  required?: string;
  name: string;
  value?: string;
  tooltip?: React.ReactNode;
  onChange: (e: {target: {name: string; value: string}}) => void;
  onBlur?: FocusEventHandler;
};

const GitReferenceSelectorDropdown: React.VFC<
  GitReferenceSelectorDropdownProps
> = ({
  name,
  onChange,
  onBlur,
  references = [],
  label,
  required = false,
  value = 'master',
  isLoading = false,
  tooltip,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);
  const toggleExpanded = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleChange = useCallback(
    (value) => {
      onChange({target: {name, value}});
      setIsExpanded(false);
    },
    [name, onChange]
  );
  const handleHeaderClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      toggleExpanded();
    },
    [toggleExpanded]
  );

  const handleOutsideClick = useCallback((e: any) => {
    if (fieldRef && !fieldRef.current?.contains(e.target)) {
      setIsExpanded(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <Styled.Wrapper ref={fieldRef} onBlur={onBlur}>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.DropdownWrapper
        tabIndex={0}
        className={[
          isExpanded ? 'dropdown-state-expanded' : '',
          !references.length ? 'dropdown-state-empty' : '',
          isLoading ? 'dropdown-state-loading' : '',
        ].join(' ')}
      >
        <Styled.Header onClick={handleHeaderClick}>
          {isLoading ? (
            <Loader size={18} message="Loading..." />
          ) : (
            <>
              <i className="icon icon-share" />
              <span
                className="git-reference-selector-dropdown-value"
                title={value}
              >
                {value}
              </span>
            </>
          )}
        </Styled.Header>
        {isExpanded && !isLoading && !!references.length && (
          <Styled.Options>
            <GitReferenceSelector
              references={references}
              onChange={handleChange}
            />
          </Styled.Options>
        )}
      </Styled.DropdownWrapper>
    </Styled.Wrapper>
  );
};

export default GitReferenceSelectorDropdown;
