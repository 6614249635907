import styled from 'styled-components';
import {lighten} from 'polished';

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: #fff;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};
  padding: 14px 20px;
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0;

  small {
    font-weight: 400;
    font-size: 16px;
    color: ${({theme}) => lighten(0.25, theme.colors.mediumGray)};
    margin-left: 8px;
    margin-bottom: -1px;
  }
`;

export const Sections = styled.div``;

export const Section = styled.div`
  padding: 14px 20px;

  & + & {
    border-top: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;
