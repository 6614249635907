import styled from 'styled-components';
import {lighten, darken} from 'polished';

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  .icon {
    display: inline-block;
    margin-right: 5px;
    margin-left: -2px;
  }

  &.button-variant-primary {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
  }

  &.button-variant-secondary {
    background-color: ${(props) => props.theme.colors.lightPrimary};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
    &:hover {
      background-color: ${(props) =>
        lighten(0.02, props.theme.colors.lightPrimary)};
    }
  }

  &.button-variant-danger {
    color: ${(props) => props.theme.colors.danger};
    background-color: #ffdbdb;
    font-weight: 700;
    &:hover {
      background-color: ${() => lighten(0.02, '#FFDBDB')};
    }
  }

  &.button-fullwidth {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  &.button-loading,
  &:disabled {
    background-color: ${(props) =>
      darken(0.15, props.theme.colors.mediumGray)} !important;
    color: #fff !important;
    font-weight: 400 !important;
    cursor: not-allowed;
  }

  &.button-size-x-small {
    padding: 6px 14px;
    font-size: 15px;
  }

  &.button-size-large {
    padding: 12px 36px;
    font-size: 18px;
  }

  &:hover {
    background-color: ${(props) => lighten(0.1, props.theme.colors.primary)};
  }

  &:before {
    content: '';
    display: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSIjRkZGRkZGIj4KICA8cGF0aCBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4KICA8cGF0aCBkPSJNMTYgMCBBMTYgMTYgMCAwIDEgMzIgMTYgTDI4IDE2IEExMiAxMiAwIDAgMCAxNiA0eiI+CiAgICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InJvdGF0ZSIgZnJvbT0iMCAxNiAxNiIgdG89IjM2MCAxNiAxNiIgZHVyPSIwLjhzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4KICA8L3BhdGg+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 5px;
    width: 1em;
    height: 1em;
  }

  &.button-loading:before {
    display: inline-block;
  }
`;
