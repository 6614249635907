import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Title = styled.h3`
  font-size: 24px;
  color: ${({theme}) => theme.colors.primary};
  margin: 0;
`;

export const Group = styled.div`
  max-width: 30em;
`;

export const GroupTitle = styled.h4`
  font-size: 20px;
  margin: 0 0 16px;
  padding: 0 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const GroupBody = styled.div``;

export const Property = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PropertyLabel = styled.span`
  font-size: 16px;
`;

export const PropertyEditor = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
`;
