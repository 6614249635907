import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import InvitationList from '@features/invitations/components/InvitationList';
import React from 'react';
import LinkButton from '../../../../components/LinkButton';
import Loader from '../../../../components/Loader';
import Page from '../../../../components/Page';
import UserList from '../../components/UserList';
import {UserModel} from '../../user.model';

type UserListingPageProps = {
  users: UserModel[];
  isLoading?: boolean;
};

const UserListingPage: React.FC<UserListingPageProps> = ({
  users,
  isLoading = false,
}) => {
  return (
    <Page
      title="Users"
      actions={[
        <LinkButton key="1" to="/users/invite" icon="add_circle1">
          Invite user
        </LinkButton>,
      ]}
    >
      <Tabs>
        <Tab title="Existing users">
          {isLoading ? (
            <Loader message="Loading users..." />
          ) : (
            <UserList users={users} />
          )}
        </Tab>
        <Tab title="Invitations">
          <InvitationList />
        </Tab>
      </Tabs>
    </Page>
  );
};

export default UserListingPage;
