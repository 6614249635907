import {CreateManagedCompanyDto} from '@features/companies/dto/create-managed-company.dto';
import useCreateManagedCompany from '@features/companies/hooks/useCreateManagedCompany';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import React, {useCallback} from 'react';
import CreateManagedCompanyScreen from './CreateManagedCompanyScreen';

type Props = {
  onSuccess: () => void;
};

const CreateManagedCompanyScreenContainer: React.VFC<Props> = ({onSuccess}) => {
  const selectedCompany = useSelectedCompany();
  const {createManagedCompany} = useCreateManagedCompany(
    selectedCompany?.id || ''
  );

  const handleSubmit = useCallback(
    async (data: CreateManagedCompanyDto) => {
      await createManagedCompany(data);
      onSuccess();
    },
    [createManagedCompany, onSuccess]
  );

  return <CreateManagedCompanyScreen onSubmit={handleSubmit} />;
};

export default CreateManagedCompanyScreenContainer;
