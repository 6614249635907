import {lighten, rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.borderColor};
  border-radius: ${({theme}) => theme.dropdown.borderRadius};
  border: 2px solid ${({theme}) => theme.input.normal.borderColor};
  box-shadow: 0 0 0 transparent;
  color: ${({theme}) => theme.colors.text};
  outline: none;
  transition: 0.2s;

  &.has-error {
    border-color: ${({theme}) => theme.colors.danger};
  }

  &:hover,
  &:focus {
    border-color: ${({theme}) => lighten(0.1, theme.input.hover.borderColor)};
  }
  &:focus {
    /* box-shadow: 0 0 0 1px ${({theme}) =>
      rgba(theme.input.hover.borderColor, 1)}; */
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 700;
  padding: 8px 16px 9px;
  cursor: pointer;

  .git-reference-selector-dropdown-value {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 8em;
    overflow: hidden;
  }

  &:after {
    content: '\\e902';
    display: inline-block;
    font-family: 'icomoon' !important;
    font-size: 1.4em;
    height: 1em;
    speak: never;
    width: 1em;
    min-width: 1em;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: auto;
    transform: rotate(0);
    transition: 0.2s;
  }
  .dropdown-state-expanded &:after {
    transform: rotate(-90deg);
  }
  }
  .dropdown-state-empty &,
  .dropdown-state-loading & {
    padding: 10px 16px 11px;
  }
  .dropdown-state-empty &:after,
  .dropdown-state-loading &:after {
    display: none;
  }
`;

export const Options = styled.div`
  position: absolute;
  top: 100%;
  left: -2px;
  /* right: -2px; */
  width: 250px;
  z-index: 100;
  padding: 0;
  margin: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  /* background-color: #fff; */
`;
