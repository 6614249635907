import FileUploadField, {
  FileUploadFieldProps,
} from '@components/FileUploadField/FileUploadField';
import {PipelineService} from '@features/pipelines/pipeline.service';
import React, {useCallback} from 'react';

type PipelineFileUploadFieldProps = Omit<FileUploadFieldProps, 'onUpload'>;

const PipelineFileUploadField: React.VFC<PipelineFileUploadFieldProps> = (
  props
) => {
  const handleUploadFile = useCallback(async (file: File) => {
    const pipelineService = new PipelineService();
    const response = await pipelineService.uploadPipelineFile(file);
    console.log(response.data);
    return response.data;
  }, []);

  return <FileUploadField {...props} onUpload={handleUploadFile} />;
};

export default PipelineFileUploadField;
