import Checkbox from '@components/Checkbox';
import FieldHelpMessage from '@components/FieldHelpMessage';
import React, {useCallback} from 'react';
import * as Styled from './ChoiceEditor.styles';

export type ChoiceOption<T> = {
  label: string;
  value: T;
};

type ChoiceEditorProps<T> = {
  options: ChoiceOption<T>[];
  name: string;
  id?: string;
  label?: string;
  required?: boolean;
  tooltip?: string;
  value: T[];
  layout?: 'horizontal' | 'vertical';
  onChange: (e: {target: {name: string; value: T[]}}) => void;
};

function ChoiceEditor<T>({
  name,
  onChange,
  options,
  value,
  id,
  label,
  required,
  tooltip = '',
  layout = 'vertical',
}: ChoiceEditorProps<T>) {
  const handleClick = useCallback(
    (option: ChoiceOption<T>) =>
      (e: {target: {value: boolean; name: string}}) => {
        if (e.target.value) {
          onChange({target: {name, value: [...value, option.value]}});
        } else {
          onChange({
            target: {
              name,
              value: value.filter((item) => item !== option.value),
            },
          });
        }
      },
    [name, onChange, value]
  );
  return (
    <Styled.Wrapper className={[`choice-editor-layout-${layout}`].join(' ')}>
      {label && (
        <Styled.Label htmlFor={id || name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.Options>
        {options.map((option, key) => {
          const isSelected = value.includes(option.value);
          return (
            <Styled.Option
              key={key}
              className={[isSelected ? 'choice-option-active' : ''].join(' ')}
            >
              <Checkbox
                name={`${name}-${key}`}
                value={isSelected}
                onChange={handleClick(option)}
                label={option.label}
              />
            </Styled.Option>
          );
        })}
      </Styled.Options>
    </Styled.Wrapper>
  );
}

export default ChoiceEditor;
