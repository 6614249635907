import FormGroup from '@components/FormGroup';
import TextField from '@components/TextField';
import {CreatePipelineDto} from '@features/workspaces/dto/create-pipeline.dto';
import React, {useEffect, useState, useCallback} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import * as Styled from './CreatePipelineScreen.styles';
import {PipelineSchedule} from '@features/workspaces/pipeline-schedule.enum';
import RadioGroupField from '@components/RadioGroupField';
import pipelineScheduleMap from '@features/workspaces/pipeline-schedule.map';
import Steps from '@components/Steps';
import Step from '@components/Steps/Step';
import PipelineCatalogEditor from '../PipelineCatalogEditor';
import Button from '@components/Button';
import ConnectorConfigurator from '../ConnectorConfigurator';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import {buildValidationRules} from '../ConnectorConfigurationEditor/ConnectorConfigurationEditor';
import GridItem from '@components/Grid/GridItem';
import Grid from '@components/Grid';
import ArrayEditor from '@components/ArrayEditor';
import Loader from '@components/Loader';
import {AxiosError} from 'axios';
import ErrorMessage from '@components/ErrorMessage';
import {ConnectorsService} from '@features/connectors/connectors.service';
import {useQuery} from 'react-query';
import InstanceTypeDropdown from '../InstanceTypeDropdown';
import {InstanceType} from '@features/workspaces/instance-type.enum';

type CreatePipelineScreenProps = {
  onSubmit: (data: CreatePipelineDto) => Promise<any>;
  onGoBack?: () => any;
  onGetCatalog: (data: CreatePipelineDto) => Promise<any>;
  isLoading?: boolean;
};

const _validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please provide pipeline name')
    .matches(/^[a-z0-9-_]+$/, {
      message:
        'Only lowercase symbols, numbers, dashes and underscores are allowed.',
    }),
  emailList: Yup.array().of(Yup.string().email()),
  sourceId: Yup.string().required(),
  destinationId: Yup.string().required(),
});

const scheduleOptions = Object.keys(pipelineScheduleMap).map((scheduleKey) => ({
  label: pipelineScheduleMap[scheduleKey as PipelineSchedule].title,
  value: scheduleKey,
}));

const CreatePipelineScreen: React.VFC<CreatePipelineScreenProps> = ({
  onSubmit,
  onGoBack,
  onGetCatalog,
  isLoading = false,
}) => {
  const [validationSchema, setValidationSchema] = useState(_validationSchema);
  const [isFetchingCatalog, setIsFetchingCatalog] = useState(false);
  const [catalogError, setCatalogError] = useState('');
  const [catalogErrorDetails, setCatalogErrorDetails] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      sourceId: '',
      emailList: [],
      sourceConfig: {},
      destinationId: undefined,
      destinationConfig: {},
      instanceType: InstanceType.MEDIUM,
      catalog: null,
      schedule: PipelineSchedule.DAILY,
    },
    validateOnBlur: true,
    isInitialValid: false,
    validationSchema,
    onSubmit,
    enableReinitialize: false,
  });

  const {data: sourceConnector} = useQuery({
    queryFn: () => {
      const connectorService = new ConnectorsService();
      if (formik.values.sourceId) {
        return connectorService
          .getById(formik.values.sourceId)
          .then((response) => response.data);
      }
    },
    queryKey: ['connectors', formik.values.sourceId],
    enabled: !!formik.values.sourceId,
  });

  const {data: destinationConnector} = useQuery({
    queryFn: () => {
      const connectorService = new ConnectorsService();
      if (formik.values.destinationId) {
        return connectorService
          .getById(formik.values.destinationId)
          .then((response) => response.data);
      }
    },
    queryKey: ['connectors', formik.values.destinationId],
    enabled: !!formik.values.destinationId,
  });

  useEffect(() => {
    setValidationSchema((prev) =>
      prev.concat(
        Yup.object().shape({
          sourceConfig: buildValidationRules(sourceConnector),
        })
      )
    );
  }, [sourceConnector]);

  useEffect(() => {
    if (validationSchema.fields.sourceConfig) {
      formik.validateField('sourceConfig');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationSchema]);

  useEffect(() => {
    setValidationSchema((prev) =>
      prev.concat(
        Yup.object().shape({
          destinationConfig: buildValidationRules(destinationConnector),
        })
      )
    );
  }, [destinationConnector]);

  const handleGetCatalog = useCallback(async () => {
    if (sourceConnector?.isDiscoverySupported && !formik.values.catalog) {
      setCatalogError('');
      setCatalogErrorDetails('');
      setIsFetchingCatalog(true);
      try {
        const catalog = await onGetCatalog(formik.values);
        formik.handleChange({target: {name: 'catalog', value: catalog}});
        setIsFetchingCatalog(false);
      } catch (e) {
        setIsFetchingCatalog(false);
        setCatalogError(
          (e as AxiosError)?.response?.data?.error ||
            'Something went wrong. Please review connector configuration and try again.'
        );
        setCatalogErrorDetails((e as AxiosError)?.response?.data?.details);
        throw e;
      }
    }
  }, [formik, onGetCatalog, sourceConnector?.isDiscoverySupported]);

  const handleConnectorConfigChange = useCallback(
    (value) => {
      formik.handleChange({
        target: {name: 'destinationId', value: value.connectorId},
      });
      formik.handleChange({
        target: {name: 'destinationConfig', value: value.config},
      });
    },
    [formik]
  );

  useEffect(() => {
    if (sourceConnector?.config?.length) {
      const defaultValues = sourceConnector.config.reduce(
        (prev, configLine) => {
          if (configLine.value) {
            prev[configLine.name] = configLine.value;
          }
          return prev;
        },
        {} as {[key: string]: any}
      );
      formik.handleChange({
        target: {name: 'sourceConfig', value: defaultValues},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceConnector?.id]);

  useEffect(() => {
    if (destinationConnector?.config?.length) {
      const defaultValues = destinationConnector.config.reduce(
        (prev, configLine) => {
          if (configLine.value) {
            prev[configLine.name] = configLine.value;
          }
          return prev;
        },
        {} as {[key: string]: any}
      );
      formik.handleChange({
        target: {name: 'destinationConfig', value: defaultValues},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationConnector?.id]);

  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        {onGoBack && (
          <Button variant="secondary" onClick={onGoBack} icon="arrow_back">
            Go Back to Pipeline List
          </Button>
        )}
      </Styled.ActionBar>
      <Styled.Form onSubmit={formik.handleSubmit}>
        <Steps
          alignment="left"
          variant="compact"
          submitActionTitle="Create pipeline"
          submitAction={async () => formik.submitForm()}
          submitActionEnabled={
            !formik.isSubmitting || formik.isValid || formik.dirty
          }
        >
          <Step
            title="Pipeline Settings"
            nextActionEnabled={!!formik.values.name}
          >
            <Grid gutterWidth={40}>
              <GridItem>
                <FormGroup>
                  <TextField
                    name="name"
                    label="Pipeline friendly name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    description="Only lowercase symbols, numbers, dashes and underscores are allowed."
                    error={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : ''
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <InstanceTypeDropdown
                    value={formik.values.instanceType || ''}
                    onChange={(value) =>
                      formik.handleChange({
                        target: {name: 'instanceType', value},
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <RadioGroupField
                    name="schedule"
                    label="Run schedule"
                    tooltip="Pipeline will run at specified time interval"
                    value={formik.values.schedule}
                    onChange={formik.handleChange}
                    options={scheduleOptions}
                  />
                </FormGroup>
              </GridItem>
              <GridItem>
                <ArrayEditor
                  name="emailList"
                  label="Send alerts to this email list"
                  value={formik.values.emailList || []}
                  onChange={formik.handleChange}
                />
              </GridItem>
            </Grid>
          </Step>
          <Step
            title="Source"
            nextAction={handleGetCatalog}
            isDisabled={!formik.values.name}
            nextActionEnabled={
              !!formik.values.sourceId &&
              !!formik.values.sourceConfig &&
              !formik.errors.sourceConfig
            }
          >
            {isFetchingCatalog ? (
              <Loader message="Generating catalog. This can take a couple of minutes." />
            ) : (
              <>
                {catalogError && (
                  <ErrorMessage
                    message={catalogError}
                    details={catalogErrorDetails}
                  />
                )}
                <ConnectorConfigurator
                  type={ConnectorType.SOURCE}
                  value={{
                    connectorId: formik.values.sourceId,
                    config: formik.values.sourceConfig,
                  }}
                  onChange={(value) => {
                    formik.handleChange({
                      target: {name: 'sourceId', value: value.connectorId},
                    });
                    formik.handleChange({
                      target: {name: 'sourceConfig', value: value.config},
                    });
                  }}
                />
              </>
            )}
          </Step>
          <Step
            title="Catalog"
            isDisabled={
              !sourceConnector?.isDiscoverySupported || !formik.values.catalog
            }
          >
            <PipelineCatalogEditor
              catalog={formik.values.catalog || undefined}
              connector={sourceConnector}
              onChange={(value) =>
                formik.handleChange({target: {name: 'catalog', value}})
              }
            />
          </Step>
          <Step title="Destination" isDisabled={!formik.values.name}>
            <ConnectorConfigurator
              type={ConnectorType.DESTINATION}
              value={{
                connectorId: formik.values.destinationId,
                config: formik.values.destinationConfig,
              }}
              onChange={handleConnectorConfigChange}
            />
          </Step>
        </Steps>
      </Styled.Form>
    </Styled.Wrapper>
  );
};

export default CreatePipelineScreen;
