import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ValidationErrors} from 'types/validation-errors';
import {CreateUserDto} from './dto/create-user.dto';
import {UpdateUserDto} from './dto/update-user.dto';
import {UserFilterDto} from './dto/user-filter.dto';
import {UserModel} from './user.model';
import {UserService} from './user.service';

export const fetchUsers = createAsyncThunk<
  Promise<any>,
  UserFilterDto | undefined
>('users/get-all', async (payload, api) => {
  try {
    const userService: UserService = new UserService();
    const response = await userService.getAll(payload);
    return response.data as UserModel[];
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const fetchUserById = createAsyncThunk<
  Promise<UserModel | any>,
  {userId: string}
>('users/get-by-id', async (payload, api) => {
  try {
    const userService: UserService = new UserService();
    const response = await userService.getById(payload.userId);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const createUser = createAsyncThunk<any, CreateUserDto>(
  'users/create',
  async (payload, api) => {
    try {
      const userService: UserService = new UserService();
      const response = await userService.create(payload);
      return response.data;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

type UpdateUserActionPayload = {
  userId: string;
  data: UpdateUserDto;
};

export const updateUser = createAsyncThunk<
  Promise<UserModel | any>,
  UpdateUserActionPayload
>('users/update', async (payload, api) => {
  try {
    const userService: UserService = new UserService();
    const response = await userService.update(payload.userId, payload.data);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const removeUser = createAsyncThunk<Promise<any>, string>(
  'users/remove',
  async (payload, api) => {
    try {
      const userService: UserService = new UserService();
      await userService.remove(payload);
      return payload;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

type AddUserToWorkspaceActionPayload = {
  userId: string;
  workspaceId: string;
};

export const addUserToWorkspace = createAsyncThunk<
  Promise<UserModel | any>,
  AddUserToWorkspaceActionPayload
>('users/add-to-workspace', async (payload, api) => {
  try {
    const userService: UserService = new UserService();
    const response = await userService.addToWorkspace(
      payload.userId,
      payload.workspaceId
    );

    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

type UsersState = {
  isLoading: boolean;
  data: UserModel[];
  errors: ValidationErrors;
  message: string;
};

const initialState: UsersState = {
  isLoading: false,
  data: [],
  errors: {},
  message: '',
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    // List users thunk
    [fetchUsers.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchUsers.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [fetchUsers.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserModel[]>
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    // Get user by id thunk
    [fetchUserById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchUserById.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [fetchUserById.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserModel>
    ) => {
      state.isLoading = false;
      state.data = state.data.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    // Create user thunk
    [createUser.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [createUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [createUser.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserModel>
    ) => {
      state.isLoading = false;
      state.data.push(action.payload);
    },
    // Update user thunk
    [updateUser.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [updateUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [updateUser.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserModel>
    ) => {
      state.isLoading = false;
      state.data = state.data.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    // Add user to project
    [addUserToWorkspace.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [addUserToWorkspace.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [addUserToWorkspace.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserModel>
    ) => {
      state.isLoading = false;
      state.data.push(action.payload);
    },
    // Remove user thunk
    [removeUser.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [removeUser.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [removeUser.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.data = state.data.filter((user) => user.id !== action.payload);
    },
  },
});

export default userSlice.reducer;
