import Checkbox from '@components/Checkbox';
import Dropdown from '@components/Dropdown';
import RadioGroupField from '@components/RadioGroupField';
import {RadioGroupOption} from '@components/RadioGroupField/RadioGroupField';
import {ConnectorReplicationMode} from '@features/connectors/connector-replication-mode.enum';
import connectorReplicationModeMap from '@features/connectors/connector-replication-mode.map';
import {ConnectorModel} from '@features/connectors/connector.model';
import {
  PipelineCatalogModel,
  PipelineCatalogStream,
} from '@features/workspaces/pipeline-catalog.model';
import React, {useState, useCallback, useMemo} from 'react';
import * as Styled from './PipelineCatalogEditor.styles';
import {
  getCatalogStreams,
  getIsAllStreamsSelected,
  getStreamProperties,
  updatePropertySelected,
  updateStreamMetadata,
  updateStreamsSelection,
} from './utils';

type PipelineCatalogEditorProps = {
  catalog?: PipelineCatalogModel;
  connector?: ConnectorModel;
  onChange: (value: PipelineCatalogModel) => void;
};

type StreamPropertiesProps = {
  stream: PipelineCatalogStream;
  onChangeSelected: (
    stream: PipelineCatalogStream,
    property: {name: string; isSelected: boolean}
  ) => any;
};

const StreamProperties: React.VFC<StreamPropertiesProps> = ({
  stream,
  // connector,
  onChangeSelected,
}) => {
  return (
    <Styled.PropertyList>
      {getStreamProperties(stream).map((property, index) => (
        <Styled.PropertyListItem key={index}>
          <Styled.Property>
            <Checkbox
              name={`${stream.stream}-${property.name}`}
              value={property.isSelected}
              onChange={onChangeSelected(stream, property)}
            />
            <Styled.PropertyTitle>{property.name}</Styled.PropertyTitle>
          </Styled.Property>
        </Styled.PropertyListItem>
      ))}
    </Styled.PropertyList>
  );
};

const PipelineCatalogEditor: React.VFC<PipelineCatalogEditorProps> = ({
  catalog = {streams: []},
  onChange,
  connector,
}) => {
  const [expandedStreams, setExpandedStreams] = useState<{
    [key: string]: boolean;
  }>({});

  const replicationModeOptions = useMemo<
    RadioGroupOption<ConnectorReplicationMode>[]
  >(() => {
    if (connector && connector.replicationModes) {
      return connector.replicationModes.map((mode) => ({
        label: connectorReplicationModeMap[mode].title,
        value: mode,
      }));
    }
    return [{label: 'Full Table', value: ConnectorReplicationMode.FULL_TABLE}];
  }, [connector]);

  const isAllStreamsSelected = useMemo(
    () => getIsAllStreamsSelected(catalog),
    [catalog]
  );

  const streams = useMemo(() => {
    return getCatalogStreams(catalog);
  }, [catalog]);

  const handleExpandStreamToggle = useCallback(
    (stream: string) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setExpandedStreams((prev) => ({
        ...prev,
        [stream]: !Boolean(prev[stream]),
      }));
    },
    []
  );

  const handleReplicationChange = useCallback(
    (stream: PipelineCatalogStream) => (e: {target: {value: string}}) => {
      onChange(
        updateStreamMetadata(catalog, stream, {
          'replication-method': e.target.value,
        })
      );
    },
    [catalog, onChange]
  );

  const handleStreamSelectedChange = useCallback(
    (stream: PipelineCatalogStream) => (e: {target: {value: boolean}}) => {
      onChange(
        updateStreamMetadata(catalog, stream, {
          selected: e.target.value,
        })
      );
    },
    [catalog, onChange]
  );

  const handleSetStreamReplicationKey = useCallback(
    (stream: PipelineCatalogStream) => (value: string) => {
      onChange(
        updateStreamMetadata(catalog, stream, {
          'replication-key': value,
        })
      );
    },
    [catalog, onChange]
  );

  const handlePropertySelectedChange = useCallback(
    (
        stream: PipelineCatalogStream,
        property: {name: string; isSelected: boolean}
      ) =>
      (e: {target: {value: boolean}}) => {
        onChange(
          updatePropertySelected(catalog, stream, {
            name: property.name,
            isSelected: e.target.value,
          })
        );
      },
    [catalog, onChange]
  );

  const handleSelectAllStreamsToggle = useCallback(
    (e: {target: {value: boolean}}) => {
      const replicationMethod =
        connector?.replicationModes &&
        connector.replicationModes.includes(
          ConnectorReplicationMode.INCREMENTAL
        )
          ? ConnectorReplicationMode.INCREMENTAL
          : ConnectorReplicationMode.FULL_TABLE;
      onChange(
        updateStreamsSelection(catalog, e.target.value, replicationMethod)
      );
    },
    [catalog, connector?.replicationModes, onChange]
  );

  return (
    <Styled.Wrapper>
      <Styled.StreamList>
        <Styled.Header>
          <Styled.HeaderItem />
          <Styled.HeaderItem>
            <Checkbox
              name="select-all"
              value={isAllStreamsSelected}
              onChange={handleSelectAllStreamsToggle}
            />
            Stream Name
          </Styled.HeaderItem>
          <span />
          <Styled.HeaderItem>Replication Key</Styled.HeaderItem>
          <Styled.HeaderItem>Replication Method</Styled.HeaderItem>
        </Styled.Header>
        {streams.map((stream) => (
          <Styled.StreamListItem key={stream.name}>
            <Styled.Stream>
              <Styled.StreamInner>
                <Styled.StreamToggle
                  className={expandedStreams[stream.name] ? 'active' : ''}
                  onClick={handleExpandStreamToggle(stream.name)}
                />
                <Styled.Checkbox>
                  <Checkbox
                    name={stream.name}
                    value={stream.isSelected}
                    onChange={handleStreamSelectedChange(stream.originalStream)}
                  />
                </Styled.Checkbox>
                <Styled.StreamTitle>{stream.name}</Styled.StreamTitle>
                {stream.replicationMethod === 'INCREMENTAL' ? (
                  <Styled.ReplicationKey>
                    <Dropdown
                      value={stream.replicationKey}
                      variant="compact"
                      onChange={handleSetStreamReplicationKey(
                        stream.originalStream
                      )}
                      options={stream.fields.map((field) => ({
                        title: field.name,
                        value: field.name,
                      }))}
                    />
                  </Styled.ReplicationKey>
                ) : (
                  <span />
                )}
                <Styled.ReplicationType>
                  <RadioGroupField
                    name="replication"
                    value={stream.replicationMethod}
                    onChange={handleReplicationChange(stream.originalStream)}
                    variant="compact"
                    appearance="light"
                    options={replicationModeOptions}
                  />
                </Styled.ReplicationType>
              </Styled.StreamInner>
              {expandedStreams[stream.name] && (
                <StreamProperties
                  stream={stream.originalStream}
                  onChangeSelected={handlePropertySelectedChange}
                />
              )}
            </Styled.Stream>
          </Styled.StreamListItem>
        ))}
      </Styled.StreamList>
    </Styled.Wrapper>
  );
};

export default PipelineCatalogEditor;
