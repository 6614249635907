import {StatusMap} from '@components/StatusBadge/StatusBadge';
import theme from '@shared/theme';
import {BillingStatus} from './billing-status.enum';

const billingStatusMap: StatusMap = {
  [BillingStatus.TRIALING]: {
    title: 'Trialing',
    color: theme.colors.green,
  },
  [BillingStatus.ACTIVE]: {
    title: 'Active',
    color: theme.colors.green,
  },
  [BillingStatus.CANCELED]: {
    title: 'Cancelled',
    color: theme.colors.danger,
  },
  [BillingStatus.INCOMPLETE]: {
    title: 'Incomplete',
    color: theme.colors.orange,
  },
  [BillingStatus.INCOMPLETE_EXPIRED]: {
    title: 'Expired',
    color: theme.colors.danger,
  },
  [BillingStatus.PAST_DUE]: {
    title: 'Past due',
    color: theme.colors.danger,
  },
  [BillingStatus.UNPAID]: {
    title: 'Unpaid',
    color: theme.colors.danger,
  },
};

export default billingStatusMap;
