import {AxiosResponse} from 'axios';
import {WorkspaceModel} from './workspace.model';
import {ApiClient} from '@services/api-client';
import {UploadSSHKeyDto} from './dto/upload-ssh-key.dto';
import {EnvironmentLine} from './environment-line.interface';
import {WorkspaceSettingsModel} from './workspace-settings.model';
import {MetricsTimeFrame} from './metrics-time-frame.enum';
import {MetricsDataModel} from './metrics-data.model';
import {LogEvent} from './log-event.model';
import {CreateWorkspaceDto} from './dto/create-workspace.dto';
import {PipelineModel} from './pipeline.model';
import {CreatePipelineDto} from './dto/create-pipeline.dto';
import {LogEntry} from './log-entry.model';
import {LogGroup} from './log-group.model';
import {GitReference} from '@components/GitReferencesSelector';
import {GenerateCatalogDto} from './dto/generate-catalog.dto';
import {UpdatePipelineDto} from './dto/update-pipeline.dto';

export class WorkspaceService extends ApiClient {
  async getAll() {
    return this.get('workspaces');
  }

  async getById(id: string): Promise<AxiosResponse<WorkspaceModel>> {
    return this.get(`workspaces/${id}`);
  }

  async create(dto: CreateWorkspaceDto) {
    return this.post('workspaces', dto);
  }

  async remove(id: string) {
    return this.delete(`workspaces/${id}`);
  }

  async uploadSSHKey(dto: UploadSSHKeyDto) {
    return this.post(
      `workspaces/${dto.workspaceId}/users/${dto.userId}/ssh-key`,
      {sshKey: dto.sshKey}
    );
  }

  async getEnvironment(
    workspaceId: string
  ): Promise<AxiosResponse<EnvironmentLine[]>> {
    return this.get(`workspaces/${workspaceId}/environment`);
  }

  async updateEnvironment(
    workspaceId: string,
    data: EnvironmentLine[]
  ): Promise<AxiosResponse<any>> {
    return this.put(`workspaces/${workspaceId}/environment`, {data});
  }

  async getSettings(
    workspaceId: string
  ): Promise<AxiosResponse<WorkspaceSettingsModel>> {
    return this.get(`workspaces/${workspaceId}/settings`);
  }

  async updateSettings(
    workspaceId: string,
    settings: WorkspaceSettingsModel
  ): Promise<AxiosResponse<WorkspaceSettingsModel>> {
    return this.put(`workspaces/${workspaceId}/settings`, settings);
  }

  async getMetrics(
    workspaceId: string,
    timeFrame: MetricsTimeFrame
  ): Promise<AxiosResponse<MetricsDataModel[]>> {
    return this.get(`workspaces/${workspaceId}/metrics`, {timeFrame});
  }

  async getBuildLogs(
    workspaceId: string,
    executionId: string
  ): Promise<AxiosResponse<LogEvent[]>> {
    return this.get(`workspaces/${workspaceId}/build/${executionId}`);
  }

  async getPipelineById(
    workspaceId: string,
    pipelineId: string
  ): Promise<AxiosResponse<PipelineModel>> {
    return this.get(`workspaces/${workspaceId}/pipelines/${pipelineId}`);
  }

  async getPipelines(
    workspaceId: string
  ): Promise<AxiosResponse<PipelineModel[]>> {
    return this.get(`workspaces/${workspaceId}/pipelines`);
  }

  async updatePipeline(
    workspaceId: string,
    pipelineId: string,
    dto: UpdatePipelineDto
  ): Promise<AxiosResponse<PipelineModel>> {
    return this.put(`workspaces/${workspaceId}/pipelines/${pipelineId}`, dto);
  }

  async deletePipelines(
    workspaceId: string,
    pipelineIds: string[]
  ): Promise<AxiosResponse<any>> {
    return this.delete(
      `workspaces/${workspaceId}/pipelines/${pipelineIds.join(',')}`
    );
  }

  async createPipeline(
    workspaceId: string,
    dto: CreatePipelineDto
  ): Promise<AxiosResponse<PipelineModel>> {
    return this.post(`workspaces/${workspaceId}/pipelines`, dto);
  }

  async getCatalog(workspaceId: string, dto: GenerateCatalogDto) {
    return this.post(`workspaces/${workspaceId}/pipelines/catalog`, dto);
  }

  async getPipelineLogGroups(
    workspaceId: string,
    pipelineId: string
  ): Promise<AxiosResponse<LogGroup[]>> {
    return this.get(
      `workspaces/${workspaceId}/pipelines/${pipelineId}/log-streams`
    );
  }

  async getPipelineLogs(
    workspaceId: string,
    pipelineId: string,
    logGroupName: string,
    filter: string = ''
  ): Promise<AxiosResponse<LogEntry[]>> {
    return this.get(
      `workspaces/${workspaceId}/pipelines/${pipelineId}/logs/${encodeURIComponent(
        logGroupName
      )}/?filter=${encodeURIComponent(filter)}`
    );
  }

  async generatePipelineLogsFile(
    workspaceId: string,
    pipelineId: string,
    logGroupName: string,
    filter: string = ''
  ): Promise<AxiosResponse<string>> {
    return this.get(
      `workspaces/${workspaceId}/pipelines/${pipelineId}/logs/${encodeURIComponent(
        logGroupName
      )}/download?filter=${encodeURIComponent(filter)}`
    );
  }

  async getRepoDetails(
    repoUrl: string
  ): Promise<AxiosResponse<{references: GitReference[]; executable: string}>> {
    return this.get(`workspaces/repo-details`, {repoUrl});
  }
}
