import styled from 'styled-components';

export const Wrapper = styled.div``;

export const List = styled.div`
  max-width: 45em;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px 30px 20px;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
  }

  & + & {
    margin-top: 10px;
  }
`;

export const Title = styled.h3`
  margin: 0 0 8px 0;
  font-size: 24px;
`;

export const Status = styled.div``;

export const Details = styled.div`
  margin-right: auto;
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-right: -20px;
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-left: 1px solid ${({theme}) => theme.colors.borderColor};

  .label {
    display: block;
    text-align: center;
    font-size: 14px;
    color: ${({theme}) => theme.colors.mediumGray};
  }

  .value {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-align: center;

    sup {
      font-weight: 400;
      color: ${({theme}) => theme.colors.mediumGray};
      font-size: 0.7em;
      margin-right: 0.04em;
      top: -0.115em;
    }
  }
`;
