import styled from 'styled-components';
import {lighten, rgba} from 'polished';

export const BrandedLogo = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const BrandedTitle = styled.h5`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 0.12em;
  margin: 0;
`;

export const BrandedWrapper = styled.div`
  border-radius: 8px;
  &.workspace-meltano-branded {
    background-color: ${({theme}) => rgba(theme.colors.meltano, 0.1)};
    ${BrandedTitle} {
      color: ${({theme}) => theme.colors.meltano};
    }
  }
  &.workspace-singerly-branded {
    svg {
      height: 100%;
    }
    background: #e2e8ed;
    ${BrandedLogo} {
      width: 16px;
      height: 22px;
      svg > path {
        fill: '#fff' !important;
      }
    }
    ${BrandedTitle} {
      color: #002343;
    }
  }
`;

export const BrandedWrapperContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 30px 4px;
`;

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s;

  &:hover {
    background-color: #f8f8f8;
  }
`;

export const Inner = styled.div``;

export const Section = styled.div`
  padding: 15px 30px;
  border-top: 1px solid ${({theme}) => theme.colors.borderColor};

  &.section-no-padding {
    padding: 0;
  }
`;

export const Header = styled.header`
  padding: 23px 30px;
`;

export const WorkspaceStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeLeftToComplete = styled.span`
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: ${({theme}) => theme.colors.mediumGray};
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 8px 0;
`;

export const Footer = styled.footer`
  display: flex;
  /* justify-content: space-between; */
  margin-top: 10px;
`;

export const FooterProperty = styled.div`
  & + & {
    margin-left: 30px;
  }
  & > .label {
    display: inline-block;
    font-size: 11px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #888;
  }
  & > .value {
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
  }
`;

export const PropertyList = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 -20px;
`;

export const Property = styled.div`
  padding: 0 20px;

  &.property-no-padding {
    padding: 0;
  }

  &.property-flex {
    display: flex;
    justify-content: stretch;
  }
`;

export const IconProperty = styled.div`
  display: flex;

  & > .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${({theme}) => theme.colors.primary};
    padding-right: 2px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    margin-right: 8px;
  }
`;

export const IconPropertyBody = styled.div``;

export const PropertyLabel = styled.span`
  display: block;
  font-size: 14px;
  color: ${({theme}) => theme.colors.mediumGray};
  margin-bottom: 3px;
`;
export const PropertyValue = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;

  small {
    font-size: 14px;
  }

  a {
    /* display: inline-block; */
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
  }
`;

export const WorkspaceUrl = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 15px 30px;
  color: ${({theme}) => theme.colors.text};
  flex-grow: 1;
  font-weight: 700;
  transition: 0.2s;

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: ${({theme}) => theme.colors.primary};
    padding-right: 2px;
    width: 23px;
    min-width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    margin-right: 8px;
  }

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }

  & + & {
    border-left: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;

export const DetailsLink = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${({theme}) => theme.colors.lightPrimary}; */
  border-top: 1px solid ${(props) => props.theme.colors.borderColor};
  color: ${({theme}) => theme.colors.primary};
  font-weight: 700;
  font-size: 16px;
  padding: 16px 30px;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => lighten(0.016, theme.colors.lightPrimary)};
  }

  .icon {
    margin-left: auto;
    font-weight: 700;
    transform: translateX(0);
    transition: 0.2s;
  }
  &:hover .icon {
    transform: translateX(10px);
  }
`;
