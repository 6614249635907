import {useSelector} from '@app/store';
import React, {useCallback} from 'react';
import UserCompaniesDropdownDisplay from './UserCompaniesDropdown';
import {setSelectedCompany} from '@features/auth/auth.slice';
import {connect, ConnectedProps} from 'react-redux';

const connectRedux = connect(null, {setSelectedCompany});

type UserCompaniesDropdownProps = {} & ConnectedProps<typeof connectRedux>;

const UserCompaniesDropdown = ({
  setSelectedCompany,
}: UserCompaniesDropdownProps) => {
  const userCompanies = useSelector((state) => state.auth.user.companies);
  const userSelectedCompany = useSelector(
    (state) => state.auth.selectedCompany
  );

  const handleSelectedChange = useCallback(
    (value: string) => {
      window.localStorage.setItem('cid', value);
      setSelectedCompany(value);
    },
    [setSelectedCompany]
  );

  return (
    <UserCompaniesDropdownDisplay
      companies={userCompanies || []}
      selectedCompany={userSelectedCompany}
      onSelectedChange={handleSelectedChange}
    />
  );
};

export default connectRedux(UserCompaniesDropdown);
