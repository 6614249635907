import {ApiClient} from '@services/api-client';
import {AxiosResponse} from 'axios';
import {CouponModel} from './coupon.model';
import {CreateCouponDto} from './dto/create-coupon.dto';

export class CouponService extends ApiClient {
  async getAll(): Promise<AxiosResponse<CouponModel[]>> {
    return this.get('billing/coupons');
  }

  async create(dto: CreateCouponDto): Promise<AxiosResponse<CouponModel>> {
    return this.post('billing/coupons', dto);
  }

  async remove(couponId: string) {
    return this.delete(`billing/coupons/${couponId}`);
  }
}
