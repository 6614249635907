import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import CreateConnectorPageDisplay from './CreateConnectorPage';
import {createConnector} from '@features/connectors/connectors.slice';
import {CreateConnectorDto} from '@features/connectors/dto/create-connector.dto';
import {unwrapResult} from '@reduxjs/toolkit';
import {useToasts} from 'react-toast-notifications';
import {useHistory} from 'react-router';
import useConnectorTags from '@features/connectors/hooks/useConnectorTags';
import useRepoDetails from '@features/connectors/hooks/useRepoDetails';

const connectRedux = connect(null, {createConnector});

type CreateConnectorPageProps = {} & ConnectedProps<typeof connectRedux>;

const CreateConnectorPage: React.VFC<CreateConnectorPageProps> = ({
  createConnector,
}) => {
  const [repoUrl, setRepoUrl] = useState('');

  const {addToast} = useToasts();
  const history = useHistory();

  const {tags} = useConnectorTags();
  const {isLoadingRepoDetails, repoDetails} = useRepoDetails(repoUrl);

  const handleSubmit = useCallback(
    (values: CreateConnectorDto) => {
      return createConnector(values)
        .then(unwrapResult)
        .then(() => {
          history.push('/connectors');
          addToast('Connector created successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, createConnector, history]
  );

  return (
    <CreateConnectorPageDisplay
      onSubmit={handleSubmit}
      onRepoUrlChange={setRepoUrl}
      repoDetails={repoDetails}
      tags={tags}
      isLoadingRepoDetails={isLoadingRepoDetails}
    />
  );
};

export default connectRedux(CreateConnectorPage);
