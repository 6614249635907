import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import UserListingPageDisplay from './UserListingPage';
import {fetchUsers} from '@features/users/users.slice';
import {useSelector} from '@app/store';

const connectRedux = connect(null, {fetchUsers});

type UserListingPageProps = ConnectedProps<typeof connectRedux>;

const UserListingPage: React.FC<UserListingPageProps> = ({fetchUsers}) => {
  const users = useSelector((state) => state.users.data);

  const selectedCompany = useSelector((state) => state.auth.selectedCompany);

  const isLoading = useSelector((state) => state.users.isLoading);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, selectedCompany]);

  return <UserListingPageDisplay users={users} isLoading={isLoading} />;
};

export default connectRedux(UserListingPage);
