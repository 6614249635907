import React from 'react';
import * as Styled from './Checkbox.styles';

type CheckboxProps = {
  id?: string;
  name: string;
  value: boolean;
  onBlur?: any;
  onFocus?: any;
  onChange: (e: {target: {name: string; value: boolean}}) => void;
  label?: string;
};

const Checkbox: React.VFC<CheckboxProps> = ({
  name,
  id,
  value,
  onChange,
  label = '',
  ...props
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({...e, target: {...e.target, value: e.target.checked}});
  };

  return (
    <Styled.Wrapper>
      <input
        {...props}
        id={id || name}
        type="checkbox"
        checked={value}
        onClick={handleClick}
        onChange={handleChange}
      />
      <Styled.Label htmlFor={id || name} onClick={handleClick}>
        {label && <span>{label}</span>}
      </Styled.Label>
    </Styled.Wrapper>
  );
};

export default Checkbox;
