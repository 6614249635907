import {useQuery} from 'react-query';
import {ConnectorsService} from '../connectors.service';

const useConnectorTags = () => {
  const {data: tags, isLoading: isLoadingTags} = useQuery({
    queryFn: async () => {
      const connectorService = new ConnectorsService();
      return connectorService.getTags().then((response) => response.data);
    },
    queryKey: ['connector-tags'],
  });

  return {
    tags,
    isLoadingTags,
  };
};

export default useConnectorTags;
