import styled from 'styled-components';

export const Wrapper = styled.div`
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-grow: 0;
    padding: 4px;
    font-size: 20px;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
`;
