import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 3px 8px;
  background-color: ${({theme}) => theme.colors.lightPrimary};
  color: ${({theme}) => darken(0.2, theme.colors.primary)};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  line-height: 1;
`;
