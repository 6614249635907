import {useSelector} from '@app/store';
import {unwrapResult} from '@reduxjs/toolkit';
import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {createUser} from '../../users.slice';
import CreateUserPageDisplay from './CreateUserPage';

const connectRedux = connect(null, {createUser});

type CreateUserPageProps = ConnectedProps<typeof connectRedux>;

const CreateUserPage: React.FC<CreateUserPageProps> = ({createUser}) => {
  const history = useHistory();
  const errors = useSelector((state) => state.users.errors);

  const handleSubmit = useCallback(
    (values) => {
      return createUser(values)
        .then(unwrapResult)
        .then(() => {
          setTimeout(() => {
            history.push('/users');
          }, 1000);
          return null;
        });
    },
    [createUser, history]
  );

  return <CreateUserPageDisplay onSubmit={handleSubmit} errors={errors} />;
};

export default connectRedux(CreateUserPage);
