import React, {useCallback, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';
import * as Styled from './Modal.styles';

export type ModalProps = {
  isOpen?: boolean;
  title: React.ReactNode;
  onClose: any;
  width?: string;
  children?: React.ReactNode;
};

const Modal = ({isOpen, title, onClose, children, width}: ModalProps) => {
  const modalRoot = document.getElementById('modal-root');
  const renderDomElement = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    modalRoot?.appendChild(renderDomElement);

    return () => {
      modalRoot?.removeChild(renderDomElement);
    };
  }, [modalRoot, renderDomElement]);

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onClose) {
        onClose();
      }
    },
    [onClose]
  );

  return isOpen
    ? createPortal(
        <Styled.Wrapper>
          <Styled.ModalWrapper style={{width: width || 'auto'}}>
            <Styled.Header>
              <Styled.Title>{title}</Styled.Title>
              <Styled.CloseButton onClick={handleClose}>
                &times;
              </Styled.CloseButton>
            </Styled.Header>
            <Styled.Body>{children}</Styled.Body>
          </Styled.ModalWrapper>
        </Styled.Wrapper>,
        renderDomElement
      )
    : null;
};

export default Modal;
