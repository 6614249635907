import Button from '@components/Button';
import Loader from '@components/Loader';
import Page from '@components/Page';
import StatusBadge from '@components/StatusBadge';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import companyStatusMap from '@features/companies/company-status-map';
import {CompanyStatusEnum} from '@features/companies/company-status.enum';
import {CompanyModel} from '@features/companies/company.model';
import CompanyPlanEditor from '@features/companies/components/CompanyPlanEditor';
import CompanyUsers from '@features/companies/components/CompanyUsers';
import CompanyWorkspaces from '@features/companies/components/CompanyWorkspaces';
import ConfirmationModal from '@modals/ConfirmationModal';
import React, {useCallback, useState} from 'react';
import * as Styled from './CompanyDetailsPage.styles';

type CompanyDetailsPageProps = {
  company?: CompanyModel;
  isLoading?: boolean;
  isDeleting?: boolean;
  onDelete: () => Promise<any>;
  onArchive: () => Promise<any>;
};

const CompanyDetailsPage: React.FC<CompanyDetailsPageProps> = ({
  company,
  onDelete,
  onArchive,
  isLoading = false,
  isDeleting = false,
}) => {
  const [
    isDeleteConfirmationModalOpen,
    setIsDeleteConfirmationModalOpen,
  ] = useState(false);
  const [
    isArchiveConfirmationModalOpen,
    setIsArchiveConfirmationModalOpen,
  ] = useState(false);

  const handleShowDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const handleCloseDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  const handleShowArchiveConfirmationModal = useCallback(() => {
    setIsArchiveConfirmationModalOpen(true);
  }, []);

  const handleCloseArchiveConfirmationModal = useCallback(() => {
    setIsArchiveConfirmationModalOpen(false);
  }, []);

  const handleArchiveCompany = useCallback(() => {
    handleCloseArchiveConfirmationModal();
    return onArchive();
  }, [handleCloseArchiveConfirmationModal, onArchive]);

  const handleDeleteCompany = useCallback(() => {
    handleCloseDeleteConfirmationModal();
    return onDelete();
  }, [handleCloseDeleteConfirmationModal, onDelete]);

  if (!company || isLoading) {
    return <Loader message="Loading project..." />;
  }
  return (
    <Page
      title={
        <Styled.PageTitle>
          <span className="title">{company.name}</span>
          <span className="status">
            <StatusBadge statusMap={companyStatusMap} value={company.status} />
          </span>
        </Styled.PageTitle>
      }
      actions={[
        <>
          {company.status !== CompanyStatusEnum.ARCHIVED ? (
            <Button
              onClick={handleShowArchiveConfirmationModal}
              isLoading={isDeleting}
              icon="delete"
              variant="danger"
            >
              Archive
            </Button>
          ) : null}
        </>,
        <>
          {company.status === CompanyStatusEnum.ARCHIVED ? (
            <Button
              onClick={handleShowDeleteConfirmationModal}
              isLoading={isDeleting}
              icon="delete"
              variant="danger"
            >
              Force Delete
            </Button>
          ) : null}
        </>,
      ]}
      breadcrumbs={[
        {title: 'Companies', link: '/companies'},
        {title: company.name, link: ''},
      ]}
    >
      <ConfirmationModal
        message="Are you sure you want to delete company? This action cannot be undone."
        onDismiss={handleCloseDeleteConfirmationModal}
        onConfirm={handleDeleteCompany}
        isOpen={isDeleteConfirmationModalOpen}
      />
      <ConfirmationModal
        message="Are you sure you want to archive company?"
        onDismiss={handleCloseArchiveConfirmationModal}
        onConfirm={handleArchiveCompany}
        isOpen={isArchiveConfirmationModalOpen}
      />
      <Tabs>
        <Tab title="Details">
          <CompanyPlanEditor company={company} />
        </Tab>
        <Tab title="Projects">
          <CompanyWorkspaces companyId={company.id} />
        </Tab>
        <Tab title="Users">
          <CompanyUsers companyId={company.id} />
        </Tab>
      </Tabs>
    </Page>
  );
};

export default CompanyDetailsPage;
