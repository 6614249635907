import Page from '@components/Page';
import {BillingDetails} from '@features/billing/billing-details.interface';
import React, {useCallback, useMemo, useState} from 'react';
import * as Styled from './CompanyBillingPage.styles';
import {format} from 'date-fns';
import StatusBadge from '@components/StatusBadge';
import billingStatusMap from '@features/billing/billing-status-map';
import Loader from '@components/Loader';
import {InvoiceModel} from '@features/billing/invoice.model';
import Table from '@components/Table';
import invoiceStatusMap from '@features/billing/invoice-status.map';
import Tab from '@components/Tabs/Tab';
import Tabs from '@components/Tabs';
import Button from '@components/Button';
import {BillingStatus} from '@features/billing/billing-status.enum';
import FormGroup from '@components/FormGroup';
import UpdatePaymentMethodModal from '@features/companies/modals/UpdatePaymentMethodModal';
import ConfirmationModal from '@modals/ConfirmationModal';
import PlanDetails from '@features/plans/components/PlanDetails';
import {ChargeModel} from '@features/billing/charge.model';
import chargeStatusMap from '@features/billing/charge-status.map';
import {CreateSubscriptionDto} from '@features/billing/dto/create-subscription.dto';
import CreateSubscriptionModal from '@features/billing/modals/CreateSubscriptionModal';
import HasPermission from '@features/auth/components/HasPermission';
import CompanyUsageReport from '@features/billing/components/CompanyUsageReport';

type CompanyBillingPageProps = {
  billingDetails: BillingDetails | null;
  invoices?: InvoiceModel[];
  transactions?: ChargeModel[];
  isLoading?: boolean;
  onUpdatePaymentMethod: (paymentMethodId: string) => Promise<any>;
  onCancelSubscription: () => Promise<any>;
  onCreateSubscription: (dto: CreateSubscriptionDto) => Promise<any>;
  onRenewSubscription: () => Promise<any>;
};

const CompanyBillingPage: React.VFC<CompanyBillingPageProps> = ({
  billingDetails,
  invoices = [],
  transactions = [],
  isLoading = false,
  onCancelSubscription,
  onCreateSubscription,
  onRenewSubscription,
  onUpdatePaymentMethod,
}) => {
  const [isCancelling, setIsCancelling] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCreateSubscriptionModalOpen, setIsCreateSubscriptionModalOpen] =
    useState(false);
  const [isRenewing, setIsRenewing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const cancelSubscriptionWarningMessage = useMemo(() => {
    return `Are you sure you want to cancel your subscription?${
      billingDetails &&
      billingDetails.subscription &&
      billingDetails.subscription.status === BillingStatus.TRIALING
        ? 'Your trial will also be cancelled'
        : ''
    }`;
  }, [billingDetails]);

  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);

  const handleShowConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleShowCreateSubscriptionModal = useCallback(() => {
    setIsCreateSubscriptionModalOpen(true);
  }, []);

  const handleCloseCreateSubscriptionModal = useCallback(() => {
    setIsCreateSubscriptionModalOpen(false);
  }, []);

  const handleOpenPaymentMethodModal = useCallback(() => {
    setIsPaymentMethodModalOpen(true);
  }, []);

  const handleClosePaymentMethodModal = useCallback(() => {
    setIsPaymentMethodModalOpen(false);
  }, []);

  const handleCancelSubscription = useCallback(() => {
    handleCloseConfirmationModal();
    setIsCancelling(true);
    return onCancelSubscription().then(() => {
      setIsCancelling(false);
    });
  }, [handleCloseConfirmationModal, onCancelSubscription]);

  const handleRenewSubscription = useCallback(async () => {
    setIsRenewing(true);
    await onRenewSubscription();
    setIsRenewing(false);
  }, [onRenewSubscription]);

  const handleCreateSubscription = useCallback(
    async (dto: CreateSubscriptionDto) => {
      setIsCreating(true);
      await onCreateSubscription(dto);
      setIsCreating(false);
    },
    [onCreateSubscription]
  );

  const handleSetPaymentMethod = useCallback(
    (callback: () => Promise<any>) => (paymentMethodId: string) => {
      return onUpdatePaymentMethod(paymentMethodId).then(() => {
        return callback();
      });
    },
    [onUpdatePaymentMethod]
  );

  if (!billingDetails || isLoading) {
    return (
      <Page title="Billing">
        <Loader message="Loading billing details..." />
      </Page>
    );
  }

  return (
    <Page title="Billing">
      {!billingDetails?.customer.paymentCard ||
      !billingDetails?.customer.paymentCard.last4 ? (
        <UpdatePaymentMethodModal
          isOpen={isPaymentMethodModalOpen}
          onClose={handleClosePaymentMethodModal}
          onSubmit={handleSetPaymentMethod(handleRenewSubscription)}
        />
      ) : (
        <UpdatePaymentMethodModal
          isOpen={isPaymentMethodModalOpen}
          onClose={handleClosePaymentMethodModal}
          onSubmit={onUpdatePaymentMethod}
        />
      )}
      <ConfirmationModal
        message={cancelSubscriptionWarningMessage}
        onConfirm={handleCancelSubscription}
        onDismiss={handleCloseConfirmationModal}
        isOpen={isConfirmationModalOpen}
      />
      <CreateSubscriptionModal
        isOpen={isCreateSubscriptionModalOpen}
        onClose={handleCloseCreateSubscriptionModal}
        onSubmit={handleCreateSubscription}
      />
      <Styled.Wrapper>
        <Tabs>
          <Tab title="Subscription">
            <Styled.Details>
              {billingDetails.plan && (
                <Styled.DetailsGroup className="billing-details-no-border">
                  <Styled.DetailsGroupTitle>
                    Selected plan
                  </Styled.DetailsGroupTitle>
                  <Styled.DetailsLines>
                    <PlanDetails plan={billingDetails.plan} />
                  </Styled.DetailsLines>
                </Styled.DetailsGroup>
              )}
              <Styled.DetailsGroup>
                <Styled.DetailsGroupTitle>Details</Styled.DetailsGroupTitle>
                <Styled.DetailsLines>
                  <Styled.DetailsLine>
                    <Styled.DetailsLabel>Status</Styled.DetailsLabel>
                    <Styled.DetailsValue>
                      <StatusBadge
                        statusMap={billingStatusMap}
                        value={billingDetails.subscription.status}
                      />
                    </Styled.DetailsValue>
                  </Styled.DetailsLine>
                  {billingDetails.subscription &&
                    !!billingDetails.subscription.trialEnd && (
                      <Styled.DetailsLine>
                        <Styled.DetailsLabel>Trial ends</Styled.DetailsLabel>
                        <Styled.DetailsValue>
                          {billingDetails.subscription.trialEnd
                            ? format(
                                billingDetails.subscription.trialEnd * 1000,
                                'MMMM d, yyyy'
                              )
                            : '-'}
                        </Styled.DetailsValue>
                      </Styled.DetailsLine>
                    )}
                </Styled.DetailsLines>
              </Styled.DetailsGroup>
              <Styled.DetailsGroup>
                <Styled.DetailsGroupTitle>
                  Payment Method
                </Styled.DetailsGroupTitle>
                <Styled.DetailsLines>
                  <Styled.DetailsLine>
                    <Styled.DetailsLabel>Card</Styled.DetailsLabel>
                    {billingDetails.customer.paymentCard &&
                    billingDetails.customer.paymentCard.brand ? (
                      <Styled.DetailsValue>
                        {billingDetails.customer.paymentCard.brand.toUpperCase()}
                        &nbsp;••••&nbsp;
                        {billingDetails.customer.paymentCard.last4}
                      </Styled.DetailsValue>
                    ) : (
                      <Styled.DetailsValue>
                        Payment method is not set
                      </Styled.DetailsValue>
                    )}
                  </Styled.DetailsLine>
                </Styled.DetailsLines>
              </Styled.DetailsGroup>
              <Styled.Actions>
                <FormGroup>
                  {billingDetails.subscription &&
                  billingDetails.subscription.status ===
                    BillingStatus.INCOMPLETE_EXPIRED ? (
                    <Button
                      variant="primary"
                      size="large"
                      fullWidth
                      isLoading={isCreating}
                      onClick={handleShowCreateSubscriptionModal}
                    >
                      Setup subscription
                    </Button>
                  ) : null}
                </FormGroup>
                <HasPermission permissions={['update:billing']}>
                  <FormGroup>
                    {billingDetails.subscription &&
                    billingDetails.subscription.status ===
                      BillingStatus.CANCELED ? (
                      <Button
                        variant="primary"
                        size="large"
                        fullWidth
                        isLoading={isRenewing}
                        onClick={
                          billingDetails.customer.paymentCard?.last4
                            ? handleRenewSubscription
                            : handleOpenPaymentMethodModal
                        }
                      >
                        Renew subscription
                      </Button>
                    ) : null}
                  </FormGroup>
                </HasPermission>
                <HasPermission permissions={['update:billing']}>
                  <FormGroup>
                    {billingDetails.subscription &&
                    billingDetails.subscription.status !==
                      BillingStatus.CANCELED &&
                    billingDetails.subscription.status !==
                      BillingStatus.INCOMPLETE_EXPIRED ? (
                      <Button
                        variant="danger"
                        size="large"
                        fullWidth
                        isLoading={isCancelling}
                        onClick={handleShowConfirmationModal}
                      >
                        Cancel subscription
                      </Button>
                    ) : null}
                  </FormGroup>
                </HasPermission>
                <HasPermission permissions={['update:billing']}>
                  <FormGroup>
                    {billingDetails.subscription &&
                    billingDetails.subscription.status !==
                      BillingStatus.CANCELED &&
                    billingDetails.subscription.status !==
                      BillingStatus.INCOMPLETE_EXPIRED ? (
                      <Button
                        variant="secondary"
                        size="large"
                        fullWidth
                        onClick={handleOpenPaymentMethodModal}
                      >
                        Update payment method
                      </Button>
                    ) : null}
                  </FormGroup>
                </HasPermission>
              </Styled.Actions>
            </Styled.Details>
          </Tab>
          <Tab title="Usage report">
            <CompanyUsageReport />
          </Tab>
          <Tab title="Invoices">
            <Styled.Invoices>
              <Table
                columnDefinitions={[
                  {
                    accessor: (row) => <strong>{row.customer_email}</strong>,
                    title: 'Customer email',
                  },
                  {
                    accessor: (row) =>
                      format(row.created * 1000, 'MMMM d, yyyy'),
                    title: 'Created at',
                  },
                  {
                    accessor: (row) => (
                      <StatusBadge
                        statusMap={invoiceStatusMap}
                        value={row.status}
                      />
                    ),
                    compact: true,
                    title: 'Status',
                  },
                  {
                    accessor: (row) =>
                      `$${row.amount_due / 100} / $${row.amount_paid / 100}`,
                    title: 'Amount due/paid',
                  },
                  {
                    accessor: (row) => (
                      <a
                        href={row.hosted_invoice_url}
                        className="invoice-link"
                        rel="noopener noreferrer nofollow"
                        target="__blank"
                      >
                        <i className="icon icon-link" />
                        <span>View details</span>
                      </a>
                    ),
                    title: 'Invoice link',
                  },
                ]}
                data={invoices}
              />
            </Styled.Invoices>
          </Tab>
          <Tab title="Transactions">
            <Styled.Transactions>
              <Table
                data={transactions}
                columnDefinitions={[
                  {
                    accessor: (row) =>
                      format(row.created * 1000, 'MMMM d, yyyy'),
                    title: 'Created at',
                  },
                  {
                    title: 'Status',
                    accessor: (row) => (
                      <StatusBadge
                        statusMap={chargeStatusMap}
                        value={row.status}
                      />
                    ),
                  },
                  {
                    title: 'Amount',
                    accessor: (row) => <strong>${row.amount / 100}</strong>,
                  },
                  {
                    title: 'Description',
                    accessor: 'description',
                  },
                  {
                    accessor: (row) => (
                      <>
                        {row.invoice ? (
                          <a
                            href={row.invoice?.hosted_invoice_url}
                            className="invoice-link"
                            rel="noopener noreferrer nofollow"
                            target="__blank"
                          >
                            <i className="icon icon-link" />
                            <span>View details</span>
                          </a>
                        ) : (
                          '-'
                        )}
                      </>
                    ),
                    title: 'Invoice link',
                  },
                ]}
              />
            </Styled.Transactions>
          </Tab>
        </Tabs>
      </Styled.Wrapper>
    </Page>
  );
};

export default CompanyBillingPage;
