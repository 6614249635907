import Loader from '@components/Loader';
import usePipelineLogs from '@features/workspaces/hooks/usePipelineLogs';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {format, formatDistanceStrict} from 'date-fns';
import * as Styled from './PipelineLogs.styles';
import Button from '@components/Button';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import useLogGroups from '@features/workspaces/hooks/useLogGroups';
import {LogGroup} from '@features/workspaces/log-group.model';
import PipelineLogsSkeletonLoader from './PipelineLogs.skeleton';
import pipelineStateMap from '@features/workspaces/pipeline-state.map';
import TextField from '@components/TextField';
import {LogEntry} from '@features/workspaces/log-entry.model';

type PipelineLogsProps = {
  workspaceId: string;
  pipeline: PipelineModel;
  onGoBack?: () => any;
};

const PipelineLogs: React.VFC<PipelineLogsProps> = ({
  workspaceId,
  pipeline,
  onGoBack,
}) => {
  const [selectedLogGroup, setSelectedLogGroup] = useState<LogGroup | null>(
    null
  );
  const [isDownloadingLogs, setIsDownloadingLogs] = useState(false);
  const logEntriesRef = useRef<HTMLDivElement>(null);
  const logEntriesTopScrollStubRef = useRef<HTMLDivElement>(null);
  const logEntriesBottomScrollStubRef = useRef<HTMLDivElement>(null);

  const [filter, setFilter] = useState('');
  const {isLoading: isLoadingLogGroups, logGroups} = useLogGroups(
    workspaceId,
    pipeline.id,
    (data: LogGroup[]) => {
      if (!selectedLogGroup && data.length) {
        setSelectedLogGroup(data[0]);
      }
    }
  );

  const {
    logs,
    isFetching: isFetchingLogs,
    onGenerateLogsFile,
  } = usePipelineLogs({
    workspaceId: workspaceId,
    pipelineId: pipeline.id,
    isEnabled: !!selectedLogGroup,
    logGroupName: selectedLogGroup?.logStreamName || '',
    doRefetch: !!selectedLogGroup && selectedLogGroup.state === 'running',
  });

  const handleFilterChange = useCallback((e: {target: {value: string}}) => {
    setFilter(e.target.value);
  }, []);

  const filteredLogs = useMemo<LogEntry[]>(() => {
    if (!filter.trim()) {
      return logs || [];
    }
    return (logs || []).reduce((prev, logEntry) => {
      if (logEntry.message.toLowerCase().includes(filter.toLowerCase())) {
        prev.push({
          ...logEntry,
          message: logEntry.message.replace(
            new RegExp(`(${filter})`, 'ig'),
            '<em>$1</em>'
          ),
        });
      }
      return prev;
    }, [] as any[]);
  }, [filter, logs]);

  const handleScrollBottom = useCallback(() => {
    if (logEntriesBottomScrollStubRef.current) {
      logEntriesBottomScrollStubRef.current?.scrollIntoView();
    }
  }, []);

  const handleScrollTop = useCallback(() => {
    if (logEntriesRef.current) {
      logEntriesRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, []);

  const handleDownloadLogs = useCallback(async () => {
    setIsDownloadingLogs(true);
    const response = await onGenerateLogsFile();
    if (response.data) {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'pipeline-logs.txt');
      document.body.appendChild(link);
      setIsDownloadingLogs(false);
      link.click();
    }
  }, [onGenerateLogsFile]);

  if (!isLoadingLogGroups && !logGroups.length) {
    return <Styled.Wrapper>No logs recorded yet.</Styled.Wrapper>;
  }

  if (isLoadingLogGroups || !selectedLogGroup) {
    return <Loader message="Loading pipeline logs..." />;
  }

  return (
    <>
      <Styled.Wrapper>
        <Grid gutterWidth={16}>
          <GridItem cols={5}>
            <Styled.LogGroups>
              {logGroups.map(
                (logGroup, key) =>
                  logGroup.creationTime && (
                    <Styled.LogGroup
                      className={[
                        logGroup.logStreamName ===
                        selectedLogGroup?.logStreamName
                          ? 'active'
                          : '',
                      ].join(' ')}
                      key={key}
                      onClick={() => setSelectedLogGroup(logGroup)}
                    >
                      <Styled.LogGroupRow>
                        {logGroup.state && (
                          <i
                            className={`icon icon-${
                              pipelineStateMap[logGroup.state].icon
                            }`}
                            style={{
                              color: pipelineStateMap[logGroup.state].color,
                            }}
                          />
                        )}
                        {format(
                          new Date(logGroup.startTime || logGroup.creationTime),
                          'MMM d, y h:mm aaa'
                        )}
                        {isFetchingLogs &&
                          logGroup.logStreamName ===
                            selectedLogGroup?.logStreamName && (
                            <Loader size={16} />
                          )}
                      </Styled.LogGroupRow>
                      <Styled.LogGroupRow>
                        {logGroup.startTime && (
                          <Styled.LogGroupDuration>
                            Duration:{' '}
                            <strong>
                              {formatDistanceStrict(
                                new Date(logGroup.endTime || Date.now()),
                                new Date(logGroup.startTime)
                              )}
                            </strong>
                          </Styled.LogGroupDuration>
                        )}
                      </Styled.LogGroupRow>
                    </Styled.LogGroup>
                  )
              )}
            </Styled.LogGroups>
          </GridItem>
          <GridItem cols={16}>
            <Styled.LogActionBar>
              <Styled.LogActionBarSection>
                <TextField
                  name="filter"
                  value={filter}
                  placeholder="Filter logs"
                  buttons={
                    filter.trim()
                      ? [
                          <button
                            className="filter-reset"
                            onClick={() => setFilter('')}
                          >
                            <span>&times;</span>
                          </button>,
                        ]
                      : null
                  }
                  onChange={handleFilterChange}
                />
              </Styled.LogActionBarSection>
              <Styled.LogActionBarSection>
                <Button
                  variant="secondary"
                  icon="download"
                  disabled={!filteredLogs.length}
                  isLoading={isDownloadingLogs}
                  onClick={handleDownloadLogs}
                >
                  Download logs
                </Button>
              </Styled.LogActionBarSection>
              <Styled.LogActionBarSection alignment="right">
                <Button variant="secondary" onClick={handleScrollBottom}>
                  Scroll to bottom
                </Button>
                <Button variant="secondary" onClick={handleScrollTop}>
                  Scroll to top
                </Button>
              </Styled.LogActionBarSection>
            </Styled.LogActionBar>
            <Styled.LogEntries ref={logEntriesRef}>
              <div ref={logEntriesTopScrollStubRef} />
              {isFetchingLogs && !filteredLogs.length ? (
                <PipelineLogsSkeletonLoader />
              ) : null}
              {!isFetchingLogs && !filteredLogs.length ? (
                <div>
                  No log entries to display yet. It could take a couple of
                  minutes until first log messages arrive.
                </div>
              ) : null}
              {!!filteredLogs.length &&
                filteredLogs.map((logEntry, key) => (
                  <Styled.LogEntry key={key}>
                    <span className="timestamp">
                      {format(new Date(logEntry.timestamp), 'h:mm aaa')}
                    </span>
                    <div
                      className="message"
                      dangerouslySetInnerHTML={{__html: logEntry.message}}
                    />
                  </Styled.LogEntry>
                ))}
              <div ref={logEntriesBottomScrollStubRef} />
            </Styled.LogEntries>
          </GridItem>
        </Grid>
      </Styled.Wrapper>
    </>
  );
};

export default PipelineLogs;
