import React from 'react';

import Loader from '@components/Loader';
import useConnector from '@features/connectors/hooks/useConnector';
import * as Styled from './ConnectorValue.styles';

type Props = {
  connectorId?: string;
  config?: any;
};

const ConnectorValue: React.VFC<Props> = ({config, connectorId}) => {
  const {connector, isLoading} = useConnector(connectorId);
  return (
    <Styled.Wrapper>
      {isLoading && <Loader message="Loading connector..." size={18} />}
      <strong
        style={{
          display: 'inline-flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        {connector ? (
          <>
            {connector.logo ? (
              <img
                src={connector.logo}
                width="20px"
                height="20px"
                style={{objectFit: 'contain'}}
                alt={connector.name}
              />
            ) : (
              <i className="icon icon-electrical_services" />
            )}
            <span>{connector.name}</span>
          </>
        ) : (
          'Not specified'
        )}
      </strong>
    </Styled.Wrapper>
  );
};

export default ConnectorValue;
