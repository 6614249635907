import React, {useCallback, useMemo, useState} from 'react';
import Loader from '@components/Loader';
import Page from '@components/Page';
import StatusBadge from '@components/StatusBadge';
import workspaceStatusMap from '@features/workspaces/workspace-status-map';
import {
  WorkspaceModel,
  WorkspaceStatusEnum,
} from '@features/workspaces/workspace.model';
import * as Styled from './AirflowWorkspacePage.styles';
import Button from '@components/Button';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import WorkspaceUsers from '@features/workspaces/components/WorkspaceUsers';
import ConfirmationModal from '@modals/ConfirmationModal';
import CompanyConnectors from '@features/companies/components/CompanyConnectors';
import CreateCompanyConnector from '@features/companies/components/CreateCompanyConnector';
import {ConnectorModel} from '@features/connectors/connector.model';
import EditCompanyConnector from '@features/companies/components/EditCompanyConnector';
import HasPermission from '@features/auth/components/HasPermission';
import PipelineList from '@features/workspaces/components/PipelineList';
import CreatePipelineScreen from '@features/workspaces/components/CreatePipelineScreen';
import LinkButton from '@components/LinkButton';
import {useCreatePipeline, useGetPipelines} from './hooks';
import {useToasts} from 'react-toast-notifications';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import PipelineDetailsScreen from '@features/pipelines/components/PipelineDetailsScreen';

type AirflowWorkspacePageProps = {
  workspace?: WorkspaceModel;
  onDelete: () => Promise<any>;
  isLoading?: boolean;
  isDeleting?: boolean;
};

const AirflowWorkspacePage: React.FC<AirflowWorkspacePageProps> = ({
  workspace,
  onDelete,
  isLoading = false,
  isDeleting = false,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const {pipelines, isLoading: isLoadingPipelines} = useGetPipelines(
    workspace?.id || ''
  );
  const [selectedPipeline, setSelectedPipeline] =
    useState<PipelineModel | null>(null);

  const {addToast} = useToasts();

  const airflowUrl = useMemo(() => {
    return workspace && workspace.infrastructure
      ? `https://${workspace.infrastructure.airflowUiUrl}`
      : '';
  }, [workspace]);

  const [connectorsView, setConnectorsView] = useState<
    'list' | 'create' | 'edit'
  >('list');

  const [pipelinesView, setPipelinesView] = useState<
    'list' | 'create' | 'edit' | 'logs'
  >('list');

  const [connectorToEdit, setConnectorToEdit] = useState<ConnectorModel | null>(
    null
  );

  const {createPipeline, isLoading: isCreatingPipeline} = useCreatePipeline(
    workspace?.id || '',
    () => {
      addToast('Pipeline created successfully', {
        appearance: 'success',
        autoDismiss: true,
      });
      setPipelinesView('list');
    }
  );

  const handleShowConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(true);
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmationModalOpen(false);
  }, []);

  const handleDeleteWorkspace = useCallback(() => {
    handleCloseConfirmationModal();
    return onDelete();
  }, [handleCloseConfirmationModal, onDelete]);

  const handleEditConnector = useCallback((connector: ConnectorModel) => {
    setConnectorToEdit(connector);
    setConnectorsView('edit');
  }, []);

  const handleClearEditState = useCallback(() => {
    setConnectorsView('list');
    setConnectorToEdit(null);
  }, []);

  const handleAddPipeline = useCallback(
    async () => setPipelinesView('create'),
    []
  );

  if (!workspace || isLoading) {
    return <Loader message="Loading project..." />;
  }

  return (
    <Page
      title={
        <Styled.PageTitle>
          <span className="title">{workspace.name}</span>
          <span className="status">
            <StatusBadge
              statusMap={workspaceStatusMap}
              value={workspace.status}
            />
          </span>
        </Styled.PageTitle>
      }
      actions={[
        <>
          <LinkButton
            to={airflowUrl}
            isExternal
            icon="link"
            disabled={workspace.status === WorkspaceStatusEnum.CREATING}
            variant="secondary"
          >
            Go to Airflow
          </LinkButton>
          <HasPermission permissions={['delete:projects']}>
            <Button
              onClick={handleShowConfirmationModal}
              isLoading={isDeleting}
              icon="delete"
              disabled={workspace.status === WorkspaceStatusEnum.CREATING}
              variant="danger"
            >
              Delete
            </Button>
          </HasPermission>
        </>,
      ]}
      breadcrumbs={[
        {title: 'Projects', link: '/projects'},
        {title: workspace.name, link: ''},
      ]}
    >
      <ConfirmationModal
        message="Are you sure you want to delete project? This action cannot be undone."
        onDismiss={handleCloseConfirmationModal}
        onConfirm={handleDeleteWorkspace}
        isOpen={isConfirmationModalOpen}
      />
      {pipelinesView === 'logs' && !!selectedPipeline && (
        <PipelineDetailsScreen
          workspaceId={workspace.id}
          pipelineId={selectedPipeline.id}
          onGoBack={() => setPipelinesView('list')}
        />
      )}
      {['create', 'list'].includes(pipelinesView) && (
        <Tabs>
          <Tab title="Pipelines">
            {pipelinesView === 'list' && (
              <PipelineList
                workspaceId={workspace.id}
                onAddPipeline={
                  workspace.status === WorkspaceStatusEnum.RUNNING
                    ? handleAddPipeline
                    : undefined
                }
                onClick={(pipeline) => {
                  setSelectedPipeline(pipeline);
                  setPipelinesView('logs');
                }}
                pipelines={pipelines || []}
                isLoading={isLoadingPipelines}
              />
            )}
            {pipelinesView === 'create' && (
              <CreatePipelineScreen
                workspaceId={workspace.id}
                onGoBack={() => setPipelinesView('list')}
                isLoading={isCreatingPipeline}
                onSubmit={createPipeline}
              />
            )}
          </Tab>
          <Tab title="Users">
            <WorkspaceUsers workspaceId={workspace.id} />
          </Tab>
          <Tab title="Connectors">
            {connectorsView === 'list' && (
              <CompanyConnectors
                onCreate={() => setConnectorsView('create')}
                onEdit={handleEditConnector}
              />
            )}
            {connectorsView === 'create' && (
              <CreateCompanyConnector
                onGoBack={() => setConnectorsView('list')}
              />
            )}
            {connectorsView === 'edit' && connectorToEdit && (
              <EditCompanyConnector
                connector={connectorToEdit}
                onGoBack={handleClearEditState}
              />
            )}
          </Tab>
          {/* <Tab title="Monitoring">
            <WorkspaceMonitoring workspaceId={workspace.id} />
          </Tab>
          <Tab title="Environment">
            <WorkspaceEnvironment workspaceId={workspace.id} />
          </Tab>
          <Tab title="Settings">
            <WorkspaceSettings workspaceId={workspace.id} />
          </Tab> */}
        </Tabs>
      )}
    </Page>
  );
};

export default AirflowWorkspacePage;
