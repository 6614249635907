import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Options = styled.div`
  display: flex;
`;

export const OptionLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;

  svg {
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;

export const OptionLabel = styled.h5`
  margin: 10px 0 0;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  color: inherit;
`;

export const Option = styled.div`
  position: relative;
  border-radius: 8px;
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  color: ${({theme}) => theme.colors.primary};
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  transition: 0.2s;

  ${OptionLabel} {
    color: ${({theme}) => theme.colors.text};
  }

  &.disabled {
    background-color: ${({theme}) => theme.colors.lightGray} !important;
    border-color: ${({theme}) => theme.colors.borderColor} !important;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1) !important;
    pointer-events: none;
    color: ${({theme}) => theme.colors.mediumGray};
    cursor: not-allowed;

    ${OptionLabel} {
      color: ${({theme}) => theme.colors.mediumGray};
    }
  }

  & + & {
    margin-left: 10px;
  }

  &:hover,
  &.active {
    border-color: ${({theme}) => theme.colors.primary};
  }

  &.active {
    box-shadow: 0 2px 1px ${({theme}) => theme.colors.primary};
  }
`;

export const OptionInner = styled.div`
  padding: 18px 16px;
`;

export const OptionBadge = styled.span`
  position: absolute;
  right: 15px;
  top: 0;
  transform: translateY(-50%);
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 800;
  color: ${({theme}) => theme.colors.text};
  letter-spacing: 0.05em;
  padding: 3px 10px;
  border-radius: 30px;
  white-space: nowrap;

  &.accent {
    color: ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.lightPrimary};
  }

  &.gold {
    background-color: #ffe1a6;
  }
`;
