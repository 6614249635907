import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {useSelector} from '@app/store';
import LinkButton from '@components/LinkButton';
import Loader from '@components/Loader';
import Page from '@components/Page';
import WorkspaceList from '@features/workspaces/components/WorkspaceList';
import {fetchWorkspaces} from '@features/workspaces/workspace.slice';
import {getCurrentCompany} from '@features/auth/auth.slice';
import WarningMessage from '@components/WarningMessage';
import * as Styled from './WorkspaceListPage.styles';
import HasPermission from '@features/auth/components/HasPermission';
import EmptyPlaceholderImage from '@assets/images/projects-empty.svg';
import useWorkspaces from '@features/workspaces/hooks/useWorkspaces';

type WorkspaceListPageProps = {
  fetchWorkspaces: any;
};

const WorkspaceListPage: React.FC<WorkspaceListPageProps> = ({
  fetchWorkspaces,
}) => {
  const currentCompany = useSelector(getCurrentCompany);
  const {workspaces, isLoading} = useWorkspaces(currentCompany?.id);

  const userExceededMaxWorkspaceCountLimit = useMemo(() => {
    return (
      currentCompany &&
      currentCompany.plan &&
      currentCompany.plan.maxProjectCount <=
        (currentCompany.workspaces || []).length
    );
  }, [currentCompany]);

  if (!isLoading && !workspaces.length) {
    return (
      <Page title="Projects">
        <Styled.EmptyPlaceholder
          style={{backgroundImage: `url(${EmptyPlaceholderImage})`}}
        >
          <HasPermission
            permissions="create:projects"
            fallback={
              <Styled.Title>
                No projects to show here yet.{' '}
                <em>
                  Project creation is restricted to company admin users only.
                </em>
              </Styled.Title>
            }
          >
            <Styled.Title>
              Let’s start by creating
              <br />a new Meltano project!
              <em>
                The project will contain Meltano and Airflow installation.
              </em>
            </Styled.Title>
          </HasPermission>
          <Styled.ActionBar>
            <HasPermission permissions="create:projects">
              <LinkButton to="/projects/create" size="large">
                Create a project
              </LinkButton>
            </HasPermission>
          </Styled.ActionBar>
        </Styled.EmptyPlaceholder>
      </Page>
    );
  }

  return (
    <Page
      title="Projects"
      actions={[
        isLoading ? null : (
          <HasPermission permissions="create:projects">
            <LinkButton
              disabled={userExceededMaxWorkspaceCountLimit}
              to="/projects/create"
              icon="add_circle1"
            >
              Create new
            </LinkButton>
          </HasPermission>
        ),
      ]}
    >
      {isLoading ? (
        <Loader message="Loading projects..." />
      ) : (
        <>
          {userExceededMaxWorkspaceCountLimit ? (
            <WarningMessage
              message={`Your current plan is limited to ${currentCompany?.plan?.maxProjectCount} project(s) per company`}
            />
          ) : null}
          <WorkspaceList workspaces={workspaces} />
        </>
      )}
    </Page>
  );
};

export default connect(null, {fetchWorkspaces})(React.memo(WorkspaceListPage));
