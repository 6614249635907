import LinkButton from '@components/LinkButton';
import Page from '@components/Page';
import ManagedCompaniesScreen from '@features/companies/components/ManagedCompaniesScreen';
import React from 'react';
import * as Styled from './ManagedCompaniesPage.styles';

type ManagedCompaniesPageProps = {};

const ManagedCompaniesPage: React.VFC<ManagedCompaniesPageProps> = () => {
  return (
    <Page
      title="Managed Companies"
      actions={[
        <LinkButton to="/managed-companies/create" icon="add">
          Add New
        </LinkButton>,
      ]}
    >
      <ManagedCompaniesScreen />
    </Page>
  );
};

export default ManagedCompaniesPage;
