import React, {useCallback, useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import App from '../../App';
import auth0Config from '../../config/auth0';
import {getProfile} from '../../features/auth/auth.slice';
import {connect, ConnectedProps} from 'react-redux';

const connectRedux = connect(null, {getProfile});

type RootProps = ConnectedProps<typeof connectRedux>;

const Root: React.FC<RootProps> = ({getProfile}) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [isFetchingToken, setIsFetchingToken] = useState(true);

  const handleSetToken = useCallback((token: string) => {
    window.localStorage.setItem('token', token);
    setIsFetchingToken(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      setIsFetchingToken(true);
      getAccessTokenSilently({
        audience: auth0Config.audience,
        scope: auth0Config.scope,
      })
        .then(handleSetToken)
        .then(getProfile)
        .then(() => {
          setIsLoadingProfile(false);
        });
    }
  }, [
    isAuthenticated,
    handleSetToken,
    getAccessTokenSilently,
    getProfile,
    isLoading,
    loginWithRedirect,
  ]);

  if (!isAuthenticated || isLoading || isFetchingToken || isLoadingProfile) {
    return null;
  }

  return <App />;
};

export default connectRedux(Root);
