import {StatusMap} from '@components/StatusBadge/StatusBadge';
import theme from '@shared/theme';
import {ChargeStatus} from './charge.model';

const chargeStatusMap: StatusMap = {
  [ChargeStatus.SUCCEEDED]: {
    title: 'Succeeded',
    color: theme.colors.green,
  },
  [ChargeStatus.PENDING]: {
    title: 'Pending',
    color: theme.colors.orange,
  },
  [ChargeStatus.FAILED]: {
    title: 'Failed',
    color: theme.colors.danger,
  },
};

export default chargeStatusMap;
