import Loader from '@components/Loader';
import useManagedCompanies from '@features/companies/hooks/useManagedCompanies';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import React from 'react';
import ManagedCompaniesScreen from './ManagedCompaniesScreen';

type Props = {};

const ManagedCompaniesScreenContainer: React.VFC<Props> = () => {
  const selectedCompany = useSelectedCompany();
  const {companies, isLoading} = useManagedCompanies(selectedCompany?.id || '');

  if (isLoading) {
    return <Loader message="Loading..." />;
  }

  return <ManagedCompaniesScreen companies={companies || []} />;
};

export default ManagedCompaniesScreenContainer;
