import {useAuth0} from '@auth0/auth0-react';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@app/store';
import {AuthUser} from '@features/auth/auth-user.model';
import HeaderUserDisplay from './HeaderUser';

type HeaderUserProps = {};

const HeaderUser: React.FC<HeaderUserProps> = () => {
  const user = useSelector<RootState, Partial<AuthUser>>(
    (state) => state.auth.user
  );
  const {logout} = useAuth0();

  const handleLogout = useCallback(() => {
    return logout({returnTo: window.origin});
  }, [logout]);

  if (!user) {
    return null;
  }
  return <HeaderUserDisplay user={user} onLogout={handleLogout} />;
};

export default HeaderUser;
