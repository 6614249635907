import {useFormik} from 'formik';
import React, {useCallback} from 'react';
import {Helmet} from 'react-helmet';
import Header from '../../../../components/Header';
import * as Styled from './CompleteRegistrationPage.styles';
import * as Yup from 'yup';
import FormGroup from '../../../../components/FormGroup';
import TextField from '../../../../components/TextField';
import {CompaniesService} from '../../../companies/companies.service';
import Steps from '@components/Steps';
import Step from '@components/Steps/Step';
import {ValidationErrors} from 'types/validation-errors';
import InfoMessage from '@components/InfoMessage';
import GridItem from '@components/Grid/GridItem';
import Grid from '@components/Grid';
import ToggleField from '@components/ToggleField';

const companiesService = new CompaniesService();

type CompleteRegistrationPageProps = {
  onSubmit: (values: any) => Promise<any>;
  billingEmail: string;
  errors?: ValidationErrors;
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  companyName: Yup.string()
    .required('Company name is required')
    .test('nameIsUnique', 'Name already exists', (value?: string) => {
      return companiesService.validateName(value || '');
    }),
});

const CompleteRegistrationPage: React.FC<CompleteRegistrationPageProps> = ({
  onSubmit,
  billingEmail,
  errors = {},
}) => {
  const handleSubmit = useCallback(
    async (values) => {
      // const paymentMethodId = await submitPaymentMethod();
      return onSubmit({...values});
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      billingEmail,
      companyName: '',
      coupon: '',
      inviteBillingUser: false,
    },
    enableReinitialize: true,
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Helmet title="Singer.ly" />
      <Styled.Wrapper>
        <Header simple />
        <Styled.Main>
          <Styled.Title>
            Welcome to Singerly!
            <small>Please complete your profile to proceed further</small>
          </Styled.Title>
          {/* <InfoMessage>
            Currently Singerly is only open for beta users. If you would like to
            participate in beta, please fill out{' '}
            <a
              href="https://forms.gle/breswaACQ16tjb1GA"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <strong>this form</strong>
            </a>
            .
          </InfoMessage> */}
          <Steps
            submitAction={() => formik.submitForm()}
            submitActionTitle="Create account"
            submitActionEnabled={formik.isValid}
          >
            <Step title="Your info">
              <Styled.Form>
                <form onSubmit={formik.handleSubmit}>
                  <FormGroup>
                    <Grid>
                      <GridItem>
                        <TextField
                          label="First Name"
                          name="firstName"
                          value={formik.values.firstName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firstName && formik.errors.firstName
                              ? formik.errors.firstName
                              : ''
                          }
                        />
                      </GridItem>
                      <GridItem>
                        <TextField
                          label="Last Name"
                          name="lastName"
                          value={formik.values.lastName}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.lastName && formik.errors.lastName
                              ? formik.errors.lastName
                              : ''
                          }
                        />
                      </GridItem>
                    </Grid>
                  </FormGroup>
                  {/* <FormGroup>
                    <TextField
                      label="Billing email"
                      name="billingEmail"
                      value={formik.values.billingEmail}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingEmail &&
                        formik.errors.billingEmail
                          ? formik.errors.billingEmail
                          : ''
                      }
                    />
                  </FormGroup> */}
                  {/* {formik.values.billingEmail !== billingEmail && (
                    <FormGroup>
                      <ToggleField
                        inline
                        name="inviteBillingUser"
                        value={formik.values.inviteBillingUser}
                        label="Invite user as a Billing Manager"
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                  )} */}
                  <FormGroup>
                    <TextField
                      label="Company Name"
                      name="companyName"
                      value={formik.values.companyName}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.companyName && formik.errors.companyName
                          ? formik.errors.companyName
                          : ''
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <TextField
                      label="Coupon Code (optional)"
                      name="coupon"
                      value={formik.values.coupon}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.coupon && formik.errors.coupon
                          ? formik.errors.coupon
                          : ''
                      }
                    />
                  </FormGroup>
                </form>
              </Styled.Form>
            </Step>
            {/* <Step title="Payment setup">
              <FormGroup>
                <PlanSelectorList
                  value={selectedPlanId}
                  onChange={setSelectedPlanId}
                />
              </FormGroup>
              <FormGroup>
                <Styled.CardElementWrapper>
                  <Styled.Label>
                    Please provide your credit card info
                  </Styled.Label>
                  <Styled.CardElementInput>
                    <CardElement
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            fontSize: '16px',
                          },
                        },
                      }}
                    />
                  </Styled.CardElementInput>
                  {paymentMethodError && (
                    <FieldErrorMessage>{paymentMethodError}</FieldErrorMessage>
                  )}
                </Styled.CardElementWrapper>
              </FormGroup>
            </Step> */}
          </Steps>
        </Styled.Main>
      </Styled.Wrapper>
    </>
  );
};

export default CompleteRegistrationPage;
