import React from 'react';

import * as Styled from './ConnectorSelector.styles';
import Dropdown from '@components/Dropdown';
import {DropdownOption} from '@components/Dropdown/Dropdown';

type ConnectorSelectorProps = {
  onChange: (value: string) => void;
  options: DropdownOption[];
  value: string;
};

const ConnectorOption = (option: DropdownOption) => {
  const {title, resource} = option;
  return (
    <Styled.ConnectorWrapper>
      {resource.logo ? (
        <Styled.ConnectorLogo src={resource.logo} />
      ) : (
        <i className="icon icon-electrical_services" />
      )}
      <Styled.ConnectorDetails>
        <Styled.ConnectorTitle>{title}</Styled.ConnectorTitle>
        {resource && resource.description && (
          <Styled.ConnectorDescription>
            {resource.description}
          </Styled.ConnectorDescription>
        )}
      </Styled.ConnectorDetails>
    </Styled.ConnectorWrapper>
  );
};

const ConnectorSelector: React.VFC<ConnectorSelectorProps> = ({
  value,
  onChange,
  options,
}) => {
  return (
    <Styled.Wrapper>
      <Dropdown
        value={value}
        label="Choose Connector"
        // selectFirstByDefault
        renderOption={ConnectorOption}
        options={options}
        onChange={onChange}
      />
    </Styled.Wrapper>
  );
};

export default ConnectorSelector;
