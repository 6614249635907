import {ApiClient} from '@services/api-client';
import {AxiosResponse} from 'axios';
import {ConnectorModel} from './connector.model';
import {ConnectorFilterDto} from './dto/connector-filter.dto';
import {CreateConnectorDto} from './dto/create-connector.dto';
import {UpdateConnectorDto} from './dto/update-connector.dto';

export class ConnectorsService extends ApiClient {
  async getAll(
    filterDto: ConnectorFilterDto = {}
  ): Promise<AxiosResponse<ConnectorModel[]>> {
    return this.get('connectors', filterDto);
  }

  async getById(id: string): Promise<AxiosResponse<ConnectorModel>> {
    return this.get(`connectors/${id}`);
  }

  async create(
    dto: CreateConnectorDto
  ): Promise<AxiosResponse<ConnectorModel>> {
    return this.post('connectors', dto);
  }

  async update(
    id: string,
    data: UpdateConnectorDto
  ): Promise<AxiosResponse<ConnectorModel>> {
    return this.put(`connectors/${id}`, data);
  }

  async uploadLogo(file: File): Promise<AxiosResponse<string>> {
    const formData = new FormData();
    formData.append('logo', file);

    return this.post(`connectors/upload-logo`, formData);
  }

  async remove(id: string) {
    return this.delete(`connectors/${id}`);
  }

  async getTags(): Promise<AxiosResponse<string[]>> {
    return this.get(`connectors/tags`);
  }
}
