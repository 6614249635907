import {lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

export const Toggler = styled.button`
  display: inline-block;
  padding: 0;
  margin: 0 0 0 5px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  vertical-align: middle;
  transition: 0.2s;

  .icon {
    font-size: 15px;
    color: ${({theme}) => theme.colors.primary};
    margin-top: -1px;
  }

  &:hover .icon {
    color: ${({theme}) => lighten(0.1, theme.colors.primary)};
  }
`;

export const Message = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 100;
  margin-top: -3px;
  margin-left: 3px;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  padding: 8px 16px;
  background-color: #fff;
  line-height: 1.3;
  border-radius: 5px;
  font-size: 14px;
  text-align: left;
  min-width: 18em;
  color: ${({theme}) => theme.colors.text};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
`;
