import {
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  parseJSON,
  format,
} from 'date-fns';

export const formatDifferenceToNow = (dateString: string) => {
  const date = parseJSON(dateString);
  const now = new Date();
  const minutesFromNow = differenceInMinutes(now, date);
  const hoursFromNow = differenceInHours(now, date);
  const daysFromNow = differenceInDays(now, date);

  if (minutesFromNow < 1) {
    return '< 1 min. ago';
  }

  if (hoursFromNow < 1) {
    return `${minutesFromNow} min. ago`;
  }

  if (daysFromNow < 1) {
    return `${hoursFromNow} hours ago`;
  }

  if (daysFromNow > 7) {
    return format(date, 'MMM d, yyyy');
  }

  return `${daysFromNow} days ago`;
};
