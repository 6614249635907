import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import Modal from '@components/Modal';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {StripeCardElementChangeEvent} from '@stripe/stripe-js';
import React, {useCallback, useState} from 'react';
import * as Styled from './UpdatePaymentMethodModal.styles';

type UpdatePaymentMethodModalProps = {
  onClose: any;
  onSubmit: any;
  isOpen: boolean;
  title?: string;
};

const UpdatePaymentMethodModal: React.VFC<UpdatePaymentMethodModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
  title = 'Update payment method',
}) => {
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const submitPaymentMethod = useCallback(async () => {
    const cardElement = elements?.getElement(CardElement);
    if (stripe && cardElement) {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        setPaymentMethodError(error.message || 'Payment method is invalid.');
        throw error;
      }
      return paymentMethod?.id;
    }
  }, [elements, stripe]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const paymentMethodId = await submitPaymentMethod();
    return onSubmit(paymentMethodId).then(() => {
      setIsSubmitting(false);
      onClose();
    });
  }, [onClose, onSubmit, submitPaymentMethod]);

  const handleChange = useCallback((e: StripeCardElementChangeEvent) => {
    setIsEmpty(e.empty);
  }, []);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title={title} width="30em">
      <FormGroup>
        <Styled.CardElementWrapper>
          <Styled.Label>Please provide your credit card info</Styled.Label>
          <Styled.CardElementInput>
            <CardElement
              onChange={handleChange}
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '16px',
                  },
                },
              }}
            />
          </Styled.CardElementInput>
        </Styled.CardElementWrapper>
      </FormGroup>
      <FormGroup>
        <Button
          size="large"
          onClick={handleSubmit}
          disabled={isEmpty}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </FormGroup>
    </Modal>
  );
};

export default UpdatePaymentMethodModal;
