import {useSelector} from '@app/store';
import {
  getCompanyById,
  fetchCompany,
  archiveCompany,
  forceDeleteCompany,
} from '@features/companies/companies.slice';
import {unwrapResult} from '@reduxjs/toolkit';
import React, {useCallback, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useHistory, useRouteMatch} from 'react-router-dom';
import CompanyDetailsPageDisplay from './CompanyDetailsPage';

const connectRedux = connect(null, {
  fetchCompany,
  archiveCompany,
  forceDeleteCompany,
});

type CompanyDetailsProps = {} & ConnectedProps<typeof connectRedux>;

const CompanyDetailsPage = ({
  fetchCompany,
  archiveCompany,
  forceDeleteCompany,
}: CompanyDetailsProps) => {
  const match = useRouteMatch<{id: string}>();
  const company = useSelector((state) =>
    getCompanyById(state, match.params.id)
  );
  const history = useHistory();

  const isLoading = useSelector((state) => state.companies.isLoading);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleArchive = useCallback(async () => {
    if (company) {
      setIsDeleting(true);
      return archiveCompany(company.id)
        .then(unwrapResult)
        .then(() => {
          setIsDeleting(false);
          history.push('/companies');
        });
    }
  }, [archiveCompany, company, history]);

  const handleDelete = useCallback(async () => {
    if (company) {
      setIsDeleting(true);
      return forceDeleteCompany(company.id)
        .then(unwrapResult)
        .then(() => {
          setIsDeleting(false);
          history.push('/companies');
        });
    }
  }, [company, forceDeleteCompany, history]);

  useEffect(() => {
    fetchCompany(match.params.id);
  }, [fetchCompany, match.params.id]);

  return (
    <CompanyDetailsPageDisplay
      company={company}
      isLoading={isLoading}
      isDeleting={isDeleting}
      onArchive={handleArchive}
      onDelete={handleDelete}
    />
  );
};

export default connectRedux(CompanyDetailsPage);
