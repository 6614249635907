import {useSelector} from '@app/store';
import React, {useEffect} from 'react';
import {fetchPlans} from '@features/plans/plan.slice';
import {connect, ConnectedProps} from 'react-redux';
import PlanListPageDisplay from './PlanListPage';

const connectRedux = connect(null, {fetchPlans});

type PlanListPageProps = {} & ConnectedProps<typeof connectRedux>;

const PlanListPage = ({fetchPlans}: PlanListPageProps) => {
  const plans = useSelector((state) => state.plans.data);
  const isLoading = useSelector((state) => state.plans.isLoading);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  return <PlanListPageDisplay plans={plans} isLoading={isLoading} />;
};

export default connectRedux(PlanListPage);
