import Button from '@components/Button';
import React from 'react';
import {Redirect} from 'react-router-dom';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import UserForm from '../forms/UserForm';
import {UserModel} from '../user.model';

type EditUserPageProps = {
  onSubmit: (values: any) => Promise<any>;
  onDelete: () => Promise<any>;
  isLoading?: boolean;
  isDeleting?: boolean;
  user?: UserModel;
};

const EditUserPage: React.FC<EditUserPageProps> = ({
  onSubmit,
  onDelete,
  isLoading = false,
  isDeleting = false,
  user,
}) => {
  if (!isLoading && !user) {
    return <Redirect to="/users" />;
  }
  return (
    <Page
      title="Edit user"
      actions={[
        <Button
          onClick={onDelete}
          variant="danger"
          icon="delete"
          isLoading={isDeleting}
        >
          Delete
        </Button>,
      ]}
    >
      {isLoading ? (
        <Loader message="Loading user..." />
      ) : (
        <UserForm onSubmit={onSubmit} mode="edit" initialData={user} />
      )}
    </Page>
  );
};

export default EditUserPage;
