import Page from '@components/Page';
import PlanForm from '@features/plans/components/PlanForm';
import {ValidationErrors} from 'types/validation-errors';
import React from 'react';

type CreatePlanPageProps = {
  onSubmit: (values: any) => Promise<any>;
  errors?: ValidationErrors;
};

const CreatePlanPage: React.FC<CreatePlanPageProps> = ({
  onSubmit,
  errors = {},
}) => {
  return (
    <Page title="Create a plan">
      <PlanForm mode="create" onSubmit={onSubmit} errors={errors} />
    </Page>
  );
};

export default CreatePlanPage;
