import styled from 'styled-components';
import {darken, lighten} from 'polished';
import {Label as CheckboxLabel} from '@components/Checkbox/Checkbox.styles';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Options = styled.div`
  .choice-editor-layout-horizontal & {
    display: flex;
    gap: 5px;
  }
`;

export const Option = styled.div`
  .choice-editor-layout-horizontal & {
    margin-top: 0 !important;
    flex-grow: 1;
  }
  & + & {
    margin-top: 5px;
  }

  &.choice-option-active ${CheckboxLabel} {
    border-color: ${({theme}) => lighten(0.2, theme.colors.primary)} !important;
    background-color: ${({theme}) => theme.colors.lightPrimary};
  }

  ${CheckboxLabel} {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 16px 10px;
    background-color: #fff;
    border: 2px solid ${({theme}) => theme.colors.borderColor};
    border-radius: 3px;
    transition: 0.2s;

    &:hover {
      border-color: ${({theme}) => darken(0.15, theme.colors.borderColor)};
      /* background-color: ${({theme}) => theme.colors.lightPrimary}; */
    }
  }
`;
