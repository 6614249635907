import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {ErrorResponse} from 'types/error-response.interface';
import {ValidationErrors} from 'types/validation-errors';
import {CreateInvitationDto} from './dto/create-invitation.dto';
import {InvitationModel} from './invitation.model';
import {InvitationsService} from './invitations.service';

type InvitationsState = {
  data: InvitationModel[];
  isLoading: boolean;
  errors: ValidationErrors;
  message: string;
};

const initialState: InvitationsState = {
  data: [],
  isLoading: false,
  errors: {},
  message: '',
};

export const fetchInvitations = createAsyncThunk(
  'get-invitations',
  async (payload, api) => {
    try {
      const invitationsService = new InvitationsService();
      const response = await invitationsService.getAll();
      return response.data;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const fetchInvitationById = createAsyncThunk<InvitationModel, string>(
  'get-invitation-by-id',
  async (payload, api) => {
    try {
      const invitationsService = new InvitationsService();
      const response = await invitationsService.getById(payload);
      return response.data;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const createInvitation = createAsyncThunk<
  InvitationModel,
  CreateInvitationDto
>('create-invitation', async (payload, api) => {
  try {
    const invitationsService = new InvitationsService();
    const response = await invitationsService.create(payload);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {},
  extraReducers: {
    // List invitations
    [fetchInvitations.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchInvitations.rejected.toString()]: (
      state,
      action: PayloadAction<ErrorResponse>
    ) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [fetchInvitations.fulfilled.toString()]: (
      state,
      action: PayloadAction<InvitationModel[]>
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Get invitation by ID
    [fetchInvitationById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchInvitationById.rejected.toString()]: (
      state,
      action: PayloadAction<ErrorResponse>
    ) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [fetchInvitationById.fulfilled.toString()]: (
      state,
      action: PayloadAction<InvitationModel>
    ) => {
      state.isLoading = false;
      state.data = state.data
        .filter((invitation) => invitation.id !== action.payload.id)
        .concat(action.payload);
    },

    // Create invitation
    [createInvitation.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [createInvitation.rejected.toString()]: (
      state,
      action: PayloadAction<ErrorResponse>
    ) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    [createInvitation.fulfilled.toString()]: (
      state,
      action: PayloadAction<InvitationModel>
    ) => {
      state.isLoading = false;
      state.data.push(action.payload);
    },
  },
});

export default invitationsSlice.reducer;
