import {DropdownOption} from '@components/Dropdown/Dropdown';
import {CompanyModel} from '@features/companies/company.model';
import React, {useCallback, useMemo, useState} from 'react';
import omit from 'lodash/omit';
import * as Styled from './UserCompaniesDropdown.styles';

type UserCompaniesDropdownProps = {
  companies: Partial<CompanyModel>[];
  selectedCompany?: string;
  onSelectedChange: (value: string) => any;
};

const flattenCompanyChildren = (
  company: Partial<CompanyModel>
): Partial<CompanyModel>[] => {
  const companyFlattened = omit(company, 'children');
  return [{...companyFlattened}].concat(company.children || []);
};

const UserCompaniesDropdown: React.VFC<UserCompaniesDropdownProps> = ({
  companies,
  selectedCompany = '',
  onSelectedChange,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const isDisabled = useMemo(() => {
    if (
      companies.length === 1 &&
      companies[0].children &&
      companies[0].children.length
    ) {
      return false;
    }
    return companies.length <= 1;
  }, [companies]);

  const companiesMapped = useMemo(() => {
    return companies
      .reduce((prev, company) => {
        prev = prev.concat(company.children || []);
        return prev;
      }, companies.slice())
      .reduce((prev, company) => {
        if (typeof company.id === 'string') {
          prev[company.id] = company;
        }
        return prev;
      }, {} as {[key: string]: Partial<CompanyModel>});
  }, [companies]);

  const selectedCompanyModel = useMemo(() => {
    return companiesMapped[selectedCompany];
  }, [companiesMapped, selectedCompany]);

  const companyOptions = useMemo<DropdownOption[]>(() => {
    const companiesToMap =
      companies.length === 1 ? flattenCompanyChildren(companies[0]) : companies;
    return companiesToMap
      .filter((company) => !!company.id && !!company.name)
      .map((company) => ({
        title: company.name || '',
        value: company.id || '',
        children: company.children?.map((item) => ({
          title: item.name || '',
          value: item.id || '',
        })),
      }));
  }, [companies]);

  const handleToggleDropdown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setIsDropdownShown((prev) => !prev);
    },
    []
  );

  const handleSelectedChange = useCallback(
    (companyId) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      onSelectedChange(companyId);
      setIsDropdownShown(false);
    },
    [onSelectedChange]
  );

  return (
    <Styled.Wrapper
      className={[isDisabled ? 'companies-dropdown-disabled' : ''].join(' ')}
    >
      {isDropdownShown && <Styled.Overlay onClick={handleToggleDropdown} />}
      <Styled.Header onClick={handleToggleDropdown}>
        <Styled.HeaderInner>
          <Styled.Label>Company</Styled.Label>
          <Styled.Selected>
            {selectedCompanyModel
              ? selectedCompanyModel.name
              : 'Click to select'}
          </Styled.Selected>
        </Styled.HeaderInner>
        <Styled.ToggleButton />
      </Styled.Header>
      {isDropdownShown && (
        <Styled.List>
          {companyOptions.map((option, key) => (
            <Styled.ListItem
              key={key}
              className={[
                option.children && option.children.length ? 'has-children' : '',
                option.value === selectedCompany ? 'active' : '',
              ].join(' ')}
              onClick={handleSelectedChange(option.value)}
            >
              {option.title}
              {option.children && option.children.length ? (
                <Styled.ChildrenList>
                  {option.children.map((option, key) => (
                    <Styled.ListItem
                      key={key}
                      onClick={handleSelectedChange(option.value)}
                    >
                      {option.title}
                    </Styled.ListItem>
                  ))}
                </Styled.ChildrenList>
              ) : null}
            </Styled.ListItem>
          ))}
        </Styled.List>
      )}
    </Styled.Wrapper>
  );
};

export default UserCompaniesDropdown;
