import {useSelector} from '@app/store';
import {getCurrentCompany} from '@features/auth/auth.slice';

const useSelectedCompany = () => {
  const currentCompany = useSelector(getCurrentCompany);

  return currentCompany;
};

export default useSelectedCompany;
