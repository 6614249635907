import FieldErrorMessage from '@components/FieldErrorMessage';
import FieldHelpMessage from '@components/FieldHelpMessage';
import Loader from '@components/Loader';
import React, {useCallback, useRef, useState} from 'react';
import * as Styled from './ImageUploadField.styles';

type ImageUploadFieldProps = {
  name: string;
  label?: string;
  description?: string;
  error?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  value?: string;
  onChange: (e: {target: {name: string; value: string}}) => void;
  onUpload: (file: File) => Promise<string>;
};

const ImageUploadField: React.VFC<ImageUploadFieldProps> = ({
  name,
  onChange,
  onUpload,
  value = '',
  description,
  error,
  label,
  tooltip,
  required = false,
}) => {
  const fieldRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleUpload = useCallback(
    async ({target: {files}}) => {
      if (!files || !files.length) {
        return;
      }
      setIsUploading(true);
      const imageUrl = await onUpload(files[0]);
      onChange({target: {name, value: imageUrl}});
      setIsUploading(false);
    },
    [name, onChange, onUpload]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.FieldWrapper>
        {isUploading && (
          <Styled.Loader>
            <Loader size={20} message="Loading..." />
          </Styled.Loader>
        )}
        {value && !isUploading && <Styled.Preview src={value} />}
        {!value && !isUploading && (
          <Styled.EmptyMessage>
            <i className="icon icon-file_upload" />
            Click to upload
          </Styled.EmptyMessage>
        )}
        <input
          type="file"
          ref={fieldRef}
          onChange={handleUpload}
          disabled={isUploading}
        />
      </Styled.FieldWrapper>
      <FieldErrorMessage error={error} />
      {description && !error && (
        <Styled.Description>{description}</Styled.Description>
      )}
    </Styled.Wrapper>
  );
};

export default ImageUploadField;
