import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Inner = styled.div`
  display: flex;
  margin: 0 -10px;
`;

export const ChartWrapper = styled.div`
  width: 50%;
  /* height: 500px; */
  /* border-radius: 8px; */
  padding: 0 10px;
  /* background-color: #fff;
  & + & {
    margin-left: 20px;
  } */
`;

export const ChartTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 20px;
`;

export const ActionBar = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
