import TextField from '@components/TextField';
import React, {useCallback, useMemo} from 'react';
import * as Styled from './ObjectEditor.styles';

export type ObjectEditorProps = {
  value?: Record<string, any>;
  required?: boolean;
  name?: string;
  id?: string;
  label?: string;
  keyLabel?: string;
  valueLabel?: string;
  onChange: (e: {target: {value: any; name?: string}}) => void;
};

const ObjectEditor: React.VFC<ObjectEditorProps> = ({
  value = {},
  name = '',
  onChange,
  label,
  keyLabel,
  valueLabel,
  required = false,
  ...props
}) => {
  const valueTransformed = useMemo(() => {
    const valueKeys = Object.keys(value);
    if (!valueKeys.length) {
      return [{key: '', value: ''}];
    }
    return valueKeys.map((key) => ({
      key,
      value: value[key],
    }));
  }, [value]);

  const transformRecordListToMap = (
    records: {key: string; value: string}[]
  ) => {
    return records.reduce((prev, current) => {
      prev[current.key] = current.value;
      return prev;
    }, {} as {[key: string]: any});
  };

  const handleKeyChange = useCallback(
    (index: number) => (e: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {key: e.target.value, value: item.value};
        }
        return item;
      }, {} as {[key: string]: any});
      if (index === valueTransformed.length - 1) {
        valueMapped = [...valueMapped, {key: '', value: ''}];
      }
      if (!e.target.value && index < valueTransformed.length - 1) {
        valueMapped = valueMapped.filter((item, _index) => _index !== index);
      }
      onChange({target: {value: transformRecordListToMap(valueMapped), name}});
    },
    [name, onChange, valueTransformed]
  );

  const handleValueChange = useCallback(
    (index: number) => ({target}: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {key: item.key, value: target.value};
        }
        return item;
      }, {} as {[key: string]: any});
      if (index === valueTransformed.length - 1) {
        valueMapped = [...valueMapped, {key: '', value: ''}];
      }
      onChange({target: {value: transformRecordListToMap(valueMapped), name}});
    },
    [name, onChange, valueTransformed]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || name}>
          {label}
          {required && <span className="required">*</span>}
        </Styled.Label>
      )}
      <Styled.ValuesTable>
        <Styled.ValuesHeader>
          <th>{keyLabel || 'Key'}</th>
          <th>{valueLabel || 'Value'}</th>
        </Styled.ValuesHeader>
        <tbody>
          {valueTransformed.map((valueObject, index) => (
            <Styled.ValuesRow key={index}>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.key}
                  onChange={handleKeyChange(index)}
                  placeholder="Enter the key"
                />
              </Styled.ValuesCell>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.value}
                  onChange={handleValueChange(index)}
                  placeholder="Enter the value or leave it blank"
                />
              </Styled.ValuesCell>
            </Styled.ValuesRow>
          ))}
        </tbody>
      </Styled.ValuesTable>
    </Styled.Wrapper>
  );
};

export default ObjectEditor;
