import Button from '@components/Button';
import {ConnectorModel} from '@features/connectors/connector.model';
import React from 'react';
import ConnectorCard from '../ConnectorCard';
import * as Styled from './ConnectorList.styles';

type ConnectorListProps = {
  connectors: ConnectorModel[];
  onEnabledToggle: (connector: ConnectorModel) => Promise<any>;
  onCreate: () => void;
  onEdit: (connector: ConnectorModel) => void;
};

const ConnectorList: React.VFC<ConnectorListProps> = ({
  connectors,
  onEnabledToggle,
  onCreate,
  onEdit,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {connectors.length > 0 ? (
          connectors.map((connector, index) => (
            <Styled.ListItem key={index}>
              <ConnectorCard
                connector={connector}
                onEnabledToggle={onEnabledToggle}
                onEdit={onEdit}
              />
            </Styled.ListItem>
          ))
        ) : (
          <Styled.EmptyList>
            <h3>Nothing to show here yet.</h3>
            Go ahead and create a new connector!
            <p>
              <Button onClick={onCreate}>Create a connector</Button>
            </p>
          </Styled.EmptyList>
        )}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default ConnectorList;
