import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {createConnector} from '@features/connectors/connectors.slice';
import {CreateConnectorDto} from '@features/connectors/dto/create-connector.dto';
import {useToasts} from 'react-toast-notifications';
import CreateCompanyConnectorDisplay from './CreateCompanyConnector';
import useRepoDetails from '@features/connectors/hooks/useRepoDetails';
import useConnectorTags from '@features/connectors/hooks/useConnectorTags';

const connectRedux = connect(null, {createConnector});

type CreateCompanyConnectorProps = {
  onGoBack: () => void;
} & ConnectedProps<typeof connectRedux>;

const CreateCompanyConnector: React.VFC<CreateCompanyConnectorProps> = ({
  createConnector,
  onGoBack,
}) => {
  const [repoUrl, setRepoUrl] = useState('');

  const {addToast} = useToasts();

  const {tags} = useConnectorTags();
  const {isLoadingRepoDetails, repoDetails} = useRepoDetails(repoUrl);

  const handleSubmit = useCallback(
    (values: CreateConnectorDto) => {
      return createConnector(values).then(() => {
        onGoBack();
        addToast('Connector created successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      });
    },
    [addToast, createConnector, onGoBack]
  );

  return (
    <CreateCompanyConnectorDisplay
      onSubmit={handleSubmit}
      onGoBack={onGoBack}
      onRepoUrlChange={setRepoUrl}
      repoDetails={repoDetails}
      tags={tags}
      isLoadingRepoDetails={isLoadingRepoDetails}
    />
  );
};

export default connectRedux(CreateCompanyConnector);
