import React from 'react';
import theme from '../../../../shared/theme';
import {BuildStatusEnum} from '../../build-status.enum';
import * as Styled from './BuildStatus.styles';

type BuildStatusProps = {
  status: BuildStatusEnum;
};

const statusMap: {
  [key in BuildStatusEnum]: {title: string; color: string; icon: string};
} = {
  Succeeded: {
    title: 'Succeeded',
    color: theme.colors.green,
    icon: 'check_circle',
  },
  Failed: {
    title: 'Failed',
    color: theme.colors.danger,
    icon: 'error',
  },
  Stopping: {
    title: 'Stopping',
    color: theme.colors.mediumGray,
    icon: 'pending',
  },
  Stopped: {
    title: 'Stopped',
    color: theme.colors.mediumGray,
    icon: 'error',
  },
  Cancelled: {
    title: 'Cancelled',
    color: theme.colors.mediumGray,
    icon: 'error',
  },
  InProgress: {
    title: 'Pending',
    color: theme.colors.primary,
    icon: 'pending',
  },
};

const BuildStatus: React.FC<BuildStatusProps> = ({status}) => {
  return (
    <Styled.Wrapper style={{color: statusMap[status].color}}>
      <i className={`icon icon-${statusMap[status].icon}`} />
      {statusMap[status].title}
    </Styled.Wrapper>
  );
};

export default BuildStatus;
