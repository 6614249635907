import React, {useCallback, useState} from 'react';
import {AuthUser} from '../../../features/auth/auth-user.model';
import * as Styled from './HeaderUser.styles';

type HeaderUserProps = {
  user: Partial<AuthUser>;
  onLogout: any;
};

const HeaderUser: React.FC<HeaderUserProps> = ({user, onLogout}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpanded = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setIsExpanded((prev) => !prev);
    },
    []
  );

  const handleLogout = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      e.preventDefault();

      onLogout();
    },
    [onLogout]
  );

  return (
    <>
      {isExpanded && <Styled.Overlay onClick={handleToggleExpanded} />}
      <Styled.Wrapper
        className={[isExpanded ? 'header-user-expanded' : ''].join(' ')}
        onClick={handleToggleExpanded}
      >
        <Styled.Avatar style={{backgroundImage: `url(${user.avatar})`}} />
        <Styled.Username>{user.name}</Styled.Username>
        <Styled.DropdownArrow />
        {isExpanded && (
          <Styled.Dropdown>
            <Styled.DropdownList>
              <Styled.DropdownItem>
                <Styled.DropdownLink to="/profile">Profile</Styled.DropdownLink>
              </Styled.DropdownItem>
              <Styled.DropdownItem>
                <Styled.DropdownLink to="/" onClick={handleLogout}>
                  Logout
                </Styled.DropdownLink>
              </Styled.DropdownItem>
            </Styled.DropdownList>
          </Styled.Dropdown>
        )}
      </Styled.Wrapper>
    </>
  );
};

HeaderUser.propTypes = {};

export default HeaderUser;
