import {MetricsDataModel} from '@features/workspaces/metrics-data.model';
import {MetricsTimeFrame} from '@features/workspaces/metrics-time-frame.enum';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useState} from 'react';
import {useEffect} from 'react';
import WorkspaceMonitoringDisplay from './WorkspaceMonitoring';

type WorkspaceMonitoringProps = {
  workspaceId: string;
};

const WorkspaceMonitoring: React.VFC<WorkspaceMonitoringProps> = ({
  workspaceId,
}) => {
  const [metrics, setMetrics] = useState<MetricsDataModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [timeFrame, setTimeFrame] = useState(MetricsTimeFrame.HOUR);

  useEffect(() => {
    const workspaceService = new WorkspaceService();

    setIsLoading(true);
    workspaceService.getMetrics(workspaceId, timeFrame).then((response) => {
      setMetrics(response.data);
      setIsLoading(false);
    });
  }, [timeFrame, workspaceId]);

  return (
    <WorkspaceMonitoringDisplay
      isLoading={isLoading}
      data={metrics}
      onChangeTimeFrame={setTimeFrame}
      timeFrame={timeFrame}
    />
  );
};

export default WorkspaceMonitoring;
