import {lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const StreamList = styled.div``;

export const StreamListItem = styled.div`
  & + & {
    margin-top: -1px;
  }
`;

export const Stream = styled.div`
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  background-color: #fff;
`;

export const StreamInner = styled.div`
  display: grid;
  grid-template-columns: 55px 20px 3fr 0.7fr 1fr;
  align-items: center;
  gap: 10px;
`;

export const StreamToggle = styled.button`
  position: relative;
  padding: 12px 18px;
  outline: none;
  border: none;
  margin: 0 5px 0 0;
  cursor: pointer;
  background-color: transparent;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
  }

  &.active:before {
    transform: rotate(90deg);
  }

  &:before {
    display: inline-block;
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 0 .59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6Z' fill='%23000'/%3E%3C/svg%3E");
    transform: rotate(0);
    transition: 0.2s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 50%;
    transform: translateY(-50%);
    background-color: ${({theme}) => theme.colors.borderColor};
  }
`;

export const StreamTitle = styled.h4`
  margin: 0;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 1rem;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
    /* color: ${({theme}) => theme.colors.primary}; */
  }
`;

export const ReplicationType = styled.div`
  margin-left: auto;
  padding: 0 16px;
`;

export const ReplicationKey = styled.div``;

export const Checkbox = styled.div`
  display: inline-block;
`;

export const PropertyList = styled.div`
  border: 2px solid ${({theme}) => theme.colors.borderColor};
  border-width: 1px 0 0;
  padding: 10px 60px 10px 66px;
`;

export const PropertyListItem = styled.div`
  position: relative;
  padding: 8px 0;
  & + & {
    border-top: 1px solid
      ${({theme}) => lighten(0.04, theme.colors.borderColor)};
  }
  &:before {
    content: '';
    position: absolute;
    left: -1.1em;
    top: 0;
    width: 0.7em;
    height: 1em;
    border: 1px solid ${({theme}) => lighten(0.01, theme.colors.borderColor)};
    border-width: 0 0 2px 2px;
  }
  &:after {
    content: '';
    position: absolute;
    left: -1.1em;
    bottom: -2px;
    width: 0.7em;
    height: 1.4em;
    border: 1px solid ${({theme}) => lighten(0.01, theme.colors.borderColor)};
    border-width: 0 0 0 2px;
  }
  &:last-child:after {
    display: none;
  }
`;

export const Property = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PropertyTitle = styled.h5`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 55px 3fr 20px 0.7fr 1fr;
  align-items: center;
  padding: 10px 16px 10px 10px;
  background-color: ${({theme}) => theme.colors.borderColor};
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  /* font-size: 14px; */
  font-weight: 600;
  /* text-transform: uppercase;
  letter-spacing: 0.02em; */
  color: ${({theme}) => theme.colors.mediumGray};

  &:last-child {
    padding: 0 18px;
  }
`;
