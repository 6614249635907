import React, {ReactNode, useCallback, useState} from 'react';
import * as Styled from './ErrorMessage.styles';

type ErrorMessageProps = {
  message: ReactNode;
  details?: string;
};

const ErrorMessage: React.VFC<ErrorMessageProps> = ({message, details}) => {
  const [isDetailsExpanded, setIsDetailsExpanded] = useState(false);
  const handleDisableClickBubble = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Message>
        <i className="icon icon-error_outline" />
        {message}
      </Styled.Message>
      {details && (
        <Styled.Details
          onClick={() => setIsDetailsExpanded((prev) => !prev)}
          className={[isDetailsExpanded ? 'expanded' : ''].join(' ')}
        >
          <div className="title">
            <i className="icon icon-arrow_drop_down" /> View the details
          </div>
          {isDetailsExpanded && (
            <div
              className="content"
              onClick={handleDisableClickBubble}
              dangerouslySetInnerHTML={{
                __html: details.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </Styled.Details>
      )}
    </Styled.Wrapper>
  );
};

export default ErrorMessage;
