import ArrayEditor from '@components/ArrayEditor';
import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import RadioGroupField from '@components/RadioGroupField';
import TextField from '@components/TextField';
import {CreateManagedCompanyDto} from '@features/companies/dto/create-managed-company.dto';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import * as Styled from './CreateManagedCompanyScreen.styles';

type CreateManagedCompanyScreenProps = {
  onSubmit: (values: CreateManagedCompanyDto) => Promise<any>;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter company name'),
  billingManagedBy: Yup.string().required().oneOf(['parent', 'child']),
  adminUserEmail: Yup.string()
    .email()
    .when('billingManagedBy', {
      is: 'child',
      then: Yup.string().required(
        'Please enter email for the user who will manage company including billing'
      ),
    }),
});

const CreateManagedCompanyScreen: React.VFC<
  CreateManagedCompanyScreenProps
> = ({onSubmit}) => {
  const selectedCompany = useSelectedCompany();

  const formik = useFormik<CreateManagedCompanyDto>({
    initialValues: {
      name: '',
      billingManagedBy: 'parent',
      adminUserEmail: '',
      inviteEmails: [],
    },
    onSubmit,
  });
  return (
    <Styled.Wrapper>
      <FormGroup>
        <Grid>
          <GridItem>
            <FormGroup>
              <TextField
                label="Managed Company Name"
                name="name"
                value={formik.values.name}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ''
                }
              />
            </FormGroup>
            <FormGroup>
              <RadioGroupField
                name="billingManagedBy"
                value={formik.values.billingManagedBy}
                onChange={formik.handleChange}
                label="Billing managed by"
                options={[
                  {
                    label: 'Parent company',
                    value: 'parent',
                  },
                  {label: 'Managed company', value: 'child'},
                ]}
              />
            </FormGroup>
            {formik.values.billingManagedBy === 'child' && (
              <FormGroup>
                <TextField
                  label="Admin user email"
                  name="adminUserEmail"
                  value={formik.values.adminUserEmail}
                  description={`You'll need to provide email for the user who will manage company since billing management option is set to "Managed company"`}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.adminUserEmail &&
                    formik.errors.adminUserEmail
                      ? formik.errors.adminUserEmail
                      : ''
                  }
                />
              </FormGroup>
            )}
            <FormGroup>
              <ArrayEditor
                name="inviteEmails"
                label="Invite admin users to new managed company "
                value={formik.values.inviteEmails || []}
                onChange={formik.handleChange}
              />
            </FormGroup>
          </GridItem>
          <GridItem></GridItem>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Button
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          isLoading={formik.isSubmitting}
          size="large"
          onClick={formik.submitForm}
        >
          Create Project
        </Button>
      </FormGroup>
    </Styled.Wrapper>
  );
};

export default CreateManagedCompanyScreen;
