import FieldErrorMessage from '@components/FieldErrorMessage';
import FieldHelpMessage from '@components/FieldHelpMessage';
import Loader from '@components/Loader';
import React, {useCallback, useState} from 'react';
import * as Styled from './FileUploadField.styles';

export type FileUploadFieldProps = {
  value: string;
  onChange: (e: {target: {name: string; value: string}}) => void;
  name: string;
  label?: string;
  description?: string;
  error?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  /**
   * File upload callback - should return target file path
   */
  onUpload: (file: File) => Promise<string>;
};

/**
 * Upload file to backend and return target file path as a value
 */
const FileUploadField: React.VFC<FileUploadFieldProps> = ({
  name,
  onChange,
  value,
  description,
  error,
  label,
  tooltip,
  required,
  onUpload,
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length) {
        setIsUploading(true);
        const result = await onUpload(e.target.files[0]);
        onChange({target: {name, value: result}});
        setIsUploading(false);
      }
    },
    [name, onChange, onUpload]
  );

  console.log(value);

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.FieldWrapper>
        <Styled.UploadAction title="Click to upload">
          <input type="file" onChange={handleChange} disabled={isUploading} />
          {isUploading ? (
            <Loader size={18} />
          ) : (
            <i className="icon icon-file_upload" />
          )}
        </Styled.UploadAction>
        <Styled.FieldValue>
          {isUploading
            ? 'Uploading file...'
            : value || 'Click button to upload file'}
        </Styled.FieldValue>
      </Styled.FieldWrapper>
      <FieldErrorMessage error={error} />
      {description && !error && (
        <Styled.Description>{description}</Styled.Description>
      )}
    </Styled.Wrapper>
  );
};

export default FileUploadField;
