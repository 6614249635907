import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import FormGroup from '@components/FormGroup';
import Page from '@components/Page';
import TextField from '@components/TextField';
import {CouponDuration} from '@features/billing/coupon-duration.enum';
import {CreateCouponDto} from '@features/billing/dto/create-coupon.dto';
import {ValidationErrors} from 'types/validation-errors';
import {titleCase} from '@utils/string.utils';
import {useFormik} from 'formik';
import React from 'react';
import * as Yup from 'yup';
import * as Styled from './CreateCouponPage.styles';

type CreateCouponPageProps = {
  onSubmit: (data: CreateCouponDto) => Promise<any>;
  errors?: ValidationErrors;
};

const validationSchema = Yup.object().shape({
  id: Yup.string().required('Please enter coupon code'),
  max_redemptions: Yup.number().min(0, 'Value must be positive').notRequired(),
  percent_off: Yup.number().min(0, 'Value must be positive'),
  duration: Yup.string().required('Please choose duration period'),
});

const CreateCouponPage: React.FC<CreateCouponPageProps> = ({
  onSubmit,
  errors = {},
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: '',
      max_redemptions: undefined,
      percent_off: 0,
      duration: CouponDuration.ONCE,
    },
    validationSchema,
    onSubmit,
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
  });
  return (
    <Page title="Create coupon code">
      <Styled.Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <FormGroup>
            <TextField
              label="Coupon code"
              name="id"
              placeholder="e.g. 50%_OFF"
              value={formik.values.id}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.id && formik.errors.id ? formik.errors.id : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Max. redemptions"
              placeholder="Unlimited by default"
              name="max_redemptions"
              type="number"
              value={formik.values.max_redemptions}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.max_redemptions && formik.errors.max_redemptions
                  ? formik.errors.max_redemptions
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <TextField
              label="Percent off"
              name="percent_off"
              type="number"
              value={formik.values.percent_off}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.percent_off && formik.errors.percent_off
                  ? formik.errors.percent_off
                  : ''
              }
            />
          </FormGroup>
          <FormGroup>
            <Dropdown
              label="Duration period"
              options={[
                {
                  title: titleCase(CouponDuration.FOREVER),
                  value: CouponDuration.FOREVER,
                },
                {
                  title: titleCase(CouponDuration.ONCE),
                  value: CouponDuration.ONCE,
                },
                {
                  title: titleCase(CouponDuration.REPEATING),
                  value: CouponDuration.REPEATING,
                },
              ]}
              value={formik.values.duration}
              onChange={(value) =>
                formik.handleChange({target: {name: 'duration', value}})
              }
            />
          </FormGroup>
          <FormGroup>
            <Button
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              isLoading={formik.isSubmitting}
              size="large"
              onClick={formik.submitForm}
            >
              Submit
            </Button>
          </FormGroup>
        </form>
      </Styled.Wrapper>{' '}
    </Page>
  );
};

export default CreateCouponPage;
