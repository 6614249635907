import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Message = styled.div`
  line-height: 1.4;
`;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
`;
