import styled from 'styled-components';

interface WrapperProps {
  gutterWidth?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: stretch;
  gap: ${({gutterWidth = 20}) => gutterWidth}px;
  width: 100%;
`;

interface GridItemWrapperProps {
  cols: number;
  gutterWidth?: number;
}

export const GridItemWrapper = styled.div<GridItemWrapperProps>`
  flex: ${(props) => props.cols};
`;
