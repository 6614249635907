import React from 'react';
import * as Styled from './Breadcrumbs.styles';

export type Breadcrumb = {
  title: string;
  link: string;
};

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({breadcrumbs}) => {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {breadcrumbs.map((breadcrumb, key) => {
          if (key < breadcrumbs.length - 1) {
            return (
              <Styled.ListItem key={key}>
                <Styled.BreadcrumbLink to={breadcrumb.link}>
                  {breadcrumb.title}
                </Styled.BreadcrumbLink>
              </Styled.ListItem>
            );
          } else {
            return (
              <Styled.ListItem key={key}>{breadcrumb.title}</Styled.ListItem>
            );
          }
        })}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default Breadcrumbs;
