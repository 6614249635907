import React, {useCallback, useState} from 'react';
import * as ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/eclipse';
import {JsonEditor, JsonEditorProps, Mode} from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import * as Styled from './JSONEditor.styles';
import FieldHelpMessage from '@components/FieldHelpMessage';

type JSONEditorProps = {
  name: string;
  label?: string;
  description?: string;
  tooltip?: React.ReactNode;
  error?: string;
  required?: boolean;
  onChange: (e: {target: {name: string; value: any}}) => void;
} & Omit<JsonEditorProps, 'onChange'>;

const JSONEditor: React.VFC<JSONEditorProps> = ({
  name,
  error = '',
  label = '',
  onChange,
  tooltip = '',
  description = '',
  value = {},
  required = false,
  ...props
}) => {
  const [editorMode, setEditorMode] = useState<Mode>('code');

  const handleChange = useCallback(
    (value) => {
      onChange({target: {name, value}});
    },
    [name, onChange]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.EditorWrapper>
        {/* <Styled.Toolbar>
          <RadioGroupField
            name="editorMode"
            options={[
              {label: 'Code', value: 'code' as Mode},
              {label: 'Tree', value: 'tree' as Mode},
            ]}
            value={editorMode}
            onChange={({target}) => setEditorMode(target.value)}
          />
        </Styled.Toolbar> */}
        <JsonEditor
          {...props}
          schema={{}}
          ace={ace}
          theme="ace/theme/eclipse"
          onChange={handleChange}
          mode={editorMode as Mode}
          navigationBar={false}
          onModeChange={setEditorMode}
          search={false}
          value={value}
        />
      </Styled.EditorWrapper>
    </Styled.Wrapper>
  );
};

export default JSONEditor;
