import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ConnectorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-grow: 0;
    padding: 10px;
    font-size: 28px;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const ConnectorDetails = styled.div``;

export const ConnectorLogo = styled.img`
  padding: 3px;
  /* border-radius: 50%; */
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

export const ConnectorTitle = styled.h4`
  margin: 0;
  font-size: 16px;
`;

export const ConnectorDescription = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.mediumGray};
  max-width: 20em;
  margin-top: 5px;
`;
