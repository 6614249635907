import {RootState} from '@app/store';
import {AuthUser} from '@features/auth/auth-user.model';
import React, {useEffect} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {getProfile} from '@features/auth/auth.slice';
import GitCloneUrlDisplay from './GitCloneUrl';

const connectRedux = connect(null, {getProfile});

type GitCloneUrlProps = {
  workspaceId?: string;
  url: string;
} & ConnectedProps<typeof connectRedux>;

const GitCloneUrl = ({workspaceId, url, getProfile}: GitCloneUrlProps) => {
  useEffect(() => {
    // getProfile();
  }, [getProfile]);

  const user = useSelector<RootState, Partial<AuthUser>>(
    (state) => state.auth.user
  );

  return <GitCloneUrlDisplay workspaceId={workspaceId} user={user} url={url} />;
};

export default connectRedux(GitCloneUrl);
