import React, {useMemo} from 'react';
import {ConnectorConfigType} from '@features/connectors/connector-config-type.enum';
import {TextFieldProps} from '@components/TextField/TextField';
import configValueEditorMap from './config-value-editor.map';

type ConfigValueEditorByTypeProps = {
  kind: ConnectorConfigType;
} & TextFieldProps;

const ConfigValueEditorByType: React.VFC<ConfigValueEditorByTypeProps> = ({
  kind,
  ...props
}) => {
  const Component = useMemo(() => {
    return configValueEditorMap[kind];
  }, [kind]);

  if (Component) {
    return <Component {...props} />;
  }
  return null;
};

export default ConfigValueEditorByType;
