import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import Page from '@components/Page';
import CreateWorkspaceForm from '@features/workspaces/components/CreateWorkspaceForm';
import {createWorkspace} from '@features/workspaces/workspace.slice';
import {getProfile} from '@features/auth/auth.slice';
import {unwrapResult} from '@reduxjs/toolkit';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {useSelector} from '@app/store';

const connectRedux = connect(null, {createWorkspace, getProfile});

type CreateWorkspacePageProps = {
  createWorkspace: any;
} & ConnectedProps<typeof connectRedux>;

const CreateWorkspacePage: React.FC<CreateWorkspacePageProps> = ({
  createWorkspace,
  getProfile,
}) => {
  const errors = useSelector((state) => state.workspaces.errors);
  const history = useHistory();
  const {addToast} = useToasts();
  const handleSubmit = useCallback(
    (values) => {
      return createWorkspace(values)
        .then(unwrapResult)
        .then(getProfile)
        .then(() => {
          history.push('/');
          addToast('Project is being created', {
            autoDismiss: true,
            appearance: 'success',
          });
        });
    },
    [addToast, createWorkspace, getProfile, history]
  );

  return (
    <Page title="Create a Meltano Project">
      <CreateWorkspaceForm errors={errors} onSubmit={handleSubmit} />
    </Page>
  );
};

export default connectRedux(CreateWorkspacePage);
