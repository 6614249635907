import FieldHelpMessage from '@components/FieldHelpMessage';
import React, {useCallback} from 'react';
import * as Styled from './RadioGroupField.styles';

export type RadioGroupOption<T> = {
  label: string;
  value: T;
};

type RadioGroupFieldProps<T> = {
  options: RadioGroupOption<T>[];
  name: string;
  id?: string;
  label?: string;
  required?: boolean;
  tooltip?: string;
  variant?: 'compact' | 'default';
  appearance?: 'light' | 'default';
  value: T;
  onChange: (e: {target: {name: string; value: T}}) => void;
};

function RadioGroupField<T>({
  label,
  name,
  id,
  value,
  required,
  onChange,
  options,
  tooltip = '',
  variant = 'default',
  appearance = 'default',
}: RadioGroupFieldProps<T>) {
  const handleClick = useCallback(
    (option: RadioGroupOption<T>) =>
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();

        onChange({target: {name: name, value: option.value}});
      },
    [name, onChange]
  );

  return (
    <Styled.Wrapper
      className={[
        `radio-group-variant-${variant}`,
        `radio-group-appearance-${appearance}`,
      ].join(' ')}
    >
      {label && (
        <Styled.Label htmlFor={id || name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.Options>
        {options.map((option, key) => (
          <Styled.Option
            key={key}
            className={option.value === value ? 'active' : ''}
            onClick={handleClick(option)}
          >
            {option.label}
          </Styled.Option>
        ))}
      </Styled.Options>
    </Styled.Wrapper>
  );
}

export default RadioGroupField;
