import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Section = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 10px;
  }
`;

export const Title = styled.h3`
  font-size: 18px;
  margin: 0 0 20px;
`;
