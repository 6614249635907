import Table from '@components/Table';
import {UserModel} from '@features/users/user.model';
import React from 'react';
import * as Styled from './CompanyUsers.styles';

type CompanyUsersProps = {
  isLoading?: boolean;
  users: UserModel[];
};

const CompanyUsers: React.FC<CompanyUsersProps> = ({
  isLoading = false,
  users,
}) => {
  return (
    <Styled.Wrapper>
      <Table
        data={users}
        columnDefinitions={[
          {title: 'Name', accessor: (row) => <strong>{row.name}</strong>},
          {title: 'Email', accessor: 'email'},
        ]}
      />
    </Styled.Wrapper>
  );
};

export default CompanyUsers;
