import {useFormik} from 'formik';
import React, {useCallback, useEffect} from 'react';
import * as Styled from './EditConnectorForm.styles';
import * as Yup from 'yup';
import TextField from '@components/TextField';
import FormGroup from '@components/FormGroup';
import Steps from '@components/Steps';
import Step from '@components/Steps/Step';
import ConnectorConfigEditor, {
  validationSchema as connectorConfigValidationSchema,
} from '../ConnectorConfigEditor';
import RadioGroupField from '@components/RadioGroupField';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import {UpdateConnectorDto} from '@features/connectors/dto/update-connector.dto';
import ChoiceEditor from '@components/ChoiceEditor';
import {ConnectorReplicationMode} from '@features/connectors/connector-replication-mode.enum';
import TextAreaField from '@components/TextAreaField';
import GitReferenceSelectorDropdown from '@components/GitReferenceSelectorDropdown';
import {GitReference} from '@components/GitReferencesSelector';
import ImageUploadField from '@components/ImageUploadField';
import {ConnectorsService} from '@features/connectors/connectors.service';

type EditConnectorFormProps = {
  onSubmit: (values: UpdateConnectorDto) => Promise<any>;
  initialValues: UpdateConnectorDto;
  repoDetails?: {references: GitReference[]; executable: string};
  onRepoUrlChange: (value: string) => void;
  isLoadingRepoDetails?: boolean;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  executable: Yup.string()
    .required('Please provide an executable for the connector')
    .matches(/^[a-z0-9-_]+$/, {
      message:
        'Only lowercase symbols, numbers, dashes and underscores are allowed.',
    }),
  repo: Yup.string().url('Please provide valid repo url'),
  configuration: connectorConfigValidationSchema,
});

const EditConnectorForm: React.VFC<EditConnectorFormProps> = ({
  onSubmit,
  initialValues,
  onRepoUrlChange,
  isLoadingRepoDetails,
  repoDetails,
}) => {
  const formik = useFormik<UpdateConnectorDto>({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleUploadLogo = useCallback((file: File) => {
    const connectorService = new ConnectorsService();
    return connectorService.uploadLogo(file).then((response) => response.data);
  }, []);

  useEffect(() => {
    try {
      if (formik.values.repo && formik.values.repoAccess === 'private') {
        if (formik.values.repoUsername && formik.values.repoToken) {
          const url = new URL(formik.values.repo);
          url.protocol = 'https';
          url.username = formik.values.repoUsername;
          url.password = formik.values.repoToken;
          onRepoUrlChange(url.href);
        }
      }
      if (formik.values.repo && formik.values.repoAccess === 'public') {
        const url = new URL(formik.values.repo);
        url.protocol = 'https';
        onRepoUrlChange(url.href);
      }
    } catch (e) {}
  }, [
    formik.values.repo,
    formik.values.repoAccess,
    formik.values.repoUsername,
    formik.values.repoToken,
    onRepoUrlChange,
  ]);

  useEffect(() => {
    if (repoDetails?.executable && !isLoadingRepoDetails) {
      console.log(repoDetails.executable);
      formik.handleChange({
        target: {name: 'executable', value: repoDetails?.executable},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoDetails?.executable]);

  useEffect(() => {
    if ((repoDetails?.references || []).length && !formik.values.repoBranch) {
      formik.handleChange({
        target: {name: 'repoBranch', value: repoDetails?.references[0].name},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoDetails?.references, formik.values.repoBranch]);

  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <Steps
          variant="compact"
          alignment="left"
          submitAction={formik.submitForm}
          submitActionEnabled={
            !(formik.isSubmitting || !formik.isValid || !formik.dirty)
          }
          submitActionTitle="Update connector"
        >
          <Step
            title="Definition"
            nextActionTitle="Next: Installation"
            nextActionEnabled={!!formik.values.name}
          >
            <Grid gutterWidth={30}>
              <GridItem>
                <FormGroup>
                  <TextField
                    label="Name"
                    name="name"
                    value={formik.values.name}
                    placeholder="E.g. Tap Postgres"
                    required
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : ''
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <RadioGroupField
                    name="type"
                    options={[
                      {label: 'Source', value: ConnectorType.SOURCE},
                      {label: 'Destination', value: ConnectorType.DESTINATION},
                    ]}
                    label="Choose connector type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
                {formik.values.type === ConnectorType.SOURCE && (
                  <>
                    <FormGroup>
                      <ChoiceEditor
                        label="Supported Replication methods"
                        name="replicationModes"
                        layout="horizontal"
                        options={[
                          {
                            label: 'Full Table',
                            value: ConnectorReplicationMode.FULL_TABLE,
                          },
                          {
                            label: 'Incremental',
                            value: ConnectorReplicationMode.INCREMENTAL,
                          },
                          {
                            label: 'Log Based',
                            value: ConnectorReplicationMode.LOG_BASED,
                          },
                        ]}
                        value={formik.values.replicationModes || []}
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <RadioGroupField
                        name="isDiscoverySupported"
                        label="Discovery mode supported"
                        options={[
                          {label: 'Yes', value: true},
                          {label: 'No', value: false},
                        ]}
                        value={formik.values.isDiscoverySupported}
                        onChange={formik.handleChange}
                      />
                    </FormGroup>
                  </>
                )}
              </GridItem>
              <GridItem>
                <FormGroup>
                  <ImageUploadField
                    label="Logo (optional)"
                    name="logo"
                    value={formik.values.logo}
                    onChange={formik.handleChange}
                    onUpload={handleUploadLogo}
                    error={
                      formik.touched.logo && formik.errors.logo
                        ? formik.errors.logo
                        : ''
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <TextAreaField
                    label="Description (optional)"
                    name="description"
                    placeholder="You can describe what's your connector function here."
                    rows={5}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : ''
                    }
                  />
                </FormGroup>
              </GridItem>
            </Grid>
          </Step>
          <Step
            title="Installation"
            nextActionEnabled={
              !!formik.values.repo && !!formik.values.executable
            }
            nextActionTitle="Next: Configuration schema"
          >
            <Grid gutterWidth={30}>
              <GridItem>
                <FormGroup>
                  <Grid gutterWidth={0}>
                    <GridItem cols={2}>
                      <TextField
                        label="Repository URL"
                        name="repo"
                        required
                        value={formik.values.repo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.repo && formik.errors.repo
                            ? formik.errors.repo
                            : ''
                        }
                      />
                    </GridItem>
                    <GridItem cols={1}>
                      <div
                        style={{
                          marginLeft: '-2px',
                        }}
                      >
                        <GitReferenceSelectorDropdown
                          name="repoBranch"
                          label="Branch/Tag"
                          references={repoDetails?.references || []}
                          isLoading={isLoadingRepoDetails}
                          value={formik.values.repoBranch}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </GridItem>
                  </Grid>
                </FormGroup>

                <FormGroup>
                  <RadioGroupField
                    label="Is your repo private or publicly available?"
                    options={[
                      {label: 'Public', value: 'public'},
                      {label: 'Private', value: 'private'},
                    ]}
                    name="repoAccess"
                    value={formik.values.repoAccess}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
                {formik.values.repoAccess === 'private' && (
                  <FormGroup>
                    <Grid>
                      <GridItem cols={1}>
                        <TextField
                          label="Repository Username"
                          name="repoUsername"
                          required
                          value={formik.values.repoUsername}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.repoUsername &&
                            formik.errors.repoUsername
                              ? formik.errors.repoUsername
                              : ''
                          }
                        />
                      </GridItem>
                      <GridItem cols={1}>
                        <TextField
                          label="Repository Token"
                          name="repoToken"
                          type="password"
                          required
                          value={formik.values.repoToken}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.repoToken && formik.errors.repoToken
                              ? formik.errors.repoToken
                              : ''
                          }
                        />
                      </GridItem>
                    </Grid>
                  </FormGroup>
                )}
              </GridItem>
              <GridItem>
                <FormGroup>
                  <TextField
                    label="Executable"
                    name="executable"
                    placeholder="E.g. tap-postgres"
                    required
                    value={formik.values.executable}
                    onChange={formik.handleChange}
                    tooltip={
                      <>
                        Entrypoint to your connector executable,
                        <br />
                        e.g. tap-postgres, tap-mysql
                      </>
                    }
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.executable && formik.errors.executable
                        ? formik.errors.executable
                        : ''
                    }
                  />
                </FormGroup>
              </GridItem>
            </Grid>
          </Step>
          <Step title="Configuration">
            <ConnectorConfigEditor
              fieldName="configuration"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              values={formik.values.configuration || []}
              errors={formik.errors}
              touched={formik.touched}
            />
          </Step>
        </Steps>
      </form>
    </Styled.Wrapper>
  );
};

export default EditConnectorForm;
