import styled from 'styled-components';
import {lighten, rgba} from 'polished';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};
`;

export const DropdownWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: ${({theme}) => theme.dropdown.borderRadius};
  border: 2px solid ${({theme}) => theme.input.normal.borderColor};
  box-shadow: 0 0 0 transparent;
  color: ${({theme}) => theme.colors.text};
  outline: none;
  transition: 0.2s;

  &.has-error {
    border-color: ${({theme}) => theme.colors.danger};
  }

  &:hover,
  &:focus {
    border-color: ${({theme}) => lighten(0.1, theme.input.hover.borderColor)};
  }
  &:focus {
    /* box-shadow: 0 0 0 1px ${({theme}) =>
      rgba(theme.input.hover.borderColor, 1)}; */
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px 8px;
  cursor: pointer;
  user-select: none;

  .dropdown-variant-compact & {
    padding: 4px 12px 4px;
  }
  .dropdown-variant-compact &:after {
    font-size: 1.25em;
  }

  &:after {
    content: '\\e902';
    display: inline-block;
    font-family: 'icomoon' !important;
    font-size: 1.4em;
    height: 1em;
    speak: never;
    width: 1em;
    min-width: 1em;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: auto;
    transform: rotate(0);
    transition: 0.2s;
  }
  .dropdown-state-expanded &:after {
    transform: rotate(-90deg);
  }
`;

export const Options = styled.ul`
  position: absolute;
  top: 100%;
  left: -2px;
  right: -2px;
  z-index: 100;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  border-radius: ${({theme}) => theme.dropdown.borderRadius};
  border: 2px solid ${({theme}) => theme.input.normal.borderColor};
  background-color: #fff;
`;

export const Option = styled.li`
  display: block;
  padding: 10px 12px;
  color: ${({theme}) => theme.colors.text};
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;

  &:hover,
  &:focus {
    background: ${({theme}) => theme.colors.lightPrimary};
  }
`;
