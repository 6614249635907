import {GitReference} from '@components/GitReferencesSelector';
import Page from '@components/Page';
import CreateConnectorForm from '@features/connectors/components/CreateConnectorForm';
import {CreateConnectorDto} from '@features/connectors/dto/create-connector.dto';
import React from 'react';

type CreateConnectorPageProps = {
  onSubmit: (values: CreateConnectorDto) => Promise<any>;
  repoDetails?: {references: GitReference[]; executable: string};
  onRepoUrlChange: (value: string) => void;
  isLoadingRepoDetails?: boolean;
  tags?: string[];
};

const CreateConnectorPage: React.VFC<CreateConnectorPageProps> = ({
  onSubmit,
  onRepoUrlChange,
  isLoadingRepoDetails,
  repoDetails,
  tags = [],
}) => {
  return (
    <Page title="Create Connector">
      <CreateConnectorForm
        onSubmit={onSubmit}
        onRepoUrlChange={onRepoUrlChange}
        isLoadingRepoDetails={isLoadingRepoDetails}
        tags={tags}
        repoDetails={repoDetails}
      />
    </Page>
  );
};

export default CreateConnectorPage;
