import React, {useMemo} from 'react';
import {formatDistanceToNowStrict, isAfter, parseJSON} from 'date-fns';

import Loader from '@components/Loader';
import Table from '@components/Table';
import {InvitationModel} from '@features/invitations/invitation.model';
import * as Styled from './InvitationList.styles';
import {RoleModel} from '@features/users/role.model';
import StatusBadge from '@components/StatusBadge';
import invitationStatusMap from '@features/invitations/invitation-status-map';

type InvitationListProps = {
  isLoading?: boolean;
  invitations: InvitationModel[];
  roles: RoleModel[];
};

const InvitationList: React.VFC<InvitationListProps> = ({
  isLoading = false,
  invitations,
  roles = [],
}) => {
  const rolesMappedById = useMemo(() => {
    return roles.reduce((prev, role) => {
      prev[role.id] = role;
      return prev;
    }, {} as Record<string, RoleModel>);
  }, [roles]);

  return (
    <Styled.Wrapper>
      {isLoading ? (
        <Loader message="Loading invitations..." />
      ) : (
        <Table
          data={invitations}
          columnDefinitions={[
            {title: 'Email', accessor: (row) => <strong>{row.email}</strong>},
            {
              title: 'Role',
              accessor: (row) => (
                <strong>{rolesMappedById[row.roleId]?.name || '-'}</strong>
              ),
            },
            {
              title: 'Status',
              accessor: (row) => (
                <StatusBadge
                  value={row.status}
                  statusMap={invitationStatusMap}
                />
              ),
            },
            {
              title: 'Expires in',
              accessor: (row) =>
                isAfter(new Date(), parseJSON(row.expiresIn)) ? (
                  <Styled.Error>Expired</Styled.Error>
                ) : (
                  formatDistanceToNowStrict(parseJSON(row.expiresIn), {
                    addSuffix: false,
                  })
                ),
            },
          ]}
        />
      )}
    </Styled.Wrapper>
  );
};

export default InvitationList;
