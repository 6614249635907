import Checkbox from '@components/Checkbox';
import React, {ReactNode, useMemo} from 'react';
import TableSkeleton from './Table.skeleton';
import * as Styled from './Table.styles';

export type ColumnDefinition<T> = {
  title: string;
  accessor: keyof T | ((row: T) => ReactNode);
  compact?: boolean;
  onSelect?: (row: T) => void;
};

type TableProps<T> = {
  columnDefinitions: ColumnDefinition<T>[];
  data: T[];
  onRowClick?: (row: T) => void;
  isLoading?: boolean;
  emptyMessage?: string;
  onSelect?: (row: T) => (isSelected: boolean) => void;
  onBulkSelect?: (rows: T[]) => (isSelected: boolean) => void;
  selected?: {[key: string]: boolean};
};

function Table<T extends {id: string | number}>({
  columnDefinitions,
  data,
  onRowClick,
  isLoading = false,
  emptyMessage = '',
  onBulkSelect,
  onSelect,
  selected,
}: TableProps<T>) {
  const isAllSelected = useMemo(() => {
    if (typeof selected !== 'undefined' && data.length) {
      return (
        Object.keys(selected).filter((key) => selected[key]).length ==
        data.length
      );
    }
    return false;
  }, [data.length, selected]);

  const handleRowClick =
    (row: T) => (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.stopPropagation();

      if (typeof onRowClick !== 'undefined') {
        onRowClick(row);
      }
    };

  return (
    <Styled.Wrapper>
      <Styled.Table>
        <Styled.Header>
          {typeof onBulkSelect !== 'undefined' && (
            <th className="table-cell-compact">
              <Checkbox
                name="table-select-all"
                value={isAllSelected}
                onChange={(e) => onBulkSelect(data)(e.target.value)}
              />
            </th>
          )}
          {columnDefinitions.map((columnDefinition, key) => (
            <th
              className={[
                columnDefinition.compact ? 'table-cell-compact' : '',
              ].join(' ')}
              key={key}
            >
              {columnDefinition.title}
            </th>
          ))}
        </Styled.Header>
        <Styled.Body>
          {!isLoading && !data.length && (
            <Styled.Row>
              <Styled.Cell
                colSpan={
                  columnDefinitions.length +
                  (typeof onSelect !== 'undefined' ? 1 : 0)
                }
              >
                {emptyMessage || 'No data available to display.'}
              </Styled.Cell>
            </Styled.Row>
          )}
          {isLoading &&
            [...Array(2).keys()].map((_, key) => (
              <Styled.Row key={key}>
                {typeof onSelect !== 'undefined' && (
                  <Styled.Cell className="table-cell-compact">
                    <TableSkeleton />
                  </Styled.Cell>
                )}
                {columnDefinitions.map((columnDefinition, key) => (
                  <Styled.Cell
                    className={[
                      columnDefinition.compact ? 'table-cell-compact' : '',
                    ].join(' ')}
                    key={key}
                  >
                    <TableSkeleton />
                  </Styled.Cell>
                ))}
              </Styled.Row>
            ))}
          {!isLoading &&
            data.map((row, key) => (
              <Styled.Row key={key} onClick={handleRowClick(row)}>
                {typeof onSelect !== 'undefined' &&
                  typeof selected !== 'undefined' && (
                    <Styled.Cell className="table-cell-compact">
                      <Checkbox
                        name={`table-select-${key}`}
                        value={selected[row.id] || false}
                        onChange={(e) => onSelect(row)(e.target.value)}
                      />
                    </Styled.Cell>
                  )}
                {columnDefinitions.map((columnDefinition, key) => (
                  <Styled.Cell
                    className={[
                      columnDefinition.compact ? 'table-cell-compact' : '',
                    ].join(' ')}
                    key={key}
                  >
                    {typeof columnDefinition.accessor === 'function'
                      ? columnDefinition.accessor(row)
                      : row[columnDefinition.accessor]}
                  </Styled.Cell>
                ))}
              </Styled.Row>
            ))}
        </Styled.Body>
      </Styled.Table>
    </Styled.Wrapper>
  );
}

export default Table;
