import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 400;
  margin: 0;
`;
