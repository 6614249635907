import LinkButton from '@components/LinkButton';
import Page from '@components/Page';
import Table from '@components/Table';
import {CouponModel} from '@features/billing/coupon.model';
import {titleCase} from '@utils/string.utils';
import React from 'react';
import * as Styled from './CouponsPage.styles';

type CouponsPageProps = {
  isLoading?: boolean;
  coupons: CouponModel[];
};

const CouponsPage: React.FC<CouponsPageProps> = ({
  coupons = [],
  isLoading = false,
}) => {
  return (
    <Page
      title="Coupons"
      actions={[
        <LinkButton to="/coupons/create" icon="add_circle1">
          Create new
        </LinkButton>,
      ]}
    >
      <Table
        data={coupons}
        columnDefinitions={[
          {title: 'ID', accessor: (row) => <strong>{row.id}</strong>},
          {title: 'Max. Redemptions', accessor: 'max_redemptions'},
          {title: 'Percent Off', accessor: 'percent_off'},
          {title: 'Duration', accessor: (row) => titleCase(row.duration)},
        ]}
      />
    </Page>
  );
};

export default CouponsPage;
