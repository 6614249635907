import Loader from '@components/Loader';
import Page from '@components/Page';
import PlanForm from '@features/plans/components/PlanForm';
import {PlanModel} from '@features/plans/plan.model';
import React from 'react';

type EditPlanPageProps = {
  plan?: PlanModel;
  onSubmit: (values: any) => Promise<any>;
  isLoading?: boolean;
};

const EditPlanPage: React.FC<EditPlanPageProps> = ({
  onSubmit,
  plan,
  isLoading = false,
}) => {
  return (
    <Page title="Edit plan">
      {isLoading ? (
        <Loader message="Loading plan data..." />
      ) : (
        <PlanForm onSubmit={onSubmit} initialData={plan} />
      )}
    </Page>
  );
};

export default EditPlanPage;
