import {ValidationErrors} from 'types/validation-errors';
import React from 'react';
import Page from '@components/Page';
import UserForm from '../../forms/UserForm';

type CreateUserPageProps = {
  onSubmit: (values: any) => Promise<any>;
  errors?: ValidationErrors;
};

const CreateUserPage: React.FC<CreateUserPageProps> = ({
  onSubmit,
  errors = {},
}) => {
  return (
    <Page title="Create a user">
      <UserForm errors={errors} onSubmit={onSubmit} mode="create" />
    </Page>
  );
};

export default CreateUserPage;
