import React, {useMemo} from 'react';
import {UserModel} from '@features/users/user.model';
import * as Styled from './UserForm.styles';
import * as Yup from 'yup';
import PasswordField, {
  passwordValidationSchema,
} from '@components/PasswordField';
import {useFormik} from 'formik';
import FormGroup from '@components/FormGroup';
import TextField from '@components/TextField';
import Button from '@components/Button';
import {ValidationErrors} from 'types/validation-errors';

type UserFormProps = {
  onSubmit: (values: object) => void | Promise<any>;
  mode?: 'edit' | 'create';
  initialData?: UserModel;
  errors?: ValidationErrors;
};

const validationSchemaByMode = {
  edit: {
    password: passwordValidationSchema.notRequired(),
  },
  create: {
    email: Yup.string().email('Email not valid').required('Email is required'),
    password: passwordValidationSchema.required('Password is required'),
  },
};

const UserForm: React.FC<UserFormProps> = ({
  onSubmit,
  initialData = {},
  errors = {},
  mode = 'edit',
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        passwordConfirmation: Yup.string().test(
          'passwords-match',
          'Passwords must match',
          function (value) {
            return this.parent.password === value;
          }
        ),
        ...validationSchemaByMode[mode],
      }),
    [mode]
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: initialData.firstName,
      lastName: initialData.lastName,
      email: initialData.email,
      password: '',
      passwordConfirmation: '',
    },
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
    validationSchema,
    onSubmit,
  });
  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <TextField
            label="First Name"
            name="firstName"
            placeholder="Enter your first name"
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Last Name"
            name="lastName"
            placeholder="Enter your last name"
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ''
            }
          />
        </FormGroup>
        {mode === 'create' && (
          <FormGroup>
            <TextField
              label="Email"
              name="email"
              type="email"
              placeholder="Enter your email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ''
              }
            />
          </FormGroup>
        )}
        <FormGroup>
          <PasswordField
            label="Password"
            name="password"
            placeholder="Enter your password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            touched={formik.touched.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Password Confirmation"
            type="password"
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
                ? formik.errors.passwordConfirmation
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <Button
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            isLoading={formik.isSubmitting}
            size="large"
            onClick={formik.submitForm}
          >
            Submit
          </Button>
        </FormGroup>
      </form>
    </Styled.Wrapper>
  );
};

export default UserForm;
