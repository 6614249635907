import Button from '@components/Button';
import Loader from '@components/Loader';
import {EnvironmentLine} from '@features/workspaces/environment-line.interface';
import React, {useCallback, useEffect, useState} from 'react';
import EnvironmentEditor from '../EnvironmentEditor';
import * as Styled from './WorkspaceEnvironment.styles';

type WorkspaceEnvironmentProps = {
  environmentLines?: EnvironmentLine[];
  onSubmit: (data: EnvironmentLine[]) => Promise<any>;
  isLoading?: boolean;
};

const WorkspaceEnvironment: React.VFC<WorkspaceEnvironmentProps> = ({
  environmentLines,
  onSubmit,
  isLoading = false,
}) => {
  const [value, setValue] = useState<EnvironmentLine[]>(environmentLines || []);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback(({target}) => {
    setValue(target.value);
  }, []);

  useEffect(() => {
    if (environmentLines) {
      setValue([...environmentLines, {key: '', value: ''}]);
    }
  }, [environmentLines]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    onSubmit(value)
      .then(() => {
        setIsSubmitting(false);
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  }, [onSubmit, value]);

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.HeaderDetails>
          <Styled.Title>Environment variables</Styled.Title>
          <Styled.Description>
            Here you can manage environment variables, that will be applied to
            your Meltano &amp; Airflow instances.
          </Styled.Description>
        </Styled.HeaderDetails>
        <Button isLoading={isSubmitting} onClick={handleSubmit}>
          Save changes
        </Button>
      </Styled.Header>
      {isLoading ? (
        <Loader message="Loading environment..." />
      ) : (
        <EnvironmentEditor value={value} onChange={handleChange} />
      )}
    </Styled.Wrapper>
  );
};

export default WorkspaceEnvironment;
