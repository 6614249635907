import styled from 'styled-components';
import {darken} from 'polished';

export const InstanceTypeWrapper = styled.div``;

export const InstanceTypeTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.01em;
  margin: 0 0 5px;
`;

export const InstanceTypeFooter = styled.div`
  display: flex;
  align-items: center;
`;

export const InstanceTypeDetail = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  & > .label {
    display: inline-block;
    font-size: 13px;
    text-transform: uppercase;
    color: ${({theme}) => darken(0.1, theme.colors.mediumGray)};
    letter-spacing: 0.01em;
    margin-right: 5px;
  }
  & > .value {
    font-size: 13px;
    font-weight: 700;
  }
`;
