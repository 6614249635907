import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const DetailsLabel = styled.h4`
  margin: 0 0 10px;
  font-size: 18px;
`;

export const DetailsValue = styled.h5`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
`;

export const DetailsRow = styled.div`
  padding: 14px 0;
  & + & {
    border-top: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;
