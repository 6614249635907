import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const HeaderDetails = styled.div``;

export const Title = styled.h3`
  margin: 0 0 7px;
  font-size: 24px;
  color: ${({theme}) => theme.colors.primary};
`;

export const Description = styled.div`
  max-width: 25em;
  color: ${({theme}) => theme.colors.mediumGray};
  line-height: 1.3;
  font-size: 14px;
`;
