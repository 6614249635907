import FieldHelpMessage from '@components/FieldHelpMessage';
import {values} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import * as Styled from './MultiSelectField.styles';

export type MultiSelectOption<T> = {
  title: string;
  value: T;
};

type MultiSelectFieldProps<T> = {
  options: MultiSelectOption<T>[];
  name: string;
  value?: T[];
  label?: string;
  description?: string;
  error?: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
  onChange: (e: {target: {name: string; value: T[]}}) => void;
};

function MultiSelectField<T extends string>({
  onChange,
  name,
  options,
  value = [],
  description,
  error,
  label,
  tooltip,
  placeholder = '',
  required = false,
}: MultiSelectFieldProps<T>) {
  const [enteredValue, setEnteredValue] = useState('');
  const [popupEnabled, setPopupEnabled] = useState(false);

  const fieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const optionsGroupedByValue = useMemo(() => {
    return options.reduce((prev, item) => {
      prev.set(item.value, item);
      return prev;
    }, new Map<T, MultiSelectOption<T>>());
  }, [options]);

  const optionsFilteredByValue = useMemo(() => {
    return options
      .filter((option) => !value.includes(option.value))
      .filter((item) =>
        item.value.toLowerCase().includes(enteredValue.toLowerCase())
      );
  }, [enteredValue, options, value]);

  const handleEnteredValueChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEnteredValue(e.target.value);
    },
    []
  );

  const handleValueRemove = useCallback(
    (item) => (e: React.MouseEvent<HTMLButtonElement>) => {
      onChange({
        target: {name, value: value.filter((_item) => _item !== item)},
      });
    },
    [name, onChange, value]
  );

  const handleOptionSelect = useCallback(
    (option: MultiSelectOption<T>) => (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onChange({target: {name, value: [...value, option.value]}});
    },
    [name, onChange, value]
  );

  const handleInputFocus = useCallback(() => {
    setPopupEnabled(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    // setPopupEnabled(false);
  }, []);

  const handleOutsideClick = useCallback((e: any) => {
    if (fieldRef && !fieldRef.current?.contains(e.target)) {
      setPopupEnabled(false);
    }
  }, []);

  const handleInputKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && inputRef.current?.value) {
        if (
          value.filter(
            (item) =>
              item.toLowerCase() === inputRef.current?.value.toLowerCase()
          ).length
        ) {
          return;
        }
        onChange({
          target: {name, value: [...value, inputRef.current.value as T]},
        });
        setEnteredValue('');
      }
    },
    [name, onChange, value]
  );

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <Styled.Wrapper ref={fieldRef} onBlur={handleInputBlur}>
      {label && (
        <Styled.Label htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && <FieldHelpMessage message={tooltip} />}
        </Styled.Label>
      )}
      <Styled.Header>
        <>
          {value.map((item, key) => (
            <Styled.HeaderValue key={key}>
              {optionsGroupedByValue.get(item)?.title || item}
              <button onClick={handleValueRemove(item)}>&times;</button>
            </Styled.HeaderValue>
          ))}
        </>
        <Styled.HeaderInput
          placeholder={placeholder}
          onChange={handleEnteredValueChanged}
          onFocus={handleInputFocus}
          onKeyPress={handleInputKeyPress}
          ref={inputRef}
          value={enteredValue}
        />
        {!!optionsFilteredByValue.length && popupEnabled && (
          <Styled.Popup>
            <Styled.Options>
              {optionsFilteredByValue.map((option, key) => (
                <Styled.Option key={key} onClick={handleOptionSelect(option)}>
                  {option.title}
                </Styled.Option>
              ))}
            </Styled.Options>
          </Styled.Popup>
        )}
      </Styled.Header>
    </Styled.Wrapper>
  );
}

export default MultiSelectField;
