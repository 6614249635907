import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px 16px;
  border: 1px solid ${({theme}) => darken(0.05, theme.colors.borderColor)};
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    /* background-color: ${({theme}) => theme.colors.lightGray}; */
    box-shadow: 0 0 0 3px ${({theme}) => theme.colors.borderColor};
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: stretch;
`;

export const Group = styled.div`
  display: flex;
  flex-basis: 10%;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;

  &.connector-group-x2 {
    flex-basis: 25%;
  }

  &.connector-group-full {
    flex-grow: 1;
  }

  & + & {
    border-left: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;

export const GroupLabel = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.mediumGray};
  margin-bottom: 6px;
`;

export const GroupValue = styled.div`
  line-height: 22px;
  &.connector-group-value-price {
    font-weight: 700;
    font-size: 18px;
  }
`;

export const Title = styled.h4`
  font-size: 20px;
  margin: 0;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 1.3;
  margin: 5px 0 0 0;
  color: ${({theme}) => theme.colors.mediumGray};
`;

export const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 48px;
  width: 48px;
  margin-right: -4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 90%;
    height: 90%;
    font-size: 28px;
    background-color: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }
`;
