import {UsageReport} from '@features/billing/usage-report.interface';
import {InstanceType} from '@features/workspaces/instance-type.enum';
import instanceTypeMap from '@features/workspaces/instance-type.map';
import React from 'react';
import * as Styled from './CompanyUsageReport.styles';

type CompanyUsageReportProps = {
  report: UsageReport;
};

const formatCentPrice = (price: number) => {
  return Math.round(price * 100) / 10000;
};

const formatMinutesToHours = (minutes: number) => {
  return Math.round((minutes * 1000) / 60) / 1000;
};

const CompanyUsageReport: React.VFC<CompanyUsageReportProps> = ({report}) => {
  return (
    <Styled.Wrapper>
      {/* <Styled.CompanyTotal>${report.companyTotal / 100}</Styled.CompanyTotal> */}
      {report.workspaceBreakdown.map((usageRecord, key) => (
        <Styled.Group key={key}>
          <Styled.GroupTitle>
            <strong>{usageRecord.workspaceName}</strong> Project - Instance
            usage
          </Styled.GroupTitle>
          <Styled.UsageTable>
            <thead>
              <th>Instance Type</th>
              <th>Hours</th>
              <th>Cost</th>
            </thead>
            <tbody>
              <tr>
                <td>Airflow instance</td>
                <td>
                  {formatMinutesToHours(usageRecord.airflowInstanceUsage.count)}
                  &nbsp;hrs.
                </td>
                <td>
                  ${formatCentPrice(usageRecord.airflowInstanceUsage.price)}
                </td>
              </tr>
            </tbody>
          </Styled.UsageTable>
          <Styled.GroupTitle>
            <strong>{usageRecord.workspaceName}</strong> Project - Pipelines
          </Styled.GroupTitle>
          <Styled.UsageTable>
            <thead>
              <th>Pipeline name</th>
              <th>Instance type</th>
              <th>Hours</th>
              <th>Cost</th>
            </thead>
            <tbody>
              {usageRecord.pipelineUsage.map((pipelineUsage, key) => (
                <tr>
                  <td>{pipelineUsage.pipelineName}</td>
                  <td>
                    {
                      instanceTypeMap[
                        pipelineUsage.instanceType as InstanceType
                      ].title
                    }
                  </td>
                  <td>{formatMinutesToHours(pipelineUsage.count)}&nbsp;hrs.</td>
                  <td>${formatCentPrice(pipelineUsage.price)}</td>
                </tr>
              ))}
            </tbody>
          </Styled.UsageTable>
        </Styled.Group>
      ))}
    </Styled.Wrapper>
  );
};

export default CompanyUsageReport;
