import React from 'react';
import * as Styled from './FieldErrorMessage.styles';

type FieldErrorMessageProps = {
  error?: string;
};

const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({error = ''}) => {
  if (!error) {
    return null;
  }
  return <Styled.Wrapper>{error}</Styled.Wrapper>;
};

export default FieldErrorMessage;
