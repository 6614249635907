import {useSelector} from '@app/store';
import {getCurrentCompany} from '@features/auth/auth.slice';
import React from 'react';
import BillingStatusWidgetDisplay from './BillingStatusWidget';

type BillingStatusWidgetProps = {};

const BillingStatusWidget: React.VFC<BillingStatusWidgetProps> = () => {
  const currentCompany = useSelector(getCurrentCompany);
  if (!currentCompany) {
    return null;
  }
  return <BillingStatusWidgetDisplay company={currentCompany} />;
};

export default BillingStatusWidget;
