import {useAuth0} from '@auth0/auth0-react';
import React, {useCallback} from 'react';
import HeaderDisplay from './Header';

type HeaderProps = {
  showCompanySelect?: boolean;
  simple?: boolean;
};

const Header: React.VFC<HeaderProps> = ({
  showCompanySelect = false,
  simple = false,
}) => {
  const {logout} = useAuth0();

  const handleLogout = useCallback(() => {
    return logout({returnTo: window.origin});
  }, [logout]);
  return (
    <HeaderDisplay
      simple={simple}
      showCompanySelect={showCompanySelect}
      onLogout={handleLogout}
    />
  );
};

export default Header;
