import {PlanModel} from '@features/plans/plan.model';
import {ValidationErrors} from 'types/validation-errors';
import React, {useMemo} from 'react';
import * as Styled from './PlanForm.styles';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import TextField from '@components/TextField';
import FormGroup from '@components/FormGroup';
import Button from '@components/Button';

type PlanFormProps = {
  onSubmit: (values: object) => void | Promise<any>;
  mode?: 'edit' | 'create';
  initialData?: PlanModel;
  errors?: ValidationErrors;
};

const validationSchemaByMode = {
  edit: {},
  create: {},
};

const PlanForm: React.FC<PlanFormProps> = ({
  onSubmit,
  initialData = {},
  errors = {},
  mode = 'edit',
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        price: Yup.number().min(0).required('Price is required'),
        trialPeriodDays: Yup.number().min(0).notRequired(),
        maxProjectCount: Yup.number().min(0),
        ...validationSchemaByMode[mode],
      }),
    [mode]
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: initialData.name,
      maxProjectCount: initialData.maxProjectCount || 0,
      price: initialData.price || 0,
      trialPeriodDays: initialData.trialPeriodDays || 0,
      allowedInstaceTypes: initialData.allowedInstanceTypes
        ? initialData.allowedInstanceTypes.split(',')
        : [],
    },
    initialErrors: Object.keys(errors).reduce((prev, field) => {
      prev[field] = errors[field][0];
      return prev;
    }, {} as {[key in keyof typeof errors]: string}),
    validationSchema,
    onSubmit,
  });
  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <TextField
            label="Plan name"
            name="name"
            value={formik.values.name}
            placeholder="e.g. Trial"
            required
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Price in USD"
            type="number"
            name="price"
            required={mode === 'create'}
            disabled={mode === 'edit'}
            value={formik.values.price}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.price && formik.errors.price
                ? formik.errors.price
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Trial period in days"
            type="number"
            name="trialPeriodDays"
            value={formik.values.trialPeriodDays}
            required={mode === 'create'}
            disabled={mode === 'edit'}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.trialPeriodDays && formik.errors.trialPeriodDays
                ? formik.errors.trialPeriodDays
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Max project count (zero for unlimited)"
            name="maxProjectCount"
            type="number"
            value={formik.values.maxProjectCount}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.maxProjectCount && formik.errors.maxProjectCount
                ? formik.errors.maxProjectCount
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <Button
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            isLoading={formik.isSubmitting}
            size="large"
            onClick={formik.submitForm}
          >
            Submit
          </Button>
        </FormGroup>
      </form>
    </Styled.Wrapper>
  );
};

export default PlanForm;
