import {ApiClient} from '@services/api-client';
import {AxiosResponse} from 'axios';
import {CheckInvitationResponse} from './dto/check-invitation-response.dto';
import {CompleteInvitationDto} from './dto/complete-invitation.dto';
import {CreateInvitationDto} from './dto/create-invitation.dto';
import {InvitationModel} from './invitation.model';

export class InvitationsService extends ApiClient {
  async getById(id: string): Promise<AxiosResponse<InvitationModel>> {
    return this.get(`invitations/${id}`);
  }

  async getAll(): Promise<AxiosResponse<InvitationModel[]>> {
    return this.get(`invitations`);
  }

  async create(
    dto: CreateInvitationDto
  ): Promise<AxiosResponse<InvitationModel>> {
    return this.post(`invitations`, dto);
  }

  async checkInvitation(
    token: string
  ): Promise<CheckInvitationResponse | null> {
    try {
      const response = await this.get(`invitations/${token}/check`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async completeInvitation(
    token: string,
    dto: CompleteInvitationDto | null
  ): Promise<AxiosResponse<any>> {
    return this.post(`invitations/${token}/complete`, dto || {});
  }

  async completeExistingUserInvitation(
    token: string
  ): Promise<AxiosResponse<any>> {
    return this.post(`invitations/${token}/complete-existing`, {});
  }
}
