import {darken, lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const Options = styled.div`
  display: inline-flex;
  background-color: ${({theme}) => theme.colors.borderColor};
  border-radius: 8px;
  padding: 5px;
  align-items: center;

  .radio-group-variant-compact & {
    padding: 0 !important;
  }

  .radio-group-appearance-light & {
    font-size: 14px;
    padding: 6px 12px;
    background-color: ${({theme}) => darken(0.01, theme.colors.lightGray)};
  }
`;

export const Option = styled.button`
  position: relative;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 8px 16px;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.01em;
  white-space: nowrap;

  .radio-group-variant-compact & {
    font-size: 15px;
    padding: 7px 12px;
  }

  .radio-group-appearance-light & {
    background-color: transparent;
  }

  &:before {
    content: '';
    height: 60%;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &.active:before,
  &:first-child:before,
  &.active + &:before {
    display: none;
  }

  & + & {
    margin-left: 5px;
  }

  &.active {
    background-color: ${({theme}) => theme.colors.primary};
    letter-spacing: 0.02em;
    color: #fff;
  }

  .radio-group-appearance-light &.active {
    background-color: ${({theme}) => theme.colors.primary};
    color: #fff;
  }
`;
