import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import Modal from '@components/Modal';
import TextAreaField from '@components/TextAreaField';
import React, {useCallback, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {getProfile} from '@features/auth/auth.slice';
import * as Styled from './UploadSSHKeyModal.styles';

const connectRedux = connect(null, {getProfile});

type UploadSSHKeyModalProps = {
  onClose: any;
  isOpen: boolean;
  onSubmit: (value: string) => Promise<any>;
} & ConnectedProps<typeof connectRedux>;

const UploadSSHKeyModal: React.FC<UploadSSHKeyModalProps> = ({
  onClose,
  onSubmit,
  getProfile,
  isOpen = false,
}) => {
  const [sshKey, setSshKey] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSshKey(e.target.value);
    },
    []
  );

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    onSubmit(sshKey)
      .then(() => getProfile())
      .then(() => {
        setIsSubmitting(false);
      });
  }, [getProfile, onSubmit, sshKey]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Upload SSH Key">
      <FormGroup>
        <TextAreaField
          label="Your SSH Public Key"
          rows={10}
          value={sshKey}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Button
          onClick={handleSubmit}
          size="large"
          disabled={!sshKey}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </FormGroup>
    </Modal>
  );
};

export default connectRedux(UploadSSHKeyModal);
