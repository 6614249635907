import React from 'react';
import ContentLoader from 'react-content-loader';

const PipelineLogsSkeletonLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={703}
    height={170}
    viewBox="0 0 703 170"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="1" y="0" rx="9" ry="9" width="70" height="19" />
    <rect x="81" y="0" rx="3" ry="3" width="622" height="15" />
    <rect x="81" y="20" rx="3" ry="3" width="324" height="15" />
    <rect x="1" y="45" rx="9" ry="9" width="70" height="19" />
    <rect x="81" y="45" rx="3" ry="3" width="622" height="15" />
    <rect x="81" y="64" rx="3" ry="3" width="393" height="15" />
    <rect x="1" y="90" rx="9" ry="9" width="70" height="19" />
    <rect x="81" y="90" rx="3" ry="3" width="622" height="15" />
    <rect x="81" y="109" rx="3" ry="3" width="230" height="15" />
    <rect x="1" y="135" rx="9" ry="9" width="70" height="19" />
    <rect x="81" y="135" rx="3" ry="3" width="622" height="15" />
    <rect x="81" y="154" rx="3" ry="3" width="496" height="15" />
  </ContentLoader>
);

export default PipelineLogsSkeletonLoader;
