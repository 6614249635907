import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Validation = styled.div`
  margin-top: 8px;
`;

export const ValidationItem = styled.div`
  font-size: 12px;
  color: ${({theme}) => theme.colors.mediumGray};
  &.valid {
    color: ${({theme}) => theme.colors.green};
  }
  &.invalid {
    color: ${({theme}) => theme.colors.danger};
  }
  & + & {
    margin-top: 5px;
  }
`;

export const RevealButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 16px;
  padding: 0 16px;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`;
