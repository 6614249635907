import {EnvironmentLine} from '@features/workspaces/environment-line.interface';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useCallback, useEffect, useState} from 'react';
import WorkspaceEnvironmentDisplay from './WorkspaceEnvironment';

type WorkspaceEnvironmentProps = {
  workspaceId: string;
};

const WorkspaceEnvironment: React.VFC<WorkspaceEnvironmentProps> = ({
  workspaceId,
}) => {
  const [environmentLines, setEnvironmentLines] = useState<EnvironmentLine[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  const fetchEnvironment = useCallback(async () => {
    const workspaceService = new WorkspaceService();
    setIsLoading(true);
    workspaceService.getEnvironment(workspaceId).then((response) => {
      setEnvironmentLines(response.data);
      setIsLoading(false);
    });
  }, [workspaceId]);

  useEffect(() => {
    fetchEnvironment();
  }, [fetchEnvironment, workspaceId]);

  const handleSubmit = useCallback(
    async (data) => {
      const workspaceService = new WorkspaceService();
      await workspaceService.updateEnvironment(workspaceId, data);
      fetchEnvironment();
    },
    [fetchEnvironment, workspaceId]
  );

  return (
    <WorkspaceEnvironmentDisplay
      onSubmit={handleSubmit}
      isLoading={isLoading}
      environmentLines={environmentLines}
    />
  );
};

export default WorkspaceEnvironment;
