import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {UpdateProfileDto} from './dto/update-profile.dto';
import {AuthUser} from './auth-user.model';
import {AuthService} from './auth.service';
import {CompleteRegistrationDto} from './dto/complete-registration.dto';
import {RootState} from '@app/store';
import {ValidationErrors} from 'types/validation-errors';
import {AxiosError} from 'axios';
import {CompanyModel} from '@features/companies/company.model';

type AuthState = {
  isLoading: boolean;
  message: string;
  errors: ValidationErrors;
  user: Partial<AuthUser>;
  selectedCompany?: string;
};

export const getProfile = createAsyncThunk<Promise<AuthUser> | any>(
  'auth/get-profile',
  async (payload, api) => {
    try {
      const authService = new AuthService();
      const response = await authService.getProfile();
      return response.data;
    } catch (e) {
      return api.rejectWithValue((e as any)?.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk<Promise<any>, UpdateProfileDto>(
  'auth/update-profile',
  async (payload, api) => {
    try {
      const authService = new AuthService();
      const response = await authService.updateProfile(payload);
      return response.data;
    } catch (e) {
      return api.rejectWithValue((e as any)?.response.data);
    }
  }
);

export const completeRegistration = createAsyncThunk<
  Promise<any>,
  CompleteRegistrationDto
>('auth/complete-registration', async (payload, api) => {
  try {
    const authService = new AuthService();
    const response = await authService.completeRegistration(payload);
    return response.data;
  } catch (e) {
    return api.rejectWithValue((e as any)?.response.data);
  }
});

const initialState: AuthState = {
  isLoading: false,
  message: '',
  errors: {},
  user: {},
  selectedCompany: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSelectedCompany: (state, action: PayloadAction<string>) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: {
    // Get profile thunk
    [getProfile.pending.toString()]: (state) => {
      state.errors = {};
      state.message = '';
      state.isLoading = true;
    },
    [getProfile.fulfilled.toString()]: (
      state,
      action: PayloadAction<AuthUser>
    ) => {
      state.isLoading = false;
      state.user = action.payload;
      let selectedCompany = null;
      if (!state.selectedCompany) {
        const storedCompanyId = window.localStorage.getItem('cid');
        const flattenedCompanyList = (action.payload.companies || []).reduce(
          (prev, item) => {
            prev = prev.concat(item.children || []);
            return prev;
          },
          (action.payload.companies || []).slice()
        );
        const validatedStoredCompanyId = flattenedCompanyList.find(
          (item) => item.id === storedCompanyId
        )?.id;
        const firstAvailableCompanyId =
          action.payload.companies && !!action.payload.companies.length
            ? action.payload.companies[0].id
            : '';
        state.selectedCompany =
          validatedStoredCompanyId || firstAvailableCompanyId;
        selectedCompany = flattenedCompanyList.find(
          (item) => item.id === state.selectedCompany
        );
      }
      try {
        window?.$crisp?.push([
          'set',
          'session:data',
          [[['company-id', state.selectedCompany]]],
        ]);
        window?.$crisp?.push(['set', 'user:nickname', [state.user.name]]);
        window?.$crisp?.push(['set', 'user:email', [state.user.email]]);
        window?.$crisp?.push(['set', 'user:company', [selectedCompany?.name]]);
      } catch (e) {
        console.log(e);
      }
    },
    [getProfile.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    // Update profile thunk
    [updateProfile.pending.toString()]: (state) => {
      state.errors = {};
      state.message = '';
      state.isLoading = true;
    },
    [updateProfile.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.message = 'Profile updated successfully';
      state.user = action.payload;
    },
    [updateProfile.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    // Complete registration thunk
    [completeRegistration.pending.toString()]: (state) => {
      state.errors = {};
      state.message = '';
      state.isLoading = true;
    },
    [completeRegistration.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      // state.message = 'Profile updated successfully';
      // state.user = action.payload;
      // if (!state.selectedCompany) {
      //   state.selectedCompany =
      //     action.payload.companies && !!action.payload.companies.length
      //       ? action.payload.companies[0].id
      //       : '';
      // }
    },
    [completeRegistration.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
  },
});

export const getAuthUser = (state: RootState) => state.auth.user;

export const getCurrentCompany = (state: RootState) => {
  const companiesFlattened = (state.auth.user.companies || []).reduce(
    (prev, company) => {
      prev.push(company, ...(company.children || []));
      return prev;
    },
    [] as Partial<CompanyModel>[]
  );

  const companySelector = (company: any) =>
    company.id === state.auth.selectedCompany;
  return companiesFlattened.find(companySelector);
};

export const {setUser, setSelectedCompany} = authSlice.actions;

export default authSlice.reducer;
