import React from 'react';
import * as Styled from './FormGroup.styles';

type FormGroupProps = {};

const FormGroup: React.FC<FormGroupProps> = ({children}) => {
  return <Styled.Wrapper>{children}</Styled.Wrapper>;
};

export default FormGroup;
