import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  /* max-width: 20em; */
`;

export const Inner = styled.div`
  display: flex;
`;

export const ActionBar = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const DisplayValue = styled.div`
  flex-grow: 1;
  margin-right: 5px;
`;

export const EditorWrapper = styled.div`
  flex-grow: 1;
`;

export const EditAction = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({theme}) => theme.colors.mediumGray};
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }
`;

export const CancelAction = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`;

export const SaveAction = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
`;
