import React from 'react';

export type StepProps = {
  title: string;
  nextAction?: () => Promise<any>;
  nextActionTitle?: string;
  nextActionEnabled?: boolean;
  isDisabled?: boolean;
};

const Step: React.FC<StepProps> = ({children}) => {
  return <>{children}</>;
};

export default Step;
