import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {ValidationErrors} from 'types/validation-errors';
import {CreatePlanDto} from './dto/create-plan.dto';
import {PlanModel} from './plan.model';
import {PlanService} from './plan.service';

type PlanState = {
  data: PlanModel[];
  isLoading: boolean;
  errors: ValidationErrors;
  message: string;
};

const initialState: PlanState = {
  data: [],
  isLoading: false,
  errors: {},
  message: '',
};

export const fetchPlans = createAsyncThunk<Promise<PlanModel[] | any>>(
  'plans/get-all',
  async (payload, api) => {
    try {
      const planService = new PlanService();
      const response = await planService.getAll();
      return response.data;
    } catch (e) {
      return api.rejectWithValue(e.response.data);
    }
  }
);

export const createPlan = createAsyncThunk<
  Promise<PlanModel | any>,
  CreatePlanDto
>('plans/create', async (payload, api) => {
  try {
    const planService = new PlanService();
    const response = await planService.create(payload);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

export const updatePlan = createAsyncThunk<
  Promise<PlanModel | any>,
  {id: string; data: Partial<CreatePlanDto>}
>('plans/update', async (payload, api) => {
  try {
    const planService = new PlanService();
    const response = await planService.update(payload.id, payload.data);
    return response.data;
  } catch (e) {
    return api.rejectWithValue(e.response.data);
  }
});

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: {
    // List plans thunk
    [fetchPlans.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchPlans.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.errors;
      state.message = action.message;
    },
    [fetchPlans.fulfilled.toString()]: (
      state,
      action: PayloadAction<PlanModel[]>
    ) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    // Create plan thunk
    [createPlan.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [createPlan.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.errors;
      state.message = action.message;
    },
    [createPlan.fulfilled.toString()]: (
      state,
      action: PayloadAction<PlanModel>
    ) => {
      state.isLoading = false;
      state.data.push(action.payload);
    },
    // Update plan thunk
    [updatePlan.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [updatePlan.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.errors;
      state.message = action.message;
    },
    [updatePlan.fulfilled.toString()]: (
      state,
      action: PayloadAction<PlanModel>
    ) => {
      state.isLoading = false;
      state.data = state.data.map((plan) =>
        plan.id === action.payload.id ? action.payload : plan
      );
    },
  },
});

export default planSlice.reducer;
