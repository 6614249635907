import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {WorkspaceService} from '../workspace.service';

const usePipelineLogs = ({
  workspaceId,
  pipelineId,
  isEnabled,
  logGroupName,
  filter = '',
  doRefetch = true,
}: {
  workspaceId: string;
  pipelineId: string;
  isEnabled: boolean;
  logGroupName: string;
  filter?: string;
  doRefetch?: boolean;
}) => {
  const {data, isLoading, isFetching} = useQuery({
    queryKey: ['pipeline-logs', workspaceId, pipelineId, logGroupName, filter],
    queryFn: () => {
      const workspaceService = new WorkspaceService();
      return workspaceService.getPipelineLogs(
        workspaceId,
        pipelineId,
        logGroupName,
        filter
      );
    },
    enabled: isEnabled,
    refetchInterval: doRefetch && 10000,
  });

  const handleDownloadLogs = useCallback(() => {
    const workspaceService = new WorkspaceService();
    return workspaceService.generatePipelineLogsFile(
      workspaceId,
      pipelineId,
      logGroupName,
      filter
    );
  }, [filter, logGroupName, pipelineId, workspaceId]);

  return {
    logs: data?.data || [],
    isLoading,
    isFetching,
    onGenerateLogsFile: handleDownloadLogs,
  };
};

export default usePipelineLogs;
