import {titleCase} from '@utils/string.utils';
import React, {useEffect, useMemo, useState} from 'react';
import * as Styled from './GitReferencesSelector.styles';

export type GitReferenceType = 'tag' | 'branch';

export type GitReference = {
  name: string;
  type: GitReferenceType;
};

type GitReferencesSelectorProps = {
  references: GitReference[];
  onChange: (value: string) => void;
};

const referenceTypes: GitReferenceType[] = ['branch', 'tag'];

const GitReferencesSelector: React.VFC<GitReferencesSelectorProps> = ({
  references,
  onChange,
}) => {
  const [selectedType, setSelectedType] = useState<GitReferenceType>('branch');

  const groupedByType = useMemo(() => {
    return references.reduce<{[key: string]: GitReference[]}>((prev, item) => {
      prev[item.type] = (prev[item.type] || []).concat([item]);

      return prev;
    }, {});
  }, [references]);

  return (
    <Styled.Wrapper>
      <Styled.Tabs>
        {referenceTypes.map((referenceType, key) => (
          <Styled.Tab
            key={key}
            className={[referenceType === selectedType ? 'active' : ''].join(
              ' '
            )}
            onClick={() => setSelectedType(referenceType)}
          >
            {titleCase(referenceType)}
          </Styled.Tab>
        ))}
      </Styled.Tabs>
      <Styled.List>
        {(groupedByType[selectedType] || []).map((referenceType, key) => (
          <Styled.ListItem
            key={key}
            onClick={() => onChange(referenceType.name)}
          >
            {referenceType.name}
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default GitReferencesSelector;
