import React, {useCallback, useEffect, useState} from 'react';
import WorkspaceUsersDisplay from './WorkspaceUsers';
import {fetchUsers, addUserToWorkspace} from '@features/users/users.slice';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {UserModel} from '@features/users/user.model';
import {RootState} from '@app/store';
import AddUserToWorkspaceModal from '@features/workspaces/AddUserToWorkspaceModal';

const connectRedux = connect(null, {fetchUsers, addUserToWorkspace});

type WorkspaceUsersProps = {
  workspaceId: string;
} & ConnectedProps<typeof connectRedux>;

const WorkspaceUsers: React.FC<WorkspaceUsersProps> = ({
  workspaceId,
  fetchUsers,
  addUserToWorkspace,
}) => {
  const users = useSelector<RootState, UserModel[]>(
    (state) => state.users.data
  );
  const isLoading = useSelector<RootState, boolean>(
    (state) => state.users.isLoading
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddUserToProject = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  useEffect(() => {
    fetchUsers({workspaceId});
  }, [workspaceId, fetchUsers]);

  return (
    <>
      <WorkspaceUsersDisplay
        addUser={handleAddUserToProject}
        isLoading={isLoading}
        users={users}
      />
      {isModalOpen && (
        <AddUserToWorkspaceModal
          onClose={() => setIsModalOpen(false)}
          workspaceId={workspaceId}
        />
      )}
    </>
  );
};

export default connectRedux(WorkspaceUsers);
