import styled from 'styled-components';

export const Wrapper = styled.div``;

export const PipelineList = styled.div``;

export const PipelineListItem = styled.div``;

export const Pipeline = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

export const PipelineTitle = styled.div``;

export const PipelineSource = styled.div``;

export const PipelineDestination = styled.div``;

export const PipelineInstanceType = styled.strong`
  display: inline-flex;
  align-items: center;
  background-color: ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.02em;
  padding: 1px;
  text-transform: uppercase;

  > .title {
    display: inline-block;
    padding: 2px 6px;
  }

  > .spec {
    /* font-weight: 400; */
    display: inline-block;
    background-color: #fff;
    /* text-transform: none; */
    padding: 2px 6px;
  }
`;
