import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  .icon {
    display: inline-block;
    font-size: 16px;
    margin-right: 5px;
    color: inherit;
  }
`;
