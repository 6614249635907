import {useSelector} from '@app/store';
import {CreateSubscriptionDto} from '@features/billing/dto/create-subscription.dto';
import {ValidationErrors} from 'types/validation-errors';
import React from 'react';
import CreateSubscriptionModalDisplay from './CreateSubscriptionModal';

type CreateSubscriptionModalProps = {
  onClose: any;
  onSubmit: (dto: CreateSubscriptionDto) => Promise<any>;
  isOpen: boolean;
  title?: string;
  errors?: ValidationErrors;
};

const CreateSubscriptionModal: React.VFC<CreateSubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  errors,
  title,
}) => {
  const user = useSelector((state) => state.auth.user);

  return (
    <CreateSubscriptionModalDisplay
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      errors={errors}
      title={title}
      billingEmail={user.email || ''}
    />
  );
};

export default CreateSubscriptionModal;
