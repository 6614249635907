import {unwrapResult} from '@reduxjs/toolkit';
import React, {useCallback} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {RootState} from '../../../../app/store';
import {UpdateProfileDto} from '../../dto/update-profile.dto';
import {AuthUser} from '../../auth-user.model';
import {updateProfile, setUser} from '../../auth.slice';
import {useToasts} from 'react-toast-notifications';

import ProfilePageDisplay from './ProfilePage';

const connectRedux = connect(null, {updateProfile, setUser});

type ProfilePageProps = ConnectedProps<typeof connectRedux>;

const ProfilePage: React.FC<ProfilePageProps> = ({updateProfile, setUser}) => {
  const {addToast} = useToasts();

  const user = useSelector<RootState, Partial<AuthUser>>(
    (state) => state.auth.user
  );

  const handleSubmit = useCallback(
    (values: UpdateProfileDto) => {
      return updateProfile(values)
        .then(unwrapResult)
        .then(() => {
          addToast('Profile updated successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, updateProfile]
  );
  return <ProfilePageDisplay updateProfile={handleSubmit} user={user} />;
};

export default connectRedux(ProfilePage);
