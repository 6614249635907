import React, {useCallback} from 'react';

import {ConnectorType} from '@features/connectors/connector-type.enum';
import * as Styled from './ConnectorConfigurator.styles';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import ConnectorSelector from '../ConnectorSelector';
import ConnectorConfigurationEditor from '../ConnectorConfigurationEditor';

export type ConnectorConfiguration = {
  connectorId?: string;
  config?: any;
};

type ConnectorConfiguratorProps = {
  type: ConnectorType;
  value?: ConnectorConfiguration;
  onChange: (value: ConnectorConfiguration) => any;
  showFieldDescription?: boolean;
};

const ConnectorConfigurator: React.VFC<ConnectorConfiguratorProps> = ({
  type,
  value = {},
  onChange,
  showFieldDescription = true,
}) => {
  const handleConnectorChange = useCallback(
    (connectorId: string) => {
      onChange({...value, connectorId});
    },
    [onChange, value]
  );

  const handleConfigurationChange = useCallback(
    (config: any) => {
      onChange({...value, config});
    },
    [onChange, value]
  );

  return (
    <Styled.Wrapper>
      <Grid gutterWidth={40}>
        <GridItem cols={1}>
          <Styled.Section>
            <ConnectorSelector
              type={type}
              value={value.connectorId || ''}
              onChange={handleConnectorChange}
            />
          </Styled.Section>
          {value.connectorId && (
            <Styled.Section>
              {/* <InfoMessage>
                Please fill out configuration for the connector
              </InfoMessage> */}
              <Styled.Title>
                Please fill out configuration for the connector
              </Styled.Title>
              <ConnectorConfigurationEditor
                connectorId={value.connectorId}
                onChange={handleConfigurationChange}
                value={value.config || {}}
              />
            </Styled.Section>
          )}
        </GridItem>
        <GridItem cols={showFieldDescription ? 1 : 0}></GridItem>
      </Grid>
    </Styled.Wrapper>
  );
};

export default ConnectorConfigurator;
