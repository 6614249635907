import theme from '@shared/theme';
import {PipelineState} from './pipeline-state.enum';

const pipelineStateMap: {
  [key in PipelineState]: {title: string; icon: string; color: string};
} = {
  [PipelineState.SUCCESS]: {
    title: 'Success',
    icon: 'check_circle',
    color: theme.colors.green,
  },
  [PipelineState.FAILED]: {
    title: 'Failed',
    icon: 'error',
    color: theme.colors.danger,
  },
  [PipelineState.QUEUED]: {
    title: 'Queued',
    icon: 'pending',
    color: theme.colors.mediumGray,
  },
  [PipelineState.RUNNING]: {
    title: 'Running',
    icon: 'pending',
    color: theme.colors.primary,
  },
};

export default pipelineStateMap;
