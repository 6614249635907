import {InstanceType} from './instance-type.enum';

const instanceTypeMap: Record<
  InstanceType,
  {title: string; cpu: number; ram: number}
> = {
  [InstanceType.SMALL]: {
    title: 'Small',
    cpu: 1,
    ram: 4,
  },
  [InstanceType.MEDIUM]: {
    title: 'Medium',
    cpu: 2,
    ram: 8,
  },
  [InstanceType.LARGE]: {
    title: 'Large',
    cpu: 2,
    ram: 16,
  },
  [InstanceType.EXTRA_LARGE]: {
    title: 'Extra Large',
    cpu: 4,
    ram: 16,
  },
};

export default instanceTypeMap;
