import {darken, lighten} from 'polished';
import styled, {keyframes} from 'styled-components';

export const Wrapper = styled.div``;

export const EmptyMessage = styled.div`
  padding: 20px;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FieldDescription = styled.div`
  position: absolute;
  width: 100%;
  top: 24px;
  left: calc(100% + 30px);
  padding: 12px 16px;
  background: ${({theme}) => lighten(0.02, theme.colors.lightPrimary)};
  color: ${({theme}) => darken(0.3, theme.colors.primary)};
  border-radius: 3px;
  opacity: 0;
  transform: translateX(20px);
  animation-name: ${slideIn};
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  /* border: 2px solid ${({theme}) => theme.colors.borderColor}; */

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    top: 12px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid
      ${({theme}) => lighten(0.02, theme.colors.lightPrimary)};
  }
`;
