import Button from '@components/Button';
import React, {useState, useCallback} from 'react';

export type ToolbarActionProps = {
  title: string;
  icon?: string;
  isDisabled?: boolean;
  action: () => Promise<any>;
  variant?: 'primary' | 'secondary' | 'danger';
};

const ToolbarAction: React.VFC<ToolbarActionProps> = ({
  action,
  title,
  icon,
  isDisabled = false,
  variant = 'primary',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    await action();
    setIsLoading(false);
  }, [action]);

  return (
    <Button
      onClick={handleClick}
      icon={icon}
      disabled={isDisabled}
      isLoading={isLoading}
      variant={variant}
    >
      {title}
    </Button>
  );
};

export default ToolbarAction;
