import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import {WorkspaceModel} from '@features/workspaces/workspace.model';
import {UserModel} from '@features/users/user.model';
import {ValidationErrors} from 'types/validation-errors';
import {CreateWorkspaceDto} from './dto/create-workspace.dto';
import {AxiosError} from 'axios';

export const fetchWorkspaces = createAsyncThunk(
  'workspaces/get-all',
  async (payload, api) => {
    try {
      const workspaceService = new WorkspaceService();
      const response = await workspaceService.getAll();
      return response.data as WorkspaceModel[];
    } catch (e) {
      let error = e as AxiosError<any> | undefined;
      return api.rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchWorkspaceById = createAsyncThunk<Promise<any>, string>(
  'workspaces/get-by-id',
  async (payload, api) => {
    try {
      const workspaceService = new WorkspaceService();
      const response = await workspaceService.getById(payload);
      return response.data as WorkspaceModel;
    } catch (e) {
      let error = e as AxiosError<any> | undefined;
      return api.rejectWithValue(error?.response?.data);
    }
  }
);

export const createWorkspace = createAsyncThunk<
  Promise<any>,
  CreateWorkspaceDto
>('workspaces/create', async (payload, api) => {
  try {
    const workspaceService = new WorkspaceService();
    const response = await workspaceService.create(payload);
    return response.data as WorkspaceModel;
  } catch (e) {
    let error = e as AxiosError<any> | undefined;
    return api.rejectWithValue(error?.response?.data);
  }
});

export const removeWorkspace = createAsyncThunk<Promise<any>, string>(
  'workspaces/remove',
  async (payload, api) => {
    try {
      const workspaceService = new WorkspaceService();
      await workspaceService.remove(payload);
      return payload;
    } catch (e) {
      let error = e as AxiosError<any> | undefined;
      return api.rejectWithValue(error?.response?.data);
    }
  }
);

type WorkspaceState = {
  isLoading: boolean;
  data: WorkspaceModel[];
  users: UserModel[];
  errors: ValidationErrors;
  message: string;
};

const initialState: WorkspaceState = {
  isLoading: false,
  data: [],
  users: [],
  errors: {},
  message: '',
};

const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {},
  extraReducers: {
    // List workspaces thunk
    [fetchWorkspaces.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchWorkspaces.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [fetchWorkspaces.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    // Get workspace by id thunk
    [fetchWorkspaceById.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [fetchWorkspaceById.fulfilled.toString()]: (
      state,
      action: PayloadAction<WorkspaceModel>
    ) => {
      state.isLoading = false;
      state.data = state.data
        .filter((workspace) => workspace.id !== action.payload.id)
        .concat(action.payload);
    },
    [fetchWorkspaceById.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.data.message;
    },
    // Create workspace thunk
    [createWorkspace.pending.toString()]: (state) => {
      state.isLoading = true;
      state.errors = {};
      state.message = '';
    },
    [createWorkspace.fulfilled.toString()]: (state, action) => {
      state.isLoading = false;
      state.data.push(action.payload);
      state.message = 'Workspace created successfully';
    },
    [createWorkspace.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
    // Remove workspace thunk
    [removeWorkspace.pending.toString()]: (state) => {
      // state.isLoading = true;
    },
    [removeWorkspace.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.data = state.data.filter(
        (workspace) => workspace.id !== action.payload
      );
      state.message = 'Workspace deleted successfully';
    },
    [removeWorkspace.rejected.toString()]: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload.errors;
      state.message = action.payload.message;
    },
  },
});

export default workspaceSlice.reducer;
