import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const Header = styled.thead`
  border-bottom: 3px solid ${({theme}) => theme.colors.borderColor};
  th {
    position: relative;
    font-size: 14px;
    color: ${({theme}) => theme.colors.mediumGray};
    text-transform: uppercase;
    background-color: #fff;
    padding: 10px 20px;
    letter-spacing: 0.01em;
    text-align: left;

    &.table-cell-compact {
      width: 1%;
      white-space: nowrap;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background-color: ${({theme}) => darken(0.1, theme.colors.borderColor)};
    }
  }

  th:first-child:before {
    display: none;
  }
`;

export const Body = styled.tbody``;

export const Row = styled.tr`
  cursor: pointer;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};
  background-color: #fff;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
  }
`;

export const Cell = styled.td`
  position: relative;
  padding: 10px 20px;
  font-size: 15px;

  &.table-cell-compact {
    width: 1%;
    white-space: nowrap;
  }
`;
