import {rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const RevisionId = styled.span`
  display: inline-block;
  padding: 2px 10px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 700;
  background-color: ${({theme}) => theme.colors.borderColor};
  margin-right: 16px;
`;

export const RevisionDescription = styled.h5`
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  flex-basis: 18em;
`;

export const Status = styled.div`
  margin-left: auto;
  flex-basis: 6em;
`;

export const CreatedAt = styled.div`
  margin-left: 25px;
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 15px;
  flex-basis: 6em;
`;

export const ViewLogsLink = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  background: none;
  color: ${({theme}) => theme.colors.primary};
  border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.6)};
`;
