import {useQuery} from 'react-query';
import {ConnectorsService} from '../connectors.service';

const useConnector = (connectorId?: string) => {
  const {data: connector, isLoading} = useQuery({
    queryFn: async () => {
      const connectorService = new ConnectorsService();
      return connectorService.getById(connectorId || '').then(({data}) => data);
    },
    queryKey: ['connectors', connectorId],
    enabled: !!connectorId,
  });

  return {
    connector,
    isLoading,
  };
};

export default useConnector;
