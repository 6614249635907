import Button from '@components/Button';
import {GitReference} from '@components/GitReferencesSelector';
import EditConnectorForm from '@features/connectors/components/EditConnectorForm';
import {ConnectorModel} from '@features/connectors/connector.model';
import {UpdateConnectorDto} from '@features/connectors/dto/update-connector.dto';
import React from 'react';
import * as Styled from './EditCompanyConnector.styles';

type EditCompanyConnectorProps = {
  onSubmit: (values: UpdateConnectorDto) => Promise<any>;
  onDelete: () => Promise<any>;
  connector: ConnectorModel;
  onGoBack: () => any;
  repoDetails?: {references: GitReference[]; executable: string};
  onRepoUrlChange: (value: string) => void;
  isLoadingRepoDetails?: boolean;
  isDeleting?: boolean;
};

const EditCompanyConnector: React.VFC<EditCompanyConnectorProps> = ({
  onSubmit,
  onDelete,
  onGoBack,
  connector,
  onRepoUrlChange,
  isLoadingRepoDetails,
  isDeleting,
  repoDetails,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        <Button variant="secondary" onClick={onGoBack} icon="arrow_back">
          Go Back to Connector List
        </Button>
        <Button
          isLoading={isDeleting}
          variant="danger"
          icon="delete"
          onClick={onDelete}
        >
          Delete
        </Button>
      </Styled.ActionBar>
      <EditConnectorForm
        onSubmit={onSubmit}
        onRepoUrlChange={onRepoUrlChange}
        repoDetails={repoDetails}
        isLoadingRepoDetails={isLoadingRepoDetails}
        initialValues={{...connector, configuration: connector.config}}
      />
    </Styled.Wrapper>
  );
};

export default EditCompanyConnector;
