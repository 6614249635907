import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s;

  &:hover {
    background-color: #f8f8f8;
  }
`;

export const Column = styled.div``;

export const Name = styled.h3`
  font-size: 18px;
  letter-spacing: 0.01em;
  margin: 0 0 4px 0;
`;

export const Email = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.mediumGray};
  letter-spacing: 0.01em;
`;

export const Avatar = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 16px;
  background-color: ${({theme}) => theme.colors.lightPrimary};
  background-size: cover;
  background-repeat: no-repeat;
`;
