import {useQuery} from 'react-query';
import {WorkspaceService} from '../workspace.service';

const useLogGroups = (
  workspaceId: string,
  pipelineId: string,
  onSuccess: any
) => {
  const {data, isLoading} = useQuery({
    queryKey: ['pipeline-log-groups', workspaceId, pipelineId],
    queryFn: () => {
      console.log(pipelineId);
      const workspaceService = new WorkspaceService();
      return workspaceService.getPipelineLogGroups(workspaceId, pipelineId);
    },
    onSuccess: (data) => {
      onSuccess(data.data);
    },
    refetchOnMount: true,
    refetchInterval: 10000,
  });

  return {
    logGroups: data?.data || [],
    isLoading,
  };
};

export default useLogGroups;
