import React from 'react';
import Breadcrumbs, {Breadcrumb} from './Breadcrumbs';
import * as Styled from './Page.styles';

type PageProps = {
  title: React.ReactNode;
  actions?: React.ReactNodeArray;
  breadcrumbs?: Breadcrumb[];
};

const Page: React.FC<PageProps> = ({
  title,
  actions = null,
  breadcrumbs,
  children,
}) => {
  return (
    <Styled.Wrapper>
      {breadcrumbs && (
        <Styled.Breadcrumbs>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Styled.Breadcrumbs>
      )}
      <Styled.Header>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Actions>{actions}</Styled.Actions>
      </Styled.Header>
      <Styled.Body>{children}</Styled.Body>
    </Styled.Wrapper>
  );
};

export default Page;
