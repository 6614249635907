import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.lightGray};
`;

export const Inner = styled.div`
  width: 30em;
  padding: 30px;
  background-color: #fff;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 8px;
`;

export const Logo = styled.h1`
  margin: 0 0 30px;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 0.005em;
  text-align: center;
`;

export const CenteredLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0;
  line-height: 1.3;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: ${({theme}) => theme.colors.mediumGray};
  strong {
    color: ${({theme}) => theme.colors.text};
  }
`;

export const Form = styled.form`
  ${Title} {
    font-size: 22px;
    margin-bottom: 30px;
  }
`;

export const ActionBar = styled.div`
  margin-top: 30px;
`;
