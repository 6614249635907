import styled from 'styled-components';
import {lighten, rgba} from 'polished';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;

export const FieldWrapper = styled.div`
  position: relative;
  /* overflow: hidden; */
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 3px;
  border: 2px solid ${({theme}) => theme.input.normal.borderColor};
  box-shadow: 0 0 0 transparent;
  color: ${({theme}) => theme.colors.text};
  outline: none;
  transition: 0.2s;

  &:disabled {
    cursor: not-allowed;
    border: 2px solid ${({theme}) => theme.input.normal.borderColor} !important;
    box-shadow: 0 0 0 transparent !important;
  }

  &.has-error {
    border-color: ${({theme}) => theme.colors.danger} !important;
  }
  &.has-error:focus {
    /* box-shadow: 0 0 0 2px ${({theme}) =>
      rgba(theme.colors.danger, 0.2)} !important; */
  }

  &:hover {
    border-color: ${({theme}) => lighten(0.3, theme.input.hover.borderColor)};
  }

  &&:focus {
    border-color: ${({theme}) => lighten(0.1, theme.input.focus.borderColor)};
    /* box-shadow: 0 0 0 2px
      ${({theme}) => rgba(theme.input.focus.borderColor, 0.2)}; */
  }
`;

export const Buttons = styled.div`
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 3px;
  overflow: hidden;
`;

export const MaxLengthIndicator = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  display: flex;
  font-size: 15px;
  height: 100%;
  align-items: center;
  padding: 0 16px;
`;

export const Description = styled.div`
  color: ${({theme}) => theme.colors.mediumGray};
  font-size: 14px;
  margin-top: 8px;
`;
