import {CreatePipelineDto} from '@features/workspaces/dto/create-pipeline.dto';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import {useMutation, useQuery, useQueryClient} from 'react-query';

export const useGetPipelines = (workspaceId: string) => {
  const workspaceService = new WorkspaceService();

  const {data, isLoading} = useQuery({
    queryKey: ['pipelines', workspaceId],
    queryFn: async () => workspaceService.getPipelines(workspaceId),
    refetchInterval: 30000,
  });

  return {
    pipelines: data?.data,
    isLoading,
  };
};

export const useCreatePipeline = (
  workspaceId: string,
  onSuccess?: () => void
) => {
  const workspaceService = new WorkspaceService();
  const queryClient = useQueryClient();

  const {mutateAsync, isLoading, error, status} = useMutation({
    mutationFn: (data: CreatePipelineDto) => {
      return workspaceService.createPipeline(workspaceId, data);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['pipelines', workspaceId]);
      // queryClient.setQueryData(['pipelines', workspaceId], data);
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  return {
    createPipeline: mutateAsync,
    isLoading,
    error,
    status,
  };
};
