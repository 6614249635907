import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import ResourceLink from '@components/ResourceLink';
import {CompanyModel} from '@features/companies/company.model';
import PlanDetails from '@features/plans/components/PlanDetails';
import PlanSelectorDropdown from '@features/plans/components/PlanSelectorDropdown';
import React, {useCallback, useState} from 'react';
import * as Styled from './CompanyPlanEditor.styles';

type CompanyPlanEditorProps = {
  /**
   * Company
   */
  company: CompanyModel;
  onSubmit: (planId: string) => Promise<any>;
};

/**
 * Component to render & edit company billing plan
 */
export const CompanyPlanEditor: React.VFC<CompanyPlanEditorProps> = ({
  company,
  onSubmit,
}) => {
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [selectedPlanId, setSelectedPlanId] = useState('');

  const handleSubmit = useCallback(() => {
    onSubmit(selectedPlanId).then(() => {
      setMode('view');
    });
  }, [onSubmit, selectedPlanId]);

  if (mode === 'edit') {
    return (
      <Styled.Wrapper>
        <FormGroup>
          <PlanSelectorDropdown
            onChange={setSelectedPlanId}
            value={selectedPlanId}
          />
        </FormGroup>
        <FormGroup>
          <Button onClick={handleSubmit}>Save</Button>
        </FormGroup>
      </Styled.Wrapper>
    );
  }
  return (
    <Styled.Wrapper>
      <FormGroup>
        {company.plan ? (
          <PlanDetails plan={company.plan} />
        ) : (
          <Styled.NotAssigned>Plan not assigned</Styled.NotAssigned>
        )}
      </FormGroup>
      <FormGroup>
        <Button size="small" onClick={() => setMode('edit')}>
          Change plan
        </Button>
      </FormGroup>
    </Styled.Wrapper>
  );
};

export default CompanyPlanEditor;
