import {lighten, rgba} from 'polished';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const LinkStyled = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.5)};
  color: ${({theme}) => theme.colors.primary};
  transition: 0.2s;
  &:hover {
    color: ${({theme}) => lighten(0.1, theme.colors.primary)};
  }
`;
