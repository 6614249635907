import {rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.12);
  transition: 0.2s;
  padding: 20px 30px 50px;
`;

export const SectionTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 0.5em;
`;

export const Details = styled.div`
  max-width: 25em;
`;

export const Invoices = styled.div`
  .invoice-link {
    display: inline-flex;
    align-items: center;
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    > span {
      display: inline-block;
      border-bottom: 1px dotted ${({theme}) => rgba(theme.colors.primary, 0.5)};
    }
    > .icon {
      margin-right: 3px;
      font-size: 1.2em;
    }
  }
`;

export const Transactions = styled(Invoices)``;

export const DetailsGroupTitle = styled.h3`
  font-size: 20px;
  margin: 0 0 1em;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({theme}) => theme.colors.borderColor};
`;

export const DetailsGroup = styled.div`
  margin-bottom: 30px;

  &.billing-details-no-border ${DetailsGroupTitle} {
    border: none;
    margin: 0;
  }
`;

export const DetailsLines = styled.div``;

export const DetailsLine = styled.div`
  display: flex;
  justify-content: stretch;
  & + & {
    margin-top: 16px;
  }
`;

export const DetailsLabel = styled.div`
  flex-grow: 1;
  color: ${({theme}) => theme.colors.mediumGray};
`;

export const DetailsValue = styled.div`
  text-align: right;
  min-width: 10em;
  font-weight: 700;
`;

export const Actions = styled.div`
  margin-top: 30px;
`;
