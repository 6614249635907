import {PlanModel} from '@features/plans/plan.model';
import React from 'react';
import * as Styled from './PlanDetails.styles';

type PlanDetailsProps = {
  plan: PlanModel;
};

const PlanDetails: React.VFC<PlanDetailsProps> = ({plan}) => {
  return (
    <Styled.Wrapper>
      <Styled.Inner>
        <Styled.Title>{plan.name}</Styled.Title>
        <Styled.Price>
          ${plan.price}
          <small>/month</small>
        </Styled.Price>
      </Styled.Inner>
      <Styled.Inner className="highlighted">
        <Styled.Constraints>
          <Styled.Constraint>
            <span className="value">{plan.maxProjectCount}</span>
            <span className="label">projects</span>
          </Styled.Constraint>
          {plan.trialPeriodDays ? (
            <Styled.Constraint>
              <span className="value">{plan.trialPeriodDays}</span>
              <span className="label">days free trial</span>
            </Styled.Constraint>
          ) : null}
        </Styled.Constraints>
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default PlanDetails;
