import Button from '@components/Button';
import FormGroup from '@components/FormGroup';
import Modal from '@components/Modal';
import React, {useCallback, useState} from 'react';
import * as Styled from './ConfirmationModal.styles';

type ConfirmationModalProps = {
  onDismiss: any;
  onConfirm: any;
  isOpen: boolean;
  message: React.ReactNode;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirm,
  onDismiss,
  message,
  isOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  }, [onConfirm]);

  return (
    <Modal
      onClose={onDismiss}
      isOpen={isOpen}
      title="Confirm action"
      width="30em"
    >
      <FormGroup>
        <Styled.Message>{message}</Styled.Message>
      </FormGroup>
      <FormGroup>
        <Styled.ActionBar>
          <Button variant="danger" onClick={onDismiss}>
            Dismiss
          </Button>
          <Button onClick={handleConfirm} isLoading={isLoading}>
            Confirm
          </Button>
        </Styled.ActionBar>
      </FormGroup>
    </Modal>
  );
};

export default ConfirmationModal;
