import React from 'react';

export type TabProps = {
  title: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({children}) => {
  return <>{children}</>;
};

export default Tab;
