import Modal from '@components/Modal';
import {LogEvent} from '@features/workspaces/log-event.model';
import * as Styled from './BuildLogsModal.styles';
import {format} from 'date-fns';
import React from 'react';
import Loader from '@components/Loader';
import {parse} from 'ansicolor';

type BuildLogsModalProps = {
  logs: LogEvent[];
  onClose: any;
  isOpen: boolean;
  isLoading?: boolean;
};

const BuildLogsModal: React.VFC<BuildLogsModalProps> = ({
  isOpen,
  isLoading = false,
  onClose,
  logs = [],
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} title="Build logs" width="50em">
      {isLoading && <Loader message="Loading..." />}
      <Styled.LogsWrapper>
        {!isLoading &&
          logs
            .filter((logEvent) => !!logEvent.message.trim())
            .map((logEvent, key) => (
              <Styled.LogEvent key={key}>
                <Styled.LogEventTimestamp>
                  <span className="day">
                    {format(new Date(logEvent.timestamp), 'yyyy/MM/dd')}
                  </span>
                  <span className="time">
                    {format(new Date(logEvent.timestamp), 'HH:mm:ss')}
                  </span>
                </Styled.LogEventTimestamp>
                <Styled.LogEventMessage>
                  {parse(logEvent.message).spans.map((span) => (
                    <Styled.LogEventMessageSpan css={span.css}>
                      {span.text}
                    </Styled.LogEventMessageSpan>
                  ))}
                </Styled.LogEventMessage>
              </Styled.LogEvent>
            ))}
      </Styled.LogsWrapper>
    </Modal>
  );
};

export default BuildLogsModal;
