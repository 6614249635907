import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import EditConnectorPageDisplay from './EditConnectorPage';
import {
  updateConnector,
  fetchConnectorById,
  getConnectorById,
} from '@features/connectors/connectors.slice';
import {unwrapResult} from '@reduxjs/toolkit';
import {useToasts} from 'react-toast-notifications';
import {useHistory, useRouteMatch} from 'react-router';
import {UpdateConnectorDto} from '@features/connectors/dto/update-connector.dto';
import {useSelector} from '@app/store';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import {useQuery} from 'react-query';
import {ConnectorsService} from '@features/connectors/connectors.service';
import ConfirmationModal from '@modals/ConfirmationModal';

const connectRedux = connect(null, {updateConnector, fetchConnectorById});

type EditConnectorPageProps = {} & ConnectedProps<typeof connectRedux>;

const EditConnectorPage: React.VFC<EditConnectorPageProps> = ({
  updateConnector,
  fetchConnectorById,
}) => {
  const [repoUrl, setRepoUrl] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);

  const {addToast} = useToasts();
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();

  const connector = useSelector(getConnectorById(match.params.id));
  const isLoading = useSelector((state) => state.connectors.isLoading);

  const isRepoValid = useMemo(() => {
    try {
      const url = new URL(repoUrl);
      if (url.username && !url.password) {
        return false;
      }
      return true;
    } catch (e) {
      return false;
    }
  }, [repoUrl]);

  const {data, isLoading: isLoadingRepoDetails} = useQuery({
    queryFn: async () => {
      const workspaceService = new WorkspaceService();
      return workspaceService
        .getRepoDetails(repoUrl)
        .then((response) => response.data);
    },
    queryKey: ['git-details', repoUrl],
    enabled: isRepoValid,
  });

  const handleDelete = useCallback(async () => {
    const connectorService = new ConnectorsService();
    setIsDeleting(true);
    try {
      await connectorService.remove(match.params.id);
      setIsDeleting(false);
      history.push('/own-connectors');
    } catch (e) {
      setIsDeleting(false);
    }
  }, [history, match.params.id]);

  const handleShowConfirmationModal = useCallback(async () => {
    setIsConfirmationModalShown(true);
  }, []);

  useEffect(() => {
    fetchConnectorById(match.params.id);
  }, [fetchConnectorById, match.params.id]);

  const handleSubmit = useCallback(
    (values: UpdateConnectorDto) => {
      return updateConnector({id: match.params.id, data: values})
        .then(unwrapResult)
        .then(() => {
          history.push('/own-connectors');
          addToast('Connector updated successfully', {
            appearance: 'success',
            autoDismiss: true,
          });
        });
    },
    [addToast, history, match.params.id, updateConnector]
  );

  return (
    <>
      <ConfirmationModal
        onConfirm={handleDelete}
        onDismiss={() => setIsConfirmationModalShown(false)}
        isOpen={isConfirmationModalShown}
        message={
          <>
            Are you sure you want to delete connector?
            <br />
            This process <strong>cannot be undone</strong>.
          </>
        }
      />
      <EditConnectorPageDisplay
        isLoading={isLoading}
        connector={connector}
        onSubmit={handleSubmit}
        onDelete={handleShowConfirmationModal}
        onRepoUrlChange={setRepoUrl}
        repoDetails={data}
        isLoadingRepoDetails={isLoadingRepoDetails}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default connectRedux(EditConnectorPage);
