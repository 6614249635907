import Loader from '@components/Loader';
import {UsageReport} from '@features/billing/usage-report.interface';
import {CompaniesService} from '@features/companies/companies.service';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import React, {useEffect, useState} from 'react';
import CompanyUsageReport from './CompanyUsageReport';

type Props = {};

const CompanyUsageReportContainer: React.VFC<Props> = () => {
  const selectedCompany = useSelectedCompany();
  const [usageReport, setUsageReport] = useState<UsageReport | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const companiesService = new CompaniesService();

    if (!selectedCompany?.id) {
      return;
    }
    companiesService
      .getUsageReport(selectedCompany?.id)
      .then((response) => {
        setUsageReport(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [selectedCompany?.id]);

  if (isLoading || !usageReport) {
    return <Loader message="Loading..." />;
  }

  return <CompanyUsageReport report={usageReport} />;
};

export default CompanyUsageReportContainer;
