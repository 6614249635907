import Page from '@components/Page';
import CreateManagedCompanyScreen from '@features/companies/components/CreateManagedCompanyScreen';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import * as Styled from './CreateManagedCompanyPage.styles';

type CreateManagedCompanyPageProps = {};

const CreateManagedCompanyPage: React.VFC<
  CreateManagedCompanyPageProps
> = () => {
  const {addToast} = useToasts();
  const history = useHistory();

  const handleSuccess = useCallback(() => {
    addToast('Managed company created', {
      appearance: 'success',
      autoDismiss: true,
    });
    history.push('/managed-companies');
  }, [addToast, history]);

  return (
    <Page title="Create Managed Company">
      <CreateManagedCompanyScreen onSuccess={handleSuccess} />
    </Page>
  );
};

export default CreateManagedCompanyPage;
