export enum ConnectorConfigType {
  STRING = 'string',
  JSON = 'json',
  PASSWORD = 'password',
  EMAIL = 'email',
  INTEGER = 'integer',
  BOOLEAN = 'boolean',
  ARRAY = 'array',
  OBJECT = 'object',
  OPTIONS = 'options',
  FILE_UPLOAD = 'file_upload',
}
