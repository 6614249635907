import {RootState} from '@app/store';
import {AuthUser} from '@features/auth/auth-user.model';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import UploadSSHKeyModalDisplay from './UploadSSHKeyModal';

type UploadSSHKeyModalProps = {
  workspaceId: string;
  isOpen?: boolean;
  onClose: any;
};

const UploadSSHKeyModal = ({
  workspaceId,
  isOpen = false,
  onClose,
}: UploadSSHKeyModalProps) => {
  const user = useSelector<RootState, Partial<AuthUser>>(
    (state) => state.auth.user
  );
  const handleSubmit = useCallback(
    (sshKey: string) => {
      const workspaceService = new WorkspaceService();
      return workspaceService.uploadSSHKey({
        workspaceId,
        userId: user.id || '',
        sshKey,
      });
    },
    [user.id, workspaceId]
  );

  return (
    <UploadSSHKeyModalDisplay
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};

export default UploadSSHKeyModal;
