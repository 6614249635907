import {WorkspaceSettingsModel} from '@features/workspaces/workspace-settings.model';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import React, {useCallback, useEffect, useState} from 'react';
import WorkspaceSettingsDisplay from './WorkspaceSettings';

type WorkspaceSettingsProps = {
  workspaceId: string;
};

const WorkspaceSettings: React.VFC<WorkspaceSettingsProps> = ({
  workspaceId,
}) => {
  const [settings, setSettings] = useState<WorkspaceSettingsModel>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const workspaceService = new WorkspaceService();
    setIsLoading(true);
    workspaceService.getSettings(workspaceId).then((response) => {
      setSettings(response.data);
      setIsLoading(false);
    });
  }, [workspaceId]);

  const handleSubmit = useCallback(async () => {
    const workspaceService = new WorkspaceService();
    const response = await workspaceService.updateSettings(
      workspaceId,
      settings
    );
    setSettings(response.data);
  }, [settings, workspaceId]);

  return (
    <WorkspaceSettingsDisplay
      settings={settings}
      isLoading={isLoading}
      onChange={setSettings}
      onSubmit={handleSubmit}
    />
  );
};

export default WorkspaceSettings;
