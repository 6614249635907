import React from 'react';
import CompanyBillingPageDisplay from './CompanyBillingPage';
import useCompanyBillingDetails from '@features/companies/hooks/useCompanyBillingDetails';
import useSelectedCompany from '@features/companies/hooks/useSelectedCompany';
import {BillingStatus} from '@features/billing/billing-status.enum';
import Page from '@components/Page';
import InfoMessage from '@components/InfoMessage';

type CompanyBillingPageProps = {};

const CompanyBillingPage: React.VFC<CompanyBillingPageProps> = () => {
  const selectedCompany = useSelectedCompany();

  const {
    billingDetails,
    isLoading,
    handleCancelSubscription,
    handleRenewSubscription,
    handleCreateSubscription,
    handleUpdatePaymentMethod,
    invoices,
    transactions,
  } = useCompanyBillingDetails();

  if (
    selectedCompany &&
    selectedCompany.billingStatus === BillingStatus.PARENT_MANAGED
  ) {
    return (
      <Page title="Billing">
        <InfoMessage>Your billing is managed by parent company.</InfoMessage>
      </Page>
    );
  }

  return (
    <CompanyBillingPageDisplay
      billingDetails={billingDetails}
      invoices={invoices}
      transactions={transactions}
      isLoading={isLoading}
      onCreateSubscription={handleCreateSubscription}
      onCancelSubscription={handleCancelSubscription}
      onRenewSubscription={handleRenewSubscription}
      onUpdatePaymentMethod={handleUpdatePaymentMethod}
    />
  );
};

export default CompanyBillingPage;
