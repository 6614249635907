import axios, {AxiosInstance} from 'axios';
import store from '@app/store';

export abstract class ApiClient {
  private token: string;
  private baseUrl: string;
  private httpClient: AxiosInstance;

  constructor() {
    this.baseUrl = process.env['REACT_APP_API_URL'] || '';
    if (!this.baseUrl) {
      throw new Error('API base url not defined.');
    }
    this.token = window.localStorage.getItem('token') || '';
    this.httpClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'X-Company': store.getState().auth.selectedCompany || '',
      },
    });
  }

  protected async head(path: string, query: object = {}) {
    return this.httpClient.head(path, {params: query});
  }

  protected async get(path: string, query: object = {}) {
    return this.httpClient.get(path, {params: query});
  }

  protected async post(path: string, payload: object = {}) {
    return this.httpClient.post(path, payload);
  }

  protected async put(path: string, payload: object = {}) {
    return this.httpClient.put(path, payload);
  }

  protected async delete(path: string, query: object = {}) {
    return this.httpClient.delete(path, {params: query});
  }
}
