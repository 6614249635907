import {PipelineSchedule} from '@features/workspaces/pipeline-schedule.enum';
import React from 'react';
import * as Styled from './PipelineScheduleBadge.styles';

type PipelineScheduleBadgeProps = {
  value: PipelineSchedule;
};

const scheduleMap = {
  [PipelineSchedule.HOURLY]: {
    title: 'hourly',
  },
  [PipelineSchedule.DAILY]: {
    title: 'daily',
  },
  [PipelineSchedule.WEEKLY]: {
    title: 'weekly',
  },
  [PipelineSchedule.MONTHLY]: {
    title: 'monthly',
  },
};

const PipelineScheduleBadge: React.VFC<PipelineScheduleBadgeProps> = ({
  value,
}) => {
  return <Styled.Wrapper>{scheduleMap[value].title}</Styled.Wrapper>;
};

export default PipelineScheduleBadge;
