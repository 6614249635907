import {darken, desaturate, lighten, rgba} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px 20px 10px;
  color: ${({theme}) => darken(0.35, theme.colors.orange)};
  background-color: ${({theme}) => lighten(0.37, theme.colors.orange)};
  border: 1px solid ${({theme}) => lighten(0.33, theme.colors.orange)};
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  font-size: 14px;

  > .icon {
    margin-right: 5px;
    font-size: 1.2em;
  }
`;

export const Details = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  margin-top: 5px;
  margin-left: -4px;
  padding-top: 5px;
  &.expanded > .title > .icon {
    transform: rotate(0);
  }
  & > .title:hover {
    color: ${({theme}) => desaturate(0.3, rgba(theme.colors.danger, 0.6))};
  }
  > .title {
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    color: ${({theme}) => darken(0.35, theme.colors.orange)};
    cursor: pointer;
    border-radius: 3px;
    transition: 0.2s;

    > .icon {
      margin-right: 3px;
      font-size: 1.4em;
      transform: rotate(-90deg);
      transition: 0.2s;
    }
  }
  .content {
    padding: 10px;
    border: 1px solid ${({theme}) => lighten(0.33, theme.colors.orange)};
    border-radius: 3px;
    background-color: #fff;
    margin-top: 5px;
    color: #000;
    font-size: 15px;
    line-height: 1.3;
  }
`;
