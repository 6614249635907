import {darken} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 3px;
  /* max-width: 200px; */
  background-color: #fff;
`;

export const Tabs = styled.ul`
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0;
  list-style: none;
  padding: 0;
`;

export const Tab = styled.li`
  flex-grow: 1;
  border-bottom: 3px solid ${({theme}) => theme.colors.borderColor};
  padding: 10px 20px;
  font-weight: 700;
  /* font-size: 15px; */
  /* text-align: center; */
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGray};
  }

  &.active {
    color: ${({theme}) => darken(0.1, theme.colors.primary)};
    border-color: ${({theme}) => theme.colors.primary};
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 2px 20px;
  margin: 0;
  overflow-y: auto;
  max-height: 200px;
`;

export const ListItem = styled.li`
  padding: 8px 0;
  /* font-size: 15px; */
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
  }

  & + & {
    border-top: 1px solid ${({theme}) => theme.colors.borderColor};
  }
`;
