import Button from '@components/Button';
import React, {useCallback, useMemo, useState} from 'react';
import {StepProps} from './Step';
import * as Styled from './Steps.styles';

type StepsProps = {
  children: React.ReactElement<StepProps> | React.ReactElement<StepProps>[];
  submitActionEnabled?: boolean;
  submitAction?: () => Promise<any>;
  submitActionTitle?: string;
  variant?: 'defaut' | 'compact';
  alignment?: 'defaut' | 'left' | 'right';
};

const Steps: React.FC<StepsProps> = ({
  children,
  submitAction,
  submitActionTitle = 'Submit',
  submitActionEnabled = false,
  variant = 'defaut',
  alignment = 'defaut',
}) => {
  const [current, setCurrent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNextActionLoading, setIsNextActionLoading] = useState(false);
  const tabs = React.Children.map<
    StepProps | null,
    React.ReactElement<StepProps>
  >(children, (child) => {
    if (child) {
      return {
        title: child.props.title,
        isDisabled: child.props.isDisabled,
        nextAction: child.props.nextAction,
        nextActionTitle: child.props.nextActionTitle || 'Next',
        nextActionEnabled:
          typeof child.props.nextActionEnabled === 'undefined'
            ? true
            : child.props.nextActionEnabled,
      };
    }
    return null;
  });

  const isLastStep = useMemo(() => {
    return current === tabs.length - 1;
  }, [current, tabs.length]);

  const handleStepClick = useCallback(
    (key) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (tabs[key] && !tabs[key].isDisabled) {
        setCurrent(key);
      }
    },
    [tabs]
  );

  const handleMoveNext = useCallback(async () => {
    if (isLastStep) {
      return;
    }
    const currentTabNextAction = tabs[current]?.nextAction;
    if (
      typeof currentTabNextAction == 'function' &&
      tabs[current]?.nextActionEnabled
    ) {
      try {
        setIsNextActionLoading(true);
        await currentTabNextAction();
        setIsNextActionLoading(false);
        setCurrent(current + 1);
      } catch (e) {
        setIsNextActionLoading(false);
        return;
      }
    } else {
      setCurrent(current + 1);
    }
  }, [current, isLastStep, tabs]);

  const handleMovePrevious = useCallback(() => {
    const nextIndex = current > 0 ? current - 1 : current;
    if (!tabs[nextIndex].isDisabled) {
      setCurrent(nextIndex);
    }
  }, [current, tabs]);

  const handleSubmit = useCallback(() => {
    setIsSubmitting(true);
    console.log(submitAction);
    if (submitAction) {
      console.log(submitAction);
      return submitAction()
        .then(() => {
          setIsSubmitting(false);
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    }
  }, [submitAction]);

  return (
    <Styled.Wrapper
      className={[
        `steps-variant-${variant}`,
        `steps-alignment-${alignment}`,
      ].join(' ')}
    >
      {tabs.length > 1 && (
        <Styled.StepList>
          {tabs.map((tab, key) => (
            <Styled.StepItem
              className={[
                current === key ? 'step-active' : '',
                tab.isDisabled ? 'step-disabled' : '',
              ].join(' ')}
              onClick={handleStepClick(key)}
              key={key}
            >
              <Styled.Index>{key + 1}</Styled.Index>
              {tab.title}
            </Styled.StepItem>
          ))}
        </Styled.StepList>
      )}
      <Styled.CurrentStep>
        {React.Children.toArray(children)[current]}
      </Styled.CurrentStep>
      <Styled.Navigation>
        {current < tabs.length - 1 && (
          <Styled.NavigationButtonWrapper>
            <Button
              variant="secondary"
              fullWidth
              size="large"
              isLoading={isNextActionLoading}
              disabled={!tabs[current].nextActionEnabled}
              onClick={handleMoveNext}
            >
              {tabs[current].nextActionTitle}
            </Button>
          </Styled.NavigationButtonWrapper>
        )}
        {isLastStep && tabs.length > 1 && (
          <Styled.NavigationButtonWrapper>
            <Button
              variant="secondary"
              fullWidth
              size="large"
              disabled={tabs[current > 0 ? current - 1 : current].isDisabled}
              onClick={handleMovePrevious}
            >
              Previous
            </Button>
          </Styled.NavigationButtonWrapper>
        )}
        {submitAction && isLastStep ? (
          <Styled.NavigationButtonWrapper>
            <Button
              variant="primary"
              size="large"
              fullWidth
              disabled={!submitActionEnabled}
              isLoading={isSubmitting}
              onClick={handleSubmit}
            >
              {submitActionTitle}
            </Button>
          </Styled.NavigationButtonWrapper>
        ) : null}
      </Styled.Navigation>
    </Styled.Wrapper>
  );
};

export default Steps;
