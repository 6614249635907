import React from 'react';
import {UserModel} from '@features/users/user.model';
import UserCard from '@features/users/components/UserCard';
import * as Styled from './UserList.styles';

type UserListProps = {
  users: UserModel[];
};

const UserList: React.FC<UserListProps> = ({users}) => {
  return (
    <Styled.Wrapper>
      <Styled.List>
        {users.map((user, key) => (
          <Styled.ListItem key={key}>
            <UserCard user={user} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.Wrapper>
  );
};

export default UserList;
