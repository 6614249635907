import React from 'react';
import * as Styled from './Header.styles';
import HeaderUser from './HeaderUser';
import Container from '../Container';
import UserCompaniesDropdown from '@features/auth/components/UserCompaniesDropdown';

interface HeaderProps {
  simple?: boolean;
  onLogout: any;
  showCompanySelect?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  simple = false,
  onLogout,
  showCompanySelect = false,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.Inner className={[simple ? 'header-simple' : ''].join(' ')}>
        <Styled.Logo>singerly</Styled.Logo>
        {showCompanySelect && (
          <Styled.HeaderUserCompanies>
            <UserCompaniesDropdown />
          </Styled.HeaderUserCompanies>
        )}
        {!simple && <HeaderUser />}
        {simple && (
          <Styled.LogoutLink onClick={onLogout}>
            <i className="icon icon-logout" /> Logout
          </Styled.LogoutLink>
        )}
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

Header.propTypes = {};

export default Header;
