import styled from 'styled-components';
import {darken} from 'polished';

export const Wrapper = styled.div`
  font-size: 0;
  input[type='checkbox'] {
    position: absolute;
    left: -10000px;
    z-index: -1;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
`;

export const Label = styled.label`
  display: inline-block;
  cursor: pointer;
  margin: 0;
  line-height: 1;

  > span {
    font-size: 16px;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 2px solid ${({theme}) => darken(0.1, theme.colors.borderColor)};
    border-radius: 2px;
    background-color: #fff;
    background-position: center 0;
    transition: 0.2s;
  }

  input[type='checkbox']:checked + &:before {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 11.17 1.83 7 .41 8.41 6 14 18 2 16.59.59 6 11.17Z' fill='%23fff'/%3E%3C/svg%3E");
    background-color: ${({theme}) => theme.colors.primary};
    border-color: ${({theme}) => theme.colors.primary};
    background-size: 86%;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover input {
    color: ${({theme}) => theme.colors.primary};
  }

  &:hover:before {
    border-color: ${({theme}) => darken(0.4, theme.colors.borderColor)};
    color: ${({theme}) => theme.colors.primary};
  }
`;
