import Dropdown from '@components/Dropdown';
import {PlanModel} from '@features/plans/plan.model';
import React, {useMemo} from 'react';
import PlanDetails from '../PlanDetails';
import * as Styled from './PlanSelectorDropdown.styles';

type PlanSelectorDropdownProps = {
  plans?: PlanModel[];
  value: string;
  onChange: (planId: string) => any;
};

const PlanSelectorDropdown: React.VFC<PlanSelectorDropdownProps> = ({
  onChange,
  value,
  plans = [],
}) => {
  const plansMappedById = useMemo(() => {
    return plans.reduce((prev, plan) => {
      prev[plan.id] = plan;
      return prev;
    }, {} as {[key: string]: PlanModel});
  }, [plans]);

  return (
    <Styled.Wrapper>
      <Dropdown
        label="Select plan"
        options={plans.map((plan) => ({title: plan.name, value: plan.id}))}
        renderOption={(option) => (
          <PlanDetails plan={plansMappedById[option.value]} />
        )}
        onChange={onChange}
        value={value}
      />
    </Styled.Wrapper>
  );
};

export default PlanSelectorDropdown;
