import TextField from '@components/TextField';
import ToggleField from '@components/ToggleField';
import React, {useCallback, useMemo} from 'react';
import * as Styled from './OptionsEditor.styles';

type OptionConfig = {
  label: string;
  value: string;
  isDefault?: boolean;
};

export type OptionsEditorProps = {
  value?: OptionConfig[];
  required?: boolean;
  name?: string;
  id?: string;
  label?: string;
  onChange: (e: {target: {value: OptionConfig[]; name?: string}}) => void;
};

const OptionsEditor: React.VFC<OptionsEditorProps> = ({
  value = [],
  name = '',
  onChange,
  label,
  required = false,
  ...props
}) => {
  const valueTransformed: OptionConfig[] = useMemo(() => {
    if (!value.length) {
      return [{label: '', value: '', isDefault: false}];
    }
    return value;
  }, [value]);

  const handleKeyChange = useCallback(
    (index: number) => (e: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {
            label: e.target.value,
            value: item.value,
            isDefault: item.isDefault,
          };
        }
        return item;
      });
      if (index === valueTransformed.length - 1) {
        valueMapped = [
          ...valueMapped,
          {label: '', value: '', isDefault: false},
        ];
      }
      if (!e.target.value && index < valueTransformed.length - 1) {
        valueMapped = valueMapped.filter((item, _index) => _index !== index);
      }
      onChange({target: {value: valueMapped, name}});
    },
    [name, onChange, valueTransformed]
  );

  const handleValueChange = useCallback(
    (index: number) => ({target}: {target: {value: string}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {
            label: item.label,
            value: target.value,
            isDefault: item.isDefault,
          };
        }
        return item;
      });
      if (index === valueTransformed.length - 1) {
        valueMapped = [
          ...valueMapped,
          {label: '', value: '', isDefault: false},
        ];
      }
      onChange({target: {value: valueMapped, name}});
    },
    [name, onChange, valueTransformed]
  );

  const handleDefaultChange = useCallback(
    (index: number) => ({target}: {target: {value: boolean}}) => {
      let valueMapped = valueTransformed.map((item, _index) => {
        if (_index === index) {
          return {
            label: item.label,
            value: item.value,
            isDefault: target.value,
          };
        }
        if (target.value && _index !== index) {
          return {
            ...item,
            isDefault: false,
          };
        }
        return item;
      });
      onChange({target: {value: valueMapped, name}});
    },
    [name, onChange, valueTransformed]
  );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || name}>
          {label}
          {required && <span className="required">*</span>}
        </Styled.Label>
      )}
      <Styled.ValuesTable>
        <Styled.ValuesHeader>
          <th>Label</th>
          <th>Value</th>
          <th>Is Default</th>
        </Styled.ValuesHeader>
        <tbody>
          {valueTransformed.map((valueObject, index) => (
            <Styled.ValuesRow key={index}>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.label}
                  onChange={handleKeyChange(index)}
                  placeholder="Enter the key"
                />
              </Styled.ValuesCell>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={valueObject.value}
                  onChange={handleValueChange(index)}
                  placeholder="Enter the value or leave it blank"
                />
              </Styled.ValuesCell>
              <Styled.ValuesCell className="options-config-is-default-cell">
                <ToggleField
                  name={`${index}`}
                  value={valueObject.isDefault}
                  onChange={handleDefaultChange(index)}
                />
              </Styled.ValuesCell>
            </Styled.ValuesRow>
          ))}
        </tbody>
      </Styled.ValuesTable>
    </Styled.Wrapper>
  );
};

export default OptionsEditor;
