import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';

import {fetchInvitations} from '@features/invitations/invitations.slice';
import InvitationListDisplay from './InvitationList';
import useRoles from '@features/users/hooks/useRoles';
import {useSelector} from '@app/store';

const connectRedux = connect(null, {fetchInvitations});

type InvitationListProps = {} & ConnectedProps<typeof connectRedux>;

const InvitationList: React.VFC<InvitationListProps> = ({fetchInvitations}) => {
  const {roles, isLoading: isLoadingRoles} = useRoles();
  const invitations = useSelector((state) => state.invitations.data);
  const isLoadingInvitations = useSelector(
    (state) => state.invitations.isLoading
  );

  useEffect(() => {
    fetchInvitations();
  }, [fetchInvitations]);

  return (
    <InvitationListDisplay
      invitations={invitations}
      roles={roles}
      isLoading={isLoadingRoles || isLoadingInvitations}
    />
  );
};

export default connectRedux(InvitationList);
