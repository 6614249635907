import styled from 'styled-components';

export const Wrapper = styled.div``;

export const LogsWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const LogEvent = styled.div`
  display: flex;
  align-items: stretch;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  margin-bottom: -1px;
`;

export const LogEventTimestamp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 16px;
  background-color: ${({theme}) => theme.colors.lightGray};
  flex-basis: 8em;
  min-width: 8em;
  flex-grow: 0;
  font-size: 14px;
  color: ${({theme}) => theme.colors.mediumGray};
  > .day {
    display: block;
  }
  > .time {
    display: block;
    font-weight: 700;
    color: ${({theme}) => theme.colors.text};
  }
`;

export const LogEventMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 16px;
  font-size: 15px;
  flex-grow: 1;
`;

export const LogEventMessageSpan = styled.span<{css: string}>`
  ${(props) => props.css}
`;
