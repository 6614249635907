import Button from '@components/Button';
import Dropdown from '@components/Dropdown';
import {DropdownOption} from '@components/Dropdown/Dropdown';
import FormGroup from '@components/FormGroup';
import Modal from '@components/Modal';
import {UserModel} from '@features/users/user.model';
import React, {useCallback, useMemo, useState} from 'react';

type AddUserToWorkspaceModalProps = {
  isOpen?: boolean;
  onSubmit: (value: string) => Promise<any>;
  isLoading?: boolean;
  onClose: any;
  users?: UserModel[];
};

const AddUserToWorkspaceModal: React.FC<AddUserToWorkspaceModalProps> = ({
  isOpen,
  isLoading = false,
  onClose,
  onSubmit,
  users = [],
}) => {
  const [selectedUserId, setSelectedUserId] = useState('');

  const userDropdownOptions = useMemo<DropdownOption[]>(() => {
    return users.map((user) => ({
      title: `${user.name} (${user.email})`,
      value: user.id,
    }));
  }, [users]);

  const handleSubmit = useCallback(() => {
    return onSubmit(selectedUserId);
  }, [onSubmit, selectedUserId]);

  return (
    <Modal title="Add user to project" isOpen={isOpen} onClose={onClose}>
      <FormGroup>
        <Dropdown
          options={userDropdownOptions}
          label="Select user"
          value={selectedUserId}
          onChange={setSelectedUserId}
        />
      </FormGroup>
      <FormGroup>
        <Button
          variant="primary"
          size="large"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!selectedUserId}
        >
          Submit
        </Button>
      </FormGroup>
    </Modal>
  );
};

export default AddUserToWorkspaceModal;
