import styled from 'styled-components';
import {darken} from 'polished';
import {DefaultToastContainer} from 'react-toast-notifications';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: relative;
  width: auto;
  max-width: 30em;
  padding: 12px 20px;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  transition: 0.2s;

  &.toast-transition-entering {
    transform: scaleX(0.8) translateX(70px);
    opacity: 0;
  }

  &.toast-transition-entered {
    transform: scaleX(1) translateY(0);
    opacity: 1;
  }

  &.toast-transition-exiting {
    transform: scaleX(0.75) translateX(70px);
    opacity: 0;
  }

  &.toast-transition-exited {
    transform: scaleX(0.75) translateX(70px);
    opacity: 0;
  }

  > .icon {
    display: inline-block;
    font-size: 24px;
    margin-right: 10px;
  }

  &.toast-appearance-success {
    .icon {
      color: ${({theme}) => theme.colors.green};
    }
    &:before {
      background-color: ${({theme}) => theme.colors.green};
    }
  }

  &.toast-appearance-error {
    .icon {
      color: ${({theme}) => theme.colors.danger};
    }
    &:before {
      background-color: ${({theme}) => theme.colors.danger};
    }
  }

  &.toast-appearance-info {
    .icon {
      color: ${({theme}) => theme.colors.primary};
    }
    &:before {
      background-color: ${({theme}) => theme.colors.primary};
    }
  }

  &.toast-appearance-warning {
    .icon {
      color: ${({theme}) => theme.colors.orange};
    }
    &:before {
      background-color: ${({theme}) => theme.colors.orange};
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: ${({theme}) => theme.colors.primary};
  }
`;

export const Message = styled.div`
  margin-right: 20px;
`;

export const DismissButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${({theme}) => darken(0.05, theme.colors.lightGray)};
  color: ${({theme}) => darken(0.05, theme.colors.mediumGray)};
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 18px;
  border-radius: 30px;
  margin-left: auto;
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.lightPrimary};
  }
`;

export const CustomToastContainer = styled(DefaultToastContainer)`
  /* top: 130px !important; */
  top: 30px !important;
  bottom: 30px !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  padding: 0 30px !important;
`;
