import ArrayEditor from '@components/ArrayEditor';
import Dropdown from '@components/Dropdown';
import Grid from '@components/Grid';
import GridItem from '@components/Grid/GridItem';
import InlineEditor from '@components/InlineEditor';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import ConnectorConfigurator from '@features/workspaces/components/ConnectorConfigurator';
import {ConnectorConfiguration} from '@features/workspaces/components/ConnectorConfigurator/ConnectorConfigurator';
import PipelineEnabledToggler from '@features/workspaces/components/PipelineEnabledToggler';
import PipelineState from '@features/workspaces/components/PipelineState';
import {PipelineSchedule} from '@features/workspaces/pipeline-schedule.enum';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import React from 'react';
import {
  UpdatePipelineKeys,
  UpdatePipelineValues,
} from '../PipelineDetailsScreen/PipelineDetailsScreen.container';
import PipelineScheduleBadge from '../PipelineScheduleBadge';
import ConnectorValue from './ConnectorValue/ConnectorValue';
import * as Styled from './PipelineDetails.styles';

type PipelineDetailsProps = {
  workspaceId: string;
  pipeline: PipelineModel;
  onSave: (
    property: UpdatePipelineKeys
  ) => (value: UpdatePipelineValues) => Promise<any>;
};

const PipelineDetails: React.VFC<PipelineDetailsProps> = ({
  pipeline,
  workspaceId,
  onSave,
}) => {
  return (
    <Styled.Wrapper>
      <Grid gutterWidth={40}>
        <GridItem>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>Is Enabled</Styled.DetailsLabel>
            <PipelineEnabledToggler
              workspaceId={workspaceId}
              pipeline={pipeline}
              onToggle={() => onSave('isEnabled')(!pipeline.isEnabled)}
            />
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>Status (last execution)</Styled.DetailsLabel>
            <Styled.DetailsValue>
              <PipelineState pipeline={pipeline} />
            </Styled.DetailsValue>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>Schedule</Styled.DetailsLabel>
            <Styled.DetailsValue>
              <InlineEditor
                renderValue={(value) => <PipelineScheduleBadge value={value} />}
                value={pipeline.schedule}
                onSave={onSave('schedule')}
              >
                {({value, onChange}) => (
                  <Dropdown
                    value={value}
                    onChange={onChange}
                    variant="compact"
                    options={[
                      {title: 'Hourly', value: PipelineSchedule.HOURLY},
                      {title: 'Daily', value: PipelineSchedule.DAILY},
                      {title: 'Weekly', value: PipelineSchedule.WEEKLY},
                      {title: 'Monthly', value: PipelineSchedule.MONTHLY},
                    ]}
                  />
                )}
              </InlineEditor>
            </Styled.DetailsValue>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>
              Send notification emails to
            </Styled.DetailsLabel>
            <InlineEditor
              renderValue={(value) => value.join(', ') || 'Not specified'}
              value={pipeline.emailList?.split(',') || []}
              onSave={(value) =>
                onSave('emailList')(value.filter((item) => !!item))
              }
            >
              {({value, onChange}) => (
                <ArrayEditor
                  value={value || []}
                  onChange={({target}) => onChange(target.value)}
                />
              )}
            </InlineEditor>
          </Styled.DetailsRow>
        </GridItem>
        <GridItem>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>Source connector</Styled.DetailsLabel>
            <InlineEditor<{connectorId?: string; config?: any}>
              renderValue={(value) => (
                <ConnectorValue connectorId={value.connectorId} />
              )}
              onSave={(value) =>
                Promise.all([
                  onSave('sourceId')(value.connectorId),
                  onSave('sourceConfig')(value.config),
                ])
              }
              value={{
                connectorId: pipeline.source?.id || '',
                config: pipeline.sourceConfig || {},
              }}
            >
              {({value, onChange}) => (
                <ConnectorConfigurator
                  type={ConnectorType.SOURCE}
                  value={value}
                  showFieldDescription={false}
                  onChange={onChange}
                />
              )}
            </InlineEditor>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.DetailsLabel>Destination connector</Styled.DetailsLabel>
            <InlineEditor<{connectorId?: string; config?: any}>
              renderValue={(value) => (
                <ConnectorValue connectorId={value.connectorId} />
              )}
              onSave={(value) =>
                Promise.all([
                  onSave('sourceId')(value.connectorId),
                  onSave('sourceConfig')(value.config),
                ])
              }
              value={{
                connectorId: pipeline.destination?.id || '',
                config: pipeline.destinationConfig || {},
              }}
            >
              {({value, onChange}) => (
                <ConnectorConfigurator
                  type={ConnectorType.DESTINATION}
                  value={value}
                  showFieldDescription={false}
                  onChange={onChange}
                />
              )}
            </InlineEditor>
          </Styled.DetailsRow>
        </GridItem>
      </Grid>
    </Styled.Wrapper>
  );
};

export default PipelineDetails;
