import authReducer from '@features/auth/auth.slice';
import usersReducer from '@features/users/users.slice';
import workspacesReducer from '@features/workspaces/workspace.slice';
import companiesReducer from '@features/companies/companies.slice';
import plansReducer from '@features/plans/plan.slice';
import connectorsReducer from '@features/connectors/connectors.slice';
import invitationsReducer from '@features/invitations/invitations.slice';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
  auth: authReducer,
  workspaces: workspacesReducer,
  companies: companiesReducer,
  users: usersReducer,
  plans: plansReducer,
  connectors: connectorsReducer,
  invitations: invitationsReducer,
});

export default rootReducer;
