import React, {useCallback} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {updatePlan} from '@features/plans/plan.slice';
import EditPlanPageDisplay from './EditPlanPage';
import {useSelector} from '@app/store';
import {useHistory, useRouteMatch} from 'react-router';
import {unwrapResult} from '@reduxjs/toolkit';
import {useToasts} from 'react-toast-notifications';

const connectRedux = connect(null, {updatePlan});

type EditPlanPageProps = {} & ConnectedProps<typeof connectRedux>;

const EditPlanPage = ({updatePlan}: EditPlanPageProps) => {
  const match = useRouteMatch<{id: string}>();
  const history = useHistory();
  const {addToast} = useToasts();
  const plan = useSelector((state) =>
    state.plans.data.find((plan) => plan.id === match.params.id)
  );
  const isLoading = useSelector((state) => state.plans.isLoading);

  const handleSubmit = useCallback(
    (values) => {
      return updatePlan({id: match.params.id, data: values})
        .then(unwrapResult)
        .then(() => {
          history.push('/plans');
          addToast('Plan updated successfully', {
            autoDismiss: true,
            appearance: 'success',
          });
        });
    },
    [addToast, history, match.params.id, updatePlan]
  );

  return (
    <EditPlanPageDisplay
      plan={plan}
      isLoading={isLoading}
      onSubmit={handleSubmit}
    />
  );
};

export default connectRedux(EditPlanPage);
