import Button from '@components/Button';
import Table from '@components/Table';
import Toolbar from '@components/Toolbar';
import ToolbarAction from '@components/Toolbar/ToolbarAction';
import HasPermission from '@features/auth/components/HasPermission';
import {InstanceType} from '@features/workspaces/instance-type.enum';
import instanceTypeMap from '@features/workspaces/instance-type.map';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import useSelectable from '@hooks/useSelectable';
import ConfirmationModal from '@modals/ConfirmationModal';
import React, {useCallback, useState} from 'react';
import PipelineEnabledToggler from '../PipelineEnabledToggler';
import PipelineState from '../PipelineState';
import * as Styled from './PipelineList.styles';

type PipelineListProps = {
  workspaceId: string;
  onClick?: (pipeline: PipelineModel) => void;
  pipelines: PipelineModel[];
  isLoading?: boolean;
  onEnabledToggle: (pipeline: PipelineModel) => Promise<any>;
  onRunPipeline: (pipeline: PipelineModel) => Promise<any>;
  onDelete: (pipelineIds: string[]) => Promise<any>;
  onAddPipeline?: () => Promise<any>;
};

const PipelineList: React.VFC<PipelineListProps> = ({
  workspaceId,
  onClick,
  pipelines,
  isLoading = false,
  onEnabledToggle,
  onRunPipeline,
  onDelete,
  onAddPipeline,
}) => {
  const {onBulkSelect, onSelect, selected, selectedIds, clearSelection} =
    useSelectable();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);

  const handleClick = useCallback(
    (pipeline) => {
      if (typeof onClick !== 'undefined') {
        onClick(pipeline);
      }
    },
    [onClick]
  );

  const handleDelete = useCallback(async () => {
    await onDelete(selectedIds);
    clearSelection();
    setIsDeleteConfirmationModalOpen(false);
  }, [clearSelection, onDelete, selectedIds]);

  const handleShowDeleteConfirmationModal = useCallback(async () => {
    setIsDeleteConfirmationModalOpen(true);
  }, []);

  const handleCloseDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalOpen(false);
  }, []);

  return (
    <Styled.Wrapper>
      <ConfirmationModal
        message="Are you sure you want to delete selected pipelines? This action cannot be undone."
        onDismiss={handleCloseDeleteConfirmationModal}
        onConfirm={handleDelete}
        isOpen={isDeleteConfirmationModalOpen}
      />
      <Toolbar>
        <HasPermission permissions="create:pipelines">
          <ToolbarAction
            title="Add Pipeline"
            icon="add_circle1"
            action={onAddPipeline || (async () => null)}
            variant="primary"
            isDisabled={!onAddPipeline}
          />
        </HasPermission>
        <HasPermission permissions="delete:pipelines">
          <ToolbarAction
            title="Delete"
            icon="delete"
            action={handleShowDeleteConfirmationModal}
            variant="danger"
            isDisabled={!selectedIds.length}
          />
        </HasPermission>
      </Toolbar>
      <Table
        isLoading={isLoading}
        data={pipelines}
        onRowClick={handleClick}
        emptyMessage="No pipelines to display. Go ahead and create a new one!"
        onBulkSelect={onBulkSelect}
        onSelect={onSelect}
        selected={selected}
        columnDefinitions={[
          {
            title: '',
            compact: true,
            accessor: (row) => (
              <PipelineEnabledToggler
                workspaceId={workspaceId}
                onToggle={() => onEnabledToggle(row)}
                pipeline={row}
              />
            ),
          },
          {title: 'Name', accessor: (row) => <strong>{row.name}</strong>},
          {title: 'Schedule', accessor: 'schedule', compact: true},
          {
            title: 'Source',
            compact: true,
            accessor: (row) =>
              row.source ? row.source.name : 'Not Configured',
          },
          {
            title: 'Destination',
            compact: true,
            accessor: (row) =>
              row.destination ? row.destination.name : 'Not Configured',
          },
          {
            title: 'Size',
            compact: true,
            accessor: (row) => (
              <Styled.PipelineInstanceType>
                <span className="title">
                  {
                    instanceTypeMap[row.instanceType || InstanceType.SMALL]
                      .title
                  }
                </span>
                <span className="spec">
                  {`${
                    instanceTypeMap[row.instanceType || InstanceType.SMALL].cpu
                  }
                CPU/${
                  instanceTypeMap[row.instanceType || InstanceType.SMALL].ram
                }Gb RAM`}
                </span>
              </Styled.PipelineInstanceType>
            ),
          },
          {
            title: 'State',
            compact: true,
            accessor: (row) => <PipelineState pipeline={row} />,
          },
          {
            title: 'Actions',
            compact: true,
            accessor: (row) => (
              <Button
                onClick={() => onRunPipeline(row)}
                loadingMessage="Run"
                icon="play_arrow"
                variant="secondary"
                size="x-small"
              >
                Run
              </Button>
            ),
          },
        ]}
      />
    </Styled.Wrapper>
  );
};

export default PipelineList;
