import FormGroup from '@components/FormGroup';
import TextField from '@components/TextField';
import {AzureServiceAccountDto} from '@features/workspaces/dto/azure-service-account.dto';
import {getIn} from 'formik';
import React, {ChangeEvent} from 'react';
import * as Yup from 'yup';

import * as Styled from './AzureServiceAccountForm.styles';

type AzureServiceAccountFormProps = {
  onChange: (e: {target: {name: string; value: any}}) => void;
  onBlur: any;
  value?: AzureServiceAccountDto;
  errors?: object;
  touched?: object;
  fieldName: string;
};

export const azureAccountValidationSchema = Yup.object().shape({
  subscription_id: Yup.string().required('Field is required'),
  client_id: Yup.string().required('Field is required'),
  client_secret: Yup.string().required('Field is required'),
  tenant_id: Yup.string().required('Field is required'),
});

const AzureServiceAccountForm: React.VFC<AzureServiceAccountFormProps> = ({
  errors = {},
  fieldName,
  onBlur,
  onChange,
  value = {
    subscription_id: '',
    client_id: '',
    client_secret: '',
    tenant_id: '',
  },
  touched,
}) => {
  return (
    <Styled.Wrapper>
      <Styled.Title>Azure Service Principal Account Setup</Styled.Title>
      <Styled.HelpLink>
        <a
          href="https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal"
          target="_black"
          rel="noreferrer noopener"
        >
          How to create a Service Principal Account in Azure
        </a>
      </Styled.HelpLink>
      <FormGroup>
        <TextField
          label="Subscription ID"
          required
          name={`${fieldName}.subscription_id`}
          value={value.subscription_id}
          onBlur={onBlur}
          onChange={onChange}
          error={
            getIn(errors, `${fieldName}.subscription_id`) &&
            getIn(touched, `${fieldName}.subscription_id`)
              ? getIn(errors, `${fieldName}.subscription_id`)
              : null
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="App ID"
          required
          name={`${fieldName}.client_id`}
          value={value.client_id}
          onBlur={onBlur}
          onChange={onChange}
          error={
            getIn(errors, `${fieldName}.client_id`) &&
            getIn(touched, `${fieldName}.client_id`)
              ? getIn(errors, `${fieldName}.client_id`)
              : null
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="Client Secret"
          required
          type="password"
          name={`${fieldName}.client_secret`}
          value={value.client_secret}
          onBlur={onBlur}
          onChange={onChange}
          error={
            getIn(errors, `${fieldName}.client_secret`) &&
            getIn(touched, `${fieldName}.client_secret`)
              ? getIn(errors, `${fieldName}.client_secret`)
              : null
          }
        />
      </FormGroup>
      <FormGroup>
        <TextField
          label="Tenant ID"
          required
          name={`${fieldName}.tenant_id`}
          value={value.tenant_id}
          onBlur={onBlur}
          onChange={onChange}
          error={
            getIn(errors, `${fieldName}.tenant_id`) &&
            getIn(touched, `${fieldName}.tenant_id`)
              ? getIn(errors, `${fieldName}.tenant_id`)
              : null
          }
        />
      </FormGroup>
    </Styled.Wrapper>
  );
};

export default AzureServiceAccountForm;
