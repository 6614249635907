import Button from '@components/Button';
import React, {useCallback, useState} from 'react';
import * as Styled from './InlineEditor.styles';

type InlineEditorProps<T> = {
  onSave: (value: T) => Promise<any>;
  renderValue?: (value: T) => React.ReactNode;
  children: (props: {value: T; onChange: (value: T) => void}) => JSX.Element;
  value: T;
};

function InlineEditor<T>({
  onSave,
  value,
  renderValue,
  children,
}: InlineEditorProps<T>) {
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [editValue, setEditValue] = useState<T>(value);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      await onSave(editValue);
      setIsLoading(false);
      setMode('view');
    } catch (e) {
      setIsLoading(false);
    }
  }, [editValue, onSave]);

  const handleModeChange = useCallback((mode) => {
    setMode(mode);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setMode('view');
    setEditValue(value);
  }, [value]);

  const handleChange = useCallback((value: T) => {
    setEditValue(value);
  }, []);

  return (
    <Styled.Wrapper>
      <Styled.Inner>
        {mode === 'edit' && (
          <Styled.EditorWrapper>
            {children({value: editValue, onChange: handleChange})}
          </Styled.EditorWrapper>
        )}
        {mode === 'view' && (
          <>
            <Styled.DisplayValue>
              {renderValue ? renderValue(value) : value}
            </Styled.DisplayValue>
            <Styled.EditAction onClick={() => handleModeChange('edit')}>
              <i className="icon icon-pencil" />
            </Styled.EditAction>
          </>
        )}
      </Styled.Inner>
      {mode === 'edit' && (
        <Styled.ActionBar>
          <Button
            disabled={!editValue || editValue === value}
            size="small"
            onClick={handleSave}
            isLoading={isLoading}
          >
            Save
          </Button>
          <Button size="small" variant="danger" onClick={handleCancelEdit}>
            Cancel
          </Button>
        </Styled.ActionBar>
      )}
    </Styled.Wrapper>
  );
}

export default InlineEditor;
