import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {createPlan} from '@features/plans/plan.slice';
import CreatePlanPageDisplay from './CreatePlanPage';
import {unwrapResult} from '@reduxjs/toolkit';
import {useHistory} from 'react-router';
import {useToasts} from 'react-toast-notifications';

const connectRedux = connect(null, {createPlan});

type CreatePlanPageProps = {} & ConnectedProps<typeof connectRedux>;

const CreatePlanPage = ({createPlan}: CreatePlanPageProps) => {
  const history = useHistory();
  const {addToast} = useToasts();

  const handleSubmit = (values: any) => {
    return createPlan({
      name: values.name,
      maxProjectCount: values.maxProjectCount,
      price: values.price,
      trialPeriodDays: values.trialPeriodDays,
      allowedInstanceTypes: [],
    })
      .then(unwrapResult)
      .then(() => {
        history.push('/plans');
        addToast('Plan created successfully', {
          autoDismiss: true,
          appearance: 'success',
        });
      });
  };
  return <CreatePlanPageDisplay onSubmit={handleSubmit} />;
};

export default connectRedux(CreatePlanPage);
