import React from 'react';
import {ConnectorType} from '@features/connectors/connector-type.enum';
import ConnectorSelectorDisplay from './ConnectorSelector';
import {useQuery} from 'react-query';
import {ConnectorsService} from '@features/connectors/connectors.service';

type ConnectorSelectorProps = {
  onChange: (value: string) => void;
  value: string;
  type: ConnectorType;
};

const ConnectorSelector: React.VFC<ConnectorSelectorProps> = ({
  onChange,
  value,
  type,
}) => {
  const {data: connectors} = useQuery({
    queryFn: () => {
      const connectorService = new ConnectorsService();
      return connectorService.getAll({type}).then((response) => response.data);
    },
    queryKey: ['connectors', type],
  });

  return (
    <ConnectorSelectorDisplay
      onChange={onChange}
      value={value}
      options={(connectors || []).map((connector) => ({
        title: connector.name,
        value: connector.id,
        resource: connector,
      }))}
    />
  );
};

export default ConnectorSelector;
