import {darken, lighten} from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ConfigLineWrapper = styled.div`
  /* display: flex; */
  /* margin: 0 -10px; */
  align-items: flex-start;
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  background: #fff;
  & + & {
    margin-top: 10px;
  }
`;

export const ConfigLineFieldWrapper = styled.div`
  & + & {
    margin-top: 16px;
  }

  &.connector-config-line-type {
    flex-basis: 10em;
  }

  &.connector-config-line-value {
    flex-basis: 10em;
  }

  &.connector-config-line-description {
    flex-grow: 8;
  }
`;

export const ConfigLineExpandToggle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  min-width: 1.4em;
  width: 1.4em;
  height: 1.4em;
  border-radius: 50%;
  background: ${({theme}) => darken(0.01, theme.colors.borderColor)};
  color: ${({theme}) => theme.colors.mediumGray};
  line-height: 1;
  cursor: pointer;
  margin-right: 16px;
  transition: 0.2s;

  &:hover {
    background: ${({theme}) => theme.colors.lightPrimary};
    color: ${({theme}) => theme.colors.primary};
  }

  .icon {
    transform: translate(1px, 0) rotate(0);
    font-size: 0.9em;
    width: 1em;
    height: 1em;

    .config-line-expanded & {
      transform: translate(0, 1px) rotate(90deg);
    }
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  left: -0.7em;
  top: 50%;
  padding: 0;
  transform: translateY(-50%) scale(0.8);
  opacity: 0;
  border: none;
  border-radius: 80px;
  width: 1.28em;
  height: 1.26em;
  background-color: ${({theme}) => theme.colors.danger};
  color: #fff;
  font-weight: 700;
  font-size: 1.1em;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => lighten(0.1, theme.colors.danger)};
  }
`;

export const ConfigLineHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 20px;
  border-bottom: 2px solid ${({theme}) => theme.colors.borderColor};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${({theme}) => theme.colors.lightGray};

    ${DeleteButton} {
      display: block;
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
  }
`;

export const ConfigLineBody = styled.div`
  display: none;
  padding: 20px;
  .config-line-expanded & {
    display: block;
  }
`;

export const ConfigLineHeaderInner = styled.div`
  display: flex;
  align-items: center;
`;

export const ConfigLineNameParameter = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-top: -2px;
`;

export const ConfigLineTypeParameter = styled.div`
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  background: ${({theme}) => theme.colors.lightPrimary};
  color: ${({theme}) => theme.colors.primary};
  padding: 2px 8px;
  border-radius: 3px;
  margin-left: 16px;
`;

export const ActionBar = styled.div`
  margin-top: 20px;
`;
