import Loader from '@components/Loader';
import StatusBadge from '@components/StatusBadge';
import Table from '@components/Table';
import instanceTypeList, {
  InstanceTypeDescription,
} from '@features/workspaces/instanceTypeList';
import {InstanceType} from '@features/workspaces/instance-type.enum';
import workspaceStatusMap from '@features/workspaces/workspace-status-map';
import {WorkspaceModel} from '@features/workspaces/workspace.model';
import {parseJSON, format} from 'date-fns';
import React from 'react';
import * as Styled from './CompanyWorkspaces.styles';
import {roundToHundreds} from '@utils/number.utils';

type CompanyWorkspacesProps = {
  workspaces: WorkspaceModel[];
  isLoading?: boolean;
};

type InstanceMap = {[key in InstanceType]: InstanceTypeDescription};

const instanceTypeMap = instanceTypeList.reduce((prev, instanceType) => {
  prev[instanceType.value] = instanceType;
  return prev;
}, {} as InstanceMap);

const CompanyWorkspaces: React.FC<CompanyWorkspacesProps> = ({
  workspaces,
  isLoading,
}) => {
  if (isLoading) {
    return <Loader message="Loading company projects..." />;
  }
  return (
    <Styled.Wrapper>
      <Table
        data={workspaces}
        columnDefinitions={[
          {title: 'Name', accessor: (row) => <strong>{row.name}</strong>},
          {
            title: 'Status',
            accessor: (row) => (
              <StatusBadge statusMap={workspaceStatusMap} value={row.status} />
            ),
          },
          {
            title: 'Created At',
            accessor: (row) => format(parseJSON(row.createdAt), 'MMMM d, yyyy'),
          },
          {
            title: 'Instance Type',
            accessor: (row) =>
              row.infrastructure
                ? instanceTypeMap[row.infrastructure.instanceType].title
                : '-',
          },
          {
            title: 'Prev. month costs',
            accessor: (row) => (
              <strong>
                {row.previousMonthCosts
                  ? `$${roundToHundreds(row.previousMonthCosts)}`
                  : '-'}
              </strong>
            ),
          },
          {
            title: 'Current month costs',
            accessor: (row) => (
              <strong>
                {row.currentMonthCosts
                  ? `$${roundToHundreds(row.currentMonthCosts)}`
                  : '-'}
              </strong>
            ),
          },
        ]}
      />
    </Styled.Wrapper>
  );
};

export default CompanyWorkspaces;
