import {CompaniesService} from '@features/companies/companies.service';
import {useQuery} from 'react-query';

const useManagedCompanies = (parentId: string = '') => {
  const {data, isLoading} = useQuery({
    queryFn: async () => {
      const companiesService = new CompaniesService();
      const response = await companiesService.getManagedCompanies(parentId);
      return response.data;
    },
    queryKey: ['managed-companies', parentId],
    enabled: !!parentId,
  });

  return {
    companies: data,
    isLoading,
  };
};

export default useManagedCompanies;
