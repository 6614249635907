import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {CreateInvitationDto} from '@features/invitations/dto/create-invitation.dto';
import {RoleModel} from '@features/users/role.model';

import * as Styled from './CreateInvitationForm.styles';
import FormGroup from '@components/FormGroup';
import TextField from '@components/TextField';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is required'),
  roleId: Yup.string().required('Please select user role'),
});

type CreateInvitationFormProps = {
  onSubmit: (values: CreateInvitationDto) => Promise<any>;
  roles: RoleModel[];
  initialData?: CreateInvitationDto;
};

const CreateInvitationForm: React.VFC<CreateInvitationFormProps> = ({
  initialData,
  onSubmit,
  roles = [],
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      roleId: '',
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
  return (
    <Styled.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup>
          <TextField
            type="email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label="Which role user should be assigned to?"
            options={roles.map((role) => ({
              title: role.name || '',
              value: role.id,
            }))}
            value={formik.values.roleId}
            onBlur={formik.handleBlur}
            onChange={(value) =>
              formik.handleChange({target: {name: 'roleId', value}})
            }
            error={
              formik.touched.roleId && formik.errors.roleId
                ? formik.errors.roleId
                : ''
            }
          />
        </FormGroup>
        <FormGroup>
          <Button
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            isLoading={formik.isSubmitting}
            size="large"
            onClick={formik.submitForm}
          >
            Invite user
          </Button>
        </FormGroup>
      </form>
    </Styled.Wrapper>
  );
};

export default CreateInvitationForm;
