import ArrayEditor from '@components/ArrayEditor';
import {ArrayEditorProps} from '@components/ArrayEditor/ArrayEditor';
import ObjectEditor from '@components/ObjectEditor';
import {ObjectEditorProps} from '@components/ObjectEditor/ObjectEditor';
import OptionsEditor, {
  OptionsEditorProps,
} from '@components/OptionsEditor/OptionsEditor';
import TextField from '@components/TextField';
import {TextFieldProps} from '@components/TextField/TextField';
import ToggleField from '@components/ToggleField';
import {ToggleFieldProps} from '@components/ToggleField/ToggleField';
import {ConnectorConfigType} from '@features/connectors/connector-config-type.enum';
import React from 'react';
import JSONEditor from '@components/JSONEditor';
import PipelineFileUploadField from '@features/pipelines/components/PipelineFileUploadField';

const configValueEditorMap: {
  [key in ConnectorConfigType]: any;
} = {
  [ConnectorConfigType.JSON]: (props: any) => <JSONEditor {...props} />,
  [ConnectorConfigType.ARRAY]: (props: ArrayEditorProps) => (
    <ArrayEditor
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      label={props.label}
    />
  ),
  [ConnectorConfigType.STRING]: (props: TextFieldProps) => (
    <TextField {...props} />
  ),
  [ConnectorConfigType.FILE_UPLOAD]: (props: any) => (
    <PipelineFileUploadField {...props} />
  ),
  [ConnectorConfigType.OBJECT]: (props: ObjectEditorProps) => (
    <ObjectEditor
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      label={props.label}
    />
  ),
  [ConnectorConfigType.OPTIONS]: (props: OptionsEditorProps) => (
    <OptionsEditor
      onChange={props.onChange}
      value={props.value}
      name={props.name}
      label={props.label}
    />
  ),
  [ConnectorConfigType.EMAIL]: (props: TextFieldProps) => (
    <TextField {...props} type="email" />
  ),
  [ConnectorConfigType.INTEGER]: (props: TextFieldProps) => (
    <TextField {...props} type="number" />
  ),
  [ConnectorConfigType.PASSWORD]: (props: TextFieldProps) => (
    <TextField {...props} type="password" />
  ),
  [ConnectorConfigType.BOOLEAN]: (props: ToggleFieldProps) => (
    <ToggleField {...props} trueValueLabel="True" falseValueLabel="False" />
  ),
};

export default configValueEditorMap;
