import {CompaniesService} from '@features/companies/companies.service';
import {UserModel} from '@features/users/user.model';
import React, {useEffect, useState} from 'react';
import CompanyUsersDisplay from './CompanyUsers';

type CompanyUsersProps = {
  companyId: string;
};

const CompanyUsers = ({companyId}: CompanyUsersProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      setIsLoading(true);
      const companyService = new CompaniesService();
      const response = await companyService.getUsers(companyId);
      setUsers(response.data);
      setIsLoading(false);
    };
    getUsers();
  }, [companyId]);

  return <CompanyUsersDisplay isLoading={isLoading} users={users} />;
};

export default CompanyUsers;
