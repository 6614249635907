/* eslint-disable react-hooks/exhaustive-deps */
import TextField from '@components/TextField';
import React, {useCallback, useMemo} from 'react';
import * as Styled from './ArrayEditor.styles';

export type ArrayEditorProps = {
  value: any[];
  required?: boolean;
  name?: string;
  label?: string;
  id?: string;
  onChange: (e: {target: {value: any; name?: string}}) => void;
};

const ArrayEditor: React.VFC<ArrayEditorProps> = ({
  value = [],
  label,
  required = false,
  onChange,
  ...props
}) => {
  // const [appendRowValue, setAppendRowValue] = useState('');
  const valuesNormalized = useMemo(() => {
    if (!value.length) {
      return [''];
    }
    return value;
  }, [value]);

  const handleChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let _values = valuesNormalized.map((value, _index) => {
        return index === _index ? e.target.value : value;
      });
      if (e.target.value && index === valuesNormalized.length - 1) {
        _values.push('');
      }
      if (!e.target.value && index < valuesNormalized.length - 1) {
        _values = _values.filter((_value, _index) => {
          return _index !== index;
        });
      }
      onChange({target: {name: props.name, value: _values}});
    },
    [onChange, valuesNormalized]
  );

  // const handleAppendRowChange = useCallback(
  //   (e: React.ChangeEvent<HTMLInputElement>) => {
  //     if (e.target.value) {
  //       onChange([...valuesNormalized, e.target.value]);
  //       setAppendRowValue('');
  //     }
  //   },
  //   [onChange, valuesNormalized]
  // );

  return (
    <Styled.Wrapper>
      {label && (
        <Styled.Label htmlFor={props.id || props.name}>
          {label}
          {required && <span className="required">*</span>}
        </Styled.Label>
      )}
      <Styled.ValuesTable>
        <tbody>
          {valuesNormalized.map((value, index) => (
            <Styled.ValuesRow key={index}>
              <Styled.ValuesCell>
                <TextField
                  name={`${index}`}
                  value={value}
                  onChange={handleChange(index)}
                  placeholder="Enter the value"
                />
              </Styled.ValuesCell>
            </Styled.ValuesRow>
          ))}
          {/* <Styled.ValuesRow>
            <Styled.ValuesCell>
              <TextField
                name="append-row-editor"
                value={appendRowValue}
                onChange={handleAppendRowChange}
                placeholder="Start typing to add row"
              />
            </Styled.ValuesCell>
          </Styled.ValuesRow> */}
        </tbody>
      </Styled.ValuesTable>
    </Styled.Wrapper>
  );
};

export default ArrayEditor;
