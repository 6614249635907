import styled from 'styled-components';
import {lighten, rgba} from 'polished';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};
`;

export const FieldWrapper = styled.div``;

export const Input = styled.textarea`
  display: block;
  width: 100%;
  padding: 10px 16px;
  border-radius: 3px;
  border: 2px solid ${({theme}) => theme.input.normal.borderColor};
  box-shadow: 0 0 0 transparent;
  color: ${({theme}) => theme.colors.text};
  outline: none;
  transition: 0.2s;

  &.has-error {
    border-color: ${({theme}) => theme.colors.danger};
  }

  &:hover,
  &:focus {
    border-color: ${({theme}) => lighten(0.1, theme.input.hover.borderColor)};
  }
  &:focus {
    /* box-shadow: 0 0 0 1px ${({theme}) =>
      rgba(theme.input.hover.borderColor, 1)}; */
  }
`;
