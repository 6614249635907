import {ConnectorsService} from '@features/connectors/connectors.service';
import React, {useEffect} from 'react';
import {useQuery} from 'react-query';

import ConnectorConfigurationEditorDisplay from './ConnectorConfigurationEditor';

type ConnectorConfigurationEditorProps = {
  connectorId?: string;
  value?: any;
  onChange: (value: any) => void;
};

const ConnectorConfigurationEditor: React.VFC<
  ConnectorConfigurationEditorProps
> = ({connectorId, value = {}, onChange}) => {
  const {data: connector} = useQuery({
    queryFn: () => {
      const connectorService = new ConnectorsService();
      if (connectorId) {
        return connectorService
          .getById(connectorId)
          .then((response) => response.data);
      }
    },
    queryKey: ['connectors', connectorId],
    enabled: !!connectorId,
  });

  if (!connector) {
    return null;
  }

  return (
    <ConnectorConfigurationEditorDisplay
      connector={connector}
      value={value}
      onChange={onChange}
    />
  );
};

export default ConnectorConfigurationEditor;
