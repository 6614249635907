import styled from 'styled-components';

export const Wrapper = styled.div``;

export const ValuesTable = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  background: #fff;
  width: 100%;
  border: 4px solid ${({theme}) => theme.colors.borderColor};
`;

export const ValuesHeader = styled.thead`
  th {
    background-color: ${({theme}) => theme.colors.borderColor};
    color: ${({theme}) => theme.colors.mediumGray};
    font-weight: 700;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 5px 18px;
  }
`;

export const ValuesRow = styled.tr`
  border-top: 2px solid ${({theme}) => theme.colors.borderColor};
`;

export const ValuesCell = styled.td`
  padding: 0 !important;
  margin-top: -2px;
  & + & {
    border-left: 2px solid ${({theme}) => theme.colors.borderColor};
  }
  input {
    border-color: transparent;
    background: none;
    border-radius: 0;
    padding: 7px 16px;
    &:hover {
      border-color: transparent;
    }
    &:focus {
      border-color: ${({theme}) => theme.colors.primary};
    }
  }
`;

export const Label = styled.label`
  display: block;
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.text};

  > .required {
    display: inline-block;
    color: ${({theme}) => theme.colors.danger};
    margin-left: 5px;
  }
`;
