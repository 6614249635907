import {PipelineService} from '@features/pipelines/pipeline.service';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import {WorkspaceService} from '@features/workspaces/workspace.service';
import Axios from 'axios';
import React, {useCallback} from 'react';
import {useMutation, useQueryClient} from 'react-query';
import PipelineList from './PipelineList';

type PipelineListContainerProps = {
  workspaceId: string;
  onClick?: (pipeline: PipelineModel) => void;
  pipelines: PipelineModel[];
  isLoading?: boolean;
  onAddPipeline?: () => Promise<any>;
};

const PipelineListContainer: React.VFC<PipelineListContainerProps> = ({
  workspaceId,
  onClick,
  pipelines,
  isLoading = false,
  onAddPipeline,
}) => {
  const queryClient = useQueryClient();

  const {mutateAsync} = useMutation({
    mutationFn: (pipeline: PipelineModel) => {
      const workspaceService = new WorkspaceService();
      return workspaceService.updatePipeline(workspaceId, pipeline.id, {
        // name: pipeline.name,
        // schedule: pipeline.schedule,
        isEnabled: !pipeline.isEnabled,
      });
    },
    onSuccess: (data, _pipeline) => {
      // queryClient.invalidateQueries(['pipelines', workspaceId]);
      queryClient.setQueryData(['pipelines', workspaceId], (old: any) => ({
        data: old.data.map((pipeline: any) =>
          pipeline.id === _pipeline.id
            ? {..._pipeline, isEnabled: !_pipeline.isEnabled}
            : pipeline
        ),
      }));
    },
  });

  const {mutateAsync: deletePipelines} = useMutation({
    mutationFn: (pipelineIds: string[]) => {
      const workspaceService = new WorkspaceService();
      return workspaceService.deletePipelines(workspaceId, pipelineIds);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['pipelines', workspaceId]);
      // queryClient.fetchQuery(['pipelines', workspaceId]);
    },
  });

  const handleRunPipeline = useCallback(
    async (pipeline: PipelineModel) => {
      const pipelineService = new PipelineService();
      await pipelineService.run(workspaceId, pipeline.id);
      await queryClient.invalidateQueries(['pipelines', workspaceId]);
    },
    [queryClient, workspaceId]
  );

  const handleToggle = useCallback(
    async (pipeline: PipelineModel) => {
      return await mutateAsync(pipeline);
    },
    [mutateAsync]
  );

  const handleDelete = useCallback(
    (pipelineIds: string[]) => {
      return deletePipelines(pipelineIds);
    },
    [deletePipelines]
  );

  return (
    <PipelineList
      workspaceId={workspaceId}
      pipelines={pipelines}
      isLoading={isLoading}
      onClick={onClick}
      onEnabledToggle={handleToggle}
      onRunPipeline={handleRunPipeline}
      onDelete={handleDelete}
      onAddPipeline={onAddPipeline}
    />
  );
};

export default PipelineListContainer;
