import Button from '@components/Button';
import Tabs from '@components/Tabs';
import Tab from '@components/Tabs/Tab';
import PipelineCatalogEditor from '@features/workspaces/components/PipelineCatalogEditor';
import PipelineLogs from '@features/workspaces/components/PipelineLogs';
import {PipelineModel} from '@features/workspaces/pipeline.model';
import React, {useCallback, useEffect, useState} from 'react';
import PipelineDetails from '../PipelineDetails';
import {
  UpdatePipelineKeys,
  UpdatePipelineValues,
} from './PipelineDetailsScreen.container';
import * as Styled from './PipelineDetailsScreen.styles';

type PipelineDetailsScreenProps = {
  workspaceId: string;
  pipeline: PipelineModel;
  onGoBack?: () => any;
  onSave: (
    property: UpdatePipelineKeys
  ) => (value: UpdatePipelineValues) => Promise<any>;
};

const PipelineDetailsScreen: React.VFC<PipelineDetailsScreenProps> = ({
  pipeline,
  workspaceId,
  onGoBack,
  onSave,
}) => {
  const [catalog, setCatalog] = useState(pipeline.catalog);
  const [isSavingCatalog, setIsSavingCatalog] = useState(false);

  const handleCatalogSave = useCallback(async () => {
    setIsSavingCatalog(true);
    try {
      await onSave('catalog')(catalog);
      setIsSavingCatalog(false);
    } catch (e) {
      setIsSavingCatalog(false);
    }
  }, [catalog, onSave]);

  useEffect(() => {
    setCatalog(pipeline.catalog);
  }, [pipeline]);

  return (
    <Styled.Wrapper>
      <Styled.ActionBar>
        <Styled.Title>
          Pipeline <em>{pipeline.name}</em>
        </Styled.Title>
        {onGoBack && (
          <Button variant="secondary" onClick={onGoBack} icon="arrow_back">
            Go Back to Pipeline List
          </Button>
        )}
      </Styled.ActionBar>
      <Tabs>
        <Tab title="Details">
          <PipelineDetails
            pipeline={pipeline}
            workspaceId={workspaceId}
            onSave={onSave}
          />
        </Tab>
        <Tab title="Catalog">
          <Styled.ActionBar style={{marginBottom: '16px'}}>
            <Button
              onClick={handleCatalogSave}
              isLoading={isSavingCatalog}
              disabled={pipeline.catalog === catalog}
            >
              Save changes
            </Button>
          </Styled.ActionBar>
          <PipelineCatalogEditor
            onChange={setCatalog}
            catalog={catalog}
            connector={pipeline.source}
          />
        </Tab>
        <Tab title="Executions">
          <PipelineLogs
            pipeline={pipeline}
            workspaceId={workspaceId}
            onGoBack={onGoBack}
          />
        </Tab>
      </Tabs>
    </Styled.Wrapper>
  );
};

export default PipelineDetailsScreen;
