import StatusBadge from '@components/StatusBadge';
import Table from '@components/Table';
import billingStatusMap from '@features/billing/billing-status-map';
import {BillingStatus} from '@features/billing/billing-status.enum';
import companyStatusMap from '@features/companies/company-status-map';
import {CompanyModel} from '@features/companies/company.model';
import {roundToHundreds} from '@utils/number.utils';
import {format} from 'date-fns';
import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import * as Styled from './CompanyList.styles';

type CompanyListProps = {
  companies?: CompanyModel[];
};

const CompanyList: React.FC<CompanyListProps> = ({companies = []}) => {
  const history = useHistory();

  const handleClick = useCallback(
    (companyId: string) => {
      history.push(`/companies/${companyId}`);
    },
    [history]
  );

  return (
    <Styled.Wrapper>
      <Table
        data={companies}
        onRowClick={(row) => handleClick(row.id)}
        columnDefinitions={[
          {title: 'Name', accessor: (row) => <strong>{row.name}</strong>},
          {
            title: 'Status',
            compact: true,
            accessor: (row) => (
              <StatusBadge statusMap={companyStatusMap} value={row.status} />
            ),
          },
          {
            title: 'Billing Status',
            compact: true,
            accessor: (row) => (
              <StatusBadge
                statusMap={billingStatusMap}
                value={row.billingStatus || BillingStatus.INCOMPLETE}
              />
            ),
          },
          {
            title: 'Projects',
            compact: true,
            accessor: (row) => <strong>{row.workspaceCount}</strong>,
          },
          {
            title: 'Last 2 month cost',
            compact: true,
            accessor: (row) => (
              <>
                {typeof row.previousMonthCosts === 'undefined' ? (
                  '-'
                ) : (
                  <>
                    <strong>${roundToHundreds(row.previousMonthCosts)}</strong>
                  </>
                )}
                &nbsp;/&nbsp;
                {typeof row.currentMonthCosts === 'undefined' ? (
                  '-'
                ) : (
                  <>
                    <strong>${roundToHundreds(row.currentMonthCosts)}</strong>
                  </>
                )}
              </>
            ),
          },
          {
            title: 'Created at',
            compact: true,
            accessor: (row) => format(new Date(row.createdAt), 'MMM d, yyyy'),
          },
        ]}
      />
    </Styled.Wrapper>
  );
};

export default CompanyList;
