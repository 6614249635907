import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Inner = styled.div`
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  /* background: #fff; */
  padding: 16px;
  border-radius: 8px;
`;

export const Title = styled.h4`
  font-size: 16px;
  margin: 0 0 8px;
`;

export const BillingStatusBadge = styled.div`
  margin-bottom: 16px;
`;

export const TrialPeriodEnd = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 700;

  .title {
    font-size: 14px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.mediumGray};
    margin-bottom: 5px;
  }
`;

export const ActionBar = styled.div`
  margin-top: 16px;
`;
