import ResourceLink from '@components/ResourceLink';
import Table from '@components/Table';
import {PlanModel} from '@features/plans/plan.model';
import React from 'react';
import * as Styled from './PlanList.styles';

type PlanListProps = {
  plans: PlanModel[];
};

const PlanList: React.FC<PlanListProps> = ({plans}) => {
  return (
    <Styled.Wrapper>
      <Table
        data={plans}
        columnDefinitions={[
          {
            title: 'Name',
            accessor: (row) => (
              <ResourceLink to={`/plans/${row.id}`}>{row.name}</ResourceLink>
            ),
          },
          {title: 'Max project count', accessor: 'maxProjectCount'},
          {
            title: 'Price per month',
            accessor: (row) => <strong>${row.price}</strong>,
          },
          {
            title: 'Trial period days',
            accessor: (row) =>
              row.trialPeriodDays
                ? `${row.trialPeriodDays} days`
                : 'Not included',
          },
        ]}
      />
    </Styled.Wrapper>
  );
};

export default PlanList;
