import {useAppDispatch} from '@app/store';
import {getProfile} from '@features/auth/auth.slice';
import {CompaniesService} from '@features/companies/companies.service';
import {CreateManagedCompanyDto} from '@features/companies/dto/create-managed-company.dto';
import {useMutation, useQueryClient} from 'react-query';

const useCreateManagedCompany = (parentId: string) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const {mutateAsync, isLoading, error, status} = useMutation({
    mutationFn: async (data: CreateManagedCompanyDto) => {
      const companiesService = new CompaniesService();
      return companiesService.createManagedCompany(parentId, {
        ...data,
        inviteEmails:
          data.inviteEmails && data.inviteEmails.filter((item) => !!item),
      });
    },
    onSuccess: (data) => {
      dispatch(getProfile());
      queryClient.invalidateQueries(['managed-companies', parentId]);
    },
  });

  return {
    createManagedCompany: mutateAsync,
    isLoading,
    error,
    status,
  };
};

export default useCreateManagedCompany;
