import {useQuery} from 'react-query';
import {WorkspaceService} from '../workspace.service';

const useWorkspaces = (companyId?: string) => {
  const {data, isLoading} = useQuery({
    queryKey: ['projects', companyId],
    refetchInterval: 30000,
    refetchOnMount: true,
    enabled: !!companyId,
    queryFn: () => {
      const workspaceService = new WorkspaceService();
      return workspaceService.getAll();
    },
  });

  return {
    workspaces: data?.data || [],
    isLoading,
  };
};

export default useWorkspaces;
